import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//CONFIG
import categories from "../Search/categories.json";

//API
import Products from "../../../../common/api/Products";

//COMPONENTS
import ContentList from "../../../core/ContentList";
import SearchProduct from "../../products/SearchProduct";
import SearchOptions from "../../../core/search/SearchOptions";
import SuccessBtn from "../../../core/buttons/SuccessBtn";

function Index(props) {
  const dispatch = useDispatch();
  const page_content = useSelector((state) =>
    state.page_content_reducer.page == "makeACrate"
      ? state.page_content_reducer
      : null
  );

  if (!page_content) {
    Products.search_products({
      query: null,
      category: "Items",
      order_by: "Relevance",
    });
  }

  return (
    <div>
      <SearchOptions
        className="mainPadding"
        onChange={(value) =>
          Products.search_products(
            Object.assign({}, page_content, { order_by: value })
          )
        }
        query={{
          title: page_content.category
            ? page_content.category
            : page_content.query,
          color: page_content.category
            ? categories.find((x) => x.title == page_content.category).color
            : "white",
        }}
        order_by={page_content ? page_content.order_by : null}
        options={[
          { value: "Relevance", name: "Relevance" },
          { value: "Price: Low - High", name: "Price: Low" },
          { value: "Price: High - Low", name: "Price: High" },
        ]}
        children={
          <div style={{ margin: "auto", marginRight: "0" }}>
            <Link to="/unbox">
              <SuccessBtn context={"Continue to Crate"} />
            </Link>
          </div>
        }
      />
      <div className="mainPadding">
        <ContentList
          content={
            page_content
              ? page_content.data.map((x) => <SearchProduct product={x} />)
              : []
          }
        />
      </div>
    </div>
  );
}

export default Index;
