export default {
  parse_from: (params) => {
    return params
      ? Object.keys(params).reduce(
          (prev, next) =>
            Object.assign({}, prev, { [next]: replaceValue(params[next]) }),
          {}
        )
      : null;
  },
  parse_to: (url, params) => {
    return (
      url + params.reduce((prev, next) => prev + ("/" + replaceValue(next)), "")
    );
  },

  //WITHOUT ESCAPE VALUES
  parse_with_params: (url, params) => {
    return (
      url +
      (params
        ? "?" +
          params
            .map((x) => x.name + "=" + encodeURIComponent(x.value))
            .join("&")
        : "")
    );
  },

  //WITH ESCAPE VALUES
  parse_with_escape_params: (url, params) => {
    return (
      url +
      (params
        ? "?" +
          params
            .map(
              (x) => x.name + "=" + encodeURIComponent(replaceValue(x.value))
            )
            .join("&")
        : "")
    );
  },
};

function replaceValue(value) {
  switch (value) {
    //TO
    case "none":
      return null;

    //FROM
    case null:
      return "none";
    case undefined:
      return "none";
    default:
      return value;
  }
}
