//STORE
import store from "../../redux/store";

//API
import verify_code from "./server/verify_code";
import add_card_intent from "./server/add_card_intent";
import add_crypto_order from "./server/add_crypto_order";
import get_transaction from "./server/get_transaction";
import get_crypto_exchange_rate from "./server/get_crypto_exchange_rate";
import search_transactions from "./server/search_transactions";

export default {
  verify_code: async (code) => {
    return await verify_code(code);
  },
  get_checkout_code: async (code) => {
    let { data, error } = await verify_code(code);

    store.dispatch({
      type: "UPDATE_PAGE_CONTENT",
      content: { applied_code: data },
    });
  },
  add_card_intent: async (checkout) => {
    return await add_card_intent(checkout);
  },
  add_crypto_order: async (obj) => {
    return await add_crypto_order(obj);
  },
  get_transaction: async (obj) => {
    return await get_transaction(obj);
  },
  search: async (obj) => {
    var data = await search_transactions(obj);

    return data;
  },
};
