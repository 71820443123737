import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../common/backgrounds/Background";

export default function (props) {
  return (
    <div className="FloatingBar" style={props.style}>
      <section
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        {props.children}
      </section>
    </div>
  );
}
