import React from "react";
import { useState } from "react";
import {
  CardElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import stripe from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import store from "../../../../../common/redux/store";

import Row from "./prebuilt/Row";
import BillingDetailsFields from "./prebuilt/BillingDetailsFields";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";

//API
import Transactions from "../../../../../common/api/Transactions";

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 0px;
  }
`;

const CheckoutForm = ({ price, checkout, order, onSuccessfulCheckout }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();

  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
      },
    };

    setProcessingTo(true);

    const cardElement = elements.getElement("card");

    try {
      console.log(checkout);

      const data = await Transactions.add_card_intent(
        Object.assign({}, checkout, { order })
      );

      console.log(data);

      /*let payment = {
        clientSecret: res.data.client_secret,
        transaction_id: res.data.transaction_id,
        cardElement,
        billingDetails,
      };*/

      let clientSecret = data.client_secret;
      let transaction_id = data.transaction_id;

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const payment = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (payment.error) {
        setCheckoutError(payment.error.message);
        setProcessingTo(false);
        return;
      }

      let balance = store.getState().account_balance_reducer;

      let value = parseFloat(
        (
          price *
          (checkout.applied_code ? checkout.applied_code.bonus_multiplier : 1)
        ).toFixed(2)
      );

      onSuccessfulCheckout(transaction_id);
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  // Learning
  // A common ask/bug that users run into is:
  // How do you change the color of the card element input text?
  // How do you change the font-size of the card element input text?
  // How do you change the placeholder color?
  // The answer to all of the above is to use the `style` option.
  // It's common to hear users confused why the card element appears impervious
  // to all their styles. No matter what classes they add to the parent element
  // nothing within the card element seems to change. The reason for this is that
  // the card element is housed within an iframe and:
  // > styles do not cascade from a parent window down into its iframes

  const iframeStyles = {
    base: {
      color: "white",
      background: "var(--background)",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "rgb(193, 193, 193)",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "red",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div style={{ maxWidth: "550px", margin: "auto" }}>
        <Row>
          <BillingDetailsFields />
        </Row>
        <Row>
          <CardElementContainer>
            <CardElement
              options={cardElementOpts}
              onChange={handleCardDetailsChange}
            />
          </CardElementContainer>
        </Row>
      </div>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
        {/* TIP always disable your submit button while processing payments */}
        <SubmitButton disabled={isProcessing || !stripe}>
          {isProcessing ? "Processing..." : `Pay $${price}`}
        </SubmitButton>
      </Row>
    </form>
  );
};

export default CheckoutForm;
