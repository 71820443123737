import React, { Component } from "react";
import "./style.css";

import { Link } from "react-router-dom";

//FUNCTIONS
import getProductRank from "../../../../../common/functions/getProductRank";

//COMPONENTS
import ProductRank from "../../../products/ProductRank";
import ProfileImg from "../../../account/ProfileImg";
import Background from "../../../../common/backgrounds/Background";

var colorRank = "";

class LiveWinProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productRank: null,
      productStyling: {},
      productImgStyling: {},
    };
  }

  componentDidMount() {
    var productStyle = {
      backgroundSize: "contain",
      width: "100%",
      height: "100%",
    };

    var productImgStyle = {
      position: "relative",
      maxWidth: "100%", //"50px",
      maxHeight: "100%", //"40px",
      borderRadius: "5px",
      margin: "auto",
    };

    this.setState({
      productStyling: Object.assign({}, productStyle, { margin: "auto" }),
      productImgStyling: productImgStyle,
    });
  }

  render() {
    return (
      <li
        className="liveWinProductWrapper"
        style={{
          borderLeft:
            "4px solid var(--" +
            getProductRank(this.props.live_win.price) +
            ")",
          width: this.props.show ? "80px" : "0px",
        }}
      >
        <Background
          style={{
            background:
              "var(--" + getProductRank(this.props.live_win.price) + ")",
            opacity: "0.2",
          }}
        />
        <Link to={"/unboxing/" + this.props.live_win.id}>
          <div
            className={"liveWinProduct" + (this.props.show ? " show" : "")}
            style={this.state.productStyling}
          >
            <div
              className="liveWinFront"
              style={{
                borderRadius: "8px",
              }}
            >
              <section className="liveWinProductBackground">
                <ProductRank product={this.props.live_win} />
              </section>
            </div>
            <div className="liveWinBack">
              <div className="liveWinProductUserInfo">
                <ProfileImg user={this.props.live_win} />
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }
}

const productImgStyling = {
  position: "absolute",
  width: "50%",
  height: "50%",
};

export default LiveWinProduct;
