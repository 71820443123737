import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../common/config/env.config";

//API
import Unboxings from "../../../common/api/Unboxings";

//STYLES
import "./style.css";

//COMPONENTS
import Product from "./elements/Product";
import ShowBtn from "./elements/showBtn";
import Icon from "../../core/Icon";
import FlexArea from "../../layout/flex/FlexArea";
import Background from "../../common/backgrounds/Background";

export default (props) => {
  const initMountRef = useRef(true);

  const dispatch = useDispatch();
  const live_wins = useSelector((state) => state.live_wins_reducer);
  const show_livewins = useSelector((state) => state.show_livewins_reducer);

  useEffect(() => {
    Unboxings.get_recent();
  }, []);

  useEffect(() => {
    let root = document.documentElement;

    if (show_livewins) {
      root.style.setProperty("--leftOffset", "84px");
    } else {
      root.style.setProperty("--leftOffset", "4px");
    }
  }, [show_livewins]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    if (!initMountRef) {
      if (screenWidth <= 800) {
        if (show_livewins) {
          dispatch({ type: "HIDE_LIVEWINS" });
        }
      } else {
        dispatch({ type: "SHOW_LIVEWINS" });
      }
    } else {
      initMountRef.current = false;
    }
  }, [screenWidth]);

  return (
    <>
      <div
        id="LiveWins"
        style={
          show_livewins
            ? { width: "84px" }
            : { position: "sticky", width: "4px" }
        }
      >
        <div className="inner">
          {show_livewins ? (
            <div
              style={{
                position: "relative",
                textAlign: "left",
                boxSizing: "border-box",
                padding: "3px",
                boxSizing: "border-box",
              }}
            >
              <Background
                style={{
                  background: "var(--secondaryBackground)",
                }}
              />
              <FlexArea
                children={
                  <>
                    <Icon
                      style={{
                        width: "15px",
                        height: "15px",
                        display: "inline-block",
                        verticalAlign: "middle",
                        margin: "auto",
                        marginRight: "5px",
                      }}
                      src={envConfig.s3 + "/images/livewins/crown.svg"}
                    />
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        verticalAlign: "middle",
                        margin: "auto",
                        marginLeft: "0",
                      }}
                    >
                      Wins
                    </span>
                  </>
                }
              />
            </div>
          ) : null}
          {live_wins?.map((x, i) => (
            <div key={x.unboxing_id}>
              <Product live_win={x} show={show_livewins} />
            </div>
          ))}
        </div>
      </div>
      <ShowBtn />
    </>
  );
};
