import React, { useState } from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Section from "./elements/section";
import FlexGrid from "../../layout/flex/FlexGrid";
import Btn from "../../core/buttons/Btn";

function Index(props) {
  return (
    <div className="ExpandInfo" id="ExpandInfo" style={props.style}>
      {props.elements.map((x, i) => (
        <div style={{ marginTop: "30px" }}>
          <h2>{x.title}</h2>
          {x.sections.map((y, j) => (
            <Section title={y.title} text={y.text} />
          ))}
        </div>
      ))}
    </div>
  );
}

export default Index;
