import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import store from "../../../../common/redux/store";

//COMPONENTS
import Popup from "../Popup";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }
  setRedirect = () => {
    let last_route = store.getState().last_route_reducer;
    this.setState({ redirect: last_route });
  };

  render() {
    if (this.state.redirect) {
      store.dispatch({ type: "CLOSE_POPUP" });
      return <Navigate to={this.state.redirect} replace />;
    }
    return <Popup onClose={this.setRedirect} {...this.props} />;
  }
}
