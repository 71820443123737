import React, { useEffect, createRef, useState } from "react";
import { useSelector } from "react-redux";

//FUNCTIONS
import DetectSizeChange from "../../../common/functions/DetectSizeChange";

//STYLES
import "./style.css";

//COMPONENTS
import ContentList from "../../../core/ContentList";
import RecentWin from "./Product";
import FlexGrid from "../../../layout/flex/FlexGrid";
import Product from "../../LiveWins2/elements/Product";
import Background from "../../../common/backgrounds/Background";
import BiggestWins from "./elements/BiggestWins";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const WinsRef = createRef();
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);
  const [vertLimit, setVertLimit] = useState(1);

  const { width, height } = DetectSizeChange(WinsRef);

  useEffect(() => {
    const titleSize = 24; //px
    setVertLimit(width >= 560 ? Math.floor((height - titleSize) / 100) : 1);
  }, [height, width]);

  return crate_inventory.crate ? (
    <div id="RecentWins" ref={WinsRef}>
      {crate_inventory.crate ? (
        <div
          style={{
            position: "relative",
            background: "transparent",
            height: "100%",
            width: "100%",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            borderLeft: "0",
            borderRight: "0",
          }}
        >
          <div style={{ position: "relative" }} className="mainPadding">
            <div
              style={{
                position: "relative",
                margin: "auto",

                boxSizing: "border-box",
              }}
            >
              <h2>Crate Stats</h2>
              <FlexGrid
                style={{
                  minWidth: "250px",
                  overflow: "hidden",
                  justifyContent: "stretch",
                }}
                children={[
                  {
                    style: {
                      flex: "1 0 50%",
                      overflow: "hidden",
                      minWidth: "350px",
                      padding: "5px",
                    },
                    element: (
                      <div style={{ position: "relative", height: "100%" }}>
                        <h3 style={{ margin: "0", marginBottom: "5px" }}>
                          Biggest
                        </h3>
                        <div style={{ position: "relative" }}>
                          <Background
                            style={{
                              background: "var(--highlighted)",
                              border: "1px solid transparent",
                              borderRadius: "8px",
                            }}
                          />
                          {crate_inventory.crate.best_wins.length ? (
                            <BiggestWins
                              wins={crate_inventory.crate.best_wins}
                            />
                          ) : null}
                        </div>
                      </div>
                    ),
                  },
                  {
                    style: {
                      flex: "1 0 50%",
                      minWidth: "250px",
                      overflow: "hidden",
                      padding: "5px",
                    },
                    element: (
                      <div style={{ position: "relative", height: "100%" }}>
                        <h3 style={{ margin: "0", marginBottom: "5px" }}>
                          Recent
                        </h3>
                        <div style={{ position: "relative", height: "100%" }}>
                          <Background
                            style={{
                              background: "var(--secondaryBackground)",
                              borderRadius: "8px",
                            }}
                          />
                          {crate_inventory.crate.last_unboxings.length ? (
                            <FlexArea
                              children={
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    margin: "auto",
                                    padding: "10px",
                                  }}
                                >
                                  <ContentList
                                    style={{
                                      overflow: "hidden",
                                      padding: "0",
                                      maxHeight: "240px",
                                      maxWidth: "600px",
                                    }}
                                    content={crate_inventory.crate.last_unboxings.map(
                                      (x) => (
                                        <RecentWin live_win={x} />
                                      )
                                    )}
                                  />
                                </div>
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};
