import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import style from "./style";

//FUNCTIONS
import DateTimeFunctions from "../../../../common/functions/DateTime.Functions";

//COMPONENTS
import XP from "./elements/XP";
import ProfileImg from "../../../content/account/ProfileImg";
import FlexGrid from "../../../layout/flex/FlexGrid";
import Background from "../../../common/backgrounds/Background";
import Btn from "../../../core/buttons/Btn";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import FileUpload from "../../../core/buttons/FileUpload";
import FlexArea from "../../../layout/flex/FlexArea";
import ImageSelect from "../ImageSelect";

function Index(props) {
  const dispatch = useDispatch();
  let user = props.user;

  function changeImage(img, i) {
    console.log(img + ": " + i);
  }

  return user ? (
    <div style={Object.assign({}, props.style, style.profile_section_wrapper)}>
      <div style={style.profile_section}>
        <div style={style.inner}>
          <FlexGrid
            children={[
              {
                style: { flex: "0" },
                element: (
                  <div style={style.profile_img}>
                    <ProfileImg user={user} />
                    {props.can_edit ? (
                      <section
                        style={{
                          position: "absolute",
                          display: "flex",
                          bottom: "0",
                          right: "0",
                          left: "0",
                        }}
                      >
                        <SuccessBtn
                          style={{ margin: "auto", fontSize: "0.9rem" }}
                          context={"Change"}
                          onClick={() =>
                            dispatch({
                              type: "NEW_POPUP",
                              element: <ImageSelect onSuccess={changeImage} />,
                            })
                          }
                        />
                      </section>
                    ) : null}
                  </div>
                ),
              },
              {
                style: { flex: "1", margin: "auto" },
                element: (
                  <div style={style.info}>
                    <h2 style={style.username}>{unescape(user.username)} </h2>

                    <XP {...props} show_remaining={true} />
                    {props.options ? (
                      <div style={style.bottom}>{props.options}</div>
                    ) : null}
                    {props.children ? props.children : null}
                  </div>
                ),
              },
              props.withDeposit
                ? {
                    style: { margin: "auto" },
                    element: (
                      <div style={style.info}>
                        <Link
                          to={"/checkout?code=" + props.user.affiliate_code}
                        >
                          <Btn
                            children={
                              <div>
                                <FlexGrid
                                  children={[
                                    {
                                      element: (
                                        <img
                                          src={
                                            envConfig.s3 +
                                            "/images/icons/account/deposit.svg"
                                          }
                                          style={{
                                            width: "40px",
                                            marginRight: "10px",
                                          }}
                                        />
                                      ),
                                    },
                                    {
                                      element: (
                                        <FlexArea
                                          style={{ position: "relative" }}
                                          children={
                                            <span
                                              style={{
                                                fontSize: "0.8rem",
                                                margin: "auto",
                                                textAlign: "left",
                                              }}
                                            >
                                              Deposit With <br />
                                              Users Code
                                            </span>
                                          }
                                        />
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            }
                          />
                        </Link>
                      </div>
                    ),
                  }
                : {},
            ]}
          />
        </div>
      </div>
    </div>
  ) : null;
}

export default Index;
