const unboxing_carousel_reducer = (state = null, action) => {
  switch (action.type) {
    case "POPULATE_UNBOXING_CAROUSEL":
      return action.carousel;
      break;
    case "UPDATE_UNBOXING_CAROUSEL":
      return Object.assign({}, state, action.carousel);
    default:
      return state;
  }
};

export default unboxing_carousel_reducer;
