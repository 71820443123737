const cryptojs = require("crypto-js");

exports.calculate = (serverSeed, clientSeed, nonce) =>
  getRandomRollValue(serverSeed, clientSeed, nonce);

function getCombinedSeed(serverSeed, clientSeed, nonce) {
  return [serverSeed, clientSeed, nonce].join("-");
}

function getRandomRollValue(serverSeed, clientSeed, nonce) {
  const min = 0;
  const max = 99_999_99;

  const rollValue = getRandomIntValue(serverSeed, clientSeed, nonce, max - min);

  // Get random integer
  return rollValue + min;
}

function getRandomIntValue(serverSeed, clientSeed, nonce, maxNumber) {
  // Generate seed and hash
  const seed = getCombinedSeed(serverSeed, clientSeed, nonce);
  /*const hash = crypto.createHmac("sha256", seed).digest("hex");*/
  const hash = cryptojs.algo.HMAC.create(cryptojs.algo.SHA256, seed)
    .finalize()
    .toString(cryptojs.enc.Hex);

  // Get value from hash
  const subHash = hash.substr(0, 8);
  const valueFromHash = parseInt(subHash, 16);

  // Get dynamic result for this roll
  return (Math.abs(valueFromHash) % maxNumber) / 100000;
}
