import React from "react";
import { useParams, useLocation } from "react-router-dom";
import store from "../../../common/redux/store";

//FUNCTIONS
import URLFunctions from "../../../common/functions/URL.functions";

export default (props) => {
  const location = useLocation();
  const searchParams = {};

  // Convert URLSearchParams to an object
  for (const [key, value] of new URLSearchParams(location.search).entries()) {
    try {
      searchParams[key] = JSON.parse(value);
    } catch (e) {
      searchParams[key] = value;
    }
  }

  const params = URLFunctions.parse_from(
    Object.assign(
      {},
      useParams(),
      props?.page?.props,
      props?.popup_page?.props,
      searchParams
    )
  );

  delete params.params;
  const page = Object.assign({}, props.page, {
    props: { params: props.popup_page ? {} : params },
  });

  const popup_page = Object.assign({}, props.popup_page, {
    props: { params },
  });

  if (props.popup_page) {
    store.dispatch({ type: "NEW_POPUP", element: popup_page });
  }

  return page;
};
