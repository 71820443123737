import React from "react";

//CONFIG
import envConfig from "../../../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../../../../../../../common/backgrounds/Background";
import FlexArea from "../../../../../../../../layout/flex/FlexArea";

export default (props) => {
  return (
    <div className="Stage" style={{ color: props.color }}>
      {!props.is_end_stage ? (
        <FlexArea
          style={{
            position: "absolute",
            inset: "0",
            left: "50%",
          }}
          children={
            <div
              className="line"
              style={{
                color: props.is_reached ? props.color : "white",
              }}
            />
          }
        />
      ) : null}
      <div
        className="inner"
        style={props.is_reached ? { color: props.color } : null}
      >
        <Background
          style={{
            background: "var(--secondaryBackground)",
          }}
        />
        <div
          style={{
            position: "relative",
            display: "inline-block",
            height: "80%",
            width: "100%",
          }}
        >
          <FlexArea
            children={
              <img
                src={
                  envConfig.s3 +
                  "/images/icons/order/tracking/" +
                  props.stage.image
                }
                style={{ margin: "auto", maxWidth: "50%", maxHeight: "50%" }}
              />
            }
          />
        </div>
        <div
          style={{
            position: "relative",
            display: "inline-block",
            height: "20%",
            width: "100%",
          }}
        >
          <span>{props.stage.title}</span>
        </div>
      </div>
    </div>
  );
};
