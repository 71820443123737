import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//API
import Transactions from "../../../../../common/api/Transactions";

//FUNCTIONS
import { parseDateTime } from "../../../../../common/functions/ParseDateTime";
import GeneralFunctions from "../../../../../common/functions/GeneralFunctions";

//STYLE
import "./style.css";

//COMPONENTS
import PopupWithRedirect from "../../../../layout/popups/PopupWithRedirect";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Price from "../../../../core/Price";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";
import Confetti from "../../../../common/Countdown/Confetti";
import Icon from "../../../../core/Icon";
import Btn from "../../../../core/buttons/Btn";
import ProfileImg from "../../../account/ProfileImg";

export default (props) => {
  const dispatch = useDispatch();

  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    getTransaction(props.params.id);
  }, []);

  async function getTransaction(id) {
    const obj = await Transactions.get_transaction({ id });

    if (obj) {
      setTransaction(obj);
    }
  }

  console.log(transaction);

  return (
    <PopupWithRedirect
      title={"Your Purchase"}
      id="TransactionView"
      background={<Confetti />}
      children={
        transaction ? (
          <div>
            <div
              style={{
                position: "relative",
                boxSizing: "border-box",
              }}
            >
              <FlexGrid
                children={[
                  {
                    style: { flex: "1 0 100%", marginBottom: "10px" },
                    element: (
                      <div>
                        <FlexGrid
                          children={[
                            {
                              style: {},
                              element: (
                                <h3
                                  style={{
                                    fontWeight: "normal",
                                    margin: "0",
                                  }}
                                >
                                  Thank you for your purchase
                                </h3>
                              ),
                            },
                            {
                              style: { marginLeft: "auto" },
                              element: (
                                <FlexArea
                                  children={
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "var(--FunctionGreen)",
                                        margin: "auto",
                                        marginRight: "0",
                                      }}
                                    >
                                      {parseDateTime(
                                        transaction.transaction_date
                                      )}
                                    </span>
                                  }
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    style: { flex: "1 0 100%", padding: "5px" },
                    element: (
                      <div className="amountWrapper">
                        <FlexArea
                          children={
                            <div
                              style={{
                                margin: "auto",
                                marginLeft: "0",
                                textAlign: "left",
                              }}
                            >
                              <div>
                                <span>
                                  Paid:{" "}
                                  <span
                                    style={{ color: "var(--FunctionGreen)" }}
                                  >
                                    $
                                    {GeneralFunctions.parse_currency(
                                      transaction.value
                                    )}
                                  </span>
                                </span>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <span>
                                  Received:{" "}
                                  <Price
                                    style={{ fontSize: "1.2rem" }}
                                    price={transaction.amount_received}
                                  />
                                </span>
                              </div>

                              <div style={{ marginTop: "10px" }}>
                                <div>
                                  <span>
                                    New Balance:{" "}
                                    <Price
                                      style={{ fontSize: "1.2rem" }}
                                      price={transaction.new_balance}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    style: { flex: "1 0 100%", padding: "5px" },
                    element: <h3 style={{ margin: "0" }}>Promo Code</h3>,
                  },
                  {
                    style: { flex: "1 0 100%", padding: "5px" },
                    element: transaction.code ? (
                      <div className="code highlighted">
                        <FlexGrid
                          style={{ justifyContent: "start" }}
                          children={[
                            {
                              style: { padding: "10px" },
                              element: (
                                <FlexArea
                                  children={
                                    <Link
                                      to={"/users/" + transaction.code.user_id}
                                    >
                                      <ProfileImg
                                        style={{
                                          margin: "auto",
                                          width: "80px",
                                          height: "80px",
                                        }}
                                        user={transaction.code}
                                      />
                                    </Link>
                                  }
                                />
                              ),
                            },
                            {
                              style: { padding: "10px" },
                              element: (
                                <div>
                                  <div style={{ marginBottom: "10px" }}>
                                    <h3 style={{ margin: "0" }}>
                                      {transaction.code.username}'s Code
                                    </h3>
                                  </div>
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "var(--FunctionGreen)",
                                      }}
                                    >
                                      {Math.floor(
                                        (transaction.code.bonus_multiplier -
                                          1) *
                                          100
                                      )}
                                      % Deposit Bonus
                                    </span>
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      <div className="code highlighted">
                        <FlexArea
                          children={
                            <div style={{ margin: "auto" }}>
                              <h4>No Code Used</h4>
                            </div>
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    style: { flex: "1 0 100%", padding: "5px" },
                    element: <h3 style={{ margin: "0" }}>Payment Method</h3>,
                  },
                  {
                    style: { flex: "1 0 100%", padding: "5px" },
                    element:
                      transaction.method.method == "card" ? (
                        <div
                          className="method highlighted"
                          style={{
                            border: "2px solid var(--card)",
                          }}
                        >
                          <FlexGrid
                            style={{
                              justifyContent: "start",
                              height: "100%",
                            }}
                            children={[
                              {
                                style: { padding: "10px" },
                                element: (
                                  <FlexArea
                                    children={
                                      <h3 style={{ margin: "auto" }}>Card</h3>
                                    }
                                  />
                                ),
                              },
                              {
                                style: { padding: "10px" },
                                element: (
                                  <FlexArea
                                    children={
                                      <Icon
                                        style={{
                                          width: "100px",
                                          height: "50px",
                                          margin: "auto",
                                        }}
                                        src={
                                          envConfig.s3 +
                                          "/images/icons/checkout/card_icon.svg"
                                        }
                                      />
                                    }
                                  />
                                ),
                              },
                              {
                                style: { padding: "10px" },
                                element: (
                                  <FlexArea
                                    children={
                                      <div style={{ margin: "auto" }}>
                                        <div>
                                          <span>
                                            Type:{" "}
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {transaction.method.type}
                                            </span>
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            Last 4:{" "}
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {transaction.method.last_4}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    }
                                  />
                                ),
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        <div
                          className="method highlighted"
                          style={{
                            border: "2px solid var(--crypto)",
                          }}
                        >
                          <FlexGrid
                            style={{ justifyContent: "start", height: "100%" }}
                            children={[
                              {
                                style: { padding: "10px" },
                                element: (
                                  <FlexArea
                                    children={
                                      <h3 style={{ margin: "auto" }}>Crypto</h3>
                                    }
                                  />
                                ),
                              },

                              {
                                style: { padding: "10px" },
                                element: (
                                  <FlexArea
                                    children={
                                      <Icon
                                        style={{
                                          width: "100px",
                                          height: "50px",
                                          margin: "auto",
                                        }}
                                        src={
                                          envConfig.s3 +
                                          "/images/icons/checkout/bitcoin.svg"
                                        }
                                      />
                                    }
                                  />
                                ),
                              },
                              {
                                style: { padding: "10px" },
                                element: (
                                  <FlexArea
                                    children={
                                      <div style={{ margin: "auto" }}>
                                        <span>
                                          Type:{" "}
                                          <span style={{ fontWeight: "bold" }}>
                                            {transaction.method.coin_type}
                                          </span>
                                        </span>
                                      </div>
                                    }
                                  />
                                ),
                              },
                            ]}
                          />
                        </div>
                      ),
                  },
                  {
                    style: {
                      flex: "1 0 100%",
                      marginTop: "5px",
                      marginBottom: "5px",
                    },
                    element: (
                      <FlexGrid
                        style={{ justifyContent: "left" }}
                        children={[
                          {
                            style: { padding: "5px" },
                            element: (
                              <Link to={"/"}>
                                <Btn
                                  style={{
                                    border: "2px solid var(--mainPurple)",
                                  }}
                                  children={
                                    <Background
                                      style={{
                                        borderRadius: "8px",
                                        background: "var(--mainPurple)",
                                        opacity: "0.2",
                                      }}
                                    />
                                  }
                                  context={"Browse Crates"}
                                />
                              </Link>
                            ),
                          },
                          {
                            style: { padding: "5px" },
                            element: (
                              <Link to={"/account/transactions"}>
                                <Btn
                                  style={{
                                    border: "2px solid var(--FunctionGreen)",
                                  }}
                                  children={
                                    <Background
                                      style={{
                                        borderRadius: "8px",
                                        background: "var(--FunctionGreen)",
                                        opacity: "0.2",
                                      }}
                                    />
                                  }
                                  context={"View Transactions"}
                                />
                              </Link>
                            ),
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        ) : null
      }
    />
  );
};
