import React from "react";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../../../../../core/buttons/Btn";
import FlexArea from "../../../../../../../layout/flex/FlexArea";
import Icon from "../../../../../../../core/Icon";

export default (props) => {
  return (
    <Link to={props.path}>
      <Btn
        className={"NavBtn" + (props.selected ? " selected" : "")}
        children={
          <FlexArea
            children={
              <div style={{ margin: "auto" }}>
                <Icon
                  src={envConfig.s3 + "/images" + props.image}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    verticalAlign: "middle",
                  }}
                />
                <span style={{ verticalAlign: "middle" }}>{props.title}</span>
              </div>
            }
          />
        }
      />
    </Link>
  );
};
