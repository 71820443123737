import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import Account from "../../../../../../../../common/api/Account";

//STYLES
import "./style.css";

//COMPONENTS
import Popup from "../../../../../../../layout/popups/Popup";
import Textbox from "../../../../../../../core/inputs/text/Textbox";

function Index(props) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);
  const [usernameTxt, setUsernameTxt] = useState("");
  const [error, setError] = useState(null);

  async function handleConfirm() {
    setError(null);

    if (Account.change_username({ username: usernameTxt })) {
      dispatch({ type: "CLOSE_POPUP" });
    } else {
      setError(true);
    }
  }

  return (
    <Popup
      id="ChangeUsername"
      title={"Change Username"}
      context={"Confirm Change"}
      onSuccess={handleConfirm}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        <div>
          <span>Username</span>
          <Textbox value={session.user.username} disabled={true} />
          <span>New Username</span>
          <Textbox
            value={usernameTxt}
            onChange={(e) => setUsernameTxt(e.target.value)}
          />
        </div>
      }
    />
  );
}

export default Index;
