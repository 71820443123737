import React, { Component } from "react";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//FUNCTIONS
import getProductRank from "../../../../common/functions/getProductRank";
import getVivacoinRank from "../../../../common/functions/getVivacoinRank";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../../common/backgrounds/Background";

class ProductRank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productWrapperStyling: null,
      productRank: null,
      vivacoinText: null,
      productImgBackgroundStyling: {},
      productStyling: {},
      productImgStyling: {},
      vivacoinTextSize: null,
    };

    this.vivacoinText = React.createRef();
  }

  componentDidMount() {
    var productStyle = {};
    if (this.props.product) {
      this.setState({
        width: this.productRank.clientHeight,
      });
      if (this.vivacoinText.current) {
        this.resizeVivacoinText(this.vivacoinText.current);
      }
    }
  }

  fitVivacoinText = () => {
    var divs = document.getElementsByClassName("flexFont");
    for (var i = 0; i < divs.length; i++) {
      var relFontsize = divs[i].offsetWidth * 0.05;
      divs[i].style.fontSize = relFontsize + "px";
    }
  };

  resizeVivacoinText = (vivacoinText) => {
    this.setState(
      {
        vivacoinTextSize: vivacoinText.offsetWidth * 0.5,
      },
      () => {
        console.log(this.state.vivacoinTextSize);
      }
    );
  };

  render() {
    if (this.props.product) {
      return (
        <div
          className="productRank"
          ref={(productRank) => (this.productRank = productRank)}
          style={Object.assign(
            {},
            {
              width: "100%",
            },
            this.props.style
          )}
        >
          {this.props.background == undefined ? (
            <Background
              style={{
                backgroundSize: "contain",
                backgroundImage:
                  "url(" +
                  envConfig.s3 +
                  "/images/" +
                  (this.props.product.type == "ITEM"
                    ? "product_backgrounds/"
                    : "vivacoin_product_backgrounds/") +
                  getProductRank(this.props.product.price) +
                  ".svg)",
                borderRadius: "8px",
                top: "2%",
                left: "2%",
                width: "96%",
                height: "96%",
                opacity: "1",
              }}
            />
          ) : null}
          {this.props.product.type == "ITEM" ? (
            <div className="productRankWrapperStyling">
              <section className="productRankImgBackgroundStyling">
                <img
                  className="productRankImgStyle"
                  src={
                    envConfig.s3 +
                    "/images/products/" +
                    (this.props.product.product_id
                      ? this.props.product.product_id
                      : this.props.product.id) +
                    ".webp"
                  }
                  onError={(ev) => {
                    ev.target.src =
                      envConfig.s3 + "/images/default_product.webp";
                  }}
                />
              </section>
            </div>
          ) : (
            <div className="productRankWrapperStyling">
              <section className="productRankImgBackgroundStyling">
                <img
                  className="productRankImgStyle"
                  src={
                    envConfig.s3 +
                    "/images/vivacoin_products/" +
                    (this.props.product.product_id
                      ? this.props.product.product_id
                      : this.props.product.id) +
                    ".webp"
                  }
                />
              </section>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ProductRank;
