import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import GlowImageBackground from "../../../../common/backgrounds/GlowImageBackground";
import Crate from "../../../crates/Crate";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";
import FlexGrid from "../../../../layout/flex/FlexGrid";

export default (props) => {
  return (
    <div id="FavouriteCrate">
      <FlexGrid
        style={{ flexDirection: "column-reverse" }}
        children={[
          {
            style: { flex: "1 0 100%" },
            element: props.profile.favourite_crate ? (
              <div className="inner">
                <GlowImageBackground
                  style={{
                    borderRadius: "8px",
                    border: "1px solid var(--highlighted)",
                  }}
                  image={
                    envConfig.s3 +
                    "/images/crates2/wallpapers/" +
                    props.profile.favourite_crate.id +
                    ".webp"
                  }
                />
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Background
                    style={{
                      background: "var(--background",
                      opacity: "0.5",
                      borderRadius: "8px",
                    }}
                  />
                  <FlexArea
                    children={
                      <div className="crate">
                        <Crate crate={props.profile.favourite_crate} />
                      </div>
                    }
                  />
                </div>
              </div>
            ) : null,
          },
          {
            style: { flex: "1 0 100%" },
            element: (
              <h4 style={{ marginBottom: "5px", marginTop: "0" }}>
                Favourite Crate
              </h4>
            ),
          },
        ]}
      />
    </div>
  );
};
