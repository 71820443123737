import store from "../../../../common/redux/store";

//FUNCTIONS
import ParseDateTime from "../../../../common/functions/ParseDateTime";

export default {
  parse_order_state: (order) => {
    if (order.status == "refunded") {
      return { status: "refunded" };
    }

    switch (order.order_type) {
      case "deliver":
        let not_confirmed_product = order.products.find(
          (x) => !x.date_arriving
        );

        //IF A PRODUCT IS NOT CONFIRMED YET
        if (not_confirmed_product) {
          return { status: "deliver_review" };
        } else {
          //GROUP PRODUCTS BY DELIVER/DELIVERED DATE
          let arriving_products = [...order.products].sort(
            (a, b) => new Date(a.date_arriving) - new Date(b.date_arriving)
          );

          //IF ALL PRODUCTS DELIVERED
          if (
            !order.products.find(
              (x) =>
                ParseDateTime.calculate_days(
                  new Date(),
                  ParseDateTime.adjustForTimezone(new Date(x.date_arriving))
                ) > 0
            )
          ) {
            return {
              status: "deliver_completed",
              arriving_groups: getArrivingGroups(arriving_products),
            };
          }

          arriving_products = [...order.products].sort(
            (a, b) => new Date(a.date_arriving) + new Date(b.date_arriving)
          );

          return {
            status: "deliver_confirmed",
            arriving_groups: getArrivingGroups(arriving_products),
          };
        }

        break;
      case "withdraw":
        if (order.method.status && order.method.status == "ordered") {
          return { status: "withdraw_confirmed" };
        } else {
          return { status: "withdraw_review" };
        }
        break;
      case "giftcard":
        if (order.status && order.status == "confirmed") {
          return { status: "giftcard_confirmed" };
        } else {
          return { status: "giftcard_review" };
        }

      default:
        return { status: "review" };
    }
  },
  parse_order_state_text: (order, current_status) => {
    let productsLists = groupBy(order.products, "date_arriving");

    let dates = Object.keys(productsLists);

    let first_products = order.products.filter((x) => {
      return x.date_arriving == dates[0];
    });

    switch (current_status) {
      case "refunded":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">Order was refunded.</h3>
            <h4 className="orderViewTrackingSubTitle">
              Your Items have been returned and shipping fees returned.
            </h4>
          </div>
        );
        break;

      case "deliver_review":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">
              We are processing your order...
            </h3>
            <h4 className="orderViewTrackingSubTitle">
              You will receive an email confirming your order shortly.
            </h4>
          </div>
        );
        break;
      case "deliver_confirmed":
        return (
          <div className="orderViewTrackingSection">
            {dates[0] !== "null" ? (
              <h3 className="orderViewTrackingTitle">
                {"Arriving " + ParseDateTime.parse(dates[0]) + "."}
              </h3>
            ) : (
              <h3 className="orderViewTrackingTitle">Order Confirmed</h3>
            )}
            <h4 className="orderViewTrackingSubTitle">
              Your Order is confirmed and on it's way.
            </h4>
          </div>
        );
        break;
      case "deliver_arriving_tomorrow":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">Arriving Tomorrow...</h3>
            <h4 className="orderViewTrackingSubTitle">
              Arriving tomorrow will be delivered before 10pm.
            </h4>
          </div>
        );
        break;
      case "deliver_delivery":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">Arriving Today...</h3>
            <h4 className="orderViewTrackingSubTitle">
              Arriving today will be delivered before 10pm.
            </h4>
          </div>
        );
        break;
      case "deliver_completed":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle"> Delivery Completed</h3>
            <h4 className="orderViewTrackingSubTitle">
              Items have been delivered
            </h4>
          </div>
        );
        break;

      case "withdraw_review":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">
              Transaction Being Reviewed.
            </h3>
            <h3 className="orderViewTrackingSubTitle">
              We will notify you by email when we complete the transaction.
            </h3>
          </div>
        );
        break;
      case "withdraw_confirmed":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">Transaction Confirmed.</h3>
            <h3 className="orderViewTrackingSubTitle">
              Sent {order.method.coin_value}
              {" " + order.method.coin_type}
            </h3>
          </div>
        );
      case "giftcard_review":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">
              Giftcard Request Being Reviewed.
            </h3>
            <h3 className="orderViewTrackingSubTitle">
              We will notify you by email with your giftcard.
            </h3>
          </div>
        );
        break;
      case "giftcard_confirmed":
        return (
          <div className="orderViewTrackingSection">
            <h3 className="orderViewTrackingTitle">Giftcard Confirmed.</h3>
            <h3 className="orderViewTrackingSubTitle">
              Sent {order.method.value} Giftcard to {order.method.email_address}
            </h3>
          </div>
        );
    }
  },

  getArrivingGroups: (products) => getArrivingGroups(products),
};

const getArrivingGroups = (products) => {
  let arriving_groups = {};

  products.forEach((x) => {
    if (arriving_groups[x.date_arriving]) {
      arriving_groups[x.date_arriving].push(x);
    } else {
      arriving_groups[x.date_arriving] = [x];
    }
  });

  return arriving_groups;
};

function groupBy(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}
