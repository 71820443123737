import { useState, useCallback, useEffect } from "react";

export default function UseWidth(elementRef, optionalHooks = []) {
  const [el_width, setWidth] = useState(null);
  const [el_height, setHeight] = useState(null);

  const updateSize = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width, height } = elementRef.current.getBoundingClientRect();
      if (width !== el_width) {
        setWidth(width);
      }
      if (height !== el_height) {
        setHeight(height);
      }
    }
  }, [elementRef]);

  useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize, ...optionalHooks]);

  return { width: el_width, height: el_height };
}
