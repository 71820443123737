import React, { Component } from "react";
import { connect } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

import categories from "./categories.json";

//FUNCTIONS
import Products from "../../../../common/api/Products";

//COMPONENTS
import FlexGrid from "../../../layout/flex/FlexGrid";
import OptionsList from "../../../../components/core/OptionsList";
import Search from "../../../core/search/SearchBar";

class index extends Component {
  render() {
    return this.props.page_content ? (
      <FlexGrid
        children={[
          {
            style: { flex: "1 0 70%" },
            element: (
              <OptionsList
                selected={
                  this.props.page_content
                    ? this.props.page_content.category
                    : null
                }
                selectOption={(category) =>
                  Products.search_products({
                    category,
                    query: null,
                    order_by: null,
                  })
                }
                options={categories}
              />
            ),
          },
          {
            style: {
              flex: "1 0 30%",
              marginBottom: "0",
              marginTop: "auto",
              minWidth: "200px",
            },
            element: (
              <div style={{ marginLeft: "auto" }}>
                <Search
                  context={"products"}
                  value={this.props.page_content.query}
                  key={this.props.page_content.query}
                  onSearch={(query) =>
                    Products.search_products({
                      category: null,
                      query,
                      order_by: null,
                    })
                  }
                />
              </div>
            ),
          },
        ]}
      />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    page_content:
      state.page_content_reducer.page == "makeACrate"
        ? state.page_content_reducer
        : null,
  };
};

export default connect(mapStateToProps)(index);
