import React from "react";

import "./style.css";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import SuccessBtn from "../../../../../../core/buttons/SuccessBtn";
import ListElement from "./elements/ListElement";
import Btn from "../../../../../../core/buttons/Btn";
import Icon from "../../../../../../core/Icon";

export default (props) => {
  function renderList(elements) {
    return elements.map((el) => {
      return {
        style: { flex: "1 0 50%", minWidth: "200px", marginBottom: "5px" },
        element: (
          <ListElement
            selectElement={() => props.selectAddress(el)}
            data={el}
            isSelected={el.id == props.selected?.id ? true : false}
          />
        ),
      };
    });
  }

  return (
    <div className="listWrapper highlighted">
      <FlexGrid
        style={{ flexDirection: "column", height: "100%" }}
        children={[
          {
            style: { width: "100%" },
            element: (
              <FlexGrid
                children={[
                  {
                    style: { flex: "1" },
                    element: (
                      <h3
                        style={{
                          margin: "0",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        Saved {props.addresses.length + "/5"}
                      </h3>
                    ),
                  },
                  {
                    style: { flex: "1", textAlign: "right" },
                    element: (
                      <Btn
                        style={{
                          border: "1px solid var(--FunctionGreen)",
                          background: "var(--background)",
                        }}
                        children={
                          <span>
                            <Icon
                              src={envConfig.s3 + "/images/icons/plus.svg"}
                              style={{ width: "15px", marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "middle" }}>
                              Add New
                            </span>
                          </span>
                        }
                      />
                    ),
                  },
                ]}
              />
            ),
          },

          {
            style: { flex: "1" },
            element: (
              <div
                className="highlighted"
                style={{
                  background: "var(--secondaryBackground)",
                  height: "100%",
                }}
              >
                <FlexGrid children={renderList(props.addresses)} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
