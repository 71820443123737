/*import { createStore } from "redux";
import allReducers from "./reducers/index.js";

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;*/

import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import allReducers from "./reducers";

const preloadedState = {};

const composedEnhancers = composeWithDevTools({ maxAge: 50 });

export default configureStore({
  middleware: [],
  reducer: allReducers,
  preloadedState,
  composedEnhancers,
});
