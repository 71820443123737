import API from "../../index";

export default async (obj) => {
  const { data, status } = await API.post("/unboxing/daily", {
    crate_id: obj.crate_id,
    unboxing_session: obj.unboxing_session,
  });

  return status == 201 ? data : null;
};
