import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../../common/backgrounds/Background";
import FlexArea from "../../../layout/flex/FlexArea";
import FlexGrid from "../../../layout/flex/FlexGrid";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import Btn from "../../../core/buttons/Btn";

function Index(props) {
  const session = useSelector((state) => state.session_reducer);
  return (
    <div id="Banner">
      <FlexArea
        children={
          <div id="inner" className="mainPadding">
            <div style={{ maxWidth: "600px" }}>
              <p
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  fontFamily: "Orbitron",
                }}
                className="title"
              >
                <span className="pre">
                  Welcome <br />
                  to
                </span>
                <span> Vivavim</span>
              </p>
              <h1 style={{ marginTop: "5px" }} className="subTitle">
                Unbox online mystery boxes to win amazing prizes
              </h1>
              <div>
                <div>
                  <FlexGrid
                    children={[
                      {
                        style: {
                          flex: "1 0 50%",
                          minWidth: "250px",
                          boxSizing: "border-box",
                          padding: "5px",
                        },
                        element: session ? (
                          <Link to="/checkout?value=10">
                            <SuccessBtn
                              style={{
                                fontSize: "1.2rem",
                                width: "100%",
                              }}
                              context={"Add Funds"}
                            />
                          </Link>
                        ) : (
                          <Link to="/sign-up">
                            <SuccessBtn
                              style={{
                                fontSize: "1.2rem",

                                width: "100%",
                              }}
                              context={"Sign Up"}
                            />
                          </Link>
                        ),
                      },
                      {
                        style: {
                          flex: "1 0 50%",
                          minWidth: "250px",
                          boxSizing: "border-box",
                          padding: "5px",
                        },
                        element: (
                          <Link to="/howItWorks">
                            <Btn
                              style={{
                                fontSize: "1.2rem",
                                background: "#0000008a",
                                border: "2px solid var(--FunctionGreen)",
                                width: "100%",
                              }}
                              context={"How it Works"}
                            />
                          </Link>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Index;
