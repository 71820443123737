import React, { useState } from "react";
import { useDispatch } from "react-redux";

//API
import Account from "../../../../../../../../common/api/Account";

//STYLES
import "./style.css";

//COMPONENTS
import Popup from "../../../../../../../layout/popups/Popup";
import Textbox from "../../../../../../../core/inputs/text/Textbox";

function Index(props) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState(null);

  async function handleConfirm() {
    setError(null);
    if (
      await Account.change_password({
        password: password,
        new_password: newPassword,
        confirm_password: confirmPassword,
      })
    ) {
      dispatch({ type: "CLOSE_POPUP" });
    } else {
      setError(true);
    }
  }

  return (
    <Popup
      id="ChangePassword"
      title={"Change Password"}
      context={"Confirm Change"}
      onSuccess={handleConfirm}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        <div>
          <span>Old Password</span>
          <Textbox
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <span>New Password</span>
          <Textbox
            value={newPassword}
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <span>Confirm Password</span>
          <Textbox
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
      }
    />
  );
}

export default Index;
