const last_route_reducer = (state = "/crates", action) => {
  switch (action.type) {
    case "SET_LAST_ROUTE":
      return action.last_route;
    default:
      return state;
  }
};

export default last_route_reducer;
