import React from "react";
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import GradientBackground from "../../../common/backgrounds/GradientBackground";

export default (props) => {
  function renderDefaultImage(ev) {
    ev.target.src = envConfig.s3 + "/images/users/default.svg";
  }
  return (
    <div className="ProfileImg" style={props.style}>
      <img
        className="img"
        style={{ borderRadius: "8px" }}
        src={
          props.user.profile_img
            ? envConfig.s3 +
              "/images/users/" +
              (props.user.user_id || props.user.id) +
              ".jpg" +
              (props.user.profile_img_datetime
                ? "?t=" + new Date(props.user.profile_img_datetime).getTime()
                : "")
            : envConfig.s3 +
              "/images/default_users/default" +
              props.user.default_image +
              ".webp"
        }
        onError={renderDefaultImage}
      />
    </div>
  );
};
