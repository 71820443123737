import API from "../../index";

export default async (obj) => {
  const { data, status } = await API.get(
    "/api/orders/search/" +
      obj.status +
      "/" +
      obj.ongoing +
      "/" +
      obj.order_by +
      "/" +
      obj.list_offset +
      "/" +
      obj.limit
  );

  return status == 201 ? data : null;
};
