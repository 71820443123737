import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import envConfig from "../../../../../common/config/env.config";

//API
import Inventory from "../../../../../common/api/Inventory";

//STYLES
import "./style.css";
import Background from "../../../../common/backgrounds/Background";

function Index(props) {
  const navigate = useNavigate();
  const config = useSelector((state) => state.config_reducer);
  let can_deliver = props.products.find((x) => x.type == "COIN") ? false : true;

  const value = props.products.reduce((a, b) => {
    return a + b.price;
  }, 0);

  function onOrder(method) {
    if (can_deliver) {
      switch (method) {
        case "deliver":
          if (props.onDeliver) {
            props.onDeliver();
          }

          navigate(
            "/order/deliver?products=" +
              encodeURIComponent(
                JSON.stringify(props.products.map((x) => x.id))
              )
          );
          break;
        case "giftcard":
          if (props.onWithdraw) {
            props.onWithdraw();
          }
          navigate(
            "/order/giftcard?products=" +
              encodeURIComponent(
                JSON.stringify(props.products.map((x) => x.id))
              )
          );
      }
    }
  }

  return (
    <div id="winningInventoryControlsWrapper">
      <section
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div id="winningInventoryControlsSection">
          <button
            id="winningInventoryConvertBtn"
            onClick={() => {
              Inventory.convert_products(props.products, () => {
                props.onConvert(props.products);
              });
              //if (props.onConvert) props.onConvert(props.products);
            }}
          >
            <div style={{ position: "relative" }}>
              <span style={{ verticalAlign: "middle", marginLeft: "2.5px" }}>
                Convert
              </span>
              <img
                id="convertWinningProduct"
                src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                style={{
                  height: "25px",
                  verticalAlign: "middle",
                  marginLeft: "4.5px",
                }}
              />
              <div style={{ verticalAlign: "middle", display: "inline-block" }}>
                <span style={{ marginLeft: "2.5px", fontWeight: "bold" }}>
                  {(value * (1 - config.conversion_fee / 100)).toFixed(2)}
                </span>
              </div>
            </div>
          </button>

          <button
            id="winningInventoryDeliverBtn"
            onClick={() => onOrder("deliver")}
            style={!can_deliver ? { opacity: "0.6" } : null}
          >
            <Background
              style={{ background: "var(--FunctionGreen)", opacity: "0.3" }}
            />
            <div style={{ position: "relative" }}>
              <img
                id="convertWinningProduct"
                src={envConfig.s3 + "/images/icons/account/orders.svg"}
                style={{
                  width: "45px",
                  verticalAlign: "middle",
                  marginRight: "2.5px",
                }}
              />
              <span style={{ verticalAlign: "middle", marginLeft: "2.5px" }}>
                Deliver
              </span>
            </div>
          </button>

          <button
            id="winningInventoryWithdrawBtn"
            onClick={() => onOrder("giftcard")}
            style={!can_deliver ? { opacity: "0.6" } : null}
          >
            <Background style={{ background: "#f39224", opacity: "0.3" }} />
            <div style={{ position: "relative" }}>
              <img
                id="convertWinningProduct"
                src={envConfig.s3 + "/images/icons/giftcard.svg"}
                style={{
                  width: "50px",
                  verticalAlign: "middle",
                  marginRight: "2.5px",
                }}
              />
              <span style={{ verticalAlign: "middle", marginLeft: "2.5px" }}>
                Giftcard
              </span>
            </div>
          </button>
        </div>
      </section>
    </div>
  );
}

export default Index;
