const chat_reducer = (state = null, action) => {
  switch (action.type) {
    case "POPULATE_CHAT":
      return action.messages;
    case "ADD_CHAT_MESSAGE":
      return state ? [...state, action.new_message] : [action.new_message];
    default:
      return state;
  }
};

export default chat_reducer;
