const session_reducer = (state = null, action) => {
  switch (action.type) {
    case "POPULATE_SUBSCRIPTION_REDUCER":
      return action.sub_id;
      break;
    default:
      return state;
  }
};

export default session_reducer;
