import React from "react";
import { Link } from "react-router-dom";

//STYLES
import "./style.css";

//COMPONENTS
import FlexArea from "../../layout/flex/FlexArea";
import SuccessBtn from "../../core/buttons/SuccessBtn";

export default (props) => {
  return (
    <FlexArea
      children={
        <div id="EmptyPlaceholder" className="highlighted">
          <h3>There are no {props.context} to display</h3>
          {props.redirect ? (
            <Link to={props.redirect.route}>
              <SuccessBtn context={"Go to " + props.redirect.page} />
            </Link>
          ) : null}
        </div>
      }
    />
  );
};
