import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../core/buttons/Btn";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import FlexArea from "../../../layout/flex/FlexArea";
import Background from "../../../common/backgrounds/Background";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <FlexArea
      children={
        <SuccessBtn
          id="makeACrateBtn"
          context={"Make a Crate"}
          onClick={() => {
            dispatch({ type: "CLEAR_CRATE_INVENTORY" });
            navigate("/unbox/add");
          }}
        />
      }
    />
  );
}

export default Index;
