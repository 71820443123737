import API from "../../index";

export default async (obj) => {
  const { status } = await API.post("/api/auth/password_forgot", {
    token: obj.token,
    password: obj.password,
    confirm_password: obj.confirm_password,
  });

  return status == 200 ? true : false;
};
