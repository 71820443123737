import React from "react";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../../../../common/config/env.config";

//FUNCTIONS
import ParseDateTime from "../../../../../../../../../../common/functions/ParseDateTime";
import GeneralFunctions from "../../../../../../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../../../../../layout/flex/FlexGrid";
import ProfileImg from "../../../../../../../../account/ProfileImg";

function Index(props) {
  const session = useSelector((state) => state.session_reducer);
  return (
    <div
      style={{ width: "100%", marginBottom: "5px", marginTop: "5px" }}
      className="ListElement"
    >
      <FlexGrid
        children={[
          {
            style: { flex: "1 0 25%" },
            element: (
              <section style={{ display: "flex", height: "100%" }}>
                <div
                  style={{
                    display: "inline-block",
                    margin: "auto",
                    marginLeft: "0",
                    margin: "auto",
                  }}
                >
                  {(() => {
                    switch (props.obj.method) {
                      case "BANK":
                        return (
                          <div>
                            <img
                              style={{ width: "50px", verticalAlign: "middle" }}
                              src={
                                envConfig.s3 +
                                "/images/icons/affiliate/bank_icon.svg"
                              }
                            />
                            <span
                              style={{
                                verticalAlign: "middle",
                                fontWeight: "bold",
                              }}
                            >
                              BANK
                            </span>
                          </div>
                        );
                      case "PAYPAL":
                        return (
                          <div>
                            <img
                              style={{ width: "50px", verticalAlign: "middle" }}
                              src={
                                envConfig.s3 +
                                "/images/icons/affiliate/paypal_icon.svg"
                              }
                            />
                            <span
                              style={{
                                verticalAlign: "middle",
                                fontWeight: "bold",
                              }}
                            >
                              PAYPAL
                            </span>
                          </div>
                        );
                      case "USER":
                        return (
                          <div>
                            <ProfileImg
                              user={session.user}
                              style={{
                                width: "50px",
                                height: "50px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                marginRight: "5px",
                              }}
                            />
                            <span
                              style={{
                                verticalAlign: "middle",
                                fontWeight: "bold",
                              }}
                            >
                              {session.user.username}
                            </span>
                          </div>
                        );
                    }
                  })()}
                </div>
              </section>
            ),
          },
          {
            style: { flex: "1 0 25%" },
            element: (
              <section style={{ display: "flex", height: "100%" }}>
                <div
                  style={{
                    display: "inline-block",
                    margin: "auto",
                    marginLeft: "0",
                    margin: "auto",
                  }}
                >
                  <img
                    style={{
                      verticalAlign: "middle",
                      width: "30px",
                      marginRight: "5px",
                    }}
                    src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                      verticalAlign: "middle",
                    }}
                  >
                    {GeneralFunctions.parse_currency(props.obj.value)}
                  </span>
                </div>
              </section>
            ),
          },

          {
            style: { flex: "1 0 25%" },
            element: (
              <section style={{ display: "flex", height: "100%" }}>
                <div
                  style={{
                    display: "inline-block",
                    margin: "auto",
                    marginLeft: "0",
                    margin: "auto",
                  }}
                >
                  <span>
                    {ParseDateTime.parseDateTime(
                      ParseDateTime.adjustForTimezone(
                        new Date(props.obj.timestamp)
                      )
                    )}
                  </span>
                </div>
              </section>
            ),
          },
          {
            style: { flex: "1 0 25%" },
            element: (
              <section style={{ display: "flex", height: "100%" }}>
                <div
                  style={{
                    display: "inline-block",
                    margin: "auto",
                    marginLeft: "0",
                    margin: "auto",
                  }}
                >
                  <span>{props.obj.transaction_value}</span>
                </div>
              </section>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
