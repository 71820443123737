import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import ProfileImg from "../../../../../../content/account/ProfileImg";
import XP from "../../../../../../content/account/ProfileSection/elements/XP";
import FlexArea from "../../../../../../layout/flex/FlexArea";
import FlexGrid from "../../../../../../layout/flex/FlexGrid";

import LevelUp from "./popups/LevelUp";

function Index(props) {
  const dispatch = useDispatch();
  const [prevLevel, setPrevLevel] = useState(null);
  const account_balance = useSelector((state) => state.account_balance_reducer);
  const account_level = useSelector((state) => state.account_level_reducer);

  useEffect(() => {
    if (prevLevel !== null && account_level?.level > prevLevel) {
      dispatch({
        type: "NEW_POPUP",
        element: <LevelUp prevLevel={prevLevel || 0} />,
      });
    }
    setPrevLevel(account_level?.level);
  }, [account_level?.level]);

  return (
    <div id="User">
      <FlexGrid
        style={{ justifyContent: "start" }}
        children={[
          {
            element: (
              <div className="icon" style={{ marginRight: "0" }}>
                <Link to="/account">
                  <ProfileImg
                    user={Object.assign({}, props.user, {
                      user_id: props.user.id,
                    })}
                  />
                </Link>
              </div>
            ),
          },
          {
            style: { flex: "1" },
            element: (
              <div id="info">
                <FlexGrid
                  children={[
                    {
                      style: { margin: "auto", marginLeft: "0" },
                      element: (
                        <div id="username">
                          <span>{unescape(props.user.username)}</span>
                        </div>
                      ),
                    },
                    {
                      style: {
                        flex: "1 0 100%",
                        paddingLeft: "2px",
                        boxSizing: "border-box",
                      },
                      element: (
                        <div
                          style={{ marginTop: "2px", minWidth: "60px" }}
                          id="userXP"
                        >
                          <XP
                            style={{ height: "20px", fontSize: "0.7rem" }}
                            user={Object.assign({}, props.user, {
                              account_level: account_level.level,
                              total_xp: account_level.xp,
                            })}
                            showRemaining={false}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
