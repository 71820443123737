import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../../../../common/backgrounds/Background";
import FlexArea from "../../../../../layout/flex/FlexArea";

export default (props) => {
  return (
    <div id="titleWrapper">
      <Background
        style={{
          background: "linear-gradient(0deg, var(--background), transparent)",
          borderRadius: "8px",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
        }}
      />

      <span className="title">{unescape(props.title).toUpperCase()}</span>
    </div>
  );
};
