//API
import get_user from "./server/get_user";

export default {
  get_user: async (user_id) => {
    let user = await get_user(user_id);

    return user;
  },
};
