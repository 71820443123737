import React, { Component, useState } from "react";
import CookieFunctions from "../../../../../common/Cookies";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//FUNCTIONS
import Auth from "../../../../../common/api/Auth";
import GeneralFunctions from "../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import User from "./elements/User";
import FlexArea from "../../../../layout/flex/FlexArea";
import Balance from "./elements/Balance";
import FlexGrid from "../../../../layout/flex/FlexGrid";

export default (props) => {
  const session = useSelector((state) => state.session_reducer);

  return (
    <div
      className="section"
      id="AccountNav"
      style={{ flex: "1", maxWidth: "450px", paddingLeft: "10px" }}
    >
      <FlexGrid
        style={{ height: "100%", justifyContent: "end" }}
        children={[
          {
            style: { marginRight: "10px" },
            element: <Balance />,
          },
          session && session.user
            ? {
                style:
                  props.width > 640
                    ? {
                        flex: "1",
                      }
                    : null,
                element: <FlexArea children={<User user={session.user} />} />,
              }
            : {
                style: {},
                element: (
                  <Link
                    to="/login"
                    style={{ color: "unset", textDecoration: "unset" }}
                  >
                    <div className="element">
                      <FlexArea
                        children={
                          <div
                            className="btnWrapper"
                            style={{ margin: "auto", marginRight: "0" }}
                          >
                            <button id="signInBtn">Sign In/Sign Up</button>
                          </div>
                        }
                      />
                    </div>
                  </Link>
                ),
              },
        ]}
      />
    </div>
  );
};
