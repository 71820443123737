import React from "react";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../../common/config/env.config";

//FUNCTIONS
import getProductRank from "../../../../../../../../common/functions/getProductRank";

//STYLES
import "./style.css";

//COMPONENTS
import ProductRank from "../../../../../../products/ProductRank";
import Product from "../../../../Product";
import FlexArea from "../../../../../../../layout/flex/FlexArea";
import ProfileImg from "../../../../../../account/ProfileImg";
import Background from "../../../../../../../common/backgrounds/Background";
import Icon from "../../../../../../../core/Icon";

export default (props) => {
  return (
    <div>
      <div>
        <Icon
          style={{ width: "40px", verticalAlign: "middle" }}
          src={envConfig.s3 + "/images/icons/" + props.image}
        />
        <span style={{ fontSize: "0.8rem", verticalAlign: "middle" }}>
          Roll:{" "}
          <span style={{ fontWeight: "bold", color: "var(--FunctionGreen)" }}>
            {props.win.roll}
          </span>
        </span>
      </div>
      <div style={{ position: "relative", padding: "5px" }}>
        <Link to={"/unboxing/" + props.win.unboxing_id}>
          <Background
            style={{
              background: "var(--background)",
              opacity: "0.5",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              border:
                "2px solid var(--" + getProductRank(props.win.price) + ")",
            }}
          />
          <FlexArea
            children={
              <div
                style={{
                  position: "relative",
                  margin: "auto",
                }}
              >
                <div style={{ width: "130px", height: "130px" }}>
                  <ProductRank product={props.win} />
                </div>
              </div>
            }
          />
        </Link>
      </div>
      <Link to={"/users/" + props.win.user_id}>
        <div
          style={{
            position: "relative",
            width: "100%",
            padding: "5px",
            boxSizing: "border-box",
          }}
        >
          <Background
            style={{
              background: "var(--background)",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              border:
                "1px solid var(--" + getProductRank(props.win.price) + ")",
              borderTop: "0",
              opacity: "0.5",
            }}
          />
          <div style={{ position: "relative" }}>
            <div
              style={{
                width: "40px",
                height: "40px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <ProfileImg user={props.win} />
            </div>
            <span
              style={{
                fontWeight: "bold",
                verticalAlign: "middle",
                marginLeft: "5px",
                fontSize: "0.8rem",
              }}
            >
              {unescape(props.win.username)}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};
