import React from "react";
import { useParams } from "react-router-dom";

//COMPONENTS
import PopupWithRedirect from "../../../../layout/popups/PopupWithRedirect";
import SuccessBtn from "../../../../core/buttons/SuccessBtn";

function Index(props) {
  const params = useParams();
  return (
    <PopupWithRedirect
      className="SignUp"
      title="Verify Your Email"
      context={"Resend Email"}
      onSuccess={() => {}}
      children={
        <div className="highlighted">
          <h4>We sent an email to </h4>
          <span style={{ color: "var(--FunctionGreen)", fontWeight: "bold" }}>
            {atob(params.email)}
          </span>
          <h4>follow the link to verify your account</h4>
        </div>
      }
    />
  );
}

export default Index;
