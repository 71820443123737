import React from "react";
import { useNavigate } from "react-router-dom";

//FUNCTIONS
import parseDateTime from "../../../../../../common/functions/ParseDateTime";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import ProductRank from "../../../../products/ProductRank";
import Price from "../../../../../core/Price";
import Background from "../../../../../common/backgrounds/Background";
import OrderViewTracking from "../OrderView/elements/OrderViewTracking";

export default (props) => {
  const navigate = useNavigate();

  function renderOrderMethod(product) {
    return (
      <FlexGrid
        style={{
          flexDirection: "column",
          height: "100%",
          color:
            product.order_type == "deliver"
              ? "var(--FunctionGreen)"
              : "var(--crypto)",
        }}
        children={[
          {
            style: { width: "100%", alignSelf: "flex-start" },
            element: (
              <div>
                <div className="title">
                  <span>{product.order_type}</span>
                </div>
              </div>
            ),
          },
          {
            style: { width: "100%", flex: "1" },
            element: (
              <div className="orderSection">
                <div>
                  <span>{props.product.status}</span>
                </div>
              </div>
            ),
          },
        ]}
      />
    );
  }

  return (
    <div
      id="ProductView"
      onClick={() => navigate("/orders/" + props.product.order_id)}
    >
      <FlexGrid
        style={{ justifyContent: "start", fontSize: "0.8rem" }}
        children={[
          {
            style: { width: "100px", height: "100px", marginRight: "5px" },
            element: <ProductRank product={props.product} />,
          },
          {
            style: {
              flex: "1",
              maxWidth: "300px",
              overflow: "hidden",
              paddingTop: "10px",
              marginRight: "5px",
            },
            element: (
              <div>
                <div style={{ marginBottom: "5px" }}>
                  <Price price={props.product.price} />
                </div>
                <span>{unescape(props.product.name).substr(0, 60)}</span>
              </div>
            ),
          },
          {
            style: { flex: "1 0 200px" },
            element: renderOrderMethod(props.product),
          },
        ]}
      />
    </div>
  );
};
