import React from "react";

//STYLES
import "./style.css";

//FUNCTIONS
import RollFunctions from "../../../../unbox/functions/roll.functions";

//COMPONENTS
import FlexArea from "../../../../../layout/flex/FlexArea";
import Form from "../../../../../core/Form";
import Textbox from "../../../../../core/inputs/text/Textbox";
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";

export default (props) => {
  return (
    <div
      id="RevealRoll"
      style={{
        border:
          props.session.nonce && props.session.server_seed
            ? "2px solid var(--FunctionGreen)"
            : null,
        padding: "10px",
        boxSizing: "border-box",
      }}
    >
      <Form
        children={[
          {
            name: "Nonce",
            el: <Textbox disabled={true} value={props.session.nonce} />,
          },
          {
            name: "Server Seed",
            el: <Textbox disabled={true} value={props.session.server_seed} />,
          },
        ]}
      />
      <div>
        <p style={{ textAlign: "left" }}>
          Roll:{" "}
          {props.session.nonce && props.session.server_seed ? (
            <span style={{ color: "var(--FunctionGreen)", fontWeight: "bold" }}>
              {RollFunctions.calculate(
                props.session.server_seed,
                props.session.client_seed,
                props.session.nonce
              )}
            </span>
          ) : null}
        </p>
      </div>
      {!props.session.nonce && !props.session.server_seed ? (
        <div
          style={{
            position: "absolute",
            inset: "0",
          }}
          className="highlighted"
        >
          <FlexArea
            children={
              <SuccessBtn
                style={{ margin: "auto" }}
                context={"Reveal Server Seed"}
                onClick={props.reveal_server_seed}
              />
            }
          />
        </div>
      ) : null}
    </div>
  );
};
