import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

//API
import Orders from "../../../../../common/api/Orders";
import Inventory from "../../../../../common/api/Inventory";

//STYLES
import "../style.css";

//COMPONENTS
import SelectMethod from "./elements/SelectMethod";
import ProductList from "../elements/ProductList";
import AddressSelect from "./elements/AddressSelect";
import GiftcardSelect from "./elements/GiftcardSelect";
import SuccessBtn from "../../../../core/buttons/SuccessBtn";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Warning from "./elements/Warning";
import PopupWithRedirect from "../../../../layout/popups/PopupWithRedirect";
import InfoPanel from "./elements/InfoPanel";

function Index(props) {
  const params = props.params;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const account_balance = useSelector((state) => state.account_balance_reducer);
  const config = useSelector((state) => state.config_reducer);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [address, setAddress] = useState(null);
  const [giftcard, setGiftcard] = useState(null);
  const [warning, showWarning] = useState(false);

  useEffect(() => {
    if (
      props.params?.products?.length &&
      //props.products NOT EQUAL TO loaded products
      JSON.stringify((props.params?.products || []).sort((a, b) => a > b)) !==
        JSON.stringify((products?.map((x) => x.id) || []).sort((a, b) => a > b))
    ) {
      get_inventory_products(props.params.products);
    }
  }, [props.params?.products]);

  async function get_inventory_products(products) {
    const data = await Inventory.get_inventory({ products: products });
    setProducts(data);
    setLoading(false);
  }

  /*PRICE CHANGES + DELIVERY COST*/
  var price_changes = 0;
  var delivery_cost = 0;

  if (products) {
    price_changes = products.reduce(
      (prev, next) =>
        prev +
        (next.real_price > next.price ? next.real_price - next.price : 0),
      0
    );

    delivery_cost = products.reduce(
      (prev, next) => prev + next.delivery_cost,
      0
    );
  }

  //IF PRICE CHANGES/OUT OF STOCK/VIVACOIN PRODUCTS THEN SHOW ORDER WARNINGS
  useEffect(() => {
    if (
      !props.params?.hideWarning &&
      products &&
      params.method == "deliver" &&
      products.filter(
        (x) => x.real_price - x.price > 0 || x.in_stock == 0 || x.type == "COIN"
      ).length
    )
      showWarning(true);
    else showWarning(false);
  }, [products, params.method]);

  useEffect(() => {
    if (props.params?.hideWarning) {
      showWarning(false);
    }
  }, [props.params?.hideWarning]);

  //UPDATE ACCOUNT DROPDOWN
  useEffect(() => {
    if (products?.length) {
      dispatch({
        type: "SET_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
        selected: products,
      });
    }

    //ON UNMOUNT
    return () => {
      dispatch({
        type: "SET_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
        selected: [],
      });
    };
  }, [products]);

  async function create_order() {
    if (account_balance >= price_changes) {
      if (
        await Orders.add_order({
          order: Object.assign(
            {},
            params.method == "deliver"
              ? { address: address?.address }
              : {
                  country: giftcard.country,
                  email_address: giftcard.email_address,
                },
            {
              products: products,
            },
            { method: params.method },
            {
              cost: products.reduce(
                (prev, x) =>
                  prev +
                  (x.delivery_cost +
                    (x.real_price - x.price > 0 ? x.real_price - x.price : 0)),
                0
              ),
            }
          ),
        })
      ) {
        navigate("/orders");
      }
    } else {
      const price_change_products = products
        .filter((x) => x.real_price > x.price)
        .map((x) => x.id);

      navigate(
        "/checkout?value=" +
          parseFloat(
            (price_changes - account_balance < config.minimum_deposit
              ? config.minimum_deposit
              : price_changes - account_balance
            ).toFixed(2)
          ) +
          (price_change_products.length
            ? "&products=" +
              encodeURIComponent(JSON.stringify(price_change_products))
            : ""),
        {
          state: {
            order: {
              address: address?.address,
              products: products.map((x) => x.id),
            },
            successLink: "/orders",
          },
        }
      );
    }
  }

  return !loading ? (
    products && products.length ? (
      <PopupWithRedirect
        children={
          <div id="Order" className="mainPadding">
            <h1 style={{ margin: "0", padding: "0 10px 0 10px" }}>
              Create Order
            </h1>
            <FlexGrid
              style={{ height: "100%" }}
              children={[
                {
                  style: {
                    flex: "1 0 60%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  },
                  element: !warning ? (
                    <div
                      style={{
                        height: "100%",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        borderRight: "0",
                      }}
                    >
                      <FlexGrid
                        style={{ height: "100%", flexDirection: "column" }}
                        children={[
                          {
                            style: {
                              padding: "10px",
                              paddingTop: "0",
                              paddingBottom: "0",
                            },
                            element: (
                              <SelectMethod
                                params={props.params}
                                method={props.params.method}
                              />
                            ),
                          },
                          {
                            style: {
                              flex: "1",
                              padding: "10px",
                              paddingTop: "0",
                            },
                            element: (
                              <div id="top">
                                {params.method == "deliver" ? (
                                  <AddressSelect
                                    can_edit={true}
                                    setAddress={setAddress}
                                    data={address}
                                    products={products}
                                    price_changes={price_changes}
                                    delivery_cost={delivery_cost}
                                    onConfirmDeliver={create_order}
                                  />
                                ) : (
                                  <GiftcardSelect
                                    can_edit={true}
                                    setGiftcard={(obj) =>
                                      setGiftcard(
                                        Object.assign({}, giftcard, obj)
                                      )
                                    }
                                    giftcard={giftcard}
                                    crypto={crypto}
                                    products={products}
                                  />
                                )}
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    <Warning products={products} />
                  ),
                },
                {
                  style: { flex: "1 0 40%", paddingTop: "10px" },
                  element: (
                    <InfoPanel
                      products={products}
                      method={params.method}
                      price_changes={price_changes}
                      delivery_cost={delivery_cost}
                      create_order={create_order}
                      can_confirm={!warning}
                    />
                  ),
                },
              ]}
            />
          </div>
        }
      />
    ) : (
      <Navigate to="/inventory" />
    )
  ) : null;
}

export default Index;
