import React, { useState, useEffect } from "react";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Btn from "../../../buttons/Btn";
import Background from "../../../../common/backgrounds/Background";

function Index(props) {
  const [show, setShow] = useState(false);

  return (
    <div className="section" onClick={() => setShow(!show)}>
      <div>
        <FlexGrid
          children={[
            {
              style: { flex: "1" },
              element: <h3>{props.title}</h3>,
            },
            {
              element: (
                <Btn
                  style={
                    !show
                      ? { color: "var(--FunctionGreen)" }
                      : { color: "var(--error)" }
                  }
                  context={!show ? "show" : "hide"}
                />
              ),
            },
          ]}
        />
      </div>
      <div className={("text " + (show ? "show" : "hide")).trim()}>
        <Background
          style={{
            background: "var(--highlighted)",
            borderRadius: "8px",
          }}
        />
        <span style={{ position: "relative" }}>{props.text}</span>
      </div>
    </div>
  );
}

export default Index;
