import React from "react";
import { useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//FUNCTIONS
import getProductRank from "../../../../../common/functions/getProductRank";
import GeneralFunctions from "../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import ProductRank from "../../../products/ProductRank";
import ProvFairBtn from "../../../provablyFair/ProvFairBtn";
import RollChecker from "../../../provablyFair/RollChecker";
import Background from "../../../../common/backgrounds/Background";

function Index(props) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);
  let unboxing = props.unboxing;

  console.log(unboxing);

  return (
    <div
      id="product"
      style={{
        position: "relative",
        margin: "0",
        border: "0",
      }}
    >
      <FlexGrid
        children={[
          {
            style: { flex: "1", minWidth: "250px", textAlign: "center" },
            element: (
              <div
                style={{
                  width: "100%",
                  height: "300px",
                  display: "inline-block",
                }}
              >
                <ProductRank
                  product={{
                    id: unboxing.product_id,
                    price: unboxing.winning_product_price,
                    img_url: unboxing.img_url,
                    type: unboxing.type,
                  }}
                />
              </div>
            ),
          },
          {
            style: { flex: "1 0 50%" },
            element: (
              <div className="info">
                <p>{unescape(unboxing.name)}</p>
                <p className="price">
                  <img
                    style={{ width: "30px", verticalAlign: "middle" }}
                    src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                  />
                  <span>
                    {GeneralFunctions.parse_currency(
                      unboxing.winning_product_price
                    )}
                  </span>
                </p>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
