const addresses_reducer = (state = [], action) => {
  switch (action.type) {
    case "POPULATE_ADDRESSES":
      return action.addresses;
    case "SET_DEFAULT_ADDRESS":
      return state.map((x) =>
        x.id == action.id
          ? Object.assign({}, x, { is_default: true })
          : Object.assign({}, x, { is_default: false })
      );
    case "ADD_ADDRESS":
      return [action.address, ...state];
    case "UPDATE_ADDRESS":
      return state.map((x) =>
        x.id == action.address.id ? Object.assign({}, x, action.address) : x
      );
    case "DELETE_ADDRESS":
      return state.filter((x) => x.id !== action.id);
    default:
      return state;
  }
};

export default addresses_reducer;
