import React, { useState, useEffect, createRef, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//FUNCTIONS
import DetectSizeChange from "../../../common/functions/DetectSizeChange";
import getProductRank from "../../../../common/functions/getProductRank";
import segmentFunctions from "./functions/segment.functions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexArea from "../../../layout/flex/FlexArea";
import Background from "../../../common/backgrounds/Background";
import ProductRank from "../../products/ProductRank";
import WinningPopup from "../popups/WinningPopup";
import Segment from "./elements/Segment";

function Index(props) {
  const dispatch = useDispatch();
  const maxWidth = 800;

  //REFS
  const carousel_obj_ref = useRef();
  let wheelRef = createRef();
  var segRef = useRef();
  var timerRef = useRef();

  //REDUCERS
  const session = useSelector((state) => state.session_reducer);
  const show_live_wins = useSelector((state) => state.show_livewins_reducer);
  const carousel = useSelector((state) => state.unboxing_carousel_reducer);
  const unboxing = useSelector((state) => state.unboxing_reducer);
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);

  //STATES
  const { width } = DetectSizeChange(wheelRef, [show_live_wins]);
  const [segments, setSegments] = useState(null);

  useEffect(() => {
    if (crate_inventory?.products.length && carousel) {
      if (!segments) {
        newSegments(width, null);
      }
      if (carousel.type !== "preview") {
        animateWheel(newSegments(width, null));
      }
    }

    //UPDATE REF OBJ
    carousel_obj_ref.current = carousel;
  }, [carousel]);

  useEffect(() => {
    if (crate_inventory?.products?.length > 1) {
      setSegments(newSegments(width, null));
    }
  }, [crate_inventory.products]);

  //COMPLETE ANIMATION IF USER NAVIGATES AWAY
  useEffect(() => {
    return () => {
      const x = carousel_obj_ref.current;

      if (x.type !== "preview") {
        addNewWin(x);

        dispatch({
          type: "NEW_POPUP",
          element: (
            <WinningPopup
              unboxing={x.unboxing}
              xp_amount={x.xp_amount}
              type={x.type}
            />
          ),
        });

        dispatch({
          type: "UPDATE_UNBOXING_CAROUSEL",
          carousel: { unboxing: null, type: "preview" },
        });

        /*UNLOCK ANIMATION LOCK*/
        dispatch({ type: "SET_ANIMATION_LOCK", locked: false });

        clearTimeout(timerRef.current);
      }
    };
  }, []);

  function animateWheel(segs) {
    //RESET ROTATION
    document.getElementById("segments").style.transform = "rotate(0deg)";

    let min = 8000;
    let max = 11000;

    if (unboxing.fast_unbox) {
      min = 1500;
      max = 1500;
    }

    var rollbackDur = 650;
    var dur = Math.random() * (max - min) + min;
    var rollBack = -450;

    /*Variate landing position on winning segment*/
    var rotate = Math.random() * (1810.5 - 1789.5) + 1789.5;

    var winSeg = Math.floor((Math.floor(rotate / 22.5) + 0) % 16);

    setSegments(segs);

    //SET CSS
    let root = document.documentElement;
    root.style.setProperty("--wheelRollBackDur", rollbackDur / 1000 + "s");
    root.style.setProperty("--wheelDur", dur / 1000 + "s");
    root.style.setProperty("--wheelRollback", rollBack + "deg");
    root.style.setProperty("--wheelRotate", rotate + "deg");

    document.getElementById("segments").className = "rollback";
    timerRef.current = setTimeout(() => {
      document.getElementById("segments").className = "animate";

      //UPDATE SEGMENTS OUT OF VIEW
      segmentFunctions.update_segs(
        segRef,
        segments,
        crate_inventory.products,
        carousel?.unboxing?.winning_product,
        setSegments
      );

      timerRef.current = setTimeout(() => {
        if (carousel.type !== "preview") {
          //PRESERVE ROTATE
          document.getElementById("segments").style.transform =
            "rotate(" + rotate + "deg)";
          document.getElementById("segments").classList.remove("animate");
          dispatch({
            type: "NEW_POPUP",
            element: (
              <WinningPopup
                unboxing={carousel.unboxing}
                xp_amount={carousel.xp_amount}
                type={carousel.type}
              />
            ),
          });

          dispatch({
            type: "ADD_AUDIO",
            source: envConfig.s3 + "/sounds/unbox/win.mp3",
          });

          addNewWin(carousel);

          /*LOCK CAROUSEL IN PREVIEW STATE*/
          dispatch({
            type: "UPDATE_UNBOXING_CAROUSEL",
            carousel: { unboxing: carousel.unboxing, type: "preview" },
          });

          /*UNLOCK ANIMATION LOCK*/
          dispatch({ type: "SET_ANIMATION_LOCK", locked: false });
        }
      }, dur + 300);
    }, rollbackDur);
  }

  function addNewWin(x) {
    if (x.type == "unbox") {
      /*ADD TO LIVE WINS*/
      dispatch({
        type: "ADD_LIVE_WIN",
        new_live_win: Object.assign({}, x.unboxing.winning_product, {
          id: x.unboxing.winning_product.unboxing_id,
          user_id: session.user.id,
          profile_img: session.user.profile_img,
          profile_img_datetime: session.user.profile_img_datetime,
          default_image: session.user.default_image,
        }),
      });

      /*ADD WINNING PRODUCT TO ACCOUNT DROPDOWN*/
      dispatch({
        type: "ADD_ACCOUNT_DROPDOWN_INVENTORY",
        product: Object.assign({}, x.unboxing.winning_product, {
          product_id: x.unboxing.winning_product.product_id,
          id: x.unboxing.account_product_id,
        }),
      });

      if (carousel.unboxing?.xp_amount !== undefined) {
        //ADD XP
        dispatch({
          type: "ADD_ACCOUNT_XP",
          object: { xp: carousel.unboxing.xp_amount },
        });
      }

      /*ADD TO CRATE RECENT WINS*/
      dispatch({
        type: "ADD_CRATE_UNBOXING",
        unboxing: Object.assign({}, x.unboxing.winning_product, {
          user_id: session.user.id,
          profile_img: session.user.profile_img,
          profile_img_datetime: session.user.profile_img_datetime,
        }),
      });
    }
  }

  function newSegments(width, curSegments = null) {
    width = 1146;
    const circ = width * Math.PI;
    const scale = 1 * (width / maxWidth);
    const noOfSegs = 16;
    const offset = 320 * scale;

    const size = circ / noOfSegs;
    const segs = segmentFunctions.get_segments(size, offset, scale);

    var newSegs = segs.map((x, i) => {
      return { style: x, size };
    });

    if (!curSegments) {
      dispatch({
        type: "POPULATE_RANDOM_CAROUSEL_SEGMENTS",
        obj: {
          segments: newSegs,
          products: crate_inventory.products,
          winning_product: carousel?.unboxing?.winning_product,
        },
      });
    }

    return newSegs;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div id="Carousel" ref={wheelRef}>
        <div className="inner">
          <FlexArea
            style={{
              position: "relative",
              justifyContent: "center",
            }}
            children={
              <div
                id="Wheel"
                style={{
                  marginTop:
                    (width < maxWidth
                      ? width > 400
                        ? (400 / width) * 80
                        : 80
                      : 0) + "px",
                }}
              >
                <div id="PointerWrapper">
                  <FlexArea
                    style={{ position: "relative" }}
                    children={
                      <img
                        src={
                          envConfig.s3 + "/images/unbox/carousel/pointer.svg"
                        }
                        id="pointer"
                      />
                    }
                  />
                </div>
                <div style={{ position: "absolute", inset: "0" }}>
                  <div
                    style={Object.assign(
                      {},
                      { position: "absolute", inset: "0", left: "0" }
                    )}
                  >
                    <div
                      style={{
                        position: "absolute",
                        inset: "0%",
                        height: "unset",
                        width: "1146px",
                        height: "1146px",
                        left: -(1146 - width) / 2 + "px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        border: "2px solid var(--unboxColor)",
                        boxShadow: "0 0 10px black",
                      }}
                    >
                      <Background
                        style={{
                          background: "var(--background)",
                          opacity: "0.4",
                        }}
                      />
                    </div>
                    <div id="segments" ref={segRef}>
                      {segments?.map((x, i) => (
                        <Segment
                          index={i}
                          visible={x.visible}
                          style={x.style}
                          size={x.size}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
