import React, { useState } from "react";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../common/functions/GeneralFunctions";
import getProductRank from "../../../../common/functions/getProductRank";

//STYLES
import "./style.css";

//COMPONENTS
import VisibilitySensor from "react-visibility-sensor";
import Background from "../../../common/backgrounds/Background";
import ExpandedProduct from "./elements/ExpandedProduct";
import ProductRank from "../ProductRank";
import Price from "../../../core/Price";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";
import Btn from "../../../core/buttons/Btn";
import Checkbox from "../../../core/inputs/Checkbox";

export default (props) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  function onSelect(e) {
    if (props.show_selected) {
      props.onClick(props.product);
    }
  }

  return (
    <VisibilitySensor
      onChange={(visible) => setVisible(visible)}
      partialVisibility={true}
    >
      <div
        className={"Product" + (props.className ? " " + props.className : "")}
        style={Object.assign(
          {},
          {
            visibility: visible ? "visible" : "hidden",
            color: "var(--" + getProductRank(props.product.price) + ")",
            border: "1px solid var(--highlighted)",
            borderTop: "2px solid",
            borderBottom: "2px solid",
          },
          props.style
        )}
      >
        <Background
          style={{
            borderRadius: "8px",
            background:
              "radial-gradient(var(--" +
              getProductRank(props.product.price) +
              "), transparent)",
            opacity: "0.5",
          }}
        />
        <FlexGrid
          style={{
            position: "absolute",
            height: "100%",
            flexDirection: "column",
          }}
          children={[
            {
              style: {},
              element: props.show_selected ? (
                <div style={{ textAlign: "left" }}>
                  <Checkbox
                    style={{ width: "18px", height: "18px" }}
                    checked={props.selected}
                    onClick={() => props.onClick(props.product)}
                  />
                </div>
              ) : (
                props.children
              ),
            },
            {
              style: { position: "relative", flex: "1" },
              element: (
                <>
                  <FlexArea
                    children={
                      <ProductRank
                        style={{ margin: "auto", height: "80%" }}
                        product={props.product}
                      />
                    }
                  />

                  <div className="overlay" onClick={onSelect}>
                    <div
                      style={{
                        position: "absolute",
                        inset: "0",
                        paddingBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Background
                          style={{
                            background:
                              "radial-gradient(var(--" +
                              getProductRank(props.product.price) +
                              "),transparent)",
                            opacity: "0.4",
                          }}
                        />
                        <Background
                          style={{
                            background:
                              "radial-gradient(var(--background), transparent)",
                            opacity: "0.9",
                          }}
                        />
                      </div>
                    </div>
                    <FlexArea
                      style={{ padding: "10px" }}
                      children={
                        <span className="title">
                          {unescape(props.product.name).length > 85
                            ? unescape(props.product.name).substring(0, 85) +
                              "..."
                            : unescape(props.product.name)}
                        </span>
                      }
                    />
                  </div>
                </>
              ),
            },
            {
              style: {
                paddingLeft: "5px",
                paddingRight: "5px",
                paddingBottom: "5px",
              },
              element: (
                <FlexGrid
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                  children={[
                    {
                      style: { marginRight: "auto" },
                      element: (
                        <FlexArea
                          children={
                            <div className="price">
                              <Price
                                style={{ fontSize: "0.7rem" }}
                                img_size={"18px"}
                                price={props.product.price}
                              />
                            </div>
                          }
                        />
                      ),
                    },
                    {
                      style: { marginLeft: "auto" },
                      element: (
                        <FlexArea
                          children={
                            <Btn
                              style={{
                                margin: "auto",
                                padding: "0",
                                fontSize: "0.7rem",
                              }}
                              className="ViewBtn"
                              context={"View"}
                              onClick={() =>
                                dispatch({
                                  type: "NEW_POPUP",
                                  element: (
                                    <ExpandedProduct
                                      product_id={
                                        props.product.product_id ||
                                        props.product.id
                                      }
                                      context={"Continue"}
                                      onSuccess={() =>
                                        dispatch({ type: "CLOSE_POPUP" })
                                      }
                                    />
                                  ),
                                })
                              }
                            />
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
          ]}
        />
      </div>
    </VisibilitySensor>
  );
};
