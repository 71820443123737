import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//API
import Transactions from "../../../../../common/api/Transactions";

//FUNCTIONS
import GeneralFunctions from "../../../unbox/functions/General.functions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import ProfileImg from "../../../account/ProfileImg";
import Textbox from "../../../../core/inputs/text/Textbox";
import Btn from "../../../../core/buttons/Btn";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  const [codeTxt, setCodeTxt] = useState(props.checkout?.code?.code || null);
  const [error, setError] = useState(null);

  async function VerifyCode(code) {
    const codeObj = await Transactions.verify_code(code);
    if (!codeObj.error && codeObj.data) {
      props.updateCheckout({ code: codeObj.data });
    } else {
      setError(codeObj.error || true);
    }
  }

  useEffect(() => {
    setCodeTxt(props.checkout?.code?.code || null);
  }, [props.checkout]);

  return (
    <div id="PromoCode" className="highlighted">
      <h4 style={{ margin: "0", marginBottom: "10px" }}>Add Promo Code</h4>
      <div
        style={{
          position: "relative",
          marginTop: "10px",
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <Background
          style={{ background: "var(--background)", opacity: "0.6" }}
        />
        <FlexGrid
          style={{ boxSizing: "border-box", padding: "5px" }}
          children={[
            {
              style: { flex: "0" },
              element: (
                <div id="userImg">
                  {props.checkout.code ? (
                    <ProfileImg user={props.checkout.code} />
                  ) : null}
                </div>
              ),
            },
            props.checkout.code
              ? {
                  style: { flex: "1", paddingLeft: "10px" },
                  element: (
                    <div>
                      <div>
                        <h2 style={{ marginTop: "0" }}>
                          +
                          {parseFloat(
                            (
                              props.checkout.code.bonus_multiplier * 100 -
                              100
                            ).toFixed(2)
                          )}
                          % Deposit Bonus
                        </h2>
                      </div>
                      <span>
                        {props.checkout.code.username + "'s Code Applied"}
                      </span>
                    </div>
                  ),
                }
              : error
              ? {
                  style: { flex: "1", paddingLeft: "10px" },
                  element: (
                    <div style={{ color: "var(--error)" }}>
                      <span>Invalid Code</span>
                    </div>
                  ),
                }
              : {
                  style: { flex: "1", paddingLeft: "10px" },
                  element: (
                    <div>
                      <span>No Code Applied</span>
                    </div>
                  ),
                },
          ]}
        />
      </div>
      <FlexGrid
        children={[
          {
            style: { flex: "1", paddingRight: "5px" },
            element: (
              <Textbox
                onChange={(e) => setCodeTxt(e.target.value)}
                value={codeTxt}
                placeholder={"Enter Your Code..."}
              />
            ),
          },
          {
            style: { flex: "0" },
            element: (
              <Btn
                context={"Apply"}
                style={{
                  fontSize: "0.8rem",
                  height: "100%",
                  color: "var(--FunctionGreen)",
                  boxSizing: "border-box",
                  border: "2px solid",
                }}
                onClick={() => props.setProperty({ code: codeTxt })}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
