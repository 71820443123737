import API from "../../index";

export default async (user_id) => {
  try {
    let { data, status } = await API.get("/api/user/" + user_id);

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
