import React from "react";

//COMPONENTS
import Background from "../Background";

export default (props) => {
  return (
    <Background
      style={props.style}
      children={
        <>
          <Background
            style={{
              left: "0",
              right: "0",
              top: "0",
              background:
                "linear-gradient(180deg, var(--background), transparent)",
              height: "10%",
            }}
          />
          <Background
            style={{
              bottom: "0",
              left: "0",
              right: "0",
              top: "unset",
              background:
                "linear-gradient(0deg, var(--background), transparent)",
              height: "10%",
            }}
          />
          <Background
            style={{
              bottom: "0",
              left: "unset",
              right: "0",
              top: "0",
              background:
                "linear-gradient(90deg, transparent,var(--background))",
              width: "10%",
            }}
          />
          <Background
            style={{
              bottom: "0",
              left: "0",
              right: "unset",
              top: "0",
              background:
                "linear-gradient(-90deg, transparent,var(--background))",
              width: "10%",
            }}
          />
          {props.children}
        </>
      }
    />
  );
};
