import React from "react";
import { useNavigate } from "react-router-dom";

//STYLES
import "./style.css";

//API
import Crates from "../../../../common/api/Crates";

//COMPONENTS
import Btn from "../../../core/buttons/Btn";

function Index(props) {
  const navigate = useNavigate();
  return (
    <Btn
      style={{
        border: "2px solid var(--mainPurple)",
        background: "rgba(101, 76, 172, 0.13)",
        width: "100%",
        height: "100%",
      }}
      id="RandomCrateBtn"
      onClick={async () => {
        const id = await Crates.get_random_crate_id();
        navigate("/unbox/" + id);
      }}
      children={
        <div>
          <h2 style={{ margin: "0" }}>Random Crate</h2>
        </div>
      }
    />
  );
}

export default Index;
