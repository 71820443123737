import React, { useEffect, useState } from "react";

//API
import Account from "../../../../../common/api/Account";

//STYLES
import "./style.css";

//COMPONENTS
import PopupWithRedirect from "../../../../layout/popups/PopupWithRedirect";

export default (props) => {
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    unsubscribe();
  }, []);

  async function unsubscribe() {
    setSuccess(await Account.marketing_subscribe_toggle({ subscribed: false }));
  }

  return success !== null ? (
    <PopupWithRedirect
      id="MarketingSubscribe"
      children={
        <div>
          <h2>You have been removed from our mailing list</h2>
        </div>
      }
    />
  ) : null;
};
