import React, { useState, createRef, useEffect } from "react";
import { useSelector } from "react-redux";

//FUNCTIONS
import DetectSizeChange from "../functions/DetectSizeChange";

//STYLES
import "./style.css";

//ELEMENTS
import Logo from "./elements/Logo";
import Nav from "./elements/Nav";
import MobileNav from "./elements/MobileNav";
import Account from "./elements/Account";
import Background from "../backgrounds/Background";
import SubHomeBar from "./elements/SubHomeBar";
import MobileXP from "./elements/MobileXP";

function Index(props) {
  const chat_style = useSelector((state) => state.chat_style_reducer);
  const session = useSelector((state) => state.session_reducer);
  const show_live_wins = useSelector((state) => state.show_livewins_reducer);
  let HomeBarRef = createRef();
  const [mobileNav, setMobileNav] = useState(false);

  let { width } = DetectSizeChange(HomeBarRef);

  useEffect(() => {
    let root = document.documentElement;
    if (width > 640) {
      setMobileNav(false);
      root.style.setProperty("--topOffset", "110px");
    } else {
      if (session) {
        root.style.setProperty("--topOffset", "88px");
      } else {
        root.style.setProperty("--topOffset", "65px");
      }
    }
  }, [width, session]);

  return (
    <div
      id="HomeBar"
      ref={HomeBarRef}
      style={{
        paddingLeft: width > 800 ? (show_live_wins ? " 84px" : "0") : "0",
      }}
    >
      <Background
        style={{
          background: "var(--gradientBlue)",
          opacity: "1",
        }}
      />
      <section
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
        className="mainPadding"
      >
        <Logo toggleMobileNav={() => setMobileNav(!mobileNav)} width={width} />
        <Nav width={width} />
        <Account width={width} />
      </section>

      {mobileNav ? (
        <MobileNav
          toggleMobileNav={() => setMobileNav(!mobileNav)}
          width={width}
        />
      ) : null}
      <SubHomeBar />
      <MobileXP />
    </div>
  );
}

export default Index;
