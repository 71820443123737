import React from "react";
import { useDispatch } from "react-redux";

//STYLES
import "./style.css";

//FUNCTIONS
import ParseDateTime from "../../../../../../../../common/functions/ParseDateTime";
import getProductRank from "../../../../../../../../common/functions/getProductRank";

//COMPONENTS
import Price from "../../../../../../../core/Price";
import FlexGrid from "../../../../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../../../../layout/flex/FlexArea";
import ProductRank from "../../../../../../products/ProductRank";
import Btn from "../../../../../../../core/buttons/Btn";
import ExpandedProduct from "../../../../../../products/Product/elements/ExpandedProduct";
import Background from "../../../../../../../common/backgrounds/Background";

function Index(props) {
  const dispatch = useDispatch();
  return (
    <div
      className="OrderProduct"
      style={{
        borderLeft:
          "3px solid var(--" + getProductRank(props.product.price) + ")",
      }}
    >
      <Background
        style={{
          background: "var(--" + getProductRank(props.product.price) + ")",
          opacity: "0.4",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      />
      <FlexGrid
        style={{ flexWrap: "nowrap", justifyContent: "start", width: "100%" }}
        children={[
          {
            style: { display: "inline-block", marginRight: "5px" },
            element: (
              <div style={{ width: "100px", height: "100px" }}>
                <ProductRank product={props.product} />
              </div>
            ),
          },
          {
            style: {},
            element: (
              <div>
                <Price
                  price={props.product.price}
                  real_price={props.product.real_price}
                />
                <p
                  style={{ margin: "0", maxWidth: "450px", fontSize: "0.8rem" }}
                >
                  {unescape(props.product.name).length > 45
                    ? unescape(props.product.name).substring(0, 45) + "..."
                    : unescape(props.product.name)}
                </p>
              </div>
            ),
          },
          {
            style: { flex: "1", marginLeft: "auto" },
            element: (
              <FlexGrid
                style={{ flexWrap: "wrap", textAlign: "right" }}
                children={[
                  {
                    style: { flex: "1 0 100%" },
                    element:
                      props.order_type == "deliver" ? (
                        props.product.in_stock == 1 ? (
                          <div>
                            {props.product?.date_arriving ? (
                              <div className="delInfo">
                                <span style={{ fontWeight: "normal" }}>
                                  Arriving:{" "}
                                </span>
                                <span style={{ color: "var(--FunctionGreen)" }}>
                                  {ParseDateTime.calculate_days(
                                    ParseDateTime.adjustForTimezone(
                                      new Date(props?.product?.date_arriving)
                                    ),
                                    new Date()
                                  )}
                                </span>
                              </div>
                            ) : props.product?.delivery_time !== null &&
                              props.product?.delivery_time !== undefined ? (
                              <div className="delInfo">
                                <span style={{ fontWeight: "normal" }}>
                                  Estimated:{" "}
                                </span>
                                <span>
                                  {"< " + props.product?.delivery_time} Days
                                </span>
                              </div>
                            ) : null}
                            <div
                              style={{ marginTop: "5px" }}
                              className="delInfo"
                            >
                              <span
                                style={{
                                  fontWeight: "normal",
                                  marginRight: "5px",
                                  verticalAlign: "middle",
                                }}
                              >
                                Shipping:{" "}
                              </span>
                              <Price price={props.product.delivery_cost} />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <span
                              style={{
                                color: "var(--error)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                              }}
                            >
                              Unavailable
                            </span>
                          </div>
                        )
                      ) : null,
                  },
                  {
                    style: {
                      flex: "1 0 100%",
                      marginTop: "auto",
                    },
                    element: (
                      <div style={{ marginTop: "10px" }}>
                        <Btn
                          context={"view"}
                          className="viewBtn"
                          style={{
                            border: "1px solid white",
                            padding: "3px 12px",
                            borderRadius: "5px",
                          }}
                          onClick={() =>
                            dispatch({
                              type: "NEW_POPUP",
                              element: (
                                <ExpandedProduct
                                  product_id={props.product.product_id}
                                  context={"Continue"}
                                  onSuccessProduct={() =>
                                    dispatch({ type: "CLOSE_POPUP" })
                                  }
                                />
                              ),
                            })
                          }
                        />
                      </div>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
