import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import ProductList from "../../components/content/inventory/ProductList";
import Search from "../../components/content/inventory/Search";

export default (props) => {
  return (
    <PageLayout
      title={"Inventory"}
      sub_title={
        <span>
          <div style={{ display: "inline-block" }}>
            <span style={{ color: "gold" }}>Convert</span>,
            <span style={{ color: "#3fbd73" }}> Deliver </span> or
            <span style={{ color: "#f39225" }}> Withdraw </span>
          </div>{" "}
          your winning items.
        </span>
      }
      background={{
        backgroundImage:
          "url(" + envConfig.s3 + "/images/crates2/background.webp)",
        opacity: "0.6",
        position: "fixed",
      }}
      children={
        <div className="Inventory">
          <Helmet>
            <title>Inventory | Vivavim</title>
            <meta
              name="description"
              content="Manage your items won on vivavim"
            />
            <link rel="canonical" href={envConfig.server + "/inventory"} />
          </Helmet>
          <Search />
          <ProductList type="inventory" />
        </div>
      }
    />
  );
};
