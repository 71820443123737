import API from "../../index";

export default async (code) => {
  try {
    let { data, status } = await API.get(
      "/api/transactions/verify_code/" + code
    );

    return status == 201 ? { data } : { error: data.error };
  } catch (e) {
    throw e;
  }
};
