import React from "react";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import HowItWorks from "../../components/content/provablyFair/HowItWorks";

function Index(props) {
  return (
    <PageLayout
      background={{
        position: "fixed",
        backgroundImage:
          "url(" +
          envConfig.s3 +
          "/images/howItWorks/provablyFair/scales.webp)",
        opacity: "0.2",
      }}
      page_top={{ title: "Provably Fair" }}
      children={<HowItWorks />}
    />
  );
}

export default Index;
