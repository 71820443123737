import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import Account from "../../../../../../../../../common/api/Account";

//STYLES
import "./style.css";

//COMPONENTS
import Popup from "../../../../../../../../layout/popups/Popup";
import XP from "../../../../../../../../content/account/ProfileSection/elements/XP";
import Confetti from "../../../../../../../Countdown/Confetti";
import FlexArea from "../../../../../../../../layout/flex/FlexArea";
import Crate from "../../../../../../../../content/crates/Crate";
import Background from "../../../../../../../backgrounds/Background";
import SuccessBtn from "../../../../../../../../core/buttons/SuccessBtn";
import ContentList from "../../../../../../../../core/ContentList";

export default (props) => {
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const account_level = useSelector((state) => state.account_level_reducer);
  const session = useSelector((state) => state.session_reducer);

  useEffect(() => {
    getRewards();
  }, []);

  async function getRewards() {
    setData(
      await Account.get_level_rewards({
        min_level: props.prevLevel || account_level.level,
        max_level: account_level.level,
      })
    );
  }

  return (
    <Popup
      id="LevelUp"
      onClose={() => {
        dispatch({ type: "CLOSE_POPUP" });
      }}
      background={<Confetti />}
      children={
        <div style={{ paddingTop: "10px" }}>
          <h1>Congratulations</h1>
          <XP
            style={{ height: "30px", fontSize: "1rem" }}
            user={Object.assign({}, session.user, {
              account_level: account_level.level,
              total_xp: account_level.xp,
            })}
            show_remaining={true}
          />
          <h1 style={{ fontWeight: "normal" }}>You have reached</h1>
          <div
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              marginBottom: "10px",
            }}
            className="highlighted"
          >
            <FlexArea
              style={{ position: "relative" }}
              children={
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    boxSizing: "border-box",
                    padding: "5px",
                    border: "2px solid var(--FunctionGreen)",
                    boxShadow: "0 0 10px var(--FunctionGreen)",
                    borderRadius: "8px",
                    margin: "auto",
                  }}
                >
                  <Background
                    style={{
                      background: "var(--FunctionGreen)",
                      opacity: "0.5",
                      borderRadius: "8px",
                    }}
                  />
                  <h1
                    style={{
                      position: "relative",
                      margin: "5px",
                    }}
                  >
                    Level {account_level.level}
                  </h1>
                </div>
              }
            />
          </div>
          {data?.new_crates?.length ? (
            <div>
              <h2 style={{ fontWeight: "normal" }}>Now Unlocked</h2>
              <FlexArea
                style={{ position: "relative", justifyContent: "center" }}
                children={
                  <ContentList
                    style={{ overflow: "visible" }}
                    content={[
                      data.new_crates.map((x) => (
                        <div
                          style={{
                            position: "relative",
                            maxWidth: "200px",
                            width: "100%",
                            margin: "auto",
                          }}
                        >
                          <Crate
                            crate={x}
                            session={session}
                            style={{ boxShadow: "0 0 100px var(--mainPurple)" }}
                          />
                        </div>
                      )),
                    ]}
                  />
                }
              />
            </div>
          ) : null}
          {data?.next_crate ? (
            <div>
              <h2 style={{ fontWeight: "normal" }}>Unlocked Next Level</h2>
              <FlexArea
                style={{ position: "relative", justifyContent: "center" }}
                children={
                  <div
                    style={{
                      position: "relative",
                      maxWidth: "200px",
                      width: "100%",
                      margin: "auto",
                    }}
                  >
                    <Crate crate={data.next_crate} session={session} />
                  </div>
                }
              />
            </div>
          ) : null}
          <div style={{ marginTop: "20px" }}>
            <SuccessBtn
              context={"continue"}
              style={{ width: "100%", fontSize: "1.4rem" }}
              onClick={() => dispatch({ type: "CLOSE_POPUP" })}
            />
          </div>
        </div>
      }
    />
  );
};
