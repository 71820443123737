import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//STYLE
import "./style.css";

//API
import Address from "../../../../../common/api/Address";

//COMPONENTS
import Popup from "../../../../layout/popups/Popup";
import FlexGrid from "../../../../layout/flex/FlexGrid";

//ELEMENTS
import List from "./elements/List";
import Selected from "./elements/Selected";

function Index(props) {
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.addresses_reducer);
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    Address.get_addresses();
  }, []);

  //REFRESH SELECTED WHEN UPDATED
  useEffect(() => {
    if (selected?.id) {
      setSelected(addresses.find((x) => x.id == selected.id));
    } else {
      setEmptyAddress();
    }
  }, [addresses]);

  function setEmptyAddress() {
    setSelected({
      address: {
        name: "",
        street_address: "",
        street_address_2: "",
        city: "",
        state: "",
        zip: "",
        phone_number: "",
        country: "United States",
      },
    });
  }

  return (
    <Popup
      id="AddressManagement"
      title="Address Management"
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        <div className="addressManagement">
          <FlexGrid
            children={[
              {
                style: { flex: "1 0 35%", minWidth: "200px" },
                element: (
                  <List
                    addresses={addresses}
                    selected={selected}
                    selectAddress={(x) => setSelected(x)}
                    newAddress={setEmptyAddress}
                  />
                ),
              },
              {
                style: { flex: "1 0 65%" },
                element: (
                  <Selected
                    data={selected}
                    context={props.context}
                    saveAddress={async (address) => {
                      const x = await Address.new(address);
                      if (x) {
                        setSelected(x);
                      }
                    }}
                    makeDefault={() =>
                      Address.make_default({ id: selected.id })
                    }
                    deleteAddress={async () => {
                      if (await Address.delete({ id: selected.id })) {
                        if (addresses.length > 0) {
                          setSelected(addresses[0]);
                        } else {
                          setSelected({
                            name: "",
                            street_address: "",
                            street_address_2: "",
                            city: "",
                            state: "",
                            zip: "",
                            phone_number: "",
                            country: "United States",
                          });
                        }
                      }
                    }}
                    confirmAddress={(x) => {
                      props.onSuccess(x);
                      dispatch({ type: "CLOSE_POPUP" });
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      }
    />
  );
}

export default Index;
