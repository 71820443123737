const show_xp_bar_reducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_XP_BAR":
      return true;
      break;
    case "HIDE_XP_BAR":
      return false;
      break;
    default:
      return state;
  }
};

export default show_xp_bar_reducer;
