import React from "react";

import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import SuccessBtn from "../../../../../../core/buttons/SuccessBtn";
import ListElement from "./elements/ListElement";

export default (props) => {
  function renderList(elements) {
    return elements.map((el) => {
      return {
        style: { flex: "1 0 50%", minWidth: "200px", marginBottom: "5px" },
        element: (
          <ListElement
            selectElement={() => props.selectAddress(el)}
            data={el}
            isSelected={el.id == props.selected?.id ? true : false}
          />
        ),
      };
    });
  }

  return (
    <div className="listWrapper">
      <h3
        style={{
          margin: "0",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        Saved {props.addresses.length + "/5"}
      </h3>
      <div style={{ marginBottom: "10px" }}>
        <SuccessBtn
          context={"New Address"}
          style={{ width: "100%" }}
          onClick={props.newAddress}
        />
      </div>
      <FlexGrid children={renderList(props.addresses)} />
    </div>
  );
};
