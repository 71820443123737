import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import CrateList from "../../components/content/rewards/CrateList";

export default (props) => {
  return (
    <PageLayout
      page_top={{}}
      title={"Daily Crates"}
      sub_title={"Free Crates available every day"}
      children={
        <div id="RewardsPage">
          <Helmet>
            <title>Daily Free | Vivavim</title>
            <meta
              name="description"
              content="Free online mystery boxes are available to unbox every day"
            />
            <link rel="canonical" href={envConfig.server + "/crates/daily"} />
          </Helmet>
          <CrateList type="rewards" />
        </div>
      }
    />
  );
};
