import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//STYLES
import "./style.css";

//COMPONENTS
import Crate from "../Crate";
import ContentList from "../../../core/ContentList";
import ContentGrid from "../../../core/ContentGrid";
import Background from "../../../common/backgrounds/Background";

function Index(props) {
  const page_content = useSelector((state) =>
    state.page_content_reducer.page == "crates"
      ? state.page_content_reducer
      : null
  );

  return (
    <div
      style={{
        position: "relative",
        marginTop: "-180px",
      }}
    >
      <h3 style={{ margin: "0", marginBottom: "5px" }}>Crates of the Week</h3>
      {page_content?.pinned_crates ? (
        <div
          style={{
            position: "relative",
            width: "100%",
            justifyContent: "center",
            boxSizing: "border-box",
            padding: "10px",
            borderTop: "2px solid white",
            borderBottom: "2px solid white",
            borderLeft: "1px solid transparent",
            borderRight: "1px solid transparent",
          }}
        >
          <Background style={{ background: "white", opacity: "0.1" }} />
          <ContentGrid
            className="PinnedCratesList"
            style={{ overflow: "visible" }}
            content={page_content.pinned_crates.slice(0, 4).map((x) => (
              <Crate crate={x} />
            ))}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Index;
