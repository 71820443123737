import React from "react";
import { useSelector } from "react-redux";

//STYLES
import "./style.css";

//COMPONENTS
import XP from "../../../../content/account/ProfileSection/elements/XP";
import Background from "../../../backgrounds/Background";

export default (props) => {
  const account_level = useSelector((state) => state.account_level_reducer);
  const session = useSelector((state) => state.session_reducer);

  return session ? (
    <div id="mobileXP" className="mainPadding">
      <Background style={{ background: "var(--background)" }} />
      <XP
        style={{ height: "20px", fontSize: "0.7rem" }}
        user={Object.assign({}, session.user, {
          account_level: account_level.level,
          total_xp: account_level.xp,
        })}
        showRemaining={false}
      />
    </div>
  ) : null;
};
