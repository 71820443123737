import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";

//FUNCTIONS
import Inventory from "../../../../common/api/Inventory";

//COMPONENTS
import SearchBar from "../../../core/search/SearchBar";
import FloatingBar from "../../../layout/FloatingBar";
import Background from "../../../common/backgrounds/Background";
import Checkbox from "../../../core/inputs/Checkbox";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";
import Btn from "../../../core/buttons/Btn";
import OptionButtons from "../MultiSelectOptions/elements/OptionButtons";

export default (props) => {
  const dispatch = useDispatch();
  const page_content = useSelector((state) => state.page_content_reducer);

  return (
    <FloatingBar
      children={
        <div style={{ position: "relative", width: "100%" }}>
          <Background
            style={{
              background: "var(--background)",
              opacity: "0.85",
            }}
          />
          <SearchBar
            context={"products"}
            value={page_content?.query}
            onSearch={({ query = null, order_by = "time: low - high" }) =>
              Inventory.search_inventory({
                query,
                order_by,
                offset: 0,
              })
            }
            order_by={{
              selected: page_content?.order_by || "time: low - high",
              options: [
                { title: "Recently Won", value: "time: low - high" },
                {
                  title: "Price: Low - High",
                  value: "Price: Low - High",
                },
                {
                  title: "Price: High - Low",
                  value: "Price: High - Low",
                },
              ],
            }}
            elements={[
              {
                style: { flex: "1" },
                element: (
                  <FlexGrid
                    style={{
                      justifyContent: "left",
                      height: "100%",
                      fontSize: "0.8rem",
                    }}
                    children={[
                      {
                        style: { flex: "0" },
                        element: (
                          <FlexArea
                            style={{ padding: "0 5px 0 5px" }}
                            children={
                              <>
                                <Checkbox
                                  checked={page_content.items}
                                  onChange={(e) =>
                                    Inventory.search_inventory({
                                      items: e.target.checked,
                                    })
                                  }
                                  style={{
                                    margin: "auto 5px auto 0",
                                    height: "18px",
                                    width: "18px",
                                  }}
                                />
                                <span style={{ margin: "auto 0 auto 0" }}>
                                  Items
                                </span>
                              </>
                            }
                          />
                        ),
                      },
                      {
                        style: { flex: "0" },
                        element: (
                          <FlexArea
                            style={{ padding: "0 5px 0 5px" }}
                            children={
                              <>
                                <Checkbox
                                  checked={page_content.coin}
                                  onChange={(e) =>
                                    Inventory.search_inventory({
                                      coin: e.target.checked,
                                    })
                                  }
                                  style={{
                                    margin: "auto 5px auto 0",
                                    height: "18px",
                                    width: "18px",
                                  }}
                                />
                                <span style={{ margin: "auto 0 auto 0" }}>
                                  Coin
                                </span>
                              </>
                            }
                          />
                        ),
                      },
                      {
                        style: { flex: "1" },
                        element: (
                          <FlexArea
                            style={{ padding: "0 5px 0 5px" }}
                            children={
                              !page_content.data.length ||
                              page_content.selected.length !==
                                page_content.max ? (
                                <Btn
                                  context={"Select All"}
                                  onClick={() =>
                                    Inventory.select_all({
                                      items: page_content.items,
                                      coin: page_content.coin,
                                    })
                                  }
                                  style={{
                                    color: "var(--FunctionGreen)",
                                    padding: "0",
                                    margin: "auto 5px auto 0",
                                  }}
                                />
                              ) : (
                                <Btn
                                  context={"Cancel"}
                                  onClick={() =>
                                    dispatch({
                                      type: "CLEAR_PAGE_CONTENT_SELECTED",
                                    })
                                  }
                                  style={{
                                    color: "var(--error)",
                                    padding: "0",
                                    margin: "auto 5px auto 0",
                                  }}
                                />
                              )
                            }
                          />
                        ),
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
          {page_content.selected.length ? (
            <div
              className="mainPadding"
              style={{
                position: "absolute",
                top: "100%",
                width: "100%",
              }}
            >
              <FlexGrid
                children={[
                  {
                    style: {
                      flex: "1",
                      maxWidth: "600px",
                      marginLeft: "auto",
                      background: "var(--background)",
                      padding: "5px",
                      borderBottomLeftRadius: "8px",
                    },
                    element: (
                      <OptionButtons
                        content={page_content}
                        products={page_content.selected}
                        onConvert={() => {}}
                        onOrderBy={(order_by) => /*Inventory.search_inventory({
                  query: page_content.query,
                  order_by,
                  offset: 0,
                })*/ {}}
                      />
                    ),
                  },
                ]}
              />
            </div>
          ) : null}
        </div>
      }
    />
  );
};
