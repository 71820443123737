import React from "react";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../common/functions/GeneralFunctions";
import ParseDatetime from "../../../../../../common/functions/ParseDateTime";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";

function Index(props) {
  return (
    <div
      style={{ width: "100%", marginBottom: "10px" }}
      className="ListElement"
    >
      <Link to={"/transactions/" + props.obj.id}>
        <FlexGrid
          children={[
            {
              style: { flex: "1 0 25%" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    <img
                      style={{
                        verticalAlign: "middle",
                        width: "30px",
                        marginRight: "5px",
                      }}
                      src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                    />
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        verticalAlign: "middle",
                      }}
                    >
                      {GeneralFunctions.parse_currency(props.obj.value)}
                    </span>
                  </div>
                </section>
              ),
            },
            {
              style: { flex: "1 0 25%" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    {(() => {
                      switch (props.obj.method.type) {
                        case "card":
                          return (
                            <div>
                              <img
                                style={{
                                  width: "40px",
                                  verticalAlign: "middle",
                                  marginRight: "5px",
                                }}
                                src={
                                  envConfig.s3 +
                                  "/images/icons/checkout/card_icon.svg"
                                }
                              />
                              <span style={{ verticalAlign: "middle" }}>
                                Card
                              </span>
                            </div>
                          );
                        case "crypto":
                          return (
                            <div>
                              <img
                                style={{
                                  width: "35px",
                                  verticalAlign: "middle",
                                  marginRight: "10px",
                                }}
                                src={
                                  envConfig.s3 +
                                  "/images/icons/checkout/bitcoin.svg"
                                }
                              />
                              <span style={{ verticalAlign: "middle" }}>
                                Crypto
                              </span>
                            </div>
                          );
                      }
                    })()}
                  </div>
                </section>
              ),
            },
            {
              style: { flex: "1 0 25%" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    <span>{props.obj.id}</span>
                  </div>
                </section>
              ),
            },
            {
              style: { flex: "1 0 25%" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    <span>{ParseDatetime.parseDateTime(props.obj.date)}</span>
                  </div>
                </section>
              ),
            },
          ]}
        />
      </Link>
    </div>
  );
}

export default Index;
