import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//API
import Transactions from "../../../../common/api/Transactions";
import ContentList from "../../../core/ContentList";
import ListElement from "./elements/ListElement";

//COMPONENTS
import SearchOptions from "../../../core/search/SearchOptions";
import SearchBar from "../../../core/search/SearchBar";
import Background from "../../../common/backgrounds/Background";
import PageControls from "../../../core/PageControls";
import EmptyPlaceholder from "../../../common/EmptyPlaceholder";

function Index(props) {
  const [loading, showLoading] = useState(true);
  const [data, setData] = useState({ content: [], max: 0 });
  const [filters, setFilters] = useState({
    query: null,
    order_by: "most_recent",
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    search_transactions(filters);
  }, [filters]);

  async function search_transactions(filters) {
    showLoading(true);
    const { content, max } = await Transactions.search({
      id: filters.query,
      value: null,
      date: null,
      order_by: filters.order_by,
      offset: filters.offset,
      limit: filters.limit,
    });
    setData({ content: content || [], max });

    showLoading(false);
  }

  return (
    <div id="Transactions">
      <SearchOptions
        selected={[]}
        order_by={filters.order_by}
        options={[{ name: "Recently Won", value: "most_recent" }]}
        style={{ top: "0" }}
        children={
          <>
            <Background
              style={{
                background: "var(--background)",
                opacity: "0.85",
              }}
            />
            <div
              style={{
                position: "relative",
                margin: "auto",
                marginLeft: "0",
              }}
            >
              <h2 style={{ position: "relative", margin: "0" }}>
                Transactions
              </h2>
            </div>
            <div
              style={{
                margin: "auto",
                marginRight: "0",
                minWidth: "300px",
              }}
            >
              <SearchBar
                context={"transactions"}
                onSearch={({ query = null, order_by = null }) =>
                  setFilters(
                    Object.assign(
                      {},
                      filters,
                      query ? { query } : null,
                      order_by ? { order_by } : null
                    )
                  )
                }
                order_by={{
                  options: [
                    { title: "Most Recent", value: "Most Recent" },
                    {
                      title: "Value: Low - High",
                      value: "Value: Low - High",
                    },
                    {
                      title: "Value: High - Low",
                      value: "Value: High - Low",
                    },
                  ],
                  selected: filters.order_by,
                }}
              />
            </div>
          </>
        }
      />
      {!loading ? (
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              background: "var(--secondaryBackground)",
              padding: "10px",
              marginTop: "10px",
              borderRadius: "8px",
              overflowX: "auto",
            }}
          >
            <ContentList
              style={{ minWidth: "700px" }}
              headers={[
                { style: { flex: "1 0 25%" }, element: <span>Value</span> },
                { style: { flex: "1 0 25%" }, element: <span>Method</span> },
                { style: { flex: "1 0 25%" }, element: <span>ID</span> },
                { style: { flex: "1 0 25%" }, element: <span>Date</span> },
              ]}
              content={data.content.map((x) => (
                <ListElement obj={x} />
              ))}
            />
            {!data.content.length ? (
              <EmptyPlaceholder
                context={"transactions"}
                redirect={{ page: "checkout", route: "/checkout" }}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      <PageControls
        offset={filters.offset}
        limit={data?.max}
        pageLimit={filters.limit}
        onSelectPage={(offset) =>
          setFilters(Object.assign({}, filters, { offset }))
        }
      />
    </div>
  );
}

export default Index;
