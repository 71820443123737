//CONTROLLERS
import add_live_win from "./add_live_win";
import update_products from "./update_products";
import transaction_complete from "./transaction_complete";
import add_chat_message from "./add_chat_message";
import update_order from "./update_order";
import logout from "./logout";

export default {
  add_live_win,
  update_products,
  transaction_complete,
  add_chat_message,
  update_order,
  logout,
};
