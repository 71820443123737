import React from "react";

//STYLES
import "./style.css";

export default (props) => {
  return (
    <div
      className={"Background" + (props.className ? " " + props.className : "")}
      {...props}
    >
      {props.children}
    </div>
  );
};
