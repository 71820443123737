import API from "../../index";

//FUNCTIONS
import UnboxingFunctions from "../../../../components/content/unbox/functions/Unboxing.functions";

export default async (obj) => {
  try {
    let { data, status, error } = await API.post("/unboxing", {
      crate_inventory: UnboxingFunctions.process_crate_inventory(
        obj.crate_inventory
      ),
      unboxing_cost: obj.unboxing_cost,
      unboxing_session: obj.unboxing_session,
    });

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
