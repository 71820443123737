import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//API
import Products from "../../../../../common/api/Products";

//COMPONENTS
import Popup from "../../../../layout/popups/Popup";
import ProductList from "../../ProductList";
import Product from "../../Product";
import SearchBar from "../../../../core/search/SearchBar";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import SuccessBtn from "../../../../core/buttons/SuccessBtn";
import OptionsList from "../../../../core/OptionsList";
import PageControls from "../../../../core/PageControls";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({ offset: 0, order_by: null });
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(props.selected || []);
  const [data, setData] = useState(null);

  useEffect(() => {
    search({ query: null, vender: "amazon", offset: 0 });
  }, []);

  async function search(filters) {
    setLoading(true);
    filters = Object.assign(
      {},
      { vender: "amazon", offset: 0, order_by: "A-Z" },
      filters
    );
    const data = await Products.search_products(filters);
    setData(data);
    setFilters(filters);
    setLoading(false);
  }

  function addSelected(product) {
    setSelected([product, ...selected]);
  }

  function removeSelected(product) {
    setSelected(selected.filter((x) => x.id !== product.id));
  }

  return (
    <Popup
      className="mainPadding"
      onClose={() => {
        dispatch({ type: "CLOSE_POPUP" });
        props.onClose();
      }}
      children={
        data ? (
          <FlexGrid
            style={{
              margin: "auto",
            }}
            children={[
              {
                style: { flex: "1 0 100%" },
                element: (
                  <FlexGrid
                    style={{ textAlign: "left" }}
                    children={[
                      { style: { flex: "1" }, element: <h3>Add Items</h3> },
                    ]}
                  />
                ),
              },
              {
                style: { flex: "1 0 100%" },
                element: (
                  <SearchBar
                    style={{ padding: "0", maxWidth: "unset" }}
                    context={"product"}
                    onSearch={search}
                    order_by={{
                      selected: filters.order_by,
                      options: [
                        { title: "A-Z" },
                        {
                          title: "Price: Low - High",
                          value: "Price: Low - High",
                        },
                        {
                          title: "Price: High - Low",
                          value: "Price: High - Low",
                        },
                      ],
                    }}
                    elements={[
                      {
                        style: {
                          marginRight: "auto",
                          flex: "1",
                          maxWidth: "450px",
                        },
                        element: (
                          <OptionsList
                            selectOption={(vender) =>
                              search(
                                Object.assign(
                                  {},
                                  { vender },
                                  vender == "vivavim"
                                    ? { order_by: "Price: Low - High" }
                                    : null
                                )
                              )
                            }
                            selected={filters.selected}
                            options={[
                              {
                                title: "Item",
                                value: "amazon",
                                image: "/images/icons/categories/products.svg",
                                color: "white",
                              },
                              {
                                title: "Coin",
                                value: "vivavim",
                                image: "/images/icons/common/vivacoin.svg",
                                color: "white",
                              },
                            ]}
                          />
                        ),
                      },
                    ]}
                  />
                ),
              },
              {
                style: { flex: "1 0 100%", minHeight: "800px" },
                element: (
                  <div>
                    <Background
                      style={{
                        background: "var(--secondaryBackground)",
                        borderRadius: "8px",
                      }}
                    />
                    <ProductList
                      style={{
                        padding: "10px",
                        boxSizing: "border-box",
                      }}
                      products={data.content.map((x) => (
                        <Product
                          key={
                            x.id + "-" + [...selected].find((y) => x.id == y.id)
                          }
                          product={x}
                          show_selected={true}
                          selected={selected.find((y) => x.id == y.id)}
                          onSuccessProduct={(product) =>
                            !selected.find((y) => x.id === y.id)
                              ? addSelected(x)
                              : () => {}
                          }
                          context={"Add Item"}
                          onClick={(product) =>
                            !selected.find((y) => x.id === y.id)
                              ? addSelected(product)
                              : removeSelected(product)
                          }
                        />
                      ))}
                    />
                  </div>
                ),
              },
              {
                style: { flex: "1 0 100%" },
                element: (
                  <PageControls
                    limit={data.max}
                    pageLimit={25}
                    offset={filters.offset}
                    onSelectPage={(offset) =>
                      search(Object.assign({}, filters, { offset }))
                    }
                  />
                ),
              },
              {
                style: {
                  flex: "1 0 100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                },
                element: (
                  <div style={{ textAlign: "right", width: "100%" }}>
                    <SuccessBtn
                      style={{ fontSize: "1.2rem" }}
                      context={"Continue"}
                      onClick={
                        selected.length
                          ? () => props.onSuccess(selected)
                          : () => {}
                      }
                    />
                  </div>
                ),
              },
            ]}
          />
        ) : loading ? (
          <div className="lds-loading">
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : null
      }
    />
  );
};
