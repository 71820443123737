import React from "react";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Banner from "../../../core/Banner";
import Background from "../../../common/backgrounds/Background";
import FlexArea from "../../../layout/flex/FlexArea";
import FlexGrid from "../../../layout/flex/FlexGrid";

function Index(props) {
  return (
    <Banner
      id="USA_Only"
      {...props}
      children={
        <div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "linear-gradient(90deg, rgb(179, 35, 53), #00aaff)",
            }}
          >
            <Background
              style={{
                opacity: "0.6",
                background: "var(--background)",
                top: "1px",
                left: "1px",
                bottom: "1px",
                right: "1px",
                width: "unset",
                height: "unset",
              }}
            />
          </div>
          <FlexGrid
            children={[
              {
                style: {
                  position: "relative",
                  flex: "1",
                  height: "60px",
                },
                element: (
                  <FlexArea
                    style={{
                      height: "100%",
                      width: "100%",
                      fontSize: "0.8rem",
                    }}
                    children={
                      <>
                        <div
                          style={{ boxSizing: "border-box", padding: "10px" }}
                        >
                          <img
                            src={envConfig.s3 + "/images/logos/us-logo.svg"}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div style={{ margin: "auto", marginLeft: "0" }}>
                          <h3 style={{ margin: "0" }}>US Shipping</h3>
                        </div>
                      </>
                    }
                  />
                ),
              },
              {
                style: {
                  position: "relative",
                  marginLeft: "auto",
                  height: "60px",
                  textAlign: "right",
                  flex: "1",
                },
                element: (
                  <FlexArea
                    style={{
                      height: "100%",
                      width: "100%",
                      fontSize: "0.8rem",
                      justifyContent: "end",
                    }}
                    children={
                      <>
                        <div style={{ margin: "auto", marginRight: "0" }}>
                          <h3 style={{ margin: "0" }}>Giftcards Worldwide</h3>
                        </div>
                        <div
                          style={{ boxSizing: "border-box", padding: "10px" }}
                        >
                          <img
                            src={envConfig.s3 + "/images/icons/giftcard.svg"}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </>
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      }
    />
  );
}

export default Index;
