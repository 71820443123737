import React, { useState, useEffect } from "react";

//COMPONENTS
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";
import Dropdown from "../../Dropdown";

export default (props) => {
  const [query, setQuery] = useState("");
  const [focus, setFocus] = useState(false);

  function handleKeyPress(e) {
    if (e.keyCode == 13) {
      onSearch(query);
      document.getElementById("navBarSearchTxt").blur();
    }
  }

  useEffect(() => {
    setQuery(props.query);
  }, [props.query]);

  function onSearch(query) {
    if (query) props.onSearch({ query });
  }

  return (
    <FlexGrid
      id="navBarSearchWrapper"
      style={{ justifyContent: "right" }}
      children={[
        {
          style: { position: "relative", flex: "0 0 75%", height: "100%" },
          element: (
            <section
              id="navBarSearch"
              className="highlighted"
              style={Object.assign(
                {},
                {
                  padding: "0",
                },
                props.order_by
                  ? { borderTopRightRadius: "0", borderBottomRightRadius: "0" }
                  : null
              )}
            >
              <div
                style={{
                  position: "relative",
                  margin: "auto",
                  width: "100%",
                  margin: "0",
                  textAlign: "right",
                }}
              >
                <input
                  type="text"
                  id="navBarSearchTxt"
                  placeholder={!focus ? "Search " + props.context + "..." : ""}
                  autoComplete="off"
                  value={query || ""}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyPress}
                  style={{
                    borderRadius: !props.order_by ? "20px" : null,
                    background: "transparent",
                  }}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
              </div>
              <button
                id="navBarSearchBtn"
                style={{ padding: "0" }}
                onClick={() => onSearch(query)}
              >
                <FlexArea
                  children={
                    <svg
                      style={{ width: "15px", margin: "auto" }}
                      data-name="Layer 1"
                      viewBox="0 0 291.45 304.8"
                    >
                      <path
                        d="M210.76 39.54a121.2 121.2 0 1 0-27.54 185.72l72.43 79.54 35.8-32.6L219 192.66a121.39 121.39 0 0 0-8.24-153.12ZM170.16 175A72.79 72.79 0 1 1 175 72.13 72.88 72.88 0 0 1 170.16 175Z"
                        style={{
                          fill: "white",
                        }}
                      />
                    </svg>
                  }
                />
              </button>
            </section>
          ),
        },
        props.order_by
          ? {
              style: { position: "relative", flex: "0 0 25%" },
              element: (
                <FlexArea
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                  children={
                    <Dropdown
                      className="highlighted"
                      style={{
                        borderRadius: "0px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        boxShadow: "none",
                        boxSizing: "border-box",
                        fontSize: "0.8rem",
                        height: "100%",
                        borderLeft: "1px solid var(--highlighted)",
                      }}
                      options={props.order_by.options}
                      value={props.order_by.selected}
                      onChange={(order_by) => {
                        return props.onSearch({
                          query,
                          order_by: /*props.order_by.options.find(
                            (x) => x.title == order_by
                          )?.value*/ order_by,
                        });
                      }}
                    />
                  }
                />
              ),
            }
          : {},
      ]}
    />
  );
};
