import React from "react";
import { useSelector, useDispatch } from "react-redux";

//FUNCTIONS
import UnboxingFunctions from "../functions/Unboxing.functions";

//COMPONENTS
import OptionsBar from "./elements/OptionsBar";

export default (props) => {
  const dispatch = useDispatch();

  const unboxing = useSelector((state) => state.unboxing_reducer);
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);

  return (
    <div id="ItemOptions">
      <OptionsBar
        products={crate_inventory.products}
        toggle_edit_crate={() =>
          dispatch({
            type: "POPULATE_UNBOXING",
            object: { is_editing: !unboxing.is_editing },
          })
        }
        info={UnboxingFunctions.calculate_crate_info(crate_inventory.products)}
        unboxing={unboxing}
      />
    </div>
  );
};
