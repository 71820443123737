import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";
import ProfileImg from "../../../account/ProfileImg";
import XP from "../../../../content/account2/elements/ProfileTop/elements/XP";
import DepositWithCodeBtn from "../../../account/DepositWithCodeBtn";

export default (props) => {
  return (
    <div id="Info">
      <div style={{ margin: "auto", flex: "1 0 30%" }}>
        <FlexGrid
          children={[
            {
              style: { padding: "10px" },
              element: (
                <FlexArea
                  children={
                    <ProfileImg
                      style={{
                        width: "140px",
                        height: "140px",
                        display: "inline-block",

                        margin: "20px",
                        margin: "auto",
                      }}
                      user={props.profile.user}
                    />
                  }
                />
              ),
            },
            {
              style: { padding: "10px", flex: "1 0 70%" },
              element: (
                <FlexArea
                  children={
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "100%",
                        margin: "auto",
                      }}
                    >
                      <h1
                        style={{
                          verticalAlign: "middle",
                          margin: "0",
                        }}
                      >
                        {props.profile.user.username}
                      </h1>
                      <div style={{ marginTop: "10px" }}>
                        <XP
                          user={{
                            total_xp: props.profile.user.total_xp,
                            account_level: props.profile.user.account_level,
                          }}
                          show_remaining={true}
                        />
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <DepositWithCodeBtn
                          user={props.profile.user}
                          style={{ padding: "0" }}
                        />
                      </div>
                    </div>
                  }
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
