const audio_reducer = (
  state = { muted: false, volume: 50, source: null },
  action
) => {
  switch (action.type) {
    case "SET_AUDIO":
      return Object.assign({}, state, action.audio);
    case "ADD_AUDIO":
      return Object.assign({}, state, {
        source: !state.muted ? action.source : null,
      });
    case "REMOVE_AUDIO":
      return Object.assign({}, state, { source: null });
    default:
      return state;
  }
};

export default audio_reducer;
