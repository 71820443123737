import React, { Component } from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Dropdown from "../../Dropdown";

export default class index extends Component {
  render() {
    return (
      <div className="OrderBy">
        <p>
          Order{" "}
          {this.props.query ? (
            <span
              style={{
                color: this.props.query.color,
                fontWeight: "bold",
              }}
            >
              {this.props.query.title}
            </span>
          ) : null}{" "}
          by
          <Dropdown
            onChange={this.props.onChange}
            value={this.props.order_by}
            options={this.props.options}
          />
        </p>
      </div>
    );
  }
}
