import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//FUNCTIONS
import getProductRank from "../../../../../common/functions/getProductRank";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../layout/flex/FlexArea";
import GlowImageBackground from "../../../../common/backgrounds/GlowImageBackground";
import Background from "../../../../common/backgrounds/Background";
import ProductRank from "../../../products/ProductRank";
import Price from "../../../../core/Price";

export default (props) => {
  return (
    <div id="Win">
      {props.win ? (
        <FlexGrid
          style={{ flexDirection: "column-reverse", height: "100%" }}
          children={[
            {
              style: { flex: "1 0 100%" },
              element: (
                <div className="wrapper">
                  <GlowImageBackground
                    style={{
                      borderRadius: "8px",
                      border: "1px solid var(--highlighted)",
                    }}
                    image={
                      envConfig.s3 +
                      "/images/crates2/wallpapers/" +
                      props.win.crate_id +
                      ".webp"
                    }
                  />
                  <div
                    className="overlay"
                    style={{
                      border:
                        "2px solid var(--" +
                        getProductRank(props.win.price) +
                        ")",
                    }}
                  >
                    <Background
                      style={{
                        background: "var(--background)",
                        opacity: "0.7",
                        borderRadius: "8px",
                      }}
                    />
                    <FlexGrid
                      style={{ height: "100%" }}
                      children={[
                        {
                          style: { flex: "1 0 100%" },
                          element: (
                            <div
                              className="highlighted"
                              style={{ padding: "5px", marginBottom: "10px" }}
                            >
                              <span style={{ marginLeft: "10px" }}>
                                Roll:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "var(--FunctionGreen)",
                                  }}
                                >
                                  {props.win.roll}
                                </span>
                              </span>
                            </div>
                          ),
                        },
                        {
                          style: { flex: "1 0 100%" },
                          element: (
                            <FlexArea
                              children={
                                <div
                                  style={{
                                    margin: "auto",
                                    width: "100%",
                                  }}
                                >
                                  <FlexGrid
                                    children={[
                                      {
                                        style: {},
                                        element: (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              width: "150px",
                                              height: "150px",
                                            }}
                                          >
                                            <ProductRank product={props.win} />
                                          </div>
                                        ),
                                      },
                                      {
                                        style: { flex: "1", minWidth: "200px" },
                                        element: (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              fontSize: "0.9rem",
                                            }}
                                          >
                                            <div>
                                              <span>
                                                {unescape(props.win.name)
                                                  .length > 75
                                                  ? unescape(
                                                      props.win.name
                                                    ).substring(0, 75) + "..."
                                                  : unescape(props.win.name)}
                                              </span>
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                              <Price price={props.win.price} />
                                            </div>
                                            <div style={{ marginTop: "10px" }}>
                                              <span>
                                                Chance:{" "}
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "var(--error)",
                                                  }}
                                                >
                                                  {props.win.chance}%
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              ),
            },
            {
              style: { flex: "1 0 100%" },
              element: (
                <h4 style={{ marginBottom: "5px", marginTop: "0" }}>
                  {props.title}
                </h4>
              ),
            },
          ]}
        />
      ) : null}
    </div>
  );
};
