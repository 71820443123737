import API from "../../index";

export default async () => {
  try {
    let { status } = await API.post("/api/auth/sign-out");
    return status == 200 ? true : false;
  } catch (e) {
    throw e;
  }
};
