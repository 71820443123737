import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";

//CONFIG
import envConfig from "../../common/config/env.config";
import categories from "../../components/content/HowItWorks/categories.json";

//COMPONENTS
import HowItWorks from "../../components/content/HowItWorks";
import PageLayout from "../../components/layout/PageLayout";
import OptionsList from "../../components/core/OptionsList";

export default (props) => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <PageLayout
      title={"How it Works"}
      children={
        <>
          <Helmet>
            <title>How it works | Vivavim</title>
            <meta
              name="description"
              content="Learn how to unbox items on vivavim"
            />
            <link rel="canonical" href={envConfig.server + "/howItWorks"} />
          </Helmet>
          <HowItWorks params={params} />
        </>
      }
    />
  );
};
