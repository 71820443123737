import API from "../../index";

export default async (crate_id) => {
  try {
    let { data, status } = await API.get(
      "/api/crates/" + crate_id + "/unboxing"
    );

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
