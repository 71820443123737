import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//STYLES
import "./style.css";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//FUNCTIONS
import { parseDateTime } from "../../../../../../common/functions/ParseDateTime";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import OrderViewTracking from "./elements/OrderViewTracking";
import ProductRank from "../../../../products/ProductRank";
import Background from "../../../../../common/backgrounds/Background";
import getProductRank from "../../../../../../common/functions/getProductRank";
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";
import FlexArea from "../../../../../layout/flex/FlexArea";

function Index(props) {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config_reducer);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    renderProducts();
  }, []);

  function renderCoinMethod(currency) {
    switch (currency) {
      case "BTC":
        return (
          <img
            className="methodIcon"
            src={envConfig.s3 + "/images/icons/checkout/bitcoin.svg"}
          />
        );
        break;
      case "LTC":
        return (
          <img
            className="methodIcon"
            src={envConfig.s3 + "/images/icons/checkout/litecoin.svg"}
          />
        );
        break;
      case "ETH":
        return (
          <img
            className="methodIcon"
            src={envConfig.s3 + "/images/icons/checkout/ethereum.svg"}
          />
        );
        break;
      case "BCH":
        return (
          <img
            className="methodIcon"
            src={envConfig.s3 + "/images/icons/checkout/bitcoin_cash.svg"}
          />
        );
        break;
    }
  }

  function renderProducts() {
    let productsElements = [];
    var x = JSON.parse(JSON.stringify(props.order.products)).sort((a, b) =>
      a.price < b.price ? 1 : -1
    );
    for (let i = 0; i < (x.length > 1 ? 2 : x.length); i++) {
      productsElements.push(
        <div
          className="orderProductRankWrapper"
          style={
            products.length > 1
              ? { width: "120px", height: "120px" }
              : { width: "180px", height: "180px" }
          }
        >
          <ProductRank product={x[i]} />
        </div>
      );
    }

    setProducts(productsElements);
  }

  return (
    <div className="orderViewWrapper" order_id={props.order.id}>
      <Background
        style={{
          background: "var(--background)",
          opacity: "0.5",
          borderRadius: "8px",
        }}
      />

      <div
        style={{
          position: "absolute",
          bottom: "100%",
          left: "0",
          width: "100%",
        }}
      >
        <FlexGrid
          children={[
            {
              style: { flex: "1 0", maxWidth: "150px" },
              element: (
                <div
                  style={{
                    position: "relative",
                    maxWidth: "150px",
                    boxSizing: "border-box",
                    padding: "5px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    color:
                      props.order.order_type == "deliver"
                        ? "var(--FunctionGreen)"
                        : "var(--crypto)",
                    border: "1px solid",
                    borderBottom: "0",
                  }}
                >
                  <Background
                    style={{
                      background: "var(--background)",
                      opacity: "0.5",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {props.order.order_type}
                  </span>
                </div>
              ),
            },
            {
              style: { paddingLeft: "10px" },
              element: (
                <FlexArea
                  children={
                    <span style={{ margin: "auto", marginLeft: "0" }}>
                      {props.order.products.length} Item
                      {props.order.products.length > 1 ? "s" : ""}
                    </span>
                  }
                />
              ),
            },
            {
              style: { flex: "1 0", marginLeft: "auto" },
              element: (
                <FlexArea
                  children={
                    <span style={{ margin: "auto", marginRight: "0" }}>
                      {parseDateTime(props.order.timestamp)}
                    </span>
                  }
                />
              ),
            },
          ]}
        />
      </div>
      <FlexGrid
        style={{
          border:
            "1px solid " +
            (props.order.order_type == "deliver"
              ? "var(--FunctionGreen)"
              : "var(--crypto)"),
          borderRadius: "8px",
          borderTopLeftRadius: "0",
          padding: "5px",
        }}
        children={[
          {
            style: { flex: "1 0" },
            element: (
              <div className="orderViewLeftWrapper">
                <section
                  style={{ display: "flex", width: "100%", height: "100%" }}
                >
                  {(() => {
                    switch (props.order.order_type) {
                      case "deliver":
                        return (
                          <div className="orderViewProductsWrapper">
                            <div
                              className="orderProductRankWrapper"
                              style={{ width: "130px", height: "130px" }}
                            >
                              <ProductRank product={props.order.products[0]} />
                            </div>
                          </div>
                        );
                      case "giftcard":
                        return (
                          <div style={{ margin: "auto", marginLeft: "0" }}>
                            <div
                              className="giftcardIconWrapper"
                              style={{ width: "130px" }}
                            >
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={
                                  envConfig.s3 + "/images/icons/giftcard.svg"
                                }
                              />
                            </div>

                            <div className="methodInfo">
                              <section>
                                <div style={{ margin: "auto" }}>
                                  <p
                                    className="methodTitle"
                                    style={{ color: "rgb(43, 189, 115)" }}
                                  ></p>
                                </div>
                              </section>
                            </div>
                          </div>
                        );
                      case "withdraw":
                        return (
                          <div style={{ margin: "auto", marginLeft: "0" }}>
                            {renderCoinMethod(props.order.method.coin_type)}
                          </div>
                        );
                    }
                  })()}
                </section>
              </div>
            ),
          },
          {
            style: { flex: "1 0 55%" },
            element: (
              <section
                style={{ display: "flex", width: "100%", height: "100%" }}
              >
                <div className="orderViewRightWrapper">
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    <div className="orderViewTrackingWrapper">
                      <OrderViewTracking order={props.order} />
                    </div>
                  </div>
                </div>
              </section>
            ),
          },
          {
            style: { flex: "0 0 30px", paddingTop: "5px", marginLeft: "auto" },
            element: (
              <Link to={"/orders/" + props.order.id}>
                <section
                  className="trackBtn"
                  style={{ display: "flex", width: "100%", height: "100%" }}
                >
                  <p style={{ margin: "auto" }}>
                    <i class="arrow right"></i>
                  </p>
                </section>
              </Link>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
