import React from "react";

//STYLES
import "./row.css";

export default function (props) {
  return (
    <div
      className={"flexRow" + (props.className ? " " + props.className : "")}
      style={props.style || null}
    >
      {props.children}
    </div>
  );
}
