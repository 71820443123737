export default {
  profile_section_wrapper: {
    position: "relative",
  },
  profile_section: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    paddingTop: "20px",
  },
  inner: {
    margin: "auto",
  },
  profile_img: {
    position: "relative",
    width: "150px",
    height: "150px",
    margin: "auto",
  },
  info: {
    position: "relative",
    display: "inline-block",
    margin: "auto",
    paddingLeft: "10px",
    boxSizing: "border-box",
    width: "100%",
    textAlign: "left",
    borderRadius: "8px",
  },
  username: {
    position: "relative",
    margin: "auto",
    fontSize: "2rem",
    boxSizing: "border-box",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  info_el: {
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "1.2rem",
    marginTop: "15px",
    marginBottom: "15px",
  },
  level: {
    position: "relative",
    marginTop: "10px",
    marginBottom: "0px",
    padding: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  bottom: {
    position: "relative",
    width: "100%",
  },
};
