import RollFunctions from "./roll.functions";
import cryptojs from "crypto-js";
import { nanoid } from "nanoid";
let decimal_places = 5;

export default {
  checkProductListsSame: (list1, list2) => {
    if (list1.length === list2.length) {
      for (var i = 0; i < list1.length; i++) {
        if (
          !list2.find((element) => {
            return (
              element.product_id == list1[i].product_id &&
              element.probability == list1[i].probability
            );
          })
        ) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  },
  fix_product_list: (product_list) => {
    product_list = JSON.parse(JSON.stringify(product_list));
    var sorted = product_list.slice(0);
    var totalOutOfStockProb = 0;

    //DISTRIBUTE OUT OF STOCK PRODUCT ODDS
    if (product_list.length > 1) {
      product_list = product_list.filter((product) => {
        if (product.in_stock == 0) {
          totalOutOfStockProb += parseFloat(
            product.probability.toFixed(decimal_places)
          );
        }
        return product.in_stock == 1;
      });

      product_list[product_list.length - 1].probability = parseFloat(
        (
          product_list[product_list.length - 1].probability +
          totalOutOfStockProb
        ).toFixed(decimal_places)
      );

      //DISTRIBUTE REMAINING ODDS

      product_list = product_list.sort((a, b) => {
        return a.probability - b.probability;
      });

      let remaining_odds = 0;
      for (var i = 0; i < product_list.length; i++) {
        remaining_odds = remaining_odds + product_list[i].probability;
      }
      remaining_odds = parseFloat(
        (100 - remaining_odds).toFixed(decimal_places)
      );

      for (var i = product_list.length - 1; i >= 0; i--) {
        //IF TOTAL ODDS > 100%
        if (remaining_odds < 0) {
          //IF PRODUCT ODDS HAS ENOUGH TO TAKE REMAINING FROM
          if (product_list[i].probability + remaining_odds >= 0.01) {
            product_list[i].probability += remaining_odds;
            break;
          } else {
            remaining_odds += product_list[i].probability - 0.01;

            product_list[i].probability = 0.01;
          }
        } else {
          product_list[i].probability = parseFloat(
            (product_list[i].probability + remaining_odds).toFixed(
              decimal_places
            )
          );
          break;
        }
      }
    }

    product_list = product_list.splice(0).sort((a, b) => {
      return a.price > b.price ? -1 : 1;
    });

    return product_list;
  },
  process_crate_inventory: (crate_inventory) => {
    return crate_inventory.crate
      ? { crate: { id: crate_inventory.crate.id } }
      : {
          products: crate_inventory.products.map((x) => {
            return {
              id: x.id,
              probability: x.probability,
            };
          }),
        };
  },
  test_unbox: (crate_inventory) => {
    var server_seed = nanoid(64);
    let nonce = nanoid(1);
    var hash = cryptojs.SHA256(server_seed + nonce).toString(cryptojs.enc.Hex);
    var client_seed = nanoid(16);

    var roll = RollFunctions.calculate(server_seed, client_seed, nonce);

    var winning_product = null;
    var prob = 0;
    var products = [];

    if (crate_inventory.products != undefined) {
      products = crate_inventory.products;
    }

    for (var i = 0; i < products.length; i++) {
      if (roll >= prob && roll < prob + products[i].probability) {
        winning_product = products[i];
        break;
      }
      prob += products[i].probability;
    }

    return { winning_product, roll };
  },
  calculate_crate_info: (product_list) => {
    var prob = 0;
    var cost = 0;
    const m = 1.18;
    if (product_list) {
      for (var i = 0; i < product_list.length; i++) {
        cost += parseFloat(
          product_list[i].price * (product_list[i].probability / 100)
        );

        prob += parseFloat(
          parseFloat(
            product_list[i].probability.toFixed(decimal_places)
          ).toString()
        );
      }

      cost = cost * m;
      cost = (cost < 10 ? Math.ceil(cost * 10) / 10 : Math.ceil(cost)) - 0.01;

      cost = parseFloat(cost.toFixed(2));
      prob = parseFloat(prob.toFixed(decimal_places));
    }
    return { probability: prob, cost };
  },
};
