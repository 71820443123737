import React from "react";
import { useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../../core/buttons/Btn";
import Icon from "../../../../core/Icon";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  const dispatch = useDispatch();
  const show_livewins = useSelector((state) => state.show_livewins_reducer);

  return (
    <div id="ShowBtn" style={{ left: show_livewins ? "84px" : "4px" }}>
      <Background style={{ background: "var(--mainPurple)", opacity: "0.2" }} />
      <Btn
        style={{ padding: "0", width: "100%", height: "100%", margin: "0" }}
        onClick={() =>
          !show_livewins
            ? dispatch({ type: "SHOW_LIVEWINS" })
            : dispatch({ type: "HIDE_LIVEWINS" })
        }
        children={
          <FlexArea
            children={
              !show_livewins ? (
                <Icon
                  style={{ width: "15px", height: "15px", margin: "auto" }}
                  src={envConfig.s3 + "/images/livewins/crown.svg"}
                />
              ) : (
                <div style={{ width: "20px", height: "20px", margin: "auto" }}>
                  <div className="arrow" />
                </div>
              )
            }
          />
        }
      />
    </div>
  );
};
