import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//API
import Address from "../../../../../../../common/api/Address";

//COMPONENTS
import GoogleMap from "../../../../../../common/GoogleMap";
import AddressFormat from "../../../../../../content/account/addresses/AddressFormat";
import FlexGrid from "../../../../../../layout/flex/FlexGrid";

//POPUPS
import AddressManagement from "../../../../../account/addresses/AddressManagement";
import SuccessBtn from "../../../../../../core/buttons/SuccessBtn";
import Confirm from "../Confirm";
import Background from "../../../../../../common/backgrounds/Background";
import Btn from "../../../../../../core/buttons/Btn";
import Addresses from "./elements/Addresses";

function Index(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.addresses_reducer);
  const account_balance = useSelector((state) => state.account_balance_reducer);

  //GET ADDRESSES
  useEffect(() => {
    Address.get_addresses();
  }, []);

  //SET DEFAULT ADDRESS
  useEffect(() => {
    if (addresses.length) {
      if (!props.data && addresses?.length && !props.data) {
        props.setAddress(addresses.find((x) => x.is_default));
      }
    } else {
      props.setAddress(null);
    }
  }, [addresses]);

  return (
    <div id="AddressSelect">
      <div
        style={{
          position: "relative",
        }}
      >
        <div>
          <FlexGrid
            children={[
              {
                style: {
                  flex: "1 0 100%",
                  height: "250px",
                  overflow: "hidden",
                },
                element: (
                  <div
                    style={{
                      boxSizing: "border-box",
                      height: "100%",
                    }}
                  >
                    <GoogleMap address={props.data?.address} />
                  </div>
                ),
              },
              {
                style: {
                  flex: "1 0 50%",
                  minWidth: "250px",
                },
                element: (
                  <Addresses
                    can_edit={props.can_edit}
                    addresses={addresses}
                    address={props.data}
                    setAddress={props.setAddress}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
