import React, { useEffect, useState } from "react";

//FUNCTIONS
import CoundownFunctions from "../../../common/functions/Countdown.Functions";
import ParseDateTime from "../../../common/functions/ParseDateTime";

function Index(props) {
  const [time, setTime] = useState(null);
  const [timer, setTimer] = useState(CoundownFunctions());

  useEffect(() => {
    timer.start(new Date(props.end_time), setTime, props.onEnd);

    return () => {
      timer.end();
      setTimer(null);
    };
  }, []);

  return time ? <span style={{ veticalAlign: "middle" }}>{time}</span> : null;
}

export default Index;
