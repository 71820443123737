import API from "../../index";

export default async (obj) => {
  console.log(obj);
  let { data, status } = await API.post("/api/transaction/crypto/order", {
    amount: obj.value,
    code: obj.code ? obj.code.code : null,
    price_change_products: obj?.products || [],
    order: obj?.order,
  });

  return status == 201 ? data : null;
};
