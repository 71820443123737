import React, { Component } from "react";

//STYLES
import "./style.css";

export default class index extends Component {
  render() {
    return (
      <div id="LogoAnimated">
        <div />
      </div>
    );
  }
}
