import React from "react";
import jimp from "jimp/browser/lib/jimp";

//COMPONENTS
import SuccessBtn from "../SuccessBtn";

function Index(props) {
  function onFileChange(e) {
    const file = e.target.files[0];
    var fReader = new FileReader();
    fReader.readAsDataURL(e.target.files[0]);
    fReader.onloadend = function (event) {
      jimp.read(event.target.result).then((jimp_image) => {
        let target_size = [210, 210];

        jimp_image
          .scaleToFit(target_size[0], target_size[1])
          .getBase64(jimp.MIME_JPEG, (err, imgBase64) => {
            props.onChange(imgBase64);
          });
      });
      //props.onChange(event.target.result);
    };
  }

  return (
    <>
      <input
        type="file"
        id="FileSelectBtn"
        name="img"
        accept="image/*"
        style={{ display: "none" }}
        onChange={onFileChange}
      />
      <SuccessBtn
        context={props.context}
        style={props.style}
        onClick={() => document.getElementById("FileSelectBtn").click()}
      />
    </>
  );
}

export default Index;
