import React from "react";

//CONFIG
import envConfig from "../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../common/functions/GeneralFunctions";

function Index(props) {
  return props.price !== null ? (
    <span
      style={Object.assign(
        {},
        {
          fontWeight: "bold",
          verticalAlign: "middle",
          fontSize: "0.8rem",
          margin: "auto",
          marginLeft: "0",
        },
        props.style
      )}
    >
      {props.price == 0 ? (
        <span style={{ color: "var(--free)" }}>FREE</span>
      ) : (
        <span>
          <img
            style={{
              width: props.img_size || "20px",
              verticalAlign: "middle",
              marginRight: "5px",
            }}
            src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
          />
          {props.real_price && props.real_price > props.price ? (
            <span style={{ verticalAlign: "middle", color: "var(--warning)" }}>
              {GeneralFunctions.parse_currency(props.real_price)}
            </span>
          ) : (
            <span style={{ verticalAlign: "middle" }}>
              {GeneralFunctions.parse_currency(props.price)}
            </span>
          )}
        </span>
      )}
    </span>
  ) : null;
}

export default Index;
