//STORE
import store from "../../redux/store";

import search_products from "./server/search_products";
import get_product from "./server/get_product";
import get_products from "./server/get_products";

export default {
  search_products: async (filters) => {
    store.dispatch({
      type: "POPULATE_PAGE_CONTENT",
      content: Object.assign(
        {},
        { page: "makeACrate", loading: true },
        filters
      ),
    });
    let products = await search_products(filters);
    return products;
  },
  get_product: async (product_id) => {
    try {
      let product = await get_product(product_id);

      return product;
    } catch (e) {
      throw e;
    }
  },
  get_products: async (obj) => {
    try {
      let products = await get_products(obj);

      return products;
    } catch (e) {
      throw e;
    }
  },
};
