const unboxing_reducer = (
  state = {
    fast_unbox: false,
    error_code: null,
    type: null,
    can_edit: true,
    is_editing: false,
  },
  action
) => {
  switch (action.type) {
    case "POPULATE_UNBOXING":
      return Object.assign({}, state, action.object);
    case "RESET_UNBOXING":
      return {
        fast_unbox: true,
        error_code: null,
        type: null,
        can_edit: null,
        is_editing: false,
      };
    default:
      return state;
  }
};

export default unboxing_reducer;
