import React from "react";

//ELEMENTS
import Element from "./elements/Element";

export default (props) => {
  return (
    <div {...props}>
      {props.elements.map((x) => (
        <Element obj={x} />
      ))}
    </div>
  );
};
