import React from "react";

export default (props) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "10px", fontSize: "1rem" }}>
      <section
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <h4
          style={{
            margin: "auto",
            marginLeft: "0",
            whiteSpace: "nowrap",
            fontWeight: "normal",
          }}
        >
          {props.obj.title}
        </h4>
        <div
          style={{
            width: "100%",
            height: "1px",
            margin: "auto",
            borderBottom: "1px dashed white",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        />
        <p
          style={{
            margin: "auto",
            marginRight: "0",
            whiteSpace: "nowrap",
          }}
        >
          {props.obj.value}
        </p>
      </section>
    </div>
  );
};
