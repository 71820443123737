import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//STYLES
import "./style.css";

//API
import Account from "../../../../common/api/Account";

//COMPONENTS
import Popup from "../../../layout/popups/Popup";
import Form from "../../../core/Form/index";
import Textbox from "../../../core/inputs/text/Textbox";
import Btn from "../../../core/buttons/Btn";

function Index(props) {
  const navigate = useNavigate();

  const [client_seed, set_client_seed] = useState("");
  const session = useSelector((state) => state.session_reducer);
  const unboxing_session = useSelector(
    (state) => state.unboxing_session_reducer
  );

  useEffect(() => {
    if (unboxing_session) {
      set_client_seed(unboxing_session.client_seed);
    } else {
      navigate("/login");
    }
  }, unboxing_session);

  const dispatch = useDispatch();
  return (
    <Popup
      id="SeedSetter"
      title={"Provably Fair"}
      children={
        <div style={{ boxSizing: "border-box", padding: "10px" }}>
          <Form
            children={[
              {
                name: "Hashed Seed",
                el: (
                  <Textbox
                    disabled={true}
                    value={unboxing_session?.hashed_server_seed}
                  />
                ),
              },
              {
                name: "Client Seed",
                el: (
                  <Textbox
                    value={client_seed}
                    maxLength={20}
                    onChange={(e) => set_client_seed(e.target.value)}
                  />
                ),
              },
            ]}
          />
        </div>
      }
      context={"Save"}
      onSuccess={async () => {
        if (
          unboxing_session.client_seed == client_seed ||
          (await Account.set_client_seed({ client_seed }))
        ) {
          dispatch({ type: "CLOSE_POPUP" });
        }
      }}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
    />
  );
}

export default Index;
