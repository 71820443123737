import API from "../../index";

export default async (filters) => {
  try {
    let { status, data } = await API.get(
      "/api/crates/list/" +
        null +
        "/" +
        filters.query +
        "/" +
        (filters.category !== "Featured" && filters.category !== "All"
          ? filters.category
          : null) +
        "/" +
        (filters.category == "Featured" ? true : null) +
        "/" +
        null +
        "/" +
        (filters.order_by || "A-Z") +
        "/" +
        0 +
        "/" +
        null
    );

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
