import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//STYLES
import "./style.css";

//FUNCTIONS
import UnboxingFunctions from "../../functions/Unboxing.functions";

//COMPONENTS
import Popup from "../../../../layout/popups/Popup";
import ContentList from "../../../../core/ContentList";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../layout/flex/FlexArea";
import CrateProduct from "../../../products/CrateProduct";
import ProbabilityBar from "../../CrateInventory/elements/OptionsBar/elements/ProbabilityBar";
import SuccessBtn from "../../../../core/buttons/SuccessBtn";
import Background from "../../../../common/backgrounds/Background";
import Price from "../../../../common/Price";
import ProductList from "../../../products/ProductList";

export default (props) => {
  const navigate = useNavigate();

  const [products, setProducts] = useState(props.crate_inventory.products);

  if (products.length < 2) {
    navigate("/unbox/add");
  }

  return (
    <Popup
      id="ChangeOdds"
      /*onClose={() => dispatch({ type: "CLOSE_POPUP" })}*/
      children={
        <FlexGrid
          id="ChangeOdds"
          children={[
            {
              style: { textAlign: "left", flex: "1 0 50%" },
              element: (
                <FlexGrid
                  children={[
                    {
                      style: { marginRight: "10px" },
                      element: <h1>Change Odds</h1>,
                    },
                    {
                      style: { flex: "1 0 50%" },
                      element: (
                        <FlexArea
                          children={
                            <div style={{ margin: "auto", width: "100%" }}>
                              <ProbabilityBar
                                products={products}
                                probability={products.reduce(
                                  (prev, next) => prev + next.probability,
                                  0
                                )}
                              />
                            </div>
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              style: { flex: "1 0 100%", overflowY: "auto" },
              element: (
                <div
                  style={{
                    position: "relative",
                    boxSizing: "border-box",
                    padding: "5px",
                  }}
                >
                  <Background
                    style={{
                      background: "var(--secondaryBackground)",
                      borderRadius: "8px",
                    }}
                  />
                  <ProductList
                    products={products.map((x) => (
                      <CrateProduct
                        key={x.id + x.probability}
                        product={x}
                        is_editing={true}
                        remove_unboxing_product={(product) =>
                          setProducts(
                            products.filter((x) => x.id !== product.id)
                          )
                        }
                        onChange={(product) =>
                          setProducts(
                            products.map((x) =>
                              x.id == product.id ? product : x
                            )
                          )
                        }
                      />
                    ))}
                  />
                </div>
              ),
            },
            {
              style: {
                flex: "1 0 100%",
                textAlign: "right",
                marginTop: "10px",
                marginBottom: "10px",
              },
              element: (
                <div>
                  <FlexGrid
                    style={{ justifyContent: "end" }}
                    children={[
                      {
                        style: {
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "10px",
                        },
                        element: (
                          <Price
                            price={
                              UnboxingFunctions.calculate_crate_info(products)
                                .cost
                            }
                          />
                        ),
                      },
                      {
                        style: {},
                        element: (
                          <SuccessBtn
                            context={"Continue"}
                            onClick={() => props.onSuccess(products)}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              ),
            },
          ]}
        />
      }
    />
  );
};
