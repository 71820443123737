import API from "../../index";

export default async (products) => {
  try {
    let { data, status } = await API.post("/api/inventory/convert", {
      products,
    });

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
