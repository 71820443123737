export default () => {
  let is_stopped = false;

  function increment(sec_diff, loop_function, end_function) {
    if (!is_stopped) {
      if (sec_diff <= 0) {
        end_function();
      } else {
        let x = new Date(sec_diff);

        let days = Math.floor(sec_diff / 60 / 60 / 24);
        let hours = Math.floor((sec_diff / 60 / 60) % 24);
        let mins = Math.floor((sec_diff / 60) % 60);
        let secs = Math.floor(sec_diff % 60);

        loop_function(
          (days ? days + "d " : "") +
            (hours ? hours + "h " : "") +
            mins +
            "m " +
            secs +
            "s"
        );
        sec_diff--;

        setTimeout(
          () => increment(sec_diff, loop_function, end_function),
          1000
        );
      }
    }
  }

  return {
    start: (end_time, loop_function, end_function) => {
      is_stopped = false;
      let sec_diff =
        (new Date(end_time).getTime() - new Date().getTime()) / 1000;

      console.log(sec_diff);
      increment(sec_diff, loop_function, end_function);
    },

    end: () => {
      console.log("END TIMER");
      is_stopped = true;
    },
  };
};
