import React from "react";
import { useNavigate } from "react-router-dom";

//FUNCTIONS
import URLFunctions from "../../../../common/functions/URL.functions";

//COMPONENTS
import FloatingBar from "../../../layout/FloatingBar";
import SearchBar from "../../../core/search/SearchBar";
import Background from "../../../common/backgrounds/Background";
import SubOptions from "../OrderList/elements/SubOptions";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const navigate = useNavigate();
  return (
    <FloatingBar
      children={
        <div style={{ position: "relative", width: "100%" }}>
          <Background
            style={{ background: "var(--background", opacity: "0.85" }}
          />
          <SearchBar
            value={props.query}
            context={"Items"}
            order_by={{
              selected: props.params?.order_by || "time: low - high",
              options: [
                { title: "Most Recent", value: "time: low - high" },
                {
                  title: "Price: Low - High",
                  value: "price: low - high",
                },
                {
                  title: "Price: High - Low",
                  value: "price: high - low",
                },
              ],
            }}
            onSearch={({ query = null, order_by = "time: low - high" }) =>
              navigate(
                URLFunctions.parse_with_escape_params("/orders/products", [
                  { name: "name", value: query },
                  { name: "order_by", value: order_by },
                ])
              )
            }
            elements={[
              {
                style: { flex: "1 0 50%" },
                element: <FlexArea children={<SubOptions {...props} />} />,
              },
            ]}
          />
        </div>
      }
    />
  );
};
