import React from "react";
import { Routes, Switch, Route, useLocation } from "react-router-dom";
import store from "./common/redux/store";

//WRAPPERS
import UseParams from "./components/common/UseParams";
import WithSession from "./components/common/WithSession";

//PAGES
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import VerifySignUp from "./components/common/auth/SignUp/Landing";
import VerifyEmailLanding from "./components/common/auth/VerifyEmailLanding";
import ForgotPassword from "./components/common/auth/ForgotPassword";
import PasswordReset from "./components/common/auth/PasswordReset";
import Crates from "./pages/Crates";
import MakeACrate from "./pages/MakeACrate";
import Rewards from "./pages/Rewards";
import Unbox from "./pages/Unbox";
import Unboxing from "./pages/Unboxing";
import Inventory from "./pages/Inventory";
import Profile from "./pages/Profile";
import Checkout from "./pages/checkout";
import NewOrder from "./components/content/orders/order/New";
import Orders from "./pages/Orders";
import Order from "./components/content/orders/order/Track";
import Account from "./pages/Account";
import HowItWorks from "./pages/HowItWorks";
import ProvablyFair from "./pages/ProvablyFair";
import ContactUs from "./pages/ContactUs";
import Transaction from "./components/content/account/Transactions/popups/Transaction";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/T&Cs";
import MarketingSubscribe from "./components/content/account/popups/MarketingSubscribe";

//MAP ROUTES TO PAGES
const pages = (params) => {
  return {
    ["/"]: <Crates params={params} />,
    /*["/crates"]: <Crates params={params} />,*/
    ["/crates/:category"]: <Crates params={params} />,
    ["/crates/:category/:query"]: <Crates params={params} />,
    ["/crates/:category/:query/:order_by"]: <Crates params={params} />,
    ["/crates/daily"]: <Rewards params={params} />,
    ["/unbox"]: <Unbox params={params} type="unbox" />,
    ["/unbox/:crate_id"]: <Unbox params={params} type="unbox" />,
    ["/unbox/:crate_id/search"]: <Unbox params={params} type="unbox" />,
    ["/unbox/:crate_id/add"]: (
      <Unbox params={params} type="unbox" show_add_items={true} />
    ),
    ["/unbox/add"]: (
      <Unbox params={params} type="unbox" show_add_items={true} />
    ),
    ["/unbox/:crate_id/odds"]: (
      <Unbox params={params} type="unbox" show_change_odds={true} />
    ),
    ["/unbox/odds"]: (
      <Unbox params={params} type="unbox" show_change_odds={true} />
    ),
    ["/unbox/daily/:crate_id"]: <Unbox params={params} type="daily" />,
    ["/unboxing/:unboxing_id"]: <Unboxing params={params} />,
    ["/inventory"]: <Inventory params={params} />,
    ["/users/:user_id"]: <Profile params={params} />,
    ["/orders"]: <Orders view={"orders"} />,
    ["/orders/products"]: <Orders view={"items"} />,
    ["/account"]: <Account page="unboxings" />,
    ["/account/unboxings"]: <Account params={params} page="unboxings" />,
    ["/account/transactions"]: <Account params={params} page="transactions" />,
    ["/account/affiliate"]: <Account params={params} page="affiliate" />,
    ["/howItWorks"]: <HowItWorks />,
    ["/howItWorks/:option"]: <HowItWorks />,
    ["/provably_fair"]: <ProvablyFair />,
    ["/contact"]: <ContactUs />,
    ["/privacy_policy"]: <PrivacyPolicy />,
    ["/T&Cs"]: <TermsAndConditions />,
  };
};

//DON'T TRACK LOCATION FOR PAGES
const ignore_location_pages = (params) => {
  return {
    ["/login"]: <Login />,
    ["/sign-up"]: <SignUp />,
    ["/sign-up/verify/:email"]: <VerifySignUp />,
    ["/verify_account/:token"]: <VerifyEmailLanding params={params} />,
    ["/login/forgot"]: <ForgotPassword params={params} />,
    ["/password/reset/:token"]: <PasswordReset params={params} />,
    /*["/checkout/:value/:code/:method/payment"]: <Payment params={params} />,*/
    ["/checkout"]: <Checkout params={params} />,
    ["/transactions/:id"]: <Transaction params={params} />,
    ["/order/:method"]: <NewOrder params={params} />,
    ["/order/:method/confirm"]: <NewOrder params={params} hideWarning={true} />,
    ["/orders/:id"]: <Order />,

    //ACCOUNT
    ["/account/email/unsubscribe"]: <MarketingSubscribe />,
  };
};

//PAGES REQUIRE AUTH
const with_auth = [
  //account
  "/account",
  "/account/transactions",
  "/transactions/:id",
  "/account/unboxings",
  "/account/affiliate",
  "/account/email/unsubscribe",

  //inventory
  "/inventory",

  //orders
  "/orders",
  "/orders/:id",
];

export default (props) => {
  let last_route = store.getState().last_route_reducer;

  //TRACK PREVIOUS PAGE
  let current_location = useLocation().pathname;
  if (
    !Object.keys(ignore_location_pages(null)).find(
      (y) => y == matchPageFromLocation(current_location).last_page
    )
  ) {
    last_route = current_location;
    store.dispatch({ type: "SET_LAST_ROUTE", last_route });
  }

  //CLOSE EXISTING POPUPS
  store.dispatch({ type: "CLEAR_POPUPS" });

  //RENDER ROUTES
  return (
    <Routes>
      {/*--->RENDER NORMAL ROUTES<---*/}
      {Object.keys(pages()).map((path) => (
        <Route
          path={path}
          element={
            with_auth.find((x) => x === path) ? (
              <WithSession children={<UseParams page={pages()[path]} />} />
            ) : (
              <UseParams page={pages()[path]} />
            )
          }
        />
      ))}

      {/*--->RENDER IGNORE ROUTES<---*/}
      {Object.keys(ignore_location_pages(null)).map((path) => (
        <Route
          path={path}
          element={
            with_auth.find((x) => x === path) ? (
              <WithSession children={renderWithPrevPage(path, last_route)} />
            ) : (
              renderWithPrevPage(path, last_route)
            )
          }
        />
      ))}
    </Routes>
  );
};

//RENDER PAGE WITH PREVIOUS PAGE IN BACKGROUND
function renderWithPrevPage(path, last_route) {
  const { last_page, params } = matchPageFromLocation(last_route);
  return path == "/login" ? (
    <UseParams
      popup_page={ignore_location_pages(params)[path]}
      page={pages()["/"]}
    />
  ) : (
    <UseParams
      popup_page={ignore_location_pages(params)[path]}
      page={pages(params)[last_page || "/"]}
    />
  );
}

//MATCH PAGE FROM LOCATION
function matchPageFromLocation(location) {
  const parts = location.replace("/", "").split("/");
  let params = {};

  let last_page = [
    ...Object.keys(pages(null)),
    ...Object.keys(ignore_location_pages(null)),
  ].find((x) => {
    let page_parts = x.replace("/", "").split("/");
    let temp = parts.concat();
    params = {};

    page_parts.forEach((y, i) => {
      if (temp[i]) {
        if (y.substring(0, 1) == ":") {
          params[y.replace(":", "")] = parts[i];
          temp[i] = y;
        }
      }
    });

    return page_parts.toString() == temp.toString() ? true : false;
  });

  return { last_page, params };
}
