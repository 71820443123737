import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//FUNCTIONS
import Auth from "../../../../common/api/Auth";

//COMPONENTS
import PopupWithRedirect from "../../../layout/popups/PopupWithRedirect";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import Form from "../../../core/Form";
import FlexGrid from "../../../layout/flex/FlexGrid";
import Textbox from "../../../core/inputs/text/Textbox";
import Btn from "../../../core/buttons/Btn";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameTxt: "",
      passwordTxt: "",
      error: null,
    };
  }

  handleTextChange = ({ target }) => {
    this.setState({
      [target.id]: target.value,
    });
  };

  onConfirm = () => {
    this.setState({ error: null }, () => {
      Auth.auth_user(
        {
          username: this.state.usernameTxt,
          password: this.state.passwordTxt,
        },
        (error) => {
          console.log(error);
          this.setState({ error });
        }
      );
    });
  };

  onKeyDown = (e) => {
    if (e.keyCode == 13) {
      this.onConfirm();
    }
  };

  render() {
    if (this.props.session && this.props.session.user) {
      return <Navigate to={this.props.last_route} />;
    }
    return (
      <PopupWithRedirect
        className="Login"
        onSuccess={this.onConfirm}
        context={"Sign In"}
        children={
          <div>
            <div className="top">
              <img src={envConfig.s3 + "/images/logos/white-logo.svg"} />
              <h3>Sign In to vivavim</h3>
            </div>

            <Form
              children={[
                {
                  name: "Email/Username",
                  el: (
                    <Textbox
                      id="usernameTxt"
                      type="text"
                      onChange={this.handleTextChange}
                      error={this.state.error}
                    />
                  ),
                },
                {
                  name: "Password",
                  el: (
                    <Textbox
                      id="passwordTxt"
                      type="password"
                      onChange={this.handleTextChange}
                      onKeyDown={this.onKeyDown}
                      error={this.state.error}
                    />
                  ),
                },
              ]}
            />
            <div style={{ textAlign: "right" }}>
              <Link to="/login/forgot">
                <span>Forgot Password</span>
              </Link>
            </div>
          </div>
        }
        bottomChildren={
          <div className="subOptions">
            <Link to="/sign-up">
              <Btn
                context={"Sign Up"}
                style={{
                  width: "100%",
                  background: "var(--highlighted)",
                  border: "2px solid var(--highlighted)",
                }}
              />
            </Link>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session_reducer,
    last_route: state.last_route_reducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close_popup: () => {
      dispatch({ type: "CLOSE_POPUP" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
