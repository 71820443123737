import React from "react";

//CONFIG
import envConfig from "../../../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import ProductRank from "../../../../../../../../content/products/ProductRank";
import FlexGrid from "../../../../../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../../../../../layout/flex/FlexArea";
import Price from "../../../../../../../../core/Price";

function Index(props) {
  return (
    <div
      className="product"
      onClick={() => props.toggleSelect(props.isSelected)}
    >
      <FlexGrid
        style={{ justifyContent: "left" }}
        children={[
          {
            style: {
              maxWidth: "80px",
              display: "inline-block",
            },
            element: (
              <FlexArea
                style={{ position: "relative" }}
                children={
                  <div style={{ margin: "auto", marginLeft: "0" }}>
                    <input
                      type="checkbox"
                      style={{ width: "20px", height: "20px" }}
                      checked={props.isSelected}
                    />
                  </div>
                }
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              maxWidth: "80px",
              display: "inline-block",
            },
            element: (
              <div style={{ width: "80px", height: "80px" }}>
                <ProductRank product={props.product} />
              </div>
            ),
          },
          {
            style: {
              boxSizing: "border-box",
              padding: "5px",
              display: "inline-block",
              flex: "1 0 50%",
            },
            element: (
              <FlexArea
                style={{ position: "relative" }}
                children={
                  <div style={{ margin: "auto", marginLeft: "0" }}>
                    <span>
                      {unescape(props.product.name).length > 50
                        ? unescape(props.product.name).substr(0, 50) + "..."
                        : unescape(props.product.name)}
                    </span>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "5px" }}
                      >
                        <Price
                          price={props.product.price}
                          real_price={props.product.real_price}
                        />
                      </div>
                      {props.product.in_stock == 0 ? (
                        <span
                          style={{ color: "var(--error)", fontWeight: "bold" }}
                        >
                          Out of Stock
                        </span>
                      ) : null}
                    </div>
                  </div>
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
