import React, { useState, useEffect } from "react";

import envConfig from "../../../../common/config/env.config";

//API
import s3 from "../../../../common/api/s3";

//STYLES
import "./style.css";
import FlexGrid from "../../../layout/flex/FlexGrid";
import Background from "../../../common/backgrounds/Background";

//COMPONENTS

function Index(props) {
  const keywords = ["server_seed", "client_seed", "nonce_value"];
  const [elements, setElements] = useState([]);

  useEffect(() => {
    getJSON();
  }, []);

  const getJSON = async () => {
    setElements(
      await s3.get_resource({
        url: envConfig.s3 + "/documents/HowItWorks/ProvablyFair.json",
      })
    );
  };

  function parseKeywords(text) {
    /*keywords.forEach((x) => {
      text = text.replaceAll(
        x.toLowerCase().replace("_", " "),
        <span className={x}>{x}</span>
      );
    });*/

    return text.replaceJSX(
      keywords[0].toLowerCase().replace("_", " "),
      <span>hello</span>
    );
  }

  function replaceJSX(text, find, replace) {
    return text.split(find).flatMap((item) => [item, replace]);
  }

  return (
    <div id="ProvablyFair" className="mainPadding">
      <FlexGrid
        children={elements.map((x) => {
          return {
            style: {
              flex: "1 0 50%",
              boxSizing: "border-box",
              padding: "5px",
              display: "grid",
              minWidth: "250px",
            },
            element: (
              <div>
                <h4
                  style={{
                    color: x?.color,
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {x.title}
                </h4>
                <div
                  className="section highlighted"
                  style={{ border: "1px solid " + x?.color }}
                >
                  <p>{x.text}</p>
                </div>
              </div>
            ),
          };
        })}
      />
      <div>
        <iframe
          height={"300"}
          style={{ width: "100%" }}
          scrolling="no"
          title="vivavim Provably Fair"
          src="https://codepen.io/th3car3tak3r/embed/BamZWKy?default-tab=html%2Cresult"
          frameborder="no"
          loading="lazy"
          allowtransparency="true"
          allowfullscreen="true"
        >
          See the Pen{" "}
          <a href="https://codepen.io/th3car3tak3r/pen/BamZWKy">
            vivavim Provably Fair
          </a>{" "}
          by th3car3tak3r (
          <a href="https://codepen.io/th3car3tak3r">@th3car3tak3r</a>) on{" "}
          <a href="https://codepen.io">CodePen</a>.
        </iframe>
      </div>
      <div>
        <iframe
          height={"300"}
          style={{ width: "100%" }}
          scrolling="no"
          title="sha256 Roll Generator"
          src="https://codepen.io/th3car3tak3r/embed/RwjLemR?default-tab=html%2Cresult"
          frameborder="no"
          loading="lazy"
          allowtransparency="true"
          allowfullscreen="true"
        >
          See the Pen{" "}
          <a href="https://codepen.io/th3car3tak3r/pen/RwjLemR">
            sha256 Roll Generator
          </a>{" "}
          by th3car3tak3r (
          <a href="https://codepen.io/th3car3tak3r">@th3car3tak3r</a>) on{" "}
          <a href="https://codepen.io">CodePen</a>.
        </iframe>
      </div>
    </div>
  );
}

export default Index;
