import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Navigate, useNavigate, useLocation } from "react-router-dom";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//FUNCTIONS
import Crates from "../../../../common/api/Crates";
import GeneralFunctions from "../functions/General.functions";
import UnboxingFunctions from "../functions/Unboxing.functions";

//COMPONENTS
import Background from "../../../common/backgrounds/Background";
import ContentList from "../../../core/ContentList";
import CrateProduct from "../../products/CrateProduct";
import ProductSelect from "../../products/popups/ProductSelect";
import ChangeOdds from "../popups/ChangeOdds";
import ProductList from "../../products/ProductList";

//STYLES
import "./style.css";

//FUNCTIONS
import ScrollFunctions from "../../../../common/functions/Scroll.Functions";

function Index(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);
  const account_level = useSelector((state) => state.account_level_reducer);
  const unboxing = useSelector((state) => state.unboxing_reducer);
  const session = useSelector((state) => state.session_reducer);
  //const last_route = useSelector((state) => state.last_route_reducer);

  const new_crate_id = props.crate_id;

  const isInitialMount = useRef(true);

  //ON COMPONENT MOUNT
  useEffect(() => {
    GeneralFunctions.init_page(
      new_crate_id,
      props.products,
      props.type,
      isInitialMount.current
    );
    ScrollFunctions.scrollPageTop();
  }, []);

  //IF CRATE ID CHANGES WHILE UNBOX PAGE OPEN
  useEffect(() => {
    if (!isInitialMount.current) {
      GeneralFunctions.init_page(
        props.crate_id,
        props.products,
        props.type,
        true
      );
      ScrollFunctions.scrollPageTop();
    }
  }, [props.crate_id]);

  //IF ACCOUNT LEVEL CHANGES WHILE DAILY CRATE OPEN
  useEffect(() => {
    if (!isInitialMount.current && props.type == "daily") {
      GeneralFunctions.init_page(
        props.crate_id,
        props.products,
        props.type,
        true
      );
      ScrollFunctions.scrollPageTop();
    }
  }, [account_level?.level]);

  //DISPLAY ADD ITEMS POPUP
  useEffect(() => {
    if (props.show_add_items) {
      dispatch({
        type: "NEW_POPUP",
        element: (
          <ProductSelect
            popup_id={"product_select"}
            selected={crate_inventory.products}
            add={(product) =>
              dispatch({
                type: "ADD_UNBOXING_PRODUCT",
                product: Object.assign({}, product, {
                  probability: 1,
                }),
              })
            }
            remove={(product) =>
              dispatch({
                type: "REMOVE_UNBOXING_PRODUCT",
                product,
              })
            }
            onSuccess={(products) => AddItems(products)}
            onClose={() => dispatch({ type: "CLOSE_POPUP" })}
          />
        ),
      });
    }
  }, [props.show_add_items]);

  //DISPLAY CHANGE ODDS POPUP
  useEffect(() => {
    if (props.show_change_odds) {
      dispatch({
        type: "NEW_POPUP",
        element: (
          <ChangeOdds
            popup_id={"change_odds"}
            crate_inventory={crate_inventory}
            onSuccess={(products) => ChangeItemsOdds(products)}
          />
        ),
      });
    }
  }, [props.show_change_odds]);

  useEffect(() => {
    GeneralFunctions.init_page(null, props.products, props.type, true);
  }, [props.products]);

  //IF PRODUCTS MANUALLY CHANGED THEN NAV TO MAKE A CRATE
  useEffect(() => {
    if (crate_inventory.isCustom) {
      if (
        crate_inventory.products.length &&
        UnboxingFunctions.calculate_crate_info(crate_inventory.products)
          .probability !== 100
      ) {
        navigate(
          "/unbox/odds/?products=" +
            encodeURIComponent(
              JSON.stringify(
                crate_inventory.products.map((x) => {
                  return {
                    id: x.id,
                    prob: x.probability,
                  };
                })
              )
            )
        );
      } else {
        navigate(
          "/unbox/?products=" +
            encodeURIComponent(
              JSON.stringify(
                crate_inventory.products.map((x) => {
                  return {
                    id: x.id,
                    prob: x.probability,
                  };
                })
              )
            )
        );
      }
    }

    //IF TOTAL PROBABILITY NOT EQUALS 100%
    /*if (
      crate_inventory.products.length &&
      !props.show_add_items &&
      UnboxingFunctions.calculate_crate_info(crate_inventory.products)
        .probability !== 100
    ) {
      dispatch({
        type: "NEW_POPUP",
        element: (
          <ChangeOdds
            popup_id={"change_odds"}
            crate_inventory={crate_inventory}
            onSuccess={(products) => ChangeItemsOdds(products)}
          />
        ),
      });
    }*/
  }, [crate_inventory]);

  //WHEN USER LOGS IN/OUT
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      GeneralFunctions.init_page(
        new_crate_id,
        props.products,
        props.type,
        true
      );
    }
  }, [session]);

  //ADD ITEMS FROM PRODUCT SELECT POPUP
  function AddItems(products) {
    if (
      !UnboxingFunctions.checkProductListsSame(
        crate_inventory.products,
        products
      )
    ) {
      dispatch({
        type: "SET_UNBOXING_PRODUCTS",
        products: products.map((x) => {
          return Object.assign({}, x, { probability: x.probability || 1 });
        }),
      });
    } else {
      alert("no change");
      navigate(location.pathname.replace("/add", ""));
    }
  }

  function ChangeItemsOdds(products) {
    if (
      !UnboxingFunctions.checkProductListsSame(
        crate_inventory.products,
        products
      ) &&
      UnboxingFunctions.calculate_crate_info(products).probability == 100
    ) {
      dispatch({ type: "SET_UNBOXING_PRODUCTS", products });
    } else {
      navigate(location.pathname.replace("/odds", ""));
    }
  }

  return (
    <div id="CrateInventory">
      <Background style={{ background: "var(--background)", opacity: "0.8" }} />

      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "50px",
          border: "0",
          borderRadius: "0",
          width: "100%",
        }}
      >
        <div className="mainPadding">
          <div
            style={{
              position: "relative",
              borderRadius: "8px",
              padding: "10px",
              boxSizing: "border-box",
              background: "var(--secondaryBackground)",
            }}
          >
            <ProductList
              products={crate_inventory.products.map((x) => (
                <CrateProduct key={x.id} product={x} />
              ))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
