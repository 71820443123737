import React from "react";

//CONFIG
import envConfig from "../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../layout/flex/FlexGrid";
import SocialMedia from "./elements/SocialMedia";
import NavLinks from "./elements/NavLinks";

function Index(props) {
  return (
    <div id="BottomBar">
      <div className="mainPadding">
        <FlexGrid
          children={[
            {
              style: { flex: "1 0 50%", display: "flex" },
              element: <SocialMedia />,
            },
            {
              style: { flex: "1 0 50%", minWidth: "300px" },
              element: <NavLinks />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Index;
