import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//API
import Auth from "../../../../../common/api/Auth";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../../core/buttons/Btn";
import Icon from "../../../../core/Icon";
import Background from "../../../backgrounds/Background";
import Social from "../SubHomeBar/elements/Social";
import ProvFairBtn from "../../../../content/provablyFair/ProvFairBtn";
import FlexArea from "../../../../layout/flex/FlexArea";

export default (props) => {
  const session = useSelector((state) => state.session_reducer);
  return (
    <div id="MobileNav">
      <Background onClick={props.toggleMobileNav} />
      <div id="inner" className="mainPadding">
        <Background
          style={{
            background: "var(--background)",
            opacity: "1",
            borderBottomRightRadius: "8px",
            border: "2px solid var(--highlighted)",
            borderLeft: "0",
            borderTop: "0",
          }}
        />
        <div id="Nav">
          <Link to="/" onClick={props.toggleMobileNav}>
            <Btn
              className="navElement"
              children={
                <FlexArea
                  children={
                    <>
                      <Icon
                        className="icon"
                        src={envConfig.s3 + "/images/logos/CrateIcon.svg"}
                      />
                      <span>Crates</span>
                    </>
                  }
                />
              }
            />
          </Link>
          <Link to="/howItWorks" onClick={props.toggleMobileNav}>
            <Btn
              className="navElement"
              children={
                <FlexArea
                  children={
                    <>
                      <Icon
                        className="icon"
                        src={envConfig.s3 + "/images/logos/HowItWorksIcon.svg"}
                      />
                      <span>How it Works</span>
                    </>
                  }
                />
              }
            />
          </Link>
          <Link to="/crates/daily" onClick={props.toggleMobileNav}>
            <Btn
              className="navElement"
              children={
                <FlexArea
                  children={
                    <>
                      <Icon
                        className="icon"
                        src={envConfig.s3 + "/images/logos/FreeCrateIcon.svg"}
                      />
                      <span style={{ color: "var(--FunctionGreen)" }}>
                        Daily Free
                      </span>
                    </>
                  }
                />
              }
            />
          </Link>
        </div>
        {session ? (
          <div id="Nav">
            <Link to="/inventory" onClick={props.toggleMobileNav}>
              <Btn
                className="navElement"
                children={
                  <FlexArea
                    children={
                      <>
                        <Icon
                          className="icon"
                          src={
                            envConfig.s3 + "/images/icons/account/inventory.svg"
                          }
                        />
                        <span>Inventory</span>
                      </>
                    }
                  />
                }
              />
            </Link>
            <Link to="/account" onClick={props.toggleMobileNav}>
              <Btn
                className="navElement"
                children={
                  <FlexArea
                    children={
                      <>
                        <Icon
                          className="icon"
                          src={
                            envConfig.s3 +
                            "/images/icons/account/account_management.svg"
                          }
                        />
                        <span>Account</span>
                      </>
                    }
                  />
                }
              />
            </Link>
            <Link to="/orders" onClick={props.toggleMobileNav}>
              <Btn
                className="navElement"
                children={
                  <FlexArea
                    children={
                      <>
                        <Icon
                          className="icon"
                          src={
                            envConfig.s3 + "/images/icons/account/orders.svg"
                          }
                        />
                        <span>Orders</span>
                      </>
                    }
                  />
                }
              />
            </Link>
            <Link
              to={"/users/" + session.user.id}
              onClick={props.toggleMobileNav}
            >
              <Btn
                className="navElement"
                children={
                  <FlexArea
                    children={
                      <>
                        <Icon
                          className="icon"
                          src={envConfig.s3 + "/images/icons/account/stats.svg"}
                        />
                        <span>stats</span>
                      </>
                    }
                  />
                }
              />
            </Link>
            <Link to="/account/affiliate" onClick={props.toggleMobileNav}>
              <Btn
                className="navElement"
                children={
                  <FlexArea
                    children={
                      <>
                        <Icon
                          className="icon"
                          src={
                            envConfig.s3 + "/images/icons/account/affiliate.svg"
                          }
                        />
                        <span>Affiliate</span>
                      </>
                    }
                  />
                }
              />
            </Link>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Btn
                style={{
                  border: "2px solid var(--error)",
                  fontSize: "0.8rem",
                  padding: "5px",
                  width: "100%",
                }}
                onClick={() => {
                  props.toggleMobileNav();
                  Auth.logout_user();
                }}
                children={
                  <>
                    <Background
                      style={{
                        background: "var(--error)",
                        opacity: "0.2",
                      }}
                    />
                    <span
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      Sign Out
                    </span>
                  </>
                }
              />
            </div>
          </div>
        ) : null}
        <div>
          <Social />
          <Link to="/provably_fair" onClick={props.toggleMobileNav}>
            <Btn
              className="navElement"
              children={
                <ProvFairBtn
                  context={"Provably Fair"}
                  style={{ fontSize: "0.8rem" }}
                />
              }
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
