import React from "react";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Methods from "../../components/content/contactUs/Methods";

export default (props) => {
  return (
    <PageLayout
      background={{
        position: "fixed",
        backgroundImage:
          "url(" + envConfig.s3 + "/images/crates2/background.webp)",
      }}
      title={"Contact Us"}
      children={
        <div className="mainPadding">
          <Methods />
        </div>
      }
    />
  );
};
