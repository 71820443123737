import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//FUNCTIONS
import ParseDateTime from "../../../../../../common/functions/ParseDateTime";

//COMPONENTS
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";
import Countdown from "../../../../../common/Countdown";
import Price from "../../../../../common/Price";

function Index(props) {
  const dispatch = useDispatch();
  const page_content = useSelector((state) =>
    state.page_content_reducer.page == "rewards"
      ? state.page_content_reducer
      : null
  );
  const session = useSelector((state) => state.session_reducer);
  const navigate = useNavigate();
  if (
    (props.type == "unbox" && props.crate) ||
    (!props.crate?.time_locked_until && !props.crate?.levels_to)
  ) {
    return (
      <SuccessBtn
        style={{
          width: "100%",
          fontSize: "1.2rem",
          fontWeight: "normal",
        }}
        onClick={session ? props.add_unboxing : () => navigate("/login")}
        context={
          <div>
            <div
              className="price"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              {props.type == "unbox" ? (
                <>
                  <span style={{ verticalAlign: "middle" }}>Open for </span>
                  <Price
                    price={props.cost}
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    imgSize={"1.4rem"}
                  />
                </>
              ) : session ? (
                <>
                  <span style={{ verticalAlign: "middle" }}>Open for </span>
                  <span style={{ verticalAlign: "middle", fontWeight: "bold" }}>
                    FREE
                  </span>
                </>
              ) : (
                <Link to="/login">
                  <span>Login to Claim</span>
                </Link>
              )}
            </div>
          </div>
        }
      />
    );
  } else {
    return (
      <SuccessBtn
        style={Object.assign(
          {},
          { width: "100%", boxShadow: "0", height: "100%" },
          props.crate?.time_locked_until || props.crate?.levels_to
            ? {
                border: "2px solid var(--error)",
                boxShadow: "0 0 10px var(--error)",
              }
            : null
        )}
        color={
          props.crate?.time_locked_until || props.crate?.levels_to
            ? "var(--error)"
            : null
        }
        disabled={true}
        context={(() => {
          if (session) {
            if (props.crate?.levels_to) {
              return (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={envConfig.s3 + "/images/icons/Lockedicon.svg"}
                    className="lockedIcon"
                    style={{
                      height: "25px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                    }}
                  />
                  <span
                    style={{
                      verticalAlign: "middle",
                      paddingRight: "1px",
                    }}
                  >
                    + {props.crate.levels_to} Levels
                  </span>
                </div>
              );
            } else if (props.crate?.time_locked_until) {
              return (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={envConfig.s3 + "/images/icons/Lockedicon.svg"}
                    className="lockedIcon"
                    style={{
                      height: "25px",
                      verticalAlign: "middle",
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    <Countdown
                      end_time={ParseDateTime.adjustForTimezone(
                        new Date(props.crate.time_locked_until)
                      )}
                      onEnd={() => {
                        dispatch({
                          type: "UPDATE_CRATE",
                          crate: { time_locked_until: null },
                        });
                        if (page_content) {
                          dispatch({
                            type: "UPDATE_PAGE_CONTENT_ELEMENT",
                            element: {
                              id: props.crate.id,
                              time_locked_until: null,
                            },
                          });
                        }
                      }}
                    />
                  </span>
                </div>
              );
            } else {
              return null;
            }
          } else {
            return (
              <Link to="/login">
                <span>Login to Claim</span>
              </Link>
            );
          }
        })()}
      />
    );
  }
}

export default Index;
