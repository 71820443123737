import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../../../../common/config/env.config";

//API
import Affiliate from "../../../../../../../../../../common/api/Affiliate";

//STYLE
import "./style.css";

//COMPONENTS
import Popup from "../../../../../../../../../layout/popups/Popup";
import FlexGrid from "../../../../../../../../../layout/flex/FlexGrid";
import Textbox from "../../../../../../../../../core/inputs/text/Textbox";
import Form from "../../../../../../../../../core/Form";
import Dropdown from "../../../../../../../../../core/Dropdown";
import ProfileImg from "../../../../../../../../account/ProfileImg";

function Index(props) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);
  const [amount, setAmount] = useState(0);
  const [method, setMethod] = useState("user");

  return (
    <Popup
      title={"Create Payout"}
      id="CreatePayout"
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      context={"Create Payout"}
      onSuccess={() => {
        props.add_payout(parseFloat(amount));
        dispatch({ type: "CLOSE_POPUP" });
      }}
      children={
        <div>
          <div
            style={{
              textAlign: "left",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            <span style={{ verticalAlign: "middle", fontWeight: "bold" }}>
              Select Amount
            </span>
          </div>
          <FlexGrid
            style={{ border: "1px solid var(--borderColor)" }}
            children={[
              {
                style: { flex: "1 0 100%" },
                element: (
                  <div style={{ textAlign: "center" }}>
                    <div style={{ margin: "10px" }}>
                      <section style={{ display: "flex", height: "100%" }}>
                        <div
                          style={{
                            margin: "auto",
                            marginLeft: "0",
                          }}
                        >
                          <span
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            Balance:{" "}
                          </span>
                          <img
                            style={{
                              width: "25px",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                            src={
                              envConfig.s3 + "/images/icons/common/vivacoin.svg"
                            }
                          />
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: "1.2rem",
                              fontWeight: "bold",
                            }}
                          >
                            {props.value}
                          </span>
                        </div>
                      </section>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <section style={{ display: "flex", height: "100%" }}>
                        <div
                          style={{
                            margin: "auto",
                            marginLeft: "0",
                          }}
                        >
                          <span
                            style={{
                              verticalAlign: "middle",
                              marginRight: "10px",
                            }}
                          >
                            New Balance:{" "}
                          </span>
                          <img
                            style={{
                              width: "25px",
                              verticalAlign: "middle",
                              marginRight: "5px",
                            }}
                            src={
                              envConfig.s3 + "/images/icons/common/vivacoin.svg"
                            }
                          />
                          <span
                            style={{
                              verticalAlign: "middle",
                              fontSize: "1.2rem",
                              fontWeight: "bold",
                            }}
                          >
                            {props.value - parseFloat(amount) > 0
                              ? parseFloat(
                                  (props.value - parseFloat(amount)).toFixed(2)
                                )
                              : props.value}
                          </span>
                        </div>
                      </section>
                    </div>
                  </div>
                ),
              },
              {
                style: { flex: "1 0 100%" },
                element: (
                  <div style={{ margin: "10px" }}>
                    <Form
                      children={[
                        {
                          name: "amount",
                          el: (
                            <Textbox
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
          />
          <div
            style={{
              textAlign: "left",
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            <span style={{ verticalAlign: "middle", fontWeight: "bold" }}>
              Payout to{" "}
            </span>
            <Dropdown
              style={{ verticalAlign: "middle" }}
              options={[{ title: session.user.username, value: "user" }]}
            />
          </div>
          {(() => {
            switch (method) {
              case "user":
                return (
                  <div
                    style={{
                      border: "1px solid var(--borderColor  )",
                      boxSizing: "border-box",
                      padding: "5px",
                    }}
                  >
                    <FlexGrid
                      children={[
                        {
                          style: { flex: "1 0 50%" },
                          element: (
                            <section
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  margin: "auto",
                                  marginRight: "0",
                                  verticalAlign: "middle",
                                }}
                              >
                                <ProfileImg user={session.user} />
                              </div>
                              <span
                                style={{
                                  margin: "auto",
                                  marginLeft: "0",
                                  fontSize: "1.3rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {session.user.username}
                              </span>
                            </section>
                          ),
                        },
                        {
                          style: { flex: "1 0 50%" },
                          element: (
                            <div
                              style={{ width: "100px", height: "100px" }}
                            ></div>
                          ),
                        },
                      ]}
                    />
                  </div>
                );
            }
          })()}
        </div>
      }
    />
  );
}

export default Index;
