import React from "react";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Price from "../../../../../common/Price";
import Icon from "../../../../../core/Icon";
import Background from "../../../../../common/backgrounds/Background";
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import Countdown from "../../../../../common/Countdown";

export default (props) => {
  return (
    <div style={{ position: "relative", padding: "3px" }}>
      <Background
        style={{
          background: "var(--highlighted)",
          backdropFilter: "blur(5px)",
          opacity: "0.8",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
        }}
      />
      <div
        style={{
          position: "relative",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <Background
          style={{
            background: "var(--background)",
            borderRadius: "8px",
            opacity: "0.5",
          }}
        />

        {props.time_locked_until || props.levels_to ? (
          props.time_locked_until ? (
            <div id="priceWrapper">
              <Icon
                style={{
                  position: "relative",
                  width: "15px",
                  height: "15px",
                  verticalAlign: "middle",
                  marginRight: "5px",
                }}
                src={envConfig.s3 + "/images/icons/Lockedicon.svg"}
              />
              <span
                style={{
                  position: "relative",
                  verticalAlign: "middle",
                  fontSize: "0.6rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                <Countdown
                  end_time={props.time_locked_until}
                  onEnd={() => {}}
                />
              </span>
            </div>
          ) : (
            <div id="priceWrapper">
              <Icon
                style={{
                  position: "relative",
                  width: "15px",
                  height: "15px",
                  verticalAlign: "middle",
                  marginRight: "5px",
                }}
                src={envConfig.s3 + "/images/icons/Lockedicon.svg"}
              />
              <span
                style={{
                  position: "relative",
                  verticalAlign: "middle",
                  fontSize: "0.6rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                +{props.levels_to} Levels
              </span>
            </div>
          )
        ) : (
          <div id="priceWrapper">
            <Price
              price={props.price}
              style={{ margin: "auto" }}
              imgSize={"1rem"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
