import React, { Component } from "react";
import "./style.css";

import { Link } from "react-router-dom";

//FUNCTIONS
import getProductRank from "../../../../../common/functions/getProductRank";

//COMPONENTS
import ProductRank from "../../../products/ProductRank";
import ProfileImg from "../../../account/ProfileImg";
import FlexArea from "../../../../layout/flex/FlexArea";

var colorRank = "";

class recentWinProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productRank: null,
      productStyling: {},
      productImgStyling: {},
    };
  }

  componentDidMount() {
    var productStyle = {
      backgroundSize: "contain",
      width: "100%",
      height: "100%",
    };

    var productImgStyle = {
      position: "relative",
      maxWidth: "100%", //"50px",
      maxHeight: "100%", //"40px",
      borderRadius: "5px",
      margin: "auto",
    };

    this.setState({
      productStyling: Object.assign({}, productStyle, { margin: "auto" }),
      productImgStyling: productImgStyle,
    });
  }

  render() {
    return (
      <li className="recentWinProductWrapper">
        <Link to={"/unboxing/" + this.props.live_win.unboxing_id}>
          <div className="recentWinProduct" style={this.state.productStyling}>
            <div className="recentWinFront">
              <section className="recentWinProductBackground">
                <ProductRank product={this.props.live_win} />
              </section>
            </div>
            <div className="recentWinBack">
              <FlexArea
                children={
                  <div className="recentWinProductUserInfo">
                    <ProfileImg user={this.props.live_win} />
                  </div>
                }
              />
            </div>
          </div>
        </Link>
      </li>
    );
  }
}

const productImgStyling = {
  position: "absolute",
  width: "50%",
  height: "50%",
};

export default recentWinProduct;
