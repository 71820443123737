import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Background from "../../components/common/backgrounds/Background";
import FlexGrid from "../../components/layout/flex/FlexGrid";
import Carousel from "../../components/content/unbox/WheelCarousel2";
import Controls from "../../components/content/unbox/Controls";
import RecentWins from "../../components/content/unbox/RecentWins";
import CrateInventory from "../../components/content/unbox/CrateInventory";
import ItemOptions from "../../components/content/unbox/ItemOptions";

export default (props) => {
  var products = null;

  return (
    <PageLayout
      children={
        <div id="Unbox">
          <Helmet>
            <title>Unbox | Vivavim</title>
            <meta
              name="description"
              content="Unbox your mystery crate on vivavim to discover what you can win"
            />
            <link rel="canonical" href={envConfig.server + "/unbox"} />
          </Helmet>
          <div
            style={{
              position: "relative",
              boxSizing: "border-box",
              width: "100%",
              marginTop: "0px",
              maxWidth: "2200px",
              margin: "auto",
            }}
          >
            <div
              style={{
                position: "relative",
                background: "transparent",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                width: "100%",
                height: "100%",
              }}
            >
              <Background
                style={{
                  backgroundImage:
                    "url(" +
                    (props.params.crate_id
                      ? envConfig.s3 +
                        "/images/crates2/wallpapers/" +
                        props.params.crate_id +
                        ".webp"
                      : envConfig.s3 + "/images/crates2/banner/banner2.webp") +
                    ")",
                }}
                children={
                  <>
                    <Background
                      style={{
                        left: "0",
                        right: "0",
                        top: "0",
                        background:
                          "linear-gradient(180deg, var(--background), transparent)",
                        height: "50px",
                      }}
                    />
                    <Background
                      style={{
                        bottom: "0",
                        left: "unset",
                        right: "0",
                        top: "0",
                        background:
                          "linear-gradient(90deg, transparent,var(--background))",
                        width: "10%",
                      }}
                    />
                    <Background
                      style={{
                        bottom: "0",
                        left: "0",
                        right: "unset",
                        top: "0",
                        background:
                          "linear-gradient(-90deg, transparent,var(--background))",
                        width: "10%",
                      }}
                    />
                  </>
                }
              />

              <FlexGrid
                style={{ flexDirection: "column", height: "100%" }}
                children={[
                  { element: <Carousel /> },
                  {
                    style: { flex: "1", zIndex: "3" },
                    element: (
                      <div>
                        <Background
                          style={{
                            bottom: "0",
                            left: "0",
                            right: "0",
                            top: "unset",
                            background:
                              "linear-gradient(0deg, var(--background), transparent)",
                            height: "50px",
                          }}
                        />
                        <Controls type={props.params.type} />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div style={{ zIndex: "3" }}>
            <div>
              <RecentWins />

              <div className="mainPadding">
                <h1
                  style={{
                    position: "relative",
                    margin: "0",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  Items to Win
                </h1>
              </div>
            </div>
            <ItemOptions />
            <CrateInventory
              crate_id={props.params.crate_id}
              products={props.params.products}
              type={props.params.type}
              {...props}
              show_add_items={props.params.show_add_items}
              show_change_odds={props.params.show_change_odds}
            />
          </div>
        </div>
      }
    />
  );
};
