import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexArea from "../../../../layout/flex/FlexArea";
import Icon from "../../../../core/Icon";

function Index(props) {
  let current_location = useLocation().pathname;
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);

  return props.width > 500 ? (
    <div className="section" id="NavBar">
      <ul className="list">
        <li
          className={
            "nav element" +
            (current_location == "/crates" || current_location == "/"
              ? " selected"
              : "")
          }
        >
          <Link
            to={"/"}
            style={{ color: "unset", textDecoration: "unset" }}
            title={"Crates"}
          >
            <section>
              <div className="btnWrapper">
                <Icon
                  className="icon"
                  src={envConfig.s3 + "/images/icons/crates.svg"}
                />
                <p className="title">Crates</p>
              </div>
            </section>
          </Link>
        </li>
        <li
          className={
            "nav element" +
            (current_location.includes("/howItWorks") ? " selected" : "")
          }
        >
          <Link
            to={"/howItWorks"}
            style={{ color: "unset", textDecoration: "unset" }}
            title={"How it Works"}
          >
            <section>
              <div className="btnWrapper">
                <Icon
                  className="icon"
                  src={envConfig.s3 + "/images/logos/HowItWorksIcon.svg"}
                />
                <p className="title">How it Works</p>
              </div>
            </section>
          </Link>
        </li>
        <li
          className={
            "nav element" +
            (current_location == "/crates/daily" ? " selected" : "")
          }
        >
          <Link
            to="/crates/daily"
            style={{ color: "unset", textDecoration: "unset" }}
            title={"Daily Free"}
          >
            <section>
              <div className="btnWrapper" id="rewardsElement">
                <Icon
                  className="icon"
                  src={envConfig.s3 + "/images/logos/FreeCrateIcon.svg"}
                />
                <p className="title">Daily Free</p>
              </div>
            </section>
          </Link>
        </li>
      </ul>
    </div>
  ) : null;
}

export default Index;
