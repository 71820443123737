import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

//API
import Inventory from "../../../../../../../common/api/Inventory";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../../../../backgrounds/Background";
import ContentList from "../../../../../../core/ContentList";
import Product from "./elements/Product";
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import MultiSelectOptions from "../../../../../../content/inventory/MultiSelectOptions";
import OptionButtons from "../../../../../../content/inventory/MultiSelectOptions/elements/OptionButtons";
import Btn from "../../../../../../core/buttons/Btn";
import SuccessBtn from "../../../../../../core/buttons/SuccessBtn";

function Index(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const limit = 8;

  const account_dropdown = useSelector(
    (state) => state.account_dropdown_reducer
  );

  const page_content = useSelector((state) =>
    state.page_content_reducer.page == "inventory"
      ? state.page_content_reducer
      : null
  );

  const selected = account_dropdown.inventory.selected;

  async function selectAll() {
    let all = await Inventory.get_all();
    if (all) {
      dispatch({
        type: "ADD_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
        selected: all.data,
      });
    }
  }

  function convertProducts() {
    Inventory.convert_products(selected);
    dispatch({ type: "POPULATE_ACCOUNT_DROPDOWN", object: account_dropdown });
  }

  async function search(filters) {
    if (
      account_dropdown.inventory.data.length < account_dropdown.inventory.max
    ) {
      const data = await Inventory.dropdown_search(filters);
      if (data && account_dropdown.show == "inventory") {
        dispatch({
          type: "UPDATE_ACCOUNT_DROPDOWN",
          object: Object.assign({}, account_dropdown, {
            inventory: Object.assign({}, account_dropdown.inventory, {
              data: [...account_dropdown.inventory.data, ...data.data],
            }),
          }),
        });
      }
    }
  }

  return (
    <div id="Inventory">
      <div className="top">
        <FlexGrid
          children={[
            {
              style: { flex: "1", marginRight: "auto" },
              element: <h2 style={{ margin: "0" }}>Inventory</h2>,
            },
            {
              style: { flex: "1", marginLeft: "auto", textAlign: "right" },
              element: (
                <Link to="/inventory">
                  <SuccessBtn context={"View All Items"} />
                </Link>
              ),
            },
          ]}
        />
        <Btn
          context={"Select All"}
          onClick={selectAll}
          style={{
            background: "transparent",
            border: "0",
            color: "var(--FunctionGreen)",
            padding: "0",

            verticalAlign: "middle",
          }}
        />
        {selected.length ? (
          <Btn
            context={"Cancel"}
            onClick={() =>
              dispatch({
                type: "POPULATE_ACCOUNT_DROPDOWN",
                object: account_dropdown,
              })
            }
            style={{
              background: "transparent",
              border: "0",
              color: "var(--error)",
              padding: "0",
              marginLeft: "10px",
              verticalAlign: "middle",
            }}
          />
        ) : null}
      </div>

      <div className="middle">
        <ContentList
          style={{ maxHeight: "100%", height: "unset" }}
          onScrollBottom={(offset) =>
            search({
              query: null,
              order_by: "time: low - high",
              offset,
              limit,
            })
          }
          max={account_dropdown.inventory.max}
          content={account_dropdown.inventory.data.map((x) => (
            <Product
              product={x}
              toggleSelect={(isSelected) =>
                isSelected
                  ? dispatch({
                      type: "REMOVE_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
                      selected: [x],
                    })
                  : dispatch({
                      type: "ADD_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
                      selected: [x],
                    })
              }
              isSelected={
                account_dropdown.inventory.selected.find((y) => y.id == x.id) ||
                false
              }
            />
          ))}
        />
      </div>
      <div className="bottom">
        <div>
          <p>Selected: {selected.length}</p>
        </div>
        <OptionButtons
          products={account_dropdown.inventory.selected}
          convertProducts={convertProducts}
          onDeliver={() => {
            dispatch({
              type: "POPULATE_PAGE_CONTENT",
              content: { page: "inventory", selected },
            });
            props.onClose();
          }}
          onWithdraw={() => {
            dispatch({
              type: "POPULATE_PAGE_CONTENT",
              content: { page: "inventory", selected },
            });
            props.onClose();
          }}
        />
      </div>
    </div>
  );
}

export default Index;
