import React, { Component } from "react";

//CONFIG
import envConfig from "../../../common/config/env.config";

//STYLE
import "./style.css";

//COMPONENTS
import FlexGrid from "../../layout/flex/FlexGrid";

export default class index extends Component {
  renderOptions = (selected, options) => {
    let elements = [];

    for (var i = 0; i < options.length; i++) {
      let value = options[i].value ? options[i].value : options[i].title;

      elements.push({
        style: { flex: "1" },
        element: (
          <div
            className="Option"
            key={i}
            style={
              selected == value
                ? { borderBottom: "2px solid " + options[i].color }
                : null
            }
            onClick={this.props.selectOption.bind(this, value)}
          >
            <img
              src={options[i].image ? envConfig.s3 + options[i].image : null}
              style={Object.assign(
                {},
                { height: "25px" },
                options[i].image_style ? options[i].image_style : {}
              )}
            />
            <span>{options[i].title}</span>
          </div>
        ),
      });
    }
    return elements;
  };

  render() {
    let elements = this.renderOptions(this.props.selected, this.props.options);
    return (
      <div className="OptionList" style={this.props.style}>
        <FlexGrid children={elements} />
      </div>
    );
  }
}
