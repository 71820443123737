import React from "react";
import { useDispatch } from "react-redux";

//COMPONENTS
import SubTitle from "../../../../../../../layout/SubTitle";
import SuccessBtn from "../../../../../../../core/buttons/SuccessBtn";
import ContentList from "../../../../../../../core/ContentList";
import ListElement from "./elements/ListElement";
import SearchOptions from "../../../../../../../core/search/SearchOptions";
import SearchBar from "../../../../../../../core/search/SearchBar";
import PageControls from "../../../../../../../core/PageControls";
import Background from "../../../../../../../common/backgrounds/Background";
import Dropdown from "../../../../../../../core/Dropdown";

function Index(props) {
  const dispatch = useDispatch();
  return (
    <div>
      <div style={{ position: "relative", margin: "auto" }}>
        <SearchOptions
          selected={[]}
          order_by={props.affiliate.order_by}
          options={[
            { name: "Most Recent", value: "most_recent" },
            { name: "Value: low - high", value: "price_low" },
            { name: "Value: high - low", value: "price_high" },
          ]}
          children={
            <div
              style={{
                margin: "auto",
                marginRight: "0",
                minWidth: "300px",
                width: "100%",
              }}
            >
              <Background
                style={{ background: "var(--background)", opacity: "0.85" }}
              />
              <SearchBar
                style={{ padding: "0" }}
                context={"payouts"}
                elements={[
                  {
                    style: { margin: "auto", marginLeft: "0" },
                    element: (
                      <Dropdown
                        style={{ fontSize: "1rem" }}
                        onChange={() => {}}
                        options={[
                          {
                            title: "Payouts",
                            value: "payouts",
                            color: "white",
                          },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            </div>
          }
        />
        <div
          style={{
            position: "relative",
            padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <Background
            style={{
              background: "var(--secondaryBackground)",
              borderRadius: "8px",
            }}
          />
          {props.affiliate.payouts.content.length ? (
            <ContentList
              content={props.affiliate.payouts.content.map((obj) => (
                <ListElement obj={obj} />
              ))}
            />
          ) : (
            <div style={{ padding: "10px", boxSizing: "border-box" }}>
              <span>You have no payouts</span>
            </div>
          )}
        </div>
      </div>
      <PageControls
        offset={0}
        pageLimit={10}
        limit={40}
        onSelectPage={() => {}}
      />
    </div>
  );
}

export default Index;
