import React from "react";
import { useDispatch } from "react-redux";
import Background from "../../../../../../common/backgrounds/Background";

//COMPONENTS
import Popup from "../../../../../../layout/popups/Popup";

function Index(props) {
  const dispatch = useDispatch();
  return (
    <Popup
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      title={"Confirm Cancel Order"}
      style={{ maxWidth: "700px" }}
      context={"Confirm"}
      onSuccess={() => {}}
      children={
        <div
          style={{
            position: "relative",
            marginTop: "20px",
            marginBottom: "20px",
            padding: "10px",
            boxSizing: "border-box",
            border: "1px solid var(--mainPurple)",
            textAlign: "left",
          }}
        >
          <Background
            style={{ background: "var(--mainPurple)", opacity: "0.1" }}
          />
          <h3 style={{ fontWeight: "normal" }}>
            Your items will be returned to your inventory.
          </h3>
          <h3 style={{ fontWeight: "normal" }}>
            Any shipping fees will be refunded.
          </h3>
        </div>
      }
    />
  );
}

export default Index;
