import React from "react";

//STYLES
import "./style.css";

export default (props) => {
  return (
    <button
      {...props}
      className={(props.className ? props.className + " " : "") + "Btn"}
    >
      {props.children}
      <div style={{ position: "relative" }}>{props.context}</div>
    </button>
  );
};
