//STORE
import store from "../../redux/store";

//API
import get_account from "./server/get_account";
import set_profile_img from "./server/set_profile_img";
import set_client_seed from "./server/set_client_seed";
import change_username from "./server/change_username";
import change_password from "./server/change_password";
import get_level_rewards from "./server/get_level_rewards";
import change_email_address from "./server/change_email_address";
import marketing_subscribe_toggle from "./server/marketing_subscribe_toggle";

export default {
  get_account: async () => {
    return await get_account();
  },
  set_profile_img: async (obj) => {
    const data = await set_profile_img(obj);

    if (data) {
      if (data.default_img) {
        store.dispatch({
          type: "UPDATE_USER",
          user: {
            default_image: data.default_img,
            profile_img: null,
            profile_img_datetime: null,
          },
        });
      } else {
        store.dispatch({
          type: "UPDATE_USER",
          user: {
            profile_img: true,
            profile_img_datetime: data.profile_img_datetime,
          },
        });
      }
    }
  },

  set_client_seed: async (obj) => {
    const success = await set_client_seed(obj);

    if (success) {
      store.dispatch({ type: "SET_CLIENT_SEED", client_seed: obj.client_seed });
      return true;
    }
    return false;
  },

  change_username: async (obj) => {
    const success = await change_username(obj);
    if (success) {
      store.dispatch({ type: "UPDATE_USER", user: { username: obj.username } });
    }
    return success;
  },

  change_email_address: async (obj) => {
    const success = await change_email_address(obj);
    if (success) {
      store.dispatch({
        type: "UPDATE_USER",
        user: { email_address: obj.email_address },
      });
    }
    return success;
  },

  change_password: async (obj) => {
    return await change_password(obj);
  },

  get_level_rewards: async (obj) => {
    const data = await get_level_rewards(obj);

    return data;
  },

  marketing_subscribe_toggle: async (obj) => {
    return await marketing_subscribe_toggle(obj);
  },
};
