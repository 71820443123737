import React, { useState } from "react";

//STYLES
import "./app.css";

//API
import Sockets from "./common/Sockets";

//FUNCTIONS
import Auth from "./common/api/Auth";
import Config from "./common/api/Config";

//COMPONENTS
import Audio from "./components/common/Audio";

//ROUTERS
import Routes from "./Routes";

export default (props) => {
  const [elements, setElements] = useState(null);

  if (!elements) {
    //GET INIT PAGE DATA
    Sockets.connect(() => {});
    Config.get_config(() => {
      Auth.token_auth(() => {
        setElements(
          <div id="App">
            <Audio />
            <div id="MainWrapper">
              <Routes />
            </div>
          </div>
        );
      });
    });
  }

  return elements;
};
