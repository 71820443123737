//STORE
import store from "../../redux/store";

//SERVER
import get_recent from "./server/get_recent";
import get_unboxing from "./server/get_unboxing";
import search from "./server/search";

export default {
  get_recent: async () => {
    if (!store.getState().live_wins_reducer) {
      const data = await get_recent();

      if (data) {
        store.dispatch({ type: "POPULATE_LIVE_WINS", live_wins: data.content });
      }
    }
  },

  get_unboxing: async (id) => {
    let unboxing = await get_unboxing(id);
    store.dispatch({
      type: "POPULATE_UNBOXING",
      object: { is_editing: false },
    });
    return unboxing;
  },

  search: async (obj) => {
    obj = Object.assign(
      {},
      {
        product_name: null,
        cost: null,
        max_timestamp: null,
        client_seed: null,
        winning_product_price: null,
        offset: obj.offset || 0,
        limit: obj.limit,
        order_by: obj.order_by || "Recently Won",
      },
      obj
    );
    const data = await search(obj);

    return data;
  },
};
