import React from "react";
import { useSelector } from "react-redux";

//STYLES
import "./style.css";

//COMPONENTS
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import CloseBtn from "../../../core/buttons/CloseBtn";
import FlexGrid from "../../../layout/flex/FlexGrid";
import Background from "../../../common/backgrounds/Background";
import FlexArea from "../../../layout/flex/FlexArea";

function Index(props) {
  const show_livewins = useSelector((state) => state.show_livewins_reducer);

  function onClose(e) {
    let className = e.target.className;
    if (className.includes("background")) {
      props.onClose();
    }
  }

  return (
    <div className="Popup" style={{ left: show_livewins ? "84px" : "4px" }}>
      <Background
        style={{
          background: "var(--background)",
          backdropFilter: "blur(10px)",
          opacity: "0.9",
        }}
      />
      <Background style={{ backdropFilter: "blur(5px)" }} /> {props.background}
      {!props.show_loading ? (
        <div className="wrapper">
          <FlexArea
            className="background"
            onClick={onClose}
            children={
              <div
                className={
                  "content mainPadding" +
                  (props.className ? " " + props.className : "")
                }
                id={props.id}
                style={props.style}
              >
                {props.title ? (
                  <div className="titleDiv">
                    <FlexGrid
                      children={[
                        {
                          style: { flex: "1 0 50%" },
                          element: props.title ? <h3>{props.title}</h3> : null,
                        },
                        {
                          style: { flex: "1 0 50%" },
                          element: (
                            <div
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "28px",
                              }}
                            >
                              <CloseBtn onClick={props.onClose} />
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                ) : null}

                {props.children}
                {props.onSuccess ? (
                  <div className="bottom">
                    <SuccessBtn
                      style={{ width: "100%", fontSize: "0.8rem" }}
                      onClick={props.onSuccess}
                      context={props.context}
                    />
                    {props.bottomChildren}
                  </div>
                ) : null}
              </div>
            }
          />
        </div>
      ) : (
        <div className="lds-loading">
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
}

export default Index;
