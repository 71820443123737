import API from "../../index";

export default async (obj) => {
  try {
    let { data, status } = await API.post("/api/users", obj);

    return status == 200 ? data : { errors: data.errors };
  } catch (e) {
    throw e;
  }
};
