import API from "../../index";

export default async (obj) => {
  const { data, status } = await API.get(
    "/api/unboxings/" +
      obj.product_name +
      "/" +
      obj.cost +
      "/" +
      obj.max_timestamp +
      "/" +
      obj.client_seed +
      "/" +
      obj.winning_product_price +
      "/" +
      obj.offset +
      "/" +
      obj.limit +
      "/" +
      obj.order_by
  );

  return status == 201 ? data : null;
};
