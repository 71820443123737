import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Stat from "./elements/Stat";
import Icon from "../../../../core/Icon";

function Index(props) {
  return (
    <FlexGrid
      style={{ maxWidth: "650px", margin: "auto" }}
      children={[
        {
          style: {
            flex: "1 0 50%",
            padding: "5px",
          },
          element: (
            <Stat
              color={"var(--FunctionGreen)"}
              title={"Unbox Count"}
              children={<h4>{props.profile.user.unboxing_count}</h4>}
            />
          ),
        },
        {
          style: { flex: "1 0 50%", padding: "5px" },
          element: (
            <Stat
              color={"var(--gold)"}
              title={"Unbox Value"}
              children={
                <h4>
                  <Icon
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "5px",
                      verticalAlign: "middle",
                    }}
                    src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    {props.profile.user.unboxing_value}
                  </span>
                </h4>
              }
            />
          ),
        },
        {
          style: { flex: "1 0 50%", padding: "5px" },
          element: (
            <Stat
              color={"var(--mainPurple)"}
              title={"Daily Unbox Count"}
              children={<h4>{props.profile.user.daily_unbox_count}</h4>}
            />
          ),
        },
        {
          style: { flex: "1 0 50%", padding: "5px" },
          element: (
            <Stat
              color={"var(--error)"}
              title={"Code Used Count"}
              children={<h4>{props.profile.user.code_used_count}</h4>}
            />
          ),
        },
      ]}
    />
  );
}

export default Index;
