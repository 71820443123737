import React, { useEffect, useState } from "react";

//STYLES
import "./style.css";

//COMPONENTS
import TableHeaders from "./elements/TableHeaders";

export default (props) => {
  const [loading, setLoading] = useState(false);

  function handleScroll(target) {
    if (props.onScrollBottom) {
      if (
        Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) <
          1 &&
        props.max > props.content.length
      ) {
        setLoading(true);
        props.onScrollBottom(props.content.length);
      }
    }
  }

  useEffect(() => {
    setLoading(false);
  }, [props.content]);

  return (
    <ul
      className={("ContentList " + (props.className || "")).trim()}
      id={props.id}
      style={props.style}
      onScroll={(e) => handleScroll(e.target)}
    >
      {props.headers ? <TableHeaders children={props.headers} /> : null}
      {props.content.map((x) => x)}
      {loading ? (
        <div id="loading">
          <div className="lds-loading">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </ul>
  );
};
