import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

export default (props) => {
  const navigate = useNavigate();
  return (
    <div id="logoWrapper">
      <section>
        {props.width <= 640 ? (
          <img
            src={envConfig.s3 + "/images/logos/menuIcon.svg"}
            id="menuImg"
            onClick={props.toggleMobileNav}
          />
        ) : null}

        <div id="Logo" onClick={() => navigate("/")}>
          <section>
            <img
              src={envConfig.s3 + "/images/logos/white-logo.svg"}
              id="logoImg"
            />

            <img
              src={envConfig.s3 + "/images/logos/text-logo.svg"}
              id="textImg"
            />
          </section>
        </div>
      </section>
    </div>
  );
};
