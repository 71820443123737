//STORE
import store from "../../redux/store";

import search_inventory from "./server/search_inventory";
import convert_products from "./server/convert_products";
import get_inventory_by_ids from "./server/get_inventory_by_ids";

//COMPONENTS
import ConfirmConvertPopup from "../../../components/content/inventory/ConfirmConvertPopup";

export default {
  search_inventory: async (filters) => {
    const page_content = store.getState().page_content_reducer;
    filters = Object.assign(
      {},
      {
        query: null,
        order_by: "time: low - high",
        offset: 0,
        items: page_content?.items,
        coin: page_content?.coin,
      },
      filters,
      filters?.items !== undefined || filters?.coin !== undefined
        ? { selected: [] }
        : null
    );
    store.dispatch({
      type: "UPDATE_PAGE_CONTENT",
      content: { loading: true, data: [] },
    });
    let data = await search_inventory(
      Object.assign({}, { limit: 25 }, filters)
    );

    if (data) {
      store.dispatch({
        type: "UPDATE_PAGE_CONTENT",
        content: Object.assign(
          {},
          { loading: false, data: data.data || [], max: data.max },
          filters
        ),
      });
    }
  },
  dropdown_search: async (filters) => {
    let data = await search_inventory(
      Object.assign({}, { limit: 25, items: true, coin: true }, filters)
    );
    return data || [];
  },
  select_all: async (filters = { items: true, coin: true }) => {
    let data = await search_inventory({
      query: null,
      order_by: "time: low - high",
      offset: 0,
      items: filters.items,
      coin: filters.coin,
      limit: null,
    });

    if (data) {
      store.dispatch({
        type: "UPDATE_PAGE_CONTENT",
        content: { selected: data.data },
      });
    }
  },
  get_all: async () => {
    let data = await search_inventory({
      query: null,
      order_by: "time: low - high",
      items: true,
      coin: true,
      offset: 0,
      limit: null,
    });

    return data;
  },

  convert_products: async (products, callback = () => {}) => {
    const value = products.reduce((prev, cur) => prev + cur.price, 0);
    products = products.map((x) => x.id);

    if (products.length > 1 || value >= 15) {
      store.dispatch({
        type: "NEW_POPUP",
        element: (
          <ConfirmConvertPopup
            popup_id="ConvertConfirmPopup"
            value={value}
            onConvert={() => {
              convert(products);
              store.dispatch({ type: "CLOSE_POPUP" });
              callback();
            }}
          />
        ),
      });
    } else {
      convert(products);
      callback();
    }
  },
  get_inventory: async (filters) => {
    return await get_inventory_by_ids(filters);
  },
};

async function convert(products) {
  let obj = await convert_products(products);

  if (obj) {
    //REMOVE INVENTORY PRODUCTS
    store.dispatch({
      type: "DELETE_PAGE_CONTENT_ELEMENTS",
      elements: products,
    });
    store.dispatch({
      type: "REMOVE_PAGE_CONTENT_SELECTED",
      elements: products,
    });

    store.dispatch({
      type: "REMOVE_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
      selected: products.map((x) => new Object({ id: x })),
    });

    let account_dropdown = store.getState().account_dropdown_reducer;

    account_dropdown.inventory.data = account_dropdown.inventory.data.filter(
      (x) => !products.find((y) => x.id == y)
    );

    //REMOVE PRODUCTS FROM DROPDOWN
    store.dispatch({
      type: "UPDATE_ACCOUNT_DROPDOWN",
      object: account_dropdown,
    });

    //UPDATE ACCOUNT BALANCE
    store.dispatch({
      type: "UPDATE_ACCOUNT_BALANCE",
      account_balance: obj.new_balance,
    });

    //ADD ACCOUNT XP
    store.dispatch({ type: "ADD_ACCOUNT_XP", object: { xp: obj.xp_amount } });
  }
}
