//STORE
import store from "../../redux/store";

//API
import search_orders from "./server/search_orders";
import search_orders_products from "./server/search_orders_products";
import get_order from "./server/get_order";
import add_order from "./server/add_order";
import add_giftcard_order from "./server/add_giftcard_order";

export default {
  search_orders: async (obj) => {
    obj = Object.assign({}, store.getState().page_content_reducer, obj);
    let data = await search_orders(obj);

    return data;
  },
  search_orders_products: async (obj) => {
    console.log(obj);
    return await search_orders_products(obj);
  },
  get_order: async (obj) => {
    let data = await get_order(obj);

    return data?.order;
  },
  add_order: async (obj) => {
    let data;
    switch (obj.order.method) {
      case "deliver":
        data = await add_order(obj);
        break;
      case "giftcard":
        data = await add_giftcard_order(obj);
    }
    if (data) {
      store.dispatch({
        type: "UPDATE_ACCOUNT_BALANCE",
        account_balance: data.new_balance,
      });

      let dropdown = store.getState().account_dropdown_reducer;

      dropdown.inventory = {
        products: dropdown.inventory.data.filter(
          (x) => !obj.order.products.find((y) => y.id === x.id)
        ),
        selected: [],
      };

      //REMOVE FROM DROPDOWN
      store.dispatch({ type: "UPDATE_ACCOUNT_DROPDOWN", object: dropdown });

      return true;
    } else {
      return false;
    }
  },
};
