import React, { useState, useEffect } from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Textbox from "../../../../core/inputs/text/Textbox";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  const [valueTxt, setValueTxt] = useState(
    GeneralFunctions.parse_currency(props.checkout.value)
  );

  useEffect(() => {
    setValueTxt(GeneralFunctions.parse_currency(props.checkout.value));
  }, [props.checkout.value]);

  return (
    <div id="SelectAmount" className="highlighted">
      <h4 style={{ fontWeight: "bold", margin: "0", marginBottom: "10px" }}>
        Deposit Value
      </h4>
      <FlexGrid
        children={[
          {
            style: { flex: "0 0" },
            element: (
              <FlexArea
                children={
                  <img
                    src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                    style={{
                      width: "35px",
                      margin: "auto",
                      marginRight: "10px",
                    }}
                  />
                }
              />
            ),
          },
          {
            style: { flex: "1 0 30%" },
            element: (
              <>
                <Background
                  style={{ background: "var(--background)", opacity: "0.6" }}
                />
                <Textbox
                  id="amountTxt"
                  style={{ background: "transparent" }}
                  value={valueTxt}
                  onChange={(e) => setValueTxt(e.target.value)}
                  onBlur={() =>
                    props.setProperty({
                      value: valueTxt,
                    })
                  }
                  onFocus={() => setValueTxt(parseFloat(valueTxt))}
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? props.setProperty({
                          value: valueTxt,
                        })
                      : () => {}
                  }
                />
              </>
            ),
          },
          {
            style: {
              position: "relative",
              flex: "1",
              border: "2px solid var(--highlighted)",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            },
            element: (
              <>
                <Background
                  style={{
                    background: "var(--highlighted)",
                    opacity: "0.3",
                  }}
                />
                <FlexArea
                  children={
                    <span
                      style={{
                        margin: "auto",
                        fontSize: "1.2rem",
                        color: "var(--FunctionGreen)",
                      }}
                    >
                      ${GeneralFunctions.parse_currency(props.checkout.value)}
                    </span>
                  }
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};
