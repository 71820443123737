import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Element from "./elements/Element";

export default (props) => {
  return (
    <div className="Form" style={props.style} {...props}>
      {props.children.map((x, i) => (
        <Element key={i} name={x.name} children={x.el} />
      ))}
    </div>
  );
};
