import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageTop from "../../components/layout/PageTop";
import PageLayout from "../../components/layout/PageLayout";
import OrderList from "../../components/content/orders/OrderList";
import Banner from "../../components/content/orders/Banner";
import Search from "../../components/content/orders/Search";

export default (props) => {
  return (
    <div id="Orders">
      <Helmet>
        <title>Orders | Vivavim</title>
        <meta
          name="description"
          content="view your ongoing and previous orders"
        />
        <link rel="canonical" href={envConfig.server + "/orders"} />
      </Helmet>
      <PageLayout
        title={"Orders"}
        sub_title={"Track Your Ongoing Orders"}
        children={
          <div>
            <Search {...props} />
            <OrderList {...props} />
          </div>
        }
      />
    </div>
  );
};
