import React, { useState } from "react";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLE
import "./style.css";

//COMPONENTS
import Popup from "../../../layout/popups/Popup";
import ContentList from "../../../core/ContentList";
import FileUpload from "../../../core/buttons/FileUpload";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  return (
    <Popup
      title={"Image Select"}
      style={{ maxWidth: "1000px" }}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        <div id="ImageSelect">
          <FlexGrid
            style={{ marginTop: "10px", marginBottom: "10px" }}
            children={[
              {
                style: { flex: "1 0 50%" },
                element: (
                  <FileUpload
                    context={"Upload Custom"}
                    onChange={(value) => {
                      //props.onSuccess("image", value);
                      props.onSuccess({ image: value });
                      dispatch({ type: "CLOSE_POPUP" });
                    }}
                    style={{
                      fontSize: "1rem",
                      width: "100%",
                    }}
                  />
                ),
              },
              {
                style: {},
                element: <FlexArea children={<span></span>} />,
              },
            ]}
          />

          <ContentList
            id="options"
            style={{ justifyContent: "left" }}
            content={new Array(16).fill(1).map((x, i) => (
              <div
                className="option"
                style={{
                  position: "relative",
                  flex: "0 0 20%",
                  boxSizing: "border-box",
                  padding: "5px",
                }}
                onClick={() => {
                  /*props.onSuccess("image", null);
                  props.onSuccess("default_image", i + 1);*/

                  props.onSuccess({ image: null, default_image: i + 1 });
                  dispatch({ type: "CLOSE_POPUP" });
                }}
              >
                <FlexArea
                  children={
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        margin: "auto",
                      }}
                      src={
                        envConfig.s3 +
                        "/images/default_users/default" +
                        (i + 1) +
                        ".webp"
                      }
                    />
                  }
                />
              </div>
            ))}
          />
        </div>
      }
    />
  );
};
