import React, { useState, useEffect } from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//API
import s3 from "../../../../../common/api/s3";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";

function Index(props) {
  const [elements, setElements] = useState(null);

  useEffect(() => {
    getJSON();
  }, []);

  const getJSON = async () => {
    setElements(
      await s3.get_resource({
        url: envConfig.s3 + "/documents/HowItWorks/QuickInfo.json",
      })
    );
  };

  return (
    <div id="QuickInfo">
      {elements?.map((x) => (
        <div style={{ position: "relative" }}>
          <h3>{x.title}</h3>
          <FlexGrid
            children={x.sections.map((y) => {
              return {
                style: {
                  flex: "1 0 100%",
                  display: "inline-block",
                  alignSelf: "stretch",
                  boxSizing: "border-box",
                  padding: "5px",
                },
                element: (
                  <>
                    <FlexArea
                      children={
                        <div className="section">
                          <Background
                            style={{
                              background: "var(--secondaryBackground)",
                              borderRadius: "8px",
                            }}
                          />
                          <div style={{ position: "relative" }}>
                            <h4>{y.title}</h4>
                            <p>{y.text}</p>
                            <div
                              style={{ position: "relative", padding: "10px" }}
                            >
                              <Background
                                style={{
                                  background: "var(--background)",
                                  borderRadius: "8px",
                                }}
                              />
                              <img src={envConfig.s3 + y.image} />
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </>
                ),
              };
            })}
          />
        </div>
      ))}
    </div>
  );
}

export default Index;
