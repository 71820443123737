import React from "react";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import GoogleMap from "../../../../../../common/GoogleMap";

function Index(props) {
  return (
    <div>
      <FlexGrid
        children={[
          {
            style: { flex: "1 0 50%" },
            element: (
              <div>
                <h4>{unescape(props.address.name)}</h4>
                <h4>{unescape(props.address.street_address)}</h4>
                {props.address.street_address_2 ? (
                  <h4>{unescape(props.address.street_address_2)}</h4>
                ) : null}
                <h4>{unescape(props.address.city)}</h4>
                <h4>{unescape(props.address.state)}</h4>
                <h4>{unescape(props.address.phone_number)}</h4>
              </div>
            ),
          },
          {
            style: { flex: "1 0 50%" },
            element: (
              <div>
                <GoogleMap address={props.address} />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
