import { combineReducers } from "redux";

//REDUCERS
import page_content_reducer from "./page_content_reducer";
import session_reducer from "./session_reducer";
import subscription_reducer from "./subscription_reducer";
import account_balance_reducer from "./account_balance_reducer";
import live_wins_reducer from "./live_wins_reducer";
import popups_reducer from "./popups_reducer";
import crate_inventory_reducer from "./crate_inventory_reducer";
import unboxing_reducer from "./unboxing_reducer";
import unboxing_session_reducer from "./unboxing_session_reducer";
import account_level_reducer from "./account_level_reducer";
import show_xp_bar_reducer from "./style/show_xp_bar_reducer";
import config_reducer from "./config/config.reducer";
import last_route_reducer from "./config/last_route_reducer";
import unboxing_carousel_reducer from "./unboxing_carousel_reducer";
import carousel_segments_reducer from "./carousel_segments_reducer";
import chat_reducer from "./chat_reducer";
import addresses_reducer from "./addresses_reducer";
import chat_style_reducer from "./style/chat_style_reducer";
import animation_lock_reducer from "./animation_lock_reducer";
import audio_reducer from "./audio/audio_reducer";
import account_dropdown_reducer from "./account_dropdown_reducer";
import show_livewins_reducer from "./style/show_livewins_reducer";

const allReducers = combineReducers({
  config_reducer,
  last_route_reducer,
  animation_lock_reducer,

  //STYLE
  show_xp_bar_reducer,
  chat_style_reducer,
  show_livewins_reducer,

  //AUDIO
  audio_reducer,

  //USER
  session_reducer,
  account_balance_reducer,
  subscription_reducer,
  unboxing_session_reducer,
  account_level_reducer,

  //CONTENT
  page_content_reducer,
  live_wins_reducer,
  popups_reducer,
  crate_inventory_reducer,
  unboxing_reducer,
  unboxing_carousel_reducer,
  carousel_segments_reducer,
  chat_reducer,
  addresses_reducer,
  account_dropdown_reducer,
});

export default allReducers;
