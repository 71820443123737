import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import Account from "../../../../../../../../common/api/Account";

//COMPONENTS
import Popup from "../../../../../../../layout/popups/Popup";
import Textbox from "../../../../../../../core/inputs/text/Textbox";

function Index(props) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);
  const [emailTxt, setEmailTxt] = useState("");
  const [error, setError] = useState(null);

  async function handleConfirm() {
    setError(null);

    if (Account.change_email_address({ email_address: emailTxt })) {
      dispatch({ type: "CLOSE_POPUP" });
    } else {
      setError(true);
    }
  }

  return (
    <Popup
      id="ChangeUsername"
      title={"Change Email Address"}
      context={"Confirm Change"}
      onSuccess={handleConfirm}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        <div>
          <span>Current Email Address</span>
          <Textbox value={session.user.email_address} disabled={true} />
          <span>New Email Address</span>
          <Textbox
            value={emailTxt}
            onChange={(e) => setEmailTxt(e.target.value)}
          />
          <p>
            This address will be used to login and if we need to contact you
          </p>
        </div>
      }
    />
  );
}

export default Index;
