import React, { useState, useEffect } from "react";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//API
import s3 from "../../../../common/api/s3";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../layout/flex/FlexGrid";
import Background from "../../../common/backgrounds/Background";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const [elements, setElements] = useState(null);
  useEffect(() => {
    getJSON();
  }, []);

  const getJSON = async () => {
    setElements(
      await s3.get_resource({
        url: envConfig.s3 + "/documents/contactUs/contact_us.json",
      })
    );
  };

  console.log(elements);

  return (
    <div id="ContactMethods">
      {elements ? (
        <FlexGrid
          children={elements.map((x) => {
            return {
              style: {
                position: "relative",
                flex: "1 0 100%",
                display: "grid",
                boxSizing: "border-box",
                padding: "5px",
                asignSelf: "stretch",
              },
              element: (
                <div
                  className="method"
                  style={{ border: "1px solid " + x.color }}
                >
                  <Background
                    style={{
                      background: "var(--secondaryBackground)",
                      borderRadius: "8px",
                    }}
                  />
                  <FlexGrid
                    style={{ justifyContent: "left" }}
                    children={[
                      {
                        style: { marginRight: "10px" },
                        element: (
                          <FlexArea
                            children={<img src={envConfig.s3 + x.icon} />}
                          />
                        ),
                      },
                      {
                        style: { flex: "1" },
                        element: (
                          <FlexArea
                            children={
                              <h4 style={{ margin: "auto", marginLeft: "0" }}>
                                {x.title}
                              </h4>
                            }
                          />
                        ),
                      },
                      {
                        style: { flex: "1" },
                        element: (
                          <FlexArea
                            children={
                              <a
                                href={x.value}
                                style={{
                                  margin: "auto",
                                  marginLeft: "0",
                                  fontWeight: "bold",
                                  color: "var(--FunctionGreen)",
                                }}
                                target="_blank"
                              >
                                {x.value}
                              </a>
                            }
                          />
                        ),
                      },
                      {
                        style: { flex: "1" },
                        element: (
                          <FlexArea
                            children={
                              <p style={{ margin: "auto", marginLeft: "0" }}>
                                {x.description}
                              </p>
                            }
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              ),
            };
          })}
        />
      ) : null}
    </div>
  );
};
