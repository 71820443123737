import envConfig from "../../../../common/config/env.config";
import ServerFunctions from "./Server.functions";
import ValidationFunctions from "./Validation.functions";
import UnboxingFunctions from "./Unboxing.functions";
import ControlFunctions from "../Carousel/functions/Control.Functions";
import ParseDateTime from "../../../../common/functions/ParseDateTime";
import store from "../../../../common/redux/store";

//API
import Products from "../../../../common/api/Products";

export default {
  init_page: async (crate_id, products, type, newLoad) => {
    let crate_inventory = store.getState().crate_inventory_reducer;

    if (!newLoad) {
      return crate_inventory;
    } else {
      if (type == "daily") {
        crate_inventory = await ServerFunctions.get_daily_crate_inventory(
          crate_id
        );

        store.dispatch({
          type: "POPULATE_UNBOXING",
          object: { type: "daily", can_edit: false },
        });
      } else if (crate_id) {
        crate_inventory = await ServerFunctions.get_crate_inventory(crate_id);
        store.dispatch({
          type: "POPULATE_UNBOXING",
          object: { type: "unbox", can_edit: true },
        });
      } else if (products) {
        crate_inventory = {
          crate: null,
          products: (
            await Products.get_products({
              id_list: products.map((x) => x.id),
            })
          ).sort((a, b) => b.price - a.price),
        };
        crate_inventory.products = crate_inventory.products.map((x) =>
          Object.assign({}, x, {
            probability: products.find((y) => x.id == y.id).prob,
          })
        );
        store.dispatch({
          type: "POPULATE_UNBOXING",
          object: { type: "unbox", can_edit: true },
        });
      }
      if (crate_inventory) {
        populate_crate_inventory(crate_inventory);

        ControlFunctions.init({
          products: crate_inventory.products,
          unboxing: null,
          type: "preview",
        });

        document
          .getElementById("content")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  },

  unbox_crate: async () => {
    const animation_lock = store.getState().animation_lock_reducer;

    if (!animation_lock) {
      store.dispatch({ type: "SET_ANIMATION_LOCK", locked: true });
      let unboxing = store.getState().unboxing_reducer;
      let crate_inventory = store.getState().crate_inventory_reducer;
      let balance = store.getState().account_balance_reducer;
      let error_code = ValidationFunctions.validate_unboxing(
        crate_inventory.products,
        balance
      );
      if (!error_code) {
        //LOCK UNBOXING PAGE
        store.dispatch({ type: "LOCK_ANIMATION" });

        let object;
        if (unboxing.type == "daily") {
          object = await ServerFunctions.create_daily_unboxing(
            crate_inventory.crate.id,
            store.getState().unboxing_session_reducer
          );

          //UPDATE CRATE TIME LOCKED UNTIL
          updateCrateTimeLocked(crate_inventory, object);
        } else {
          object = await ServerFunctions.create_unboxing(
            crate_inventory,
            store.getState().unboxing_session_reducer
          );
        }

        object.winning_product = Object.assign(
          {},
          crate_inventory.products.find(
            (x) => x.id == object.winning_product.winning_product_id
          ),
          object.winning_product
        );
        object.winning_product = Object.assign({}, object.winning_product, {
          roll: object.roll,
          real_price: object.winning_product.price,
          unboxing_id: object.unboxing_id,
          id: object.winning_product.account_product_id,
          xp_amount: object.winning_product.xp_amount,
          unboxing_id: object.unboxing_id,
        });

        if (object.new_balance) {
          store.dispatch({
            type: "UPDATE_ACCOUNT_BALANCE",
            account_balance: object.new_balance,
          });

          /*store.dispatch({
            type: "ADD_NEW_NOTIFICATION",
            notification: {
              type: "TRANSACTION",
              object: {
                value: parseFloat((object.new_balance - balance).toFixed(2)),
              },
            },
          });*/
        }

        //ADD NEW PRODUCT TO INVENTORY IF CURRENTLY LOADED
        if (store.getState().page_content_reducer.page == "inventory") {
          store.dispatch({
            type: "ADD_PAGE_CONTENT_ELEMENT",
            element: object.winning_product,
          });
        }

        store.dispatch({
          type: "INCREMENT_INVENTORY_COUNT",
        });

        ControlFunctions.init({
          products: crate_inventory.products,
          unboxing: object,
          type: "unbox",
        });

        //PLAY SOUND
        store.dispatch({
          type: "ADD_AUDIO",
          source: envConfig.s3 + "/sounds/unbox/open.mp3",
        });
      } else {
        store.dispatch({
          type: "POPULATE_UNBOXING",
          object: { error_code },
        });
      }
    }
  },
  test_unbox: () => {
    const animation_lock = store.getState().animation_lock_reducer;
    if (!animation_lock) {
      store.dispatch({ type: "SET_ANIMATION_LOCK", locked: true });

      let crate_inventory = store.getState().crate_inventory_reducer;
      let error_code = ValidationFunctions.validate_unboxing(
        crate_inventory.products
      );

      if (!error_code) {
        //LOCK UNBOXING PAGE
        store.dispatch({ type: "LOCK_ANIMATION" });

        let { winning_product, roll } =
          UnboxingFunctions.test_unbox(crate_inventory);

        ControlFunctions.init({
          products: crate_inventory.products,
          unboxing: { winning_product, roll },
          type: "test",
        });

        //PLAY SOUND
        store.dispatch({
          type: "ADD_AUDIO",
          source: envConfig.s3 + "/sounds/unbox/open.mp3",
        });
      } else {
        store.dispatch({
          type: "POPULATE_UNBOXING",
          object: { error_code },
        });
      }
    }
  },
  set_client_seed: async (client_seed) => {
    client_seed = await ServerFunctions.set_client_seed(client_seed);
    if (client_seed) {
      store.dispatch({ type: "SET_CLIENT_SEED", client_seed });
      return true;
    }
    return false;
  },
};

function populate_crate_inventory(crate_inventory) {
  store.dispatch({
    type: "POPULATE_CRATE_INVENTORY",
    crate_inventory,
  });
}

function updateCrateTimeLocked(crate_inventory, object) {
  let new_crate = crate_inventory.crate;
  new_crate.time_locked_until = ParseDateTime.adjustForTimezone(
    new Date(object.timestamp)
  );

  //UPDATE UNBOXING PAGE
  store.dispatch({
    type: "POPULATE_CRATE_INVENTORY",
    crate_inventory: Object.assign({}, crate_inventory, new_crate),
  });

  //UPDATE REWARDS PAGE
  /*let page_content = store.getState().page_content_reducer;
  if (page_content && page_content.page == "/rewards") {
    store.dispatch({
      type: "UPDATE_PAGE_CONTENT_ELEMENT",
      element: {
        id: new_crate.id,
        time_locked_until: new_crate.time_locked_until,
      },
    });
  }*/
}
