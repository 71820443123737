import React from "react";
import { useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//API
import Inventory from "../../../../../common/api/Inventory";

//COMPONENTS
import Popup from "../../../../layout/popups/Popup";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import ProductRank from "../../../products/ProductRank";
import LogoAnimated from "../../../../common/backgrounds/animated/LogoAnimated";
import OptionButtons from "../../../inventory/MultiSelectOptions/elements/OptionButtons";
import XP from "../../../account/ProfileSection/elements/XP";
import getProductRank from "../../../../../common/functions/getProductRank";
import FlexArea from "../../../../layout/flex/FlexArea";
import Confetti from "../../../../common/Countdown/Confetti";

function Index(props) {
  let dispatch = useDispatch();
  const account_level = useSelector((state) => state.account_level_reducer);
  const session = useSelector((state) => state.session_reducer);
  var unboxing = new Object(props.unboxing);

  return (
    <Popup
      id="WinningPopup"
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      onSuccess={() => dispatch({ type: "CLOSE_POPUP" })}
      context={"Continue"}
      background={<Confetti />}
      children={
        <div style={{ paddingTop: "10px" }}>
          <FlexGrid
            children={[
              {
                style: {
                  flex: "1 0 100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                },
                element:
                  session && props.unboxing.xp_amount !== null ? (
                    <XP
                      style={{ height: "25px", fontSize: "1rem" }}
                      user={Object.assign({}, session.user, {
                        account_level: account_level.level,
                        total_xp: account_level.xp,
                      })}
                      show_remaining={true}
                    />
                  ) : null,
              },
              {
                style: { flex: "1 0 100%" },
                element: (
                  <FlexGrid
                    children={[
                      {
                        style: { marginRight: "auto" },
                        element: (
                          <span>
                            Roll:{" "}
                            <span
                              style={{
                                color: "var(--FunctionGreen)",
                                fontWeight: "bold",
                              }}
                            >
                              {unboxing.roll}
                            </span>
                          </span>
                        ),
                      },
                      {
                        style: { marginLeft: "auto" },
                        element: props.unboxing.xp_amount ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "var(--FunctionGreen)",
                            }}
                          >
                            +{props.unboxing.xp_amount}xp
                          </span>
                        ) : null,
                      },
                    ]}
                  />
                ),
              },
              {
                style: { flex: "1 0 100%", textAlign: "left" },
                element: <h2>You Have Won</h2>,
              },

              {
                style: {
                  flex: "1",
                  background: "var(--highlighted)",
                  borderRadius: "8px",
                  padding: "10px",
                },
                element: (
                  <FlexGrid
                    children={[
                      {
                        style: {
                          flex: "1 0 50%",
                          minWidth: "250px",
                        },
                        element: (
                          <FlexArea
                            style={{ position: "relative" }}
                            children={
                              <div id="productImg">
                                <ProductRank
                                  product={unboxing.winning_product}
                                />
                              </div>
                            }
                          />
                        ),
                      },
                      {
                        style: {
                          flex: "1 0 50%",
                        },
                        element: (
                          <div id="info">
                            <h2 id="price">
                              <img
                                src={
                                  envConfig.s3 +
                                  "/images/icons/common/vivacoin.svg"
                                }
                                style={{
                                  verticalAlign: "middle",
                                  width: "30px",
                                  marginRight: "5px",
                                }}
                              />
                              <span style={{ verticalAlign: "middle" }}>
                                {unboxing.winning_product.price}
                              </span>
                            </h2>
                            <h3 id="title">
                              {unescape(unboxing.winning_product.name)}
                            </h3>
                          </div>
                        ),
                      },
                    ]}
                  />
                ),
              },

              unboxing.xp_amount !== undefined
                ? {
                    style: {
                      flex: "1 0 100%",
                      marginTop: "10px",
                      borderTop: "1px solid var(--borderColor)",
                      borderBottom: "1px solid var(--borderColor)",
                    },
                    element: (
                      <div
                        style={{
                          display: "inline-block",
                          margin: "auto",
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <OptionButtons
                          products={[
                            Object.assign({}, unboxing.winning_product, {
                              id: unboxing.account_product_id,
                            }),
                          ]}
                          onConvert={() => {
                            dispatch({ type: "CLOSE_POPUP" });
                          }}
                        />
                      </div>
                    ),
                  }
                : null,
            ]}
          />
        </div>
      }
    />
  );
}

export default Index;
