import API from "../../index";

export default async (obj) => {
  const { data, status } = await API.post("/api/account/addresses", {
    id: obj?.id,
    address: obj.address,
  });

  return status == 201 ? data : null;
};
