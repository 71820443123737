const unboxing_session_reducer = (state = null, action) => {
  switch (action.type) {
    case "POPULATE_UNBOXING_SESSION":
      return action.unboxing_session;
      break;
    case "UPDATE_UNBOXING_HASHED_SERVER_SEED":
      console.log(action);
      return Object.assign({}, state, {
        hashed_server_seed: action.hashed_server_seed,
      });
    case "SET_CLIENT_SEED":
      return Object.assign({}, state, { client_seed: action.client_seed });
    default:
      return state;
  }
};

export default unboxing_session_reducer;
