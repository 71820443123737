import React from "react";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//FUNCTIONS
import OrderFunctions from "../../../../functions/Order.Functions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import Btn from "../../../../../../core/buttons/Btn";
import ConfirmRefund from "../../popups/ConfirmRefund";
import Background from "../../../../../../common/backgrounds/Background";

function Index(props) {
  const dispatch = useDispatch();

  const status = OrderFunctions.parse_order_state(props.order);

  return (
    <div
      id="Info"
      style={{
        border: "2px solid var(--mainPurple)",
        boxShadow: "0 0 10px var(--mainPurple)",
        borderRadius: "8px",
      }}
    >
      <Background
        style={{
          opacity: "0.2",
          background: "var(--mainPurple)",
        }}
      />
      <FlexGrid
        style={{ width: "100%", justifyContent: "start" }}
        children={[
          {
            style: {},
            element: (
              <div>
                {OrderFunctions.parse_order_state_text(
                  props.order,
                  OrderFunctions.parse_order_state(props.order).status
                )}
              </div>
            ),
          },
        ]}
      />
      <div style={{ textAlign: "right" }}>
        {status == "review" ? (
          <Btn
            context={"Cancel Order"}
            onClick={() =>
              dispatch({
                type: "NEW_POPUP",
                element: <ConfirmRefund id={props.order} />,
              })
            }
            style={{
              color: "var(--error)",
              border: "1px solid var(--error)",
              background: "transparent",
              fontSize: "1rem",
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Index;
