import React, { useState } from "react";
import { useDispatch } from "react-redux";

//API
import Affiliate from "../../../../../../../../../../common/api/Affiliate";

//COMPONENTS
import Popup from "../../../../../../../../../layout/popups/Popup";
import Textbox from "../../../../../../../../../core/inputs/text/Textbox";
import Form from "../../../../../../../../../core/Form";

function Index(props) {
  const dispatch = useDispatch("");
  const [codeTxt, setCodeTxt] = useState(null);

  return (
    <Popup
      style={{ maxWidth: "600px" }}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      title={"Edit Promo Code"}
      onSuccess={() => {
        props.change_code(codeTxt);
        dispatch({ type: "CLOSE_POPUP" });
      }}
      context={"Confirm Code"}
      children={
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Form
            children={[
              {
                name: "Current Code",
                el: <Textbox value={props.code} disabled={true} />,
              },
              {
                name: "New Code",
                el: (
                  <Textbox
                    value={codeTxt || ""}
                    maxLength={15}
                    onChange={(e) => setCodeTxt(e.target.value)}
                  />
                ),
              },
            ]}
          />
        </div>
      }
    />
  );
}

export default Index;
