import React from "react";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";

export default (props) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        marginBottom: "10px",
        paddingBottom: "5px",
        color: "white",
        borderBottom: "1px solid",
      }}
    >
      <FlexGrid
        children={props.children.map((x) =>
          Object.assign({}, x, {
            style: Object.assign(
              {},
              {
                flex: "1",
                textAlign: "left",
                fontWeight: "bold",
              },
              x.style
            ),
          })
        )}
      />
    </div>
  );
};
