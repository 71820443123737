import API from "../../index";

export default async () => {
  const { data, status } = await API.get("/api/account/addresses");

  return status == 201
    ? data?.map((x) => {
        Object.keys(x.address).forEach((y) => {
          y = x.address[y] = x.address[y] ? unescape(x.address[y]) : null;
        });
        return x;
      }) || []
    : null;
};
