import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//API
import Auth from "../../../../common/api/Auth";

//STYLES
import "./style.css";

//COMPONENTS
import PopupWithRedirect from "../../../layout/popups/PopupWithRedirect";
import Textbox from "../../../core/inputs/text/Textbox";

export default (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [passTxt, setPassTxt] = useState("");
  const [conPassTxt, setConPassTxt] = useState("");
  const [success, setSuccess] = useState(null);

  async function confirm() {
    const data = await Auth.password_reset_confirm({
      token: params.token,
      password: passTxt,
      new_password: conPassTxt,
    });

    setSuccess(data);
  }

  return !success ? (
    <PopupWithRedirect
      id="PasswordReset"
      title={"Password Reset"}
      context={"Confirm Change"}
      onSuccess={confirm}
      children={
        <div>
          <p>Enter your new password</p>
          <Textbox
            type={"password"}
            error={success === false}
            value={passTxt}
            onChange={(e) => setPassTxt(e.target.value)}
          />
          <p>Confirm new password</p>
          <Textbox
            type={"password"}
            error={success === false}
            value={conPassTxt}
            onChange={(e) => setConPassTxt(e.target.value)}
          />
        </div>
      }
    />
  ) : (
    <PopupWithRedirect
      title={"Password Reset"}
      context={"Go to login"}
      onSuccess={() => navigate("/login")}
      children={
        <div>
          <p>Your password has been changed</p>
        </div>
      }
    />
  );
};
