import React from "react";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import ContentList from "../../../../../core/ContentList";
import Product from "./elements/Product";
import Background from "../../../../../common/backgrounds/Background";
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";
import Btn from "../../../../../core/buttons/Btn";
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../../layout/flex/FlexArea";
import Price from "../../../../../core/Price";
import Icon from "../../../../../core/Icon";

export default (props) => {
  const dispatch = useDispatch();

  return (
    <div id="OrderProductList">
      <FlexGrid
        style={{ flexDirection: "column", height: "100%" }}
        children={[
          {
            element: (
              <FlexGrid
                style={{
                  width: "100%",
                  justifyContent: "start",
                  marginBottom: "5px",
                }}
                children={[
                  {
                    element: (
                      <FlexArea
                        children={<h3 style={{ margin: "auto" }}>Items</h3>}
                      />
                    ),
                  },
                  props.can_add
                    ? {
                        style: { flex: "1" },
                        element: (
                          <FlexArea
                            children={
                              <Btn
                                onClick={() => {
                                  /*dispatch({
                                    type: "SET_ACCOUNT_DROPDOWN_INVENTORY_SELECTED",
                                    selected: props.products,
                                  });*/
                                  dispatch({
                                    type: "SET_ACCOUNT_DROPDOWN_TAB",
                                    show: "inventory",
                                  });
                                }}
                                style={{
                                  fontSize: "0.8rem",
                                  margin: "auto",
                                  marginRight: "0",
                                  fontWeight: "bold",
                                  color: "white",
                                  border: "2px solid var(--FunctionGreen)",
                                  padding: "5px 10px 5px 10px",
                                  background: "var(--background)",
                                }}
                                children={
                                  <span>
                                    <Icon
                                      src={
                                        envConfig.s3 + "/images/icons/plus.svg"
                                      }
                                      style={{
                                        width: "10px",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <span style={{ verticalAlign: "middle" }}>
                                      Add Items
                                    </span>
                                  </span>
                                }
                              />
                            }
                          />
                        ),
                      }
                    : null,
                ]}
              />
            ),
          },
          {
            style: { flex: "1" },
            element: (
              <div
                style={{
                  height: "100%",
                  boxSizing: "border-box",
                  padding: "10px",
                }}
              >
                <Background
                  style={{
                    background: "var(--secondaryBackground)",
                    borderRadius: "8px",
                  }}
                />
                {!props.warning ? (
                  <ContentList
                    style={{
                      overflow: "auto",
                      margin: "0",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      height: "unset",
                      boxSizing: "border-box",
                      maxHeight: "400px",
                      paddingRight: "10px",
                    }}
                    content={props.products.map((x) => (
                      <Product product={x} order_type={props.order_type} />
                    ))}
                  />
                ) : (
                  <div style={{ maxHeight: "450px", overflow: "auto" }}>
                    {props.products.find((x) => x.in_stock == 0) ? (
                      <ContentList
                        content={props.products
                          .filter((x) => x.in_stock == 0)
                          .map((x) => (
                            <Product
                              product={x}
                              order_type={props.order_type}
                            />
                          ))}
                      />
                    ) : null}
                    {props.products.find(
                      (x) => x.real_price > x.price && x.in_stock == 1
                    ) ? (
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <ContentList
                          content={props.products
                            .filter((x) => x.real_price > x.price)
                            .map((x) => (
                              <Product
                                product={x}
                                order_type={props.order_type}
                              />
                            ))}
                        />
                      </div>
                    ) : null}
                    <ContentList
                      content={props.products
                        .filter(
                          (x) => x.in_stock == 1 && x.real_price <= x.price
                        )
                        .map((x) => (
                          <Product product={x} order_type={props.order_type} />
                        ))}
                    />
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
