import react, { useState, useEffect, createRef } from "react";
//STYLES
import "./style.css";

//FUNCTIONS
import DetectSizeChange from "../../../common/functions/DetectSizeChange";

//COMPONENTS
import Dropdown from "../../Dropdown";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";
import Search from "../Search";
import CloseBtn from "../../buttons/CloseBtn";
import Background from "../../../common/backgrounds/Background";

function Index(props) {
  const SearchBarRef = createRef();
  const [query, setQuery] = useState("");
  const [focus, setFocus] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);

  const { width } = DetectSizeChange(SearchBarRef);

  useEffect(() => {
    setMobile(width <= 220);
    if (width > 220) {
      setFocus(false);
    }

    if (mobileShow && width > 480) {
      setMobileShow(false);
    }
  }, [width]);

  useEffect(() => {
    setQuery(props.value);
  }, [props.value]);

  function handleKeyPress(e) {
    if (e.keyCode == 13) {
      props.onSearch({ query });
      document.getElementById("navBarSearchTxt").blur();
    }
  }

  return (
    <div style={{ position: "relative", height: "50px", width: "100%" }}>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        {!mobileShow ? (
          <FlexGrid
            className="mainPadding"
            style={Object.assign({}, { height: "100%" }, props.style)}
            children={[
              ...(props.elements || []),
              {
                style: { marginLeft: "auto", flex: "1" },
                element: (
                  <div
                    ref={SearchBarRef}
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      maxWidth: "450px",
                      marginLeft: "auto",
                    }}
                  >
                    <FlexArea
                      children={
                        <div style={{ margin: "auto", marginRight: "0" }}>
                          {!mobile ? (
                            <Search {...props} query={query} />
                          ) : (
                            <button
                              id="navBarSearchBtn"
                              onClick={() => setMobileShow(true)}
                            >
                              <svg
                                style={{ width: "15px" }}
                                data-name="Layer 1"
                                viewBox="0 0 291.45 304.8"
                              >
                                <path
                                  d="M210.76 39.54a121.2 121.2 0 1 0-27.54 185.72l72.43 79.54 35.8-32.6L219 192.66a121.39 121.39 0 0 0-8.24-153.12ZM170.16 175A72.79 72.79 0 1 1 175 72.13 72.88 72.88 0 0 1 170.16 175Z"
                                  style={{
                                    fill: "white",
                                  }}
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      }
                    />
                  </div>
                ),
              },
            ]}
          />
        ) : (
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "inline-block",
              height: "100%",
            }}
            ref={SearchBarRef}
          >
            <FlexArea
              children={
                <div
                  style={{
                    position: "relative",
                    margin: "auto",
                    width: "100%",
                  }}
                >
                  <FlexGrid
                    className="mainPadding"
                    style={Object.assign({}, { height: "40px" }, props.style)}
                    children={[
                      {
                        style: { position: "relative", flex: "1" },
                        element: <Search {...props} query={query} />,
                      },
                      {
                        style: { flex: "0 0 30px" },
                        element: (
                          <CloseBtn
                            style={{ marginTop: "7px" }}
                            onClick={() => setMobileShow(false)}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
