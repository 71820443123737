import store from "../../redux/store";

export default (order) => {
  const page_content = store.getState().page_content_reducer;

  switch (page_content.page) {
    case "orders":
      store.dispatch({ type: "UPDATE_PAGE_CONTENT_ELEMENT", element: order });
      break;
    case "order":
      store.dispatch({
        type: "UPDATE_PAGE_CONTENT",
        content: { order },
      });
  }

  //ACCOUNT DROPDOWN
  let object = store.getState().account_dropdown_reducer;
  if (object.orders.data.find((x) => x.id == order.id)) {
    object.orders.data = object.orders.data.map((x) =>
      x.id == order.id ? order : x
    );
  } else {
  }

  store.dispatch({ type: "UPDATE_ACCOUNT_DROPDOWN", object });
};
