import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../../../../core/buttons/Btn";
import Background from "../../../../../backgrounds/Background";
import FlexArea from "../../../../../../layout/flex/FlexArea";
import FlexGrid from "../../../../../../layout/flex/FlexGrid";

export default (props) => {
  const account_balance = useSelector((state) => state.account_balance_reducer);

  return (
    <Link to="/checkout">
      <div className="element" id="Balance">
        <FlexArea
          children={
            <FlexGrid
              style={{ margin: "auto" }}
              children={[
                {
                  element: (
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        margin: "auto",
                        padding: "6px",
                        border: "2px solid var(--mainPurple)",
                        borderRight: "0",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      <Background
                        style={{
                          background: "var(--mainPurple)",
                          opacity: "0.4",
                        }}
                      />
                      <img
                        id="vivacoinIcon"
                        style={{
                          position: "relative",

                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                        src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                      />
                      <span
                        style={{
                          position: "relative",

                          verticalAlign: "middle",
                          fontWeight: "bold",
                        }}
                      >
                        {GeneralFunctions.parse_currency(account_balance || 0)}
                      </span>
                    </div>
                  ),
                },
                {
                  element: (
                    <Btn
                      style={{
                        verticalAlign: "middle",
                        border: "2px solid var(--FunctionGreen)",
                        borderLeft: "0",
                        display: "inline-block",
                        marginTop: "auto",
                        marginBottom: "auto",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        height: "100%",
                        padding: "0 5px 0 5px",
                      }}
                      children={
                        <>
                          <Background
                            style={{
                              background: "var(--FunctionGreen)",
                              opacity: "0.5",
                            }}
                          />
                          <FlexArea
                            children={
                              <img
                                src={envConfig.s3 + "/images/icons/plus.svg"}
                                style={{ width: "15px", margin: "auto" }}
                              />
                            }
                          />
                        </>
                      }
                    />
                  ),
                },
              ]}
            />
          }
        />
      </div>
    </Link>
  );
};
