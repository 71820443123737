import React from "react";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//COMPONENTS
import Btn from "../../../core/buttons/Btn";

export default (props) => {
  return (
    <Btn
      style={Object.assign(
        {},
        {
          background: "transparent",
          border: "0",
          fontSize: "1.1rem",
          padding: "0",
          verticalAlign: "middle",
        },
        props.style
      )}
      onClick={props.onClick}
      children={
        <div>
          <img
            src={envConfig.s3 + "/images/icons/provablyFairIcon.svg"}
            style={{ width: "30px", verticalAlign: "middle" }}
          />
          <span style={{ verticalAlign: "middle" }}>{props.context}</span>
        </div>
      }
    />
  );
};
