let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

exports.parse = (date) => {
  date = new Date(date);
  let now = new Date();
  now = new Date(
    now.getFullYear() +
      "/" +
      (now.getMonth() + 1) +
      "/" +
      now.getDate() +
      " 00:00:00"
  );

  let diffDays = this.calculate_days(date, now);

  if (date < now) {
    if (diffDays == 0) {
      return "Today";
    } else if (diffDays == 1) {
      return "Yesterday";
    } else {
      return date.getDate() + " " + months[date.getMonth()];
    }
  } else {
    if (diffDays == 0) {
      return "Today";
    } else if (diffDays == 1) {
      return "Tomorrow";
    } else {
      return date.getDate() + " " + months[date.getMonth()];
    }
  }
};

exports.parseDateTime = (date) => {
  date = new Date(date);
  let now = new Date();

  let diffMins = this.calculate_mins(date, now);
  let diffDays = diffMins / 60 / 24;

  if (date < now) {
    if (diffDays < 1) {
      if (diffMins > 60) {
        return this.parseHours(diffMins).toFixed(0) + " hours";
      } else {
        return diffMins + " mins";
      }
    } else if (diffDays > 1 && diffDays < 2) {
      return "Yesterday";
    } else if (diffDays > 2) {
      return (
        date.getDate() +
        " " +
        months[date.getMonth()] +
        (date.getFullYear() !== now.getFullYear()
          ? " " + date.getFullYear()
          : "")
      );
    }
  } else {
    if (diffDays == 0) {
      let diffMins = this.calculate_mins(date, now);
      if (diffMins > 60) {
        return this.parseHours(diffMins).toFixed(2) + " hours";
      } else {
        return diffMins + " mins";
      }
    } else if (diffDays == 1) {
      return "Tomorrow";
    } else {
      return (
        date.getDate() +
        " " +
        months[date.getMonth()] +
        +(date.getFullYear() !== now.getFullYear()
          ? " " + date.getFullYear()
          : "")
      );
    }
  }
};

exports.parseHours = (mins) => {
  return mins / 60;
};

exports.calculate_days = (a, b) => {
  let diffDays = Math.abs(
    Math.floor(
      (new Date(a).getTime() - new Date(b).getTime()) / 1000 / 60 / 60 / 24
    )
  );

  return diffDays;
};

exports.calculate_mins = (a, b) => {
  let diffMins = Math.abs(
    Math.floor((new Date(a).getTime() - new Date(b).getTime()) / 1000 / 60)
  );

  return diffMins;
};

exports.adjustForTimezone = (date) => {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() - timeOffsetInMS);
  return date;
};
