import React from "react";

//FUNCTIONS
import getProductRank from "../../../../../../../../common/functions/getProductRank";

//STYLES
import "./style.css";
import FlexGrid from "../../../../../../../layout/flex/FlexGrid";

export default (props) => {
  let probability = props.probability || 100;
  let overflow_multiplier = probability > 100 ? 100 / probability : 1;

  function renderBarSegments(products) {
    let grouped = products.reduce((prev, next) => {
      prev[getProductRank(next.price)] = prev[getProductRank(next.price)] || [];
      prev[getProductRank(next.price)].push(next);
      return prev;
    }, {});

    return Object.keys(grouped).map((x) => (
      <div
        className="groupSection"
        style={{
          background: "var(--" + x + ")",
          width:
            grouped[x].reduce(
              (prev, next) => prev + next.probability * overflow_multiplier,
              0
            ) + "%",
        }}
      />
    ));
  }

  return (
    <div id="ProbabilityBar">
      <FlexGrid
        style={{ width: "100%" }}
        children={[
          { style: { marginRight: "auto" }, element: <span>Odds Spread</span> },
          props.probability && props?.products?.length
            ? {
                style: { marginLeft: "auto" },
                element: (
                  <span>
                    Odds Total:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        color:
                          probability == 100
                            ? "var(--FunctionGreen)"
                            : "var(--error)",
                      }}
                    >
                      {probability}%
                    </span>
                  </span>
                ),
              }
            : null,
        ]}
      />
      <div className="innner highlighted" style={{ padding: "0" }}>
        <div className="bar">{renderBarSegments(props.products)}</div>
      </div>
    </div>
  );
};
