import React from "react";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//COMPONENTS
import Btn from "../../../core/buttons/Btn";
import FlexArea from "../../../layout/flex/FlexArea";
import FlexGrid from "../../../layout/flex/FlexGrid";

export default (props) => {
  return (
    <Link to={"/checkout?code=" + props.user.affiliate_code}>
      <Btn
        style={props.style}
        children={
          <div>
            <FlexGrid
              children={[
                {
                  element: (
                    <img
                      src={envConfig.s3 + "/images/icons/account/deposit.svg"}
                      style={{
                        width: "30px",
                        marginRight: "10px",
                      }}
                    />
                  ),
                },
                {
                  element: (
                    <FlexArea
                      style={{ position: "relative" }}
                      children={
                        <span
                          style={{
                            fontSize: "0.8rem",
                            margin: "auto",
                            textAlign: "left",
                          }}
                        >
                          Deposit With <br />
                          Users Code
                        </span>
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        }
      />
    </Link>
  );
};
