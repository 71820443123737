const account_balance_reducer = (state = null, action) => {
  switch (action.type) {
    case "UPDATE_ACCOUNT_BALANCE":
      return action.account_balance;
    case "CLEAR_ACCOUNT_BALANCE":
      return null;
    default:
      return state;
  }
};

export default account_balance_reducer;
