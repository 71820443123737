import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Background from "../../../backgrounds/Background";
import flexArea from "../../../../layout/flex/FlexArea";
import Nav from "./elements/Nav";
import Social from "./elements/Social";
import Inventory from "./elements/Inventory";
import Orders from "./elements/Orders";
import ProvFairBtn from "../../../../content/provablyFair/ProvFairBtn";
import FlexArea from "../../../../layout/flex/FlexArea";

function Index(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(null);
  const account_dropdown = useSelector(
    (state) => state.account_dropdown_reducer
  );
  const session = useSelector((state) => state.session_reducer);
  const account_level = useSelector((state) => state.account_level_reducer);
  const last_route = useSelector((state) => state.last_route_reducer);

  useEffect(() => {
    setTab(account_dropdown.show);
  }, [account_dropdown.show]);

  useEffect(() => {
    if (session) {
      dispatch({ type: "SET_ACCOUNT_DROPDOWN_TAB", show: null });
    }
  }, [last_route]);

  return (
    <div id="SubHomeBarWrapper">
      <Background
        style={{
          background: "var(--background)",
        }}
      />
      {tab ? (
        <div id="background">
          <Background
            style={{ background: "var(--background)", opacity: "0.4" }}
            onClick={() => setTab(null)}
          />
        </div>
      ) : null}
      <div id="SubHomeBar" className="mainPadding">
        <FlexGrid
          style={{ height: "44px" }}
          children={[
            {
              style: { verticalAlign: "middle", height: "100%" },
              element: (
                <FlexArea
                  children={
                    <>
                      <ProvFairBtn
                        onClick={() => navigate("/provably_fair")}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          fontSize: "0.8rem",
                          marginRight: "5px",
                        }}
                        context={"Provably Fair"}
                      />
                      <Social />
                    </>
                  }
                />
              ),
            },
            {
              style: {
                flex: "1",
                verticalAlign: "middle",
                textAlign: "right",
                height: "100%",
              },
              element: <Nav setTab={setTab} />,
            },
          ]}
        />
        {tab ? (
          <div className="dropdown">
            <Background
              onClick={() =>
                dispatch({
                  type: "SET_ACCOUNT_DROPDOWN_TAB",
                  show: null,
                })
              }
            />
            <div
              className="content highlighted"
              style={{
                borderRadius: "0",
                borderBottomLeftRadius: "8px",
                padding: "2px",
                paddingTop: "0",
              }}
            >
              <div
                style={{
                  position: "relative",
                  padding: "10px",
                  boxSizing: "border-box",
                }}
              >
                <Background
                  style={{
                    background: "var(--background)",
                    opacity: "0.9",
                    borderBottomLeftRadius: "8px",
                  }}
                />
                {(() => {
                  switch (tab) {
                    case "inventory":
                      return (
                        <Inventory
                          onClose={() =>
                            dispatch({
                              type: "UPDATE_ACCOUNT_DROPDOWN",
                              object: { show: null },
                            })
                          }
                        />
                      );
                    case "orders":
                      return (
                        <Orders
                          onClose={() =>
                            dispatch({
                              type: "UPDATE_ACCOUNT_DROPDOWN",
                              object: { show: null },
                            })
                          }
                        />
                      );
                  }
                })()}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Index;
