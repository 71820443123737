import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Btn from "../../../../core/buttons/Btn";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  return (
    <FlexGrid
      children={[
        {
          style: { flex: "1 0 50%" },
          element: (
            <Btn
              id="CardBtn"
              className={props.checkout.method == "card" ? "selected" : ""}
              onClick={() => props.setProperty({ method: "card" })}
              children={
                props.checkout.method == "card" ? (
                  <div>
                    <Background
                      style={{
                        background: "var(--card)",
                        opacity: "0.4",
                        borderTopLeftRadius: "8px",
                      }}
                    />

                    <img
                      src={
                        envConfig.s3 + "/images/icons/checkout/card_icon.svg"
                      }
                      style={{
                        position: "relative",
                        width: "35px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    />
                    <span
                      style={{ position: "relative", verticalAlign: "middle" }}
                    >
                      Card
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      src={
                        envConfig.s3 + "/images/icons/checkout/card_icon.svg"
                      }
                      style={{
                        position: "relative",
                        width: "35px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    />
                    <span
                      style={{ position: "relative", verticalAlign: "middle" }}
                    >
                      Card
                    </span>
                  </div>
                )
              }
            />
          ),
        },
        {
          style: { flex: "1 0 50%" },
          element: (
            <Btn
              id="CryptoBtn"
              className={props.checkout.method == "crypto" ? "selected" : ""}
              onClick={() => props.setProperty({ method: "crypto" })}
              children={
                props.checkout.method == "crypto" ? (
                  <div>
                    <Background
                      style={{
                        background: "var(--crypto)",
                        opacity: "0.4",
                        borderTopRightRadius: "8px",
                      }}
                    />
                    <img
                      src={envConfig.s3 + "/images/icons/checkout/bitcoin.svg"}
                      style={{
                        position: "relative",
                        width: "30px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    />
                    <span
                      style={{ position: "relative", verticalAlign: "middle" }}
                    >
                      Crypto
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      src={envConfig.s3 + "/images/icons/checkout/bitcoin.svg"}
                      style={{
                        position: "relative",
                        width: "30px",
                        verticalAlign: "middle",
                        marginRight: "5px",
                      }}
                    />
                    <span
                      style={{ position: "relative", verticalAlign: "middle" }}
                    >
                      Crypto
                    </span>
                  </div>
                )
              }
            />
          ),
        },
      ]}
    />
  );
};
