const config_reducer = (state = null, action) => {
  switch (action.type) {
    case "POPULATE_CONFIG":
      return action.config;
    default:
      return state;
  }
};

export default config_reducer;
