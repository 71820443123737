import React from "react";

//CONFIG
import cateogories from "./categories.json";

//COMPONENTS
import CrateList from "../../../content/crates/CrateList";

function Index(props) {
  return (
    <CrateList {...props} categories={cateogories} view_all_option={false} />
  );
}

export default Index;
