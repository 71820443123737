import io from "socket.io-client";

//STORE
import store from "../redux/store";

//CONTROLLERS
import Controllers from "./Controllers";

//FUNCTIONS
import Cookies from "../Cookies";

//CONFIG
import envConfig from "../config/env.config";

export default {
  connect: (callback) => {
    const session = store.getState().session_reducer;
    var socket = io(envConfig.server, {
      query: { token: session ? session.token : null, type: "site" },
    });

    socket.on("connect", () => {
      let sub_id = socket.id;
      store.dispatch({
        type: "POPULATE_SUBSCRIPTION_REDUCER",
        sub_id,
      });

      console.log(sub_id);

      let data_object = {
        jwt: session ? session.session_token : null,
        type: "site",
      };

      Cookies.set("sub_id", sub_id);

      socket.emit("restore-object", data_object);
      socket.on("message", (message) => {
        console.log(message);
        try {
          Controllers[message.type](message.object);
        } catch (e) {
          throw e;
        }
      });

      callback();
    });

    socket.on("disconnect", () => {});
  },
};
