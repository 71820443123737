import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

//API
import Auth from "../../../../common/api/Auth";

//STYLES
import "./style.css";

//COMPONENTS
import PopupWithRedirect from "../../../layout/popups/PopupWithRedirect";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    confirm_token();
  }, []);

  async function confirm_token(token) {
    const data = await Auth.email_confirmed({ token: params.token });

    setLoading(false);
    setEmail(data?.email);
  }

  return (
    <PopupWithRedirect
      id="VerifyEmailLanding"
      title={email ? "Email Verified" : "Invalid Token"}
      children={
        <div style={{ textAlign: "left" }}>
          {!loading ? (
            email ? (
              <FlexGrid
                style={{ marginTop: "20px", marginBottom: "20px" }}
                children={[
                  {
                    style: { flex: "1 0 100%", display: "inline-block" },
                    element: (
                      <div>
                        <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                          Thank you for verifying your email
                        </p>
                        <p>You may now log into your account</p>
                      </div>
                    ),
                  },
                  {
                    style: {
                      flex: "1 0 100%",
                      display: "inline-block",
                      minHeight: "100px",
                    },
                    element: (
                      <FlexArea
                        style={{
                          boxSizing: "border-box",
                          border: "1px solid var(--FunctionGreen)",
                        }}
                        children={
                          email ? (
                            <span
                              style={{
                                margin: "auto",
                                fontWeight: "bold",
                                color: "var(--FunctionGreen)",
                                fontSize: "1.2rem",
                              }}
                            >
                              {email}
                            </span>
                          ) : null
                        }
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "var(--error)",
                  }}
                >
                  Your Token is invalid or expired
                </p>
                <p>You can resend the verification email by clicking below</p>
              </div>
            )
          ) : (
            <div style={{ position: "relative", minHeight: "200px" }}>
              <div className="lds-loading">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      }
      context={email ? "Go to Login" : "Resend Email"}
      onSuccess={email ? () => navigate("/login") : () => {}}
    />
  );
};
