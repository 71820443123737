import React from "react";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../../../layout/flex/FlexGrid";

function Index(props) {
  return props.affiliate ? (
    <div id="stats">
      <FlexGrid
        children={[
          {
            style: {
              flex: "1 0 50%",
              boxSizing: "border-box",
              padding: "5px",
            },
            element: (
              <div
                className="stat"
                style={{
                  border: "2px solid var(--mainPurple)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "var(--mainPurple)",
                    opacity: "0.2",
                    left: "0",
                    top: "0",
                  }}
                />
                <div style={{ position: "relative" }}>
                  <h3 className="title">Balance</h3>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                    />
                    <span style={{ verticalAlign: "middle" }}>
                      {props.affiliate.balance}
                    </span>
                  </div>
                </div>
              </div>
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              boxSizing: "border-box",
              padding: "5px",
            },
            element: (
              <div
                className="stat"
                style={{
                  border: "2px solid var(--FunctionGreen)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "var(--FunctionGreen)",
                    opacity: "0.2",
                    left: "0",
                    top: "0",
                  }}
                />
                <div style={{ position: "relative" }}>
                  <h3 className="title">Code Used</h3>
                  <div style={{ textAlign: "center" }}>
                    <span style={{ verticalAlign: "middle" }}>
                      {props.affiliate.stats.code_used}
                    </span>
                  </div>
                </div>
              </div>
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              boxSizing: "border-box",
              padding: "5px",
            },
            element: (
              <div
                className="stat"
                style={{
                  border: "2px solid #fdc615",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "#fdc615",
                    opacity: "0.2",
                    left: "0",
                    top: "0",
                  }}
                />
                <div style={{ position: "relative" }}>
                  <h3 className="title">Total Earned</h3>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                    />
                    <span style={{ verticalAlign: "middle" }}>
                      {props.affiliate.stats.total_value}
                    </span>
                  </div>
                </div>
              </div>
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              boxSizing: "border-box",
              padding: "5px",
            },
            element: (
              <div
                className="stat"
                style={{
                  border: "2px solid var(--error)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "var(--error)",
                    opacity: "0.2",
                    left: "0",
                    top: "0",
                  }}
                />
                <div style={{ position: "relative" }}>
                  <h3 className="title">Your Percentage</h3>
                  <div style={{ textAlign: "center" }}>
                    <span style={{ verticalAlign: "middle" }}>
                      {props.affiliate.code.commission * 100 + "%"}
                    </span>
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  ) : null;
}

export default Index;
