import API from "../../index";

export default async (obj) => {
  const { data, status } = await API.get(
    "/api/transactions/" +
      obj.id +
      "/" +
      null +
      "/" +
      obj.value +
      "/" +
      obj.date +
      "/" +
      null +
      "/" +
      null +
      "/" +
      null +
      "/" +
      obj.order_by +
      "/" +
      obj.offset +
      "/" +
      obj.limit
  );

  return status == 201 ? data : null;
};
