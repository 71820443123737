const errors = ["insufficient balance", "odds not 100%"];

export default {
  get_error_code: (err) => {
    for (var i = 0; i < errors.length; i++) {
      if (errors[i] == err) {
        return i;
      }
    }

    return null;
  },
};
