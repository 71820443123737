import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import FlexRow from "../FlexRow";

export default function (props) {
  return (
    <div
      {...props}
      className={"FlexGrid" + (props.className ? " " + props.className : "")}
    >
      {props.children.map((child, i) => {
        return (
          <FlexRow
            key={i}
            className={child?.className}
            style={Object.assign(
              {},
              { boxSizing: "border-box" },
              child?.style || null
            )}
            children={child?.element}
          />
        );
      })}
    </div>
  );
}
