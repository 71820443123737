const live_wins_reducer = (state = null, action) => {
  switch (action.type) {
    case "POPULATE_LIVE_WINS":
      return action.live_wins;
    case "ADD_LIVE_WIN":
      return state ? [action.new_live_win, ...state].splice(0, 25) : state;
    default:
      return state;
  }
};

export default live_wins_reducer;
