import React from "react";

//COMPONENTS
import FlexGrid from "../../../../../../../layout/flex/FlexGrid";

export default (props) => {
  return (
    <div
      className={"listElement" + (props.isSelected ? " selected" : "")}
      onClick={() => props.selectElement(props.data.id)}
    >
      <FlexGrid
        children={[
          {
            style: { flex: "1 0 50%" },
            element: (
              <div>
                <p>{props.data.address.name}</p>
                <p>{props.data.address.city}</p>
              </div>
            ),
          },
          {
            style: { flex: "1 0 50%" },
            element: (
              <div>
                <p>{props.data.address.state}</p>
                <p>{props.data.address.zip}</p>
              </div>
            ),
          },
        ]}
      />
      <div>
        {props.data.is_default ? <div className="addressCheck" /> : null}
      </div>
    </div>
  );
};
