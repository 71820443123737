const invMax = 8;
const orderMax = 8;

const account_dropdown_reducer = (
  state = { show: null, inventory: null, orders: null },
  action
) => {
  switch (action.type) {
    case "POPULATE_ACCOUNT_DROPDOWN":
      return {
        show: false,
        inventory: Object.assign({}, action.object.inventory, { selected: [] }),
        orders: action.object.orders,
      };
    case "UPDATE_ACCOUNT_DROPDOWN":
      return Object.assign({}, state, action.object);

    case "ADD_ACCOUNT_DROPDOWN_INVENTORY":
      return Object.assign({}, state, {
        inventory: {
          data: [action.product, ...state.inventory.data.slice(0, invMax - 1)],
          max: state.inventory.max + 1,
          selected: [],
        },
      });
    case "SET_ACCOUNT_DROPDOWN_INVENTORY_SELECTED":
      return Object.assign({}, state, {
        inventory: Object.assign({}, state.inventory, {
          selected: action.selected,
        }),
      });
    case "ADD_ACCOUNT_DROPDOWN_INVENTORY_SELECTED":
      return Object.assign({}, state, {
        inventory: Object.assign({}, state.inventory, {
          selected: [...action.selected, ...state.inventory.selected],
        }),
      });
    case "REMOVE_ACCOUNT_DROPDOWN_INVENTORY_SELECTED":
      return Object.assign({}, state, {
        inventory: Object.assign({}, state.inventory, {
          selected: state.inventory.selected.filter(
            (x) => !action.selected.find((y) => y.id == x.id)
          ),
        }),
      });
    case "SET_ACCOUNT_DROPDOWN_TAB":
      return {
        inventory: Object.assign({}, state.inventory, {
          data: state.inventory?.data
            ? state.inventory.data.slice(0, invMax)
            : [],
        }),
        orders: Object.assign({}, state.orders, {
          data: state.orders?.data ? state.orders.data.slice(0, orderMax) : [],
        }),
        show: action.show,
      };

    case "CLEAR_ACCOUNT_DROPDOWN":
      return { inventory: null, orders: null };
    default:
      return state;
  }
};

export default account_dropdown_reducer;
