import React from "react";

//STYLES
import "./style.css";

//FUNCTIONS
import OrderFunctions from "../../../../functions/Order.Functions";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import Stage from "./elements/Stage";
import ProductList from "../../../elements/ProductList";
import Info from "../Info";

export default (props) => {
  return (
    <FlexGrid
      id="StageTimeline"
      children={[
        {
          style: {
            flex: "1 0 100%",
            marginBottom: "20px",
          },
          element: (
            <Info
              order={Object.assign({}, props.order, {
                products: props.products,
              })}
            />
          ),
        },
        ...props.stages.map(
          (x, i) =>
            new Object({
              style: {
                flex: "1 0 " + 100 / props.stages.length + "%",
                padding: "10px",
              },
              element: (
                <Stage
                  color={
                    props.order.order_type == "deliver"
                      ? "var(--FunctionGreen)"
                      : "var(--crypto)"
                  }
                  is_end_stage={i === props.stages.length - 1}
                  stage={x}
                  is_reached={
                    i <=
                    props.stages.findIndex(
                      (x_stage) => x_stage.name === props.current_stage
                    )
                  }
                />
              ),
            })
        ),
        {
          style: { flex: "1 0 100%", marginTop: "20px" },
          element: (
            <ProductList order={props.order} products={props.products} />
          ),
        },
      ]}
    />
  );
};
