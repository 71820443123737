import Axios from "axios";
import { connect } from "react-redux";

import envConfig from "../config/env.config";
import store from "../redux/store";

//FUNCTIONS
import Cookies from "../Cookies";

const axios = require("axios");
const axiosApiInstance = axios.create({
  baseURL: envConfig.server,
  responseType: "json",
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.url = config.url;

    const session = await store.getState().session_reducer;
    const sub_id = await store.getState().subscription_reducer;
    config.headers.Accept = "application/json";

    let intercept_headers = JSON.parse(JSON.stringify(config.headers));

    if (Cookies.get("session-token")) {
      intercept_headers.authorization =
        "Bearer " + Cookies.get("session-token");
    }
    if (sub_id) {
      intercept_headers.sub_id = sub_id;
    }

    config = Object.assign({}, config, { headers: intercept_headers });

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    //Refresh Token invalid/expired
    if (originalRequest.url === envConfig.server + "/api/auth/refresh/site") {
      return Promise.reject(error);
    }

    if (
      error.response !== undefined &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      const session = store.getState().session_reducer;
      originalRequest._retry = true;

      return axiosApiInstance
        .post("/api/auth/refresh/site", {
          refresh_token: Cookies.get("refresh-token"),
        })
        .then((res) => {
          if (res.status === 201) {
            // 1) put token to Redux
            store.dispatch({
              type: "UPDATE_TOKEN",
              token: res.data.token,
            });

            Cookies.set("session-token", res.data.token);

            // 3) return originalRequest object with Axios.
            axiosApiInstance.defaults.headers.common["authorization"] =
              "Bearer " + res.data.token;
            return axiosApiInstance(originalRequest);
          } else {
            //REMOVE COOKIE TOKENS IF CAN'T REAUTHENTICATE
            Cookies.remove("session-token");
            Cookies.remove("refresh-token");

            // 3) return originalRequest object with Axios.
            delete axiosApiInstance.defaults.headers.common["authorization"];
            return axiosApiInstance(originalRequest);
          }
        });
    }
    console.log(error.response);
    return error.response;
  }
);

export default axiosApiInstance;
