import API from "../../index";

export default async () => {
  try {
    let { data, status } = await API.get("/api/config");

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
