import React from "react";
import "./pageControls.css";

export default function (props) {
  function renderPageNumbers(offset, limit, pageLimit) {
    let no_pages =
      limit % pageLimit !== 0
        ? Math.floor(limit / pageLimit) + 1
        : Math.floor(limit / pageLimit);

    let current_page = Math.max(1, Math.floor(offset / pageLimit) + 1);

    let page_offset = 0;

    let pageElements = [];

    //render pre current page options
    if (current_page >= 3 && no_pages > 3) {
      pageElements.push(
        <div
          className={
            "pageControlsNoBtnWrapper" + (current_page == 1 ? " selected" : "")
          }
        >
          <button
            className={
              "pageControlsNoBtn" + (current_page == 1 ? " selected" : "")
            }
            disabled={current_page == 1 ? true : false}
            onClick={props.onSelectPage.bind(this, (1 - 1) * pageLimit)}
          >
            {1}
          </button>
        </div>
      );
      pageElements.push(
        <div className="pageControlsNoBtnWrapper">
          <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>...</span>
        </div>
      );

      page_offset = current_page - 2;
    }

    if (current_page == no_pages && no_pages > 3) {
      page_offset = current_page - 3;
    }

    //render page options
    for (
      var i = 1 + page_offset;
      i < Math.min(no_pages, 3) + page_offset + 1;
      i++
    ) {
      pageElements.push(
        <div className="pageControlsNoBtnWrapper">
          <button
            className={
              "pageControlsNoBtn" + (current_page == i ? " selected" : "")
            }
            onClick={props.onSelectPage.bind(this, (i - 1) * pageLimit)}
            disabled={current_page == i ? true : false}
          >
            {i}
          </button>
        </div>
      );
    }

    //render post current page options
    if (no_pages > 3 && current_page <= no_pages - 2) {
      pageElements.push(
        <div className="pageControlsNoBtnWrapper">
          <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>...</span>
        </div>
      );
      pageElements.push(
        <div
          className={
            "pageControlsNoBtnWrapper" +
            (current_page == no_pages ? " selected" : "")
          }
        >
          <button
            className="pageControlsNoBtn"
            disabled={current_page == no_pages ? true : false}
            onClick={props.onSelectPage.bind(this, (no_pages - 1) * pageLimit)}
          >
            {no_pages}
          </button>
        </div>
      );
    }

    return pageElements;
  }

  return (
    <div className="pageControlsWrapper">
      <div className="pageControls">
        {renderPageNumbers(props.offset, props.limit, props.pageLimit)}
      </div>
      <div style={{ marginTop: "8px" }}>
        <span style={{ fontSize: "1.2rem" }}>
          <span style={{ fontWeight: "bold" }}>
            {props.offset +
              1 +
              " - " +
              Math.min(
                Math.max(1, Math.floor(props.offset / props.pageLimit) + 1) *
                  props.pageLimit,
                props.limit
              )}
          </span>{" "}
          of <span style={{ fontWeight: "bold" }}>{props.limit}</span>
        </span>
      </div>
    </div>
  );
}
