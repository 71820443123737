import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../../common/functions/GeneralFunctions";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import Dropdown from "../../../../../../core/Dropdown";
import Textbox from "../../../../../../core/inputs/text/Textbox";
import Price from "../../../../../../core/Price";
import InfoForm from "../../../../../../core/InfoForm";
import Background from "../../../../../../common/backgrounds/Background";
import FlexArea from "../../../../../../layout/flex/FlexArea";

function Index(props) {
  const config = useSelector((state) => state.config_reducer);

  const value =
    props.products.reduce((prev, next) => prev + next.price, 0) *
    ((100 - config.withdraw_fee) / 100);

  useEffect(() => {
    props.setGiftcard({ country: "USA" });
  }, []);

  return (
    <div>
      <div>
        <FlexGrid
          children={[
            {
              style: { flex: "1 0 100%" },
              element: (
                <FlexGrid
                  style={{
                    background: "var(--background)",
                    borderLeft: "1px solid var(--crypto)",
                    borderRight: "1px solid var(--crypto)",
                  }}
                  children={[
                    {
                      style: { flex: "1" },
                      element: (
                        <div
                          style={{
                            position: "relative",
                            margin: "auto",
                            width: "250px",
                            boxSizing: "border-box",
                            padding: "5px",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                          }}
                        >
                          <img
                            src={envConfig.s3 + "/images/icons/giftcard.svg"}
                            style={{ width: "100%" }}
                          />
                        </div>
                      ),
                    },
                    {
                      style: { flex: "1" },
                      element: (
                        <FlexArea
                          children={
                            <div
                              style={{
                                margin: "auto",
                                marginLeft: "0",
                                fontSize: "1rem",
                              }}
                            >
                              <h4 style={{ fontWeight: "normal" }}>
                                Fee:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "var(--warning)",
                                  }}
                                >
                                  -{config.withdraw_fee}%
                                </span>
                              </h4>
                              <h4 style={{ fontWeight: "normal" }}>
                                Card Value:{" "}
                                <span
                                  style={{
                                    color: "var(--FunctionGreen)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ${GeneralFunctions.parse_currency(value)}
                                </span>
                              </h4>
                              <h4 style={{ fontWeight: "normal" }}>
                                Sending to:{" "}
                                {props?.giftcard?.email_address ? (
                                  <span style={{ fontWeight: "bold" }}>
                                    {props?.giftcard?.email_address}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "var(--error)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    No email set
                                  </span>
                                )}
                              </h4>
                            </div>
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              style: {
                flex: "1 0 100%",
                margin: "auto",
                display: "flex",
              },
              element: (
                <div
                  style={{
                    width: "100%",
                    background: "var(--secondaryBackground)",
                    border: "1px solid var(--crypto)",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                    borderTop: "0",
                  }}
                  className="highlighted"
                >
                  <div className="highlighted" style={{ border: "0" }}>
                    <div
                      style={{
                        maxWidth: "450px",
                        margin: "auto",
                        boxSizing: "border-box",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <h4 style={{ marginBottom: "5px", marginTop: "0" }}>
                        Country:
                      </h4>
                      <Dropdown
                        onChange={(value) =>
                          props.setGiftcard({ country: value })
                        }
                        disabled={!props.can_edit}
                        options={[
                          { value: "USA", title: "United States" },
                          { value: "GB", title: "United Kingdom" },
                          { value: "CA", title: "Canada" },
                          { value: "MX", title: "Mexico" },
                          { value: "IRL", title: "Ireland" },
                          { value: "GER", title: "Germany" },
                          { value: "AT", title: "Austria" },
                          { value: "LUX", title: "Luxembourg" },
                          { value: "FR", title: "France" },
                          { value: "BEL", title: "Belgium" },
                          { value: "ITA", title: "Italy" },
                          { value: "ESP", title: "Spain" },
                          { value: "POR", title: "Portugal" },
                          { value: "NET", title: "Netherlands" },
                          { value: "PL", title: "Poland" },
                          { value: "SWE", title: "Sweden" },
                          { value: "TUR", title: "Turkey" },
                          { value: "JP", title: "Japan" },
                          { value: "SG", title: "Singapore" },
                          { value: "AU", title: "Australia" },
                          { value: "IN", title: "India" },
                        ]}
                      />
                      <h4 style={{ marginBottom: "5px" }}>
                        Enter Amazon Account Email:
                      </h4>
                      <Textbox
                        value={props?.giftcard?.email_address}
                        disabled={!props.can_edit}
                        placeholder={"joebloggs@gmail.com"}
                        onChange={(e) =>
                          props.setGiftcard({ email_address: e.target.value })
                        }
                        /*placeholder={"3FZbgi29cpjq2GjdwV8eyHuJJnkLtktZc5..."}*/
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      background: "var(--background)",
                      padding: "10px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Background
                      style={{
                        background: "var(--secondaryBackground)",
                      }}
                    />
                    <div style={{ position: "relative", fontSize: "0.8rem" }}>
                      <p>
                        {config.withdraw_fee}% fee of your items values applied
                        for Currency Exchange Costs
                      </p>
                      <p>
                        Giftcards can only be redeemed on the amazon website
                        matching the selected country
                      </p>
                      <p>Minimum Giftcard value = $20</p>
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Index;
