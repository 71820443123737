import AccountLevel from "../../functions/AccountLevel";

const account_level_reducer = (
  state = { level: null, xp: null, xp_to_level: null, boundaries: null },
  action
) => {
  let newState;
  switch (action.type) {
    case "POPULATE_ACCOUNT_LEVEL":
      newState = Object.assign({}, state, action.object);

      newState = Object.assign({}, newState, {
        level: newState.xp
          ? AccountLevel.calculate_level(newState.xp, newState.boundaries)
          : 0,
      });

      return Object.assign({}, newState, {
        xp_to_level: newState.level
          ? AccountLevel.calculate_xp_to_level(
              newState.xp,
              newState.level,
              newState.boundaries
            )
          : null,
      });
    case "ADD_ACCOUNT_XP":
      newState = Object.assign({}, state, {
        level: AccountLevel.calculate_level(
          state.xp + action.object.xp,
          state.boundaries
        ),
        xp: state.xp + action.object.xp,
      });
      return Object.assign({}, newState, {
        xp_to_level: AccountLevel.calculate_xp_to_level(
          newState.xp,
          newState.level,
          newState.boundaries
        ),
      });
      break;
    case "CLEAR_ACCOUNT_LEVEL":
      return {
        level: null,
        xp: null,
        xp_to_level: null,
        boundaries: state.boundaries,
      };
    default:
      return state;
  }
};

export default account_level_reducer;
