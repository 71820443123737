import React from "react";

//STYLE
import "./style.css";

//COMPONENTS
import Btn from "../Btn";
import Background from "../../../common/backgrounds/Background";

export default (props) => {
  return (
    <Btn
      onClick={props.onClick}
      context={props.context}
      className={(
        "SuccessBtn" + (props.className ? " " + props.className : "")
      ).trim()}
      {...props}
      style={props.style}
      children={
        <div>
          <Background
            style={{
              background: props.color || "var(--FunctionGreen)",
              opacity: "0.4",
            }}
          />
          {props.children}
        </div>
      }
    />
  );
};
