import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//API
import Auth from "../../../../common/api/Auth";

//COMPONENTS
import PopupWithRedirect from "../../../layout/popups/PopupWithRedirect";
import Form from "../../../core/Form";
import FlexGrid from "../../../layout/flex/FlexGrid";
import TextBox from "../../../core/inputs/text/Textbox";
import Checkbox from "../../../core/inputs/Checkbox";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import FileUpload from "../../../core/buttons/FileUpload";
import USA_Only from "../../../common/banners/USA_Only";
import ImageSelect from "../../../content/account/ImageSelect";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /*INPUT VALUES/ERRORS*/
  const [inputs, setInputs] = useState({
    image: { value: null, err: null },
    default_image: { value: Math.floor(Math.random() * 16) + 1, err: null },
    username: { value: "", err: null },
    email: { value: "", err: null },
    password: { value: "", err: null },
    confirmPassword: { value: "", err: null },
    termsConfirm: { value: false, err: null },
    ageConfirm: { value: false, err: null },
    markOptIn: { value: false, err: null },
  });

  console.log(inputs);

  function handleImageChange(value) {
    setInputs(
      Object.assign({}, inputs, {
        ["image"]: { value, err: null },
      })
    );
  }

  function handleInput(param, value) {
    setInputs(
      Object.assign({}, inputs, {
        [param]: Object.assign({}, inputs[param], {
          value,
        }),
      })
    );
  }

  function handleErrors(errors) {
    setInputs(
      Object.keys(inputs).reduce(
        (prev, next) =>
          Object.assign({}, prev, {
            [next]: Object.assign({}, inputs[next], {
              err: errors.find((y) => y.param == next)?.msg || null,
            }),
          }),
        {}
      )
    );
  }

  async function onConfirm() {
    const data = await Auth.create_user(
      Object.keys(inputs).reduce(
        (x, cur) => Object.assign({}, x, { [cur]: inputs[cur].value }),
        {}
      )
    );

    if (!data.errors) {
      navigate("/sign-up/verify/" + btoa(inputs.email.value));
    } else {
      handleErrors(data.errors);
    }
  }

  return (
    <PopupWithRedirect
      className="SignUp"
      title="Sign Up For Vivavim"
      onSuccess={onConfirm}
      context={"Create User"}
      children={
        <FlexGrid
          children={[
            {
              style: { flex: "1 0 100%", marginBottom: "10px" },
              element: <USA_Only />,
            },
            {
              style: { flex: "1 0 50%" },
              element: (
                <div className="profileImg">
                  <section>
                    <img
                      src={
                        inputs.image.value
                          ? inputs.image.value
                          : envConfig.s3 +
                            "/images/default_users/default" +
                            inputs.default_image.value +
                            ".webp"
                      }
                    />
                  </section>
                  <div className="options">
                    <SuccessBtn
                      context={"Change"}
                      onClick={() =>
                        dispatch({
                          type: "NEW_POPUP",
                          element: (
                            <ImageSelect
                              onSuccess={({ image, default_image }) =>
                                setInputs(
                                  Object.assign({}, inputs, {
                                    image: { value: image, err: null },
                                    default_image: {
                                      value:
                                        default_image ||
                                        inputs.default_image.value,
                                      err: null,
                                    },
                                  })
                                )
                              }
                            />
                          ),
                        })
                      }
                      style={{
                        fontSize: "0.8rem",
                        borderRadius: "unset",
                        borderTopLeftRadius: "8px",
                      }}
                    />
                    {/*<FileUpload
                      context={"Change"}
                      onChange={handleImageChange}
                      style={{
                        fontSize: "0.8rem",
                        borderRadius: "unset",
                        borderTopLeftRadius: "8px",
                      }}
                    />*/}
                  </div>
                </div>
              ),
            },
            {
              style: {
                flex: "1 0 50%",
                paddingLeft: "10px",
                boxSizing: "border-box",
              },
              element: (
                <Form
                  children={[
                    {
                      name: "Username",
                      el: (
                        <TextBox
                          autoComplete="new-password"
                          value={inputs.username.value}
                          error={inputs.username.err}
                          onChange={(e) =>
                            handleInput("username", e.target.value)
                          }
                        />
                      ),
                    },
                    {
                      name: "Email Address",
                      el: (
                        <TextBox
                          autoComplete="new-password"
                          value={inputs.email.value}
                          error={inputs.email.err}
                          onChange={(e) => handleInput("email", e.target.value)}
                        />
                      ),
                    },
                    {
                      name: "Password",
                      el: (
                        <TextBox
                          type="password"
                          autoComplete="new-password"
                          value={inputs.password.value}
                          error={inputs.password.err}
                          onChange={(e) =>
                            handleInput("password", e.target.value)
                          }
                        />
                      ),
                    },
                    {
                      name: "Confirm Password",
                      el: (
                        <TextBox
                          type="password"
                          autoComplete="new-password"
                          value={inputs.confirmPassword.value}
                          error={inputs.confirmPassword.err}
                          onChange={(e) =>
                            handleInput("confirmPassword", e.target.value)
                          }
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              style: {
                flex: "1 0 100%",
              },
              element: (
                <div className="checkInputs">
                  <div>
                    <Checkbox
                      checked={inputs.termsConfirm.value}
                      onChange={(e) =>
                        handleInput("termsConfirm", e.target.checked)
                      }
                    />
                    <span>
                      I agree to the vivavim's{" "}
                      <Link to="/T&Cs" style={{ textDecoration: "underline" }}>
                        T&Cs
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/privacy_policy"
                        style={{ textDecoration: "underline" }}
                      >
                        Privacy Policy
                      </Link>
                    </span>
                  </div>
                  <div>
                    <Checkbox
                      checked={inputs.ageConfirm.value}
                      onChange={(e) =>
                        handleInput("ageConfirm", e.target.checked)
                      }
                    />
                    <span>I confirm I am over the age of 18</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={inputs.markOptIn.value}
                      onChange={(e) =>
                        handleInput("markOptIn", e.target.checked)
                      }
                    />
                    <span>I'm happy to receive marketing offers</span>
                  </div>
                </div>
              ),
            },
          ]}
        />
      }
      bottomChildren={
        <div className="subOptions">
          <FlexGrid
            children={[
              {
                style: { flex: "1 0 50%", textAlign: "left" },
                element: (
                  <span style={{ color: "var(--error)", fontWeight: "bold" }}>
                    {
                      inputs[Object.keys(inputs).find((x) => inputs[x].err)]
                        ?.err
                    }
                  </span>
                ),
              },
              {
                style: { flex: "1 0 50%", textAlign: "right" },
                element: (
                  <Link to="/login">
                    <span>Already Have an Account?</span>
                  </Link>
                ),
              },
            ]}
          />
        </div>
      }
    />
  );
}

export default Index;
