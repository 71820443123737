import React, { useState } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Title from "./elements/Title";
import Price from "./elements/Price";
import Info from "./elements/info";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";
import Background from "../../../common/backgrounds/Background";

export default (props) => {
  const [visible, setVisible] = useState(false);
  let locked = props.crate.isFree
    ? props.crate.time_locked_until || props.crate.levels_to || !props.session
    : false;

  function onChange(isVisible) {
    setVisible(isVisible);
  }
  return (
    <VisibilitySensor onChange={onChange} partialVisibility={true}>
      <li
        className="crateListElement"
        style={{ visibility: visible ? "visible" : "hidden" }}
      >
        <Link
          to={
            props.crate.id
              ? (props.crate.isFree ? "/unbox/daily/" : "/unbox/") +
                props.crate.id
              : "/unbox/add"
          }
          style={{ color: "unset", textDecoration: "unset" }}
        >
          <div className="crateElementStyling">
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "0",
                top: "0",
              }}
            >
              <div
                className="crateElementDiv"
                style={Object.assign(
                  {},
                  {
                    backgroundSize: "cover",
                    backgroundPosition: "center",

                    backgroundImage:
                      "url(" +
                      (props.crate.id
                        ? envConfig.s3 +
                          "/images/crates2/images/" +
                          props.crate.id +
                          ".webp"
                        : envConfig.s3 +
                          "/images/crates/wallpapers/makeACrate.webp") +
                      ")",
                  },
                  props.style
                )}
              >
                <FlexGrid
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    flexDirection: "column",
                  }}
                  children={[
                    {
                      style: { marginBottom: "auto", textAlign: "right" },
                      element: (
                        <Price
                          price={props.crate.price}
                          time_locked_until={props?.crate?.time_locked_until}
                          levels_to={props?.crate?.levels_to}
                        />
                      ),
                    },
                    {
                      style: { marginTop: "auto" },
                      element: (
                        <FlexArea
                          children={
                            <div
                              style={{
                                margin: "auto",
                                marginBottom: "0",
                                width: "100%",
                              }}
                            >
                              <Title title={props.crate.name} />
                            </div>
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </Link>
      </li>
    </VisibilitySensor>
  );
};
