import React from "react";
import { Link, useNavigate } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import SuccessBtn from "../../../../../../core/buttons/SuccessBtn";
import Background from "../../../../../../common/backgrounds/Background";
import Btn from "../../../../../../core/buttons/Btn";

export default (props) => {
  const navigate = useNavigate();
  const priceChanges = props.products.filter(
    (x) => x.real_price > x.price && x.in_stock == 1
  );
  const outOfStock = props.products.filter((x) => x.in_stock == 0);

  return (
    <div id="OrderWarning" style={{ padding: "10px", paddingTop: "0" }}>
      {outOfStock.length ? (
        <div
          className="warning"
          style={{
            position: "relative",
            border: "1px dashed var(--error)",
            minHeight: "150px",
          }}
        >
          <Background
            style={{
              background: "var(--error)",
              opacity: "0.1",
              borderRadius: "8px",
            }}
          />
          <div style={{ position: "relative" }}>
            <div>
              <img
                className="icon"
                src={envConfig.s3 + "/images/icons/common/error.svg"}
              />
              <h3
                style={{
                  position: "relative",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  display: "inline-block",
                  margin: "0",
                }}
              >
                Items are unavailable
              </h3>
            </div>
            <div>
              <span style={{ fontSize: "0.8rem" }}>
                Regrettably, delivery of these items is not available at this
                time. However, you can withdraw the value in amazon gift cards
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {priceChanges.length ? (
        <div
          className="warning"
          style={{
            position: "relative",
            border: "1px dashed var(--warning)",
            minHeight: "150px",
          }}
        >
          <Background
            style={{
              background: "var(--warning)",
              opacity: "0.1",
              borderRadius: "8px",
            }}
          />
          <div style={{ position: "relative" }}>
            <img
              className="icon"
              src={envConfig.s3 + "/images/icons/common/warning.svg"}
            />
            <h3
              style={{
                position: "relative",
                fontWeight: "bold",
                verticalAlign: "middle",
                display: "inline-block",
                margin: "0",
              }}
            >
              Items have increased in price
            </h3>
            <div>
              <span style={{ fontSize: "0.8rem" }}>
                Some items have appreciated in value since their unboxing. They
                can be delivered for a nominal fee, or you may choose to redeem
                their worth in the form of Amazong gift cards.
              </span>
            </div>
          </div>
        </div>
      ) : null}

      <div style={{ textAlign: "right" }}>
        <Link
          to={
            "/order/giftcard?products=" +
            encodeURIComponent(JSON.stringify(props.products.map((x) => x.id)))
          }
        >
          <Btn
            style={{
              verticalAlign: "middle",
              border: "2px solid var(--crypto)",
              marginRight: "10px",
            }}
            children={
              <>
                <img
                  style={{
                    display: "inline-block",
                    height: "1.5rem",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src={envConfig.s3 + "/images/icons/giftcard.svg"}
                />
                <h3
                  style={{
                    position: "relative",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                    display: "inline-block",
                    margin: "0",
                    fontSize: "1.2rem",
                  }}
                >
                  Giftcard
                </h3>
              </>
            }
          />
        </Link>

        <SuccessBtn
          style={{ fontSize: "1.2rem", verticalAlign: "middle" }}
          context={"Continue"}
          onClick={() =>
            navigate(
              "/order/deliver/confirm?products=" +
                encodeURIComponent(
                  JSON.stringify(
                    props.products
                      .filter((x) => x.in_stock == 1)
                      .map((x) => x.id)
                  )
                )
            )
          }
        />
      </div>
    </div>
  );
};
