import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../common/functions/GeneralFunctions";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";

import XP from "../../../../account2/elements/ProfileTop/elements/XP";
import AddressManagement from "../../../addresses/AddressManagement";
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";
import Btn from "../../../../../core/buttons/Btn";
import Price from "../../../../../core/Price";
import Background from "../../../../../common/backgrounds/Background";

//POPUPS
import ChangeUsername from "../../../AccountManage/elements/Manage/popups/ChangeUsername";
import ChangeEmail from "../../../AccountManage/elements/Manage/popups/ChangeEmail";
import ChangePassword from "../../../AccountManage/elements/Manage/popups/ChangePassword";

export default (props) => {
  const dispatch = useDispatch();

  const account_level = useSelector((state) => state.account_level_reducer);
  const account_balance = useSelector((state) => state.account_balance_reducer);
  return (
    <div
      style={{
        border: "1px solid var(--highlighted)",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <FlexGrid
        children={[
          {
            style: { flex: "1 0 100%" },
            element: (
              <div>
                <h4 style={{ marginTop: "0" }}>
                  <span
                    style={{
                      fontWeight: "normal",
                      fontSize: "0.8rem",
                      verticalAlign: "middle",
                    }}
                  >
                    Username:{" "}
                  </span>
                  {props.user.username}
                  <Btn
                    context={"Edit"}
                    style={{
                      color: "var(--FunctionGreen",
                      padding: "0",
                      marginLeft: "5px",
                    }}
                    onClick={() =>
                      dispatch({
                        type: "NEW_POPUP",
                        element: <ChangeUsername />,
                      })
                    }
                  />
                </h4>
              </div>
            ),
          },
          {
            style: { flex: "1 0 100%" },
            element: (
              <h4 style={{ marginTop: "0" }}>
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.8rem",
                    verticalAlign: "middle",
                  }}
                >
                  Email Address:{" "}
                </span>
                {props.user.email_address}
                <Btn
                  context={"Edit"}
                  style={{
                    color: "var(--FunctionGreen",
                    padding: "0",
                    marginLeft: "5px",
                  }}
                  onClick={() =>
                    dispatch({ type: "NEW_POPUP", element: <ChangeEmail /> })
                  }
                />
              </h4>
            ),
          },
          {
            style: { flex: "1 0 100%" },
            element: (
              <h4 style={{ marginTop: "0" }}>
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.8rem",
                    verticalAlign: "middle",
                  }}
                >
                  Balance:{" "}
                </span>
                <img
                  id="vivacoinIcon"
                  style={{
                    position: "relative",

                    verticalAlign: "middle",
                    marginRight: "5px",
                    width: "20px",
                  }}
                  src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                />
                <span
                  style={{
                    position: "relative",

                    verticalAlign: "middle",
                    fontWeight: "bold",
                  }}
                >
                  {GeneralFunctions.parse_currency(account_balance || 0)}
                </span>
                <Link to="/checkout">
                  <Btn
                    context={"Add Funds"}
                    style={{
                      color: "var(--FunctionGreen",
                      padding: "0",
                      marginLeft: "5px",
                    }}
                  />
                </Link>
              </h4>
            ),
          },
          {
            style: { flex: "1 0 100%" },
            element: (
              <XP
                user={{
                  account_level: account_level.level,
                  total_xp: account_level.xp,
                }}
                show_remaining={true}
              />
            ),
          },
          {
            style: { flex: "1 0 100%", marginTop: "20px" },
            element: (
              <FlexGrid
                style={{ justifyContent: "start" }}
                children={[
                  {
                    style: { padding: "5px" },
                    element: (
                      <Btn
                        style={{ border: "2px solid var(--FunctionGreen)" }}
                        context={"Manage Addresses"}
                        children={
                          <Background
                            style={{
                              background: "var(--FunctionGreen)",
                              opacity: "0.2",
                            }}
                          />
                        }
                        onClick={() =>
                          dispatch({
                            type: "NEW_POPUP",
                            element: <AddressManagement />,
                          })
                        }
                      />
                    ),
                  },
                  {
                    style: { padding: "5px" },
                    element: (
                      <Btn
                        style={{ border: "2px solid var(--error)" }}
                        context={"Change Password"}
                        children={
                          <Background
                            style={{
                              background: "var(--error)",
                              opacity: "0.2",
                            }}
                          />
                        }
                        onClick={() =>
                          dispatch({
                            type: "NEW_POPUP",
                            element: <ChangePassword />,
                          })
                        }
                      />
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
