import React from "react";

import "./style.css";

function Index(props) {
  return (
    <div id="AddressFormat">
      {props.address ? (
        <div>
          <p>{props.address.name}</p>
          <p>
            {props.address.street_address +
              (props.address.street_address_2
                ? ", " + props.address.street_address_2
                : "")}
          </p>
          <p>
            {props.address.city +
              ", " +
              props.address.state +
              ", " +
              props.address.zip}
          </p>
          <p>{props.address.country}</p>
          <p>{props.address.phone_number}</p>
        </div>
      ) : null}
    </div>
  );
}

export default Index;
