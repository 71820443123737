import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PopupWithRedirect from "../../components/layout/popups/PopupWithRedirect";
import Checkout from "../../components/content/checkout";

export default (props) => {
  return (
    <PopupWithRedirect
      id="Checkout"
      title="Deposit Funds"
      children={
        <>
          <Helmet>
            <title>Checkout | Vivavim</title>
            <meta
              name="description"
              content="Deposit here to top up your balance"
            />
            <link rel="canonical" href={envConfig.server + "/checkout"} />
          </Helmet>
          <Checkout
            value={props.params?.value}
            method={props.params?.method}
            code={props.params?.code}
            products={props.params?.products}
            params={props.params}
          />
        </>
      }
    />
  );
};
