import getProductRank from "../../../../../common/functions/getProductRank";
import store from "../../../../../common/redux/store";

export default {
  update_segs: (segRef, segments, products, winning_product, setSegments) => {
    var prev_rot = 0;
    var rotations = 0;
    function update() {
      const computedStyle = window.getComputedStyle(segRef.current);
      const transformValue = computedStyle.getPropertyValue("transform");

      var rot = getRotationFromMatrix(transformValue); //degrees

      // Convert rotation range from -180 to 180 to 0 to 360
      rot -= 90;
      if (rot < 0) {
        rot += 360;
      }

      if (rot < prev_rot) {
        rotations++;
      }

      prev_rot = rot;

      var targetSeg = Math.floor(rot / 22.5);

      const nonVisSegs = [
        //getNextSegs(targetSeg, -1, 16),
        //getNextSegs(targetSeg, -2, 16),
        getNextSegs(targetSeg, 0, 16),
        //getNextSegs(targetSeg, 1, 16),
      ];

      store.dispatch({
        type: "NEW_CAROUSEL_SEGMENT_PRODUCTS",
        obj: {
          segments: nonVisSegs.map((x) => {
            return { index: x };
          }),
          products,
          rotations,
        },
      });
      /*store.dispatch({
        type: "NEW_CAROUSEL_SEGMENT_PRODUCT",
        segment: {
          index: nonVisSegs[0],
          product: products[Math.floor(Math.random() * products.length)],
          rotations,
        },
      });*/

      if (rotations == 5) {
        store.dispatch({
          type: "SHOW_WINNING_CAROUSEL_SEGMENT",
          winning_product,
        });
      }

      setTimeout(() => {
        if (
          segRef.current.className == "animate" ||
          segRef.current.className == "rollback"
        ) {
          console.log("animate");
          update();
        }
      }, 100);
    }

    update();
  },
  get_segments: (size, offset, scale) => {
    return [
      /*--TOP -> LEFT*/
      {
        transform: "translateY(" + -offset + "px) rotate(-180deg)",
      },
      {
        transform:
          "translateY(" +
          -(295 * scale) +
          "px) translateX(" +
          -(123 * scale) +
          "px) rotate(157.5deg)",
      },
      {
        transform:
          "translateY(" +
          -(226 * scale) +
          "px) translateX(" +
          -(226 * scale) +
          "px) rotate(135deg)",
      },
      {
        transform:
          "translateY(" +
          -(123 * scale) +
          "px) translateX(" +
          -(295 * scale) +
          "px) rotate(112.5deg)",
      },
      //LEFT -> BOTTOM
      {
        transform: "translateX(" + -offset + "px) rotate(90deg)",
      },
      {
        transform:
          "translateY(" +
          123 * scale +
          "px) translateX(" +
          -(295 * scale) +
          "px) rotate(67.5deg)",
      },
      {
        transform:
          "translateY(" +
          226 * scale +
          "px) translateX(" +
          -(226 * scale) +
          "px) rotate(45deg)",
      },
      {
        transform:
          "translateY(" +
          295 * scale +
          "px) translateX(" +
          -(123 * scale) +
          "px) rotate(22.5deg)",
      },

      //BOTTOM -> RIGHT
      {
        transform: "translateY(" + offset + "px)",
      },
      {
        transform:
          "translateY(" +
          295 * scale +
          "px) translateX(" +
          123 * scale +
          "px) rotate(-22.5deg)",
      },
      {
        transform:
          "translateY(" +
          226 * scale +
          "px) translateX(" +
          226 * scale +
          "px) rotate(-45deg)",
      },
      {
        transform:
          "translateY(" +
          123 * scale +
          "px) translateX(" +
          295 * scale +
          "px) rotate(-67.5deg)",
      },
      {
        transform: "translateX(" + offset + "px) rotate(-90deg)",
      },
      //RIGHT -> TOP
      {
        transform:
          "translateY(" +
          -(123 * scale) +
          "px) translateX(" +
          295 * scale +
          "px) rotate(-112.5deg)",
      },
      {
        transform:
          "translateY(" +
          -(226 * scale) +
          "px) translateX(" +
          226 * scale +
          "px) rotate(-135deg)",
      },
      {
        transform:
          "translateY(" +
          -(295 * scale) +
          "px) translateX(" +
          123 * scale +
          "px) rotate(-157.5deg)",
      },
    ].map((x) =>
      Object.assign({}, x, {
        top: "calc(50% - " + size / 2 + "px)",
        left: "calc(50% - " + size / 2 + "px)",
      })
    );
  },
};

function getRotationFromMatrix(matrix) {
  // Extract the values from the matrix
  const [, , a, b, ,] = matrix.match(/-?\d+(\.\d+)?/g).map(Number);

  // Calculate the rotational value in radians
  const angleRad = Math.atan2(b, a);

  // Convert the angle from radians to degrees
  const angleDeg = (angleRad * 180) / Math.PI + 180;

  return angleDeg;
}

function getNextSegs(index, target, max) {
  if (target > 0) {
    return index + target > max ? max - index + target : index + target;
  } else {
    return index + target < 0 ? max + (index + target) : index + target;
  }
}
