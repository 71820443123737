import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Product from "../../../unbox/RecentWins/Product";
import ContentList from "../../../../core/ContentList";

export default (props) => {
  return (
    <div id="RecentlyWon">
      <h4 style={{ marginBottom: "5px", marginTop: "0" }}>Recent Wins</h4>
      <div className="inner highlighted">
        <div style={{ maxWidth: "1000px", margin: "auto" }}>
          <div>
            <ContentList
              style={{
                overflow: "hidden",
                justifyContent: "center",
                maxHeight: "240px",
              }}
              content={props.profile.recent_wins.map((x) => (
                <Product live_win={x} />
              ))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
