import API from "../../index";

export default async (filters) => {
  try {
    let { data, status } = await API.get(
      "/api/inventory/search/" +
        filters.query +
        "/" +
        filters.items +
        "/" +
        filters.coin +
        "/" +
        filters.order_by +
        "/" +
        filters.offset +
        "/" +
        filters.limit
    );
    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
