import React from "react";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { useNavigate } from "react-router-dom";

//FUNCTIONS
import URLFunctions from "../../../../../../common/functions/URL.functions";

//API
import Orders from "../../../../../../common/api/Orders";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../../../core/buttons/Btn";

function Index(props) {
  const navigate = useNavigate();
  const page_content = useSelector((state) => state.page_content_reducer);

  function Search(ongoing) {
    Orders.search_orders({
      id: null,
      status: null,
      ongoing,
      order_by: null,
      list_offset: 0,
      limit: 15,
    });
  }

  return (
    <div className="subOptions">
      <Btn
        context={"Ongoing"}
        style={Object.assign(
          {},
          {
            background: "transparent",
            marginRight: "10px",

            border: "1px solid var(--highlighted)",
          },
          props?.params?.view == "orders" &&
            (props.params?.ongoing == true ||
              props.params?.ongoing == undefined)
            ? { border: "1px solid var(--FunctionGreen)" }
            : null
        )}
        onClick={() =>
          navigate(
            URLFunctions.parse_with_params("/orders", [
              { name: "ongoing", value: true },
            ])
          )
        }
      />
      <Btn
        context={"Archived"}
        style={Object.assign(
          {},
          {
            background: "transparent",
            marginRight: "10px",

            border: "1px solid var(--highlighted)",
          },
          props?.params?.view == "orders" && props.params?.ongoing == false
            ? { border: "1px solid var(--FunctionGreen)" }
            : null
        )}
        onClick={() =>
          navigate(
            URLFunctions.parse_with_params("/orders", [
              { name: "ongoing", value: false },
            ])
          )
        }
      />
      <Btn
        context={"Item View"}
        style={Object.assign(
          {},
          {
            background: "transparent",
            marginRight: "10px",

            border: "1px solid var(--highlighted)",
          },
          props?.params?.view == "items"
            ? { border: "1px solid var(--FunctionGreen)" }
            : null
        )}
        onClick={() =>
          navigate(
            URLFunctions.parse_with_params("/orders/products", [
              { name: "order_by", value: "Time: Low - High", offset: 0 },
            ])
          )
        }
      />
    </div>
  );
}

export default Index;
