import React from "react";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import Popup from "../../../layout/popups/Popup";
import Btn from "../../../core/buttons/Btn";

export default (props) => {
  const dispatch = useDispatch();
  return (
    <Popup
      id="ConvertConfirmPopup"
      context={"Nevermind"}
      onSuccess={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        <div>
          <h2>Are you sure you want to convert these items?</h2>
          <Btn
            style={{
              background: "transparent",
              border: "1px solid #fdc615",
              fontSize: "1.3rem",
              width: "80%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={props.onConvert}
            children={
              <div>
                <span style={{ verticalAlign: "middle", fontWeight: "normal" }}>
                  Convert{" "}
                </span>
                <img
                  src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                  style={{
                    width: "30px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                />
                <span style={{ verticalAlign: "middle" }}>
                  {GeneralFunctions.parse_currency(props.value)}
                </span>
              </div>
            }
          />
        </div>
      }
    />
  );
};
