import React from "react";
import { useParams } from "react-router-dom";

//CONFIG

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Profile from "../../components/content/Profile";
import envConfig from "../../common/config/env.config";

export default function (props) {
  const x = useParams();
  const params = props.params ? props.params : x;

  return <PageLayout children={<Profile user_id={params.user_id} />} />;
}
