import React from "react";

//STYLES
import "./style.css";

export default (props) => {
  return (
    <input
      {...props}
      type="checkbox"
      className="Checkbox"
      checked={props.checked}
      onChange={props.onChange}
    />
  );
};
