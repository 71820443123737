import React from "react";
import { useDispatch, useSelector } from "react-redux";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import ProvFairBtn from "../../../provablyFair/ProvFairBtn";
import RollChecker from "../../../provablyFair/RollChecker";

export default (props) => {
  const session = useSelector((state) => state.session_reducer);
  const dispatch = useDispatch();
  return (
    <div className="highlighted" style={{ marginBottom: "10px" }}>
      <FlexGrid
        children={[
          {
            style: { flex: "1 " },
            element: (
              <div>
                <h3
                  style={{
                    margin: "0",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                >
                  Roll:{" "}
                  <span style={{ color: "var(--FunctionGreen)" }}>
                    {props.unboxing.roll}
                  </span>
                </h3>
                {/*session && session.user.id == props.unboxing.user_id ? (
                  <ProvFairBtn
                    style={{ verticalAlign: "middle" }}
                    onClick={() =>
                      dispatch({
                        type: "NEW_POPUP",
                        element: (
                          <RollChecker unboxing_id={props.unboxing.id} />
                        ),
                      })
                    }
                    context={"Verify Roll"}
                  />
                ) : null*/}
              </div>
            ),
          },
          {
            style: { flex: "0" },
            element: (
              <h3 style={{ margin: "0", textAlign: "right" }}>
                {" "}
                +{props.unboxing.xp_gained}xp
              </h3>
            ),
          },
        ]}
      />
    </div>
  );
};
