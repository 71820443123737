import React from "react";
import { useSelector } from "react-redux";

//FUNCTIONS
import AccountLevel from "../../../../../../common/functions/AccountLevel";

//STYLES
import "./style.css";

function Index(props) {
  const account_level = useSelector((state) => state.account_level_reducer);
  let xp_to_level = AccountLevel.calculate_xp_to_level(
    props.user.total_xp,
    props.user.account_level,
    account_level.boundaries
  );

  return (
    <div id="XP" style={props.style}>
      <div id="xpBar">
        <div
          className="inner"
          style={{
            background: "var(--FunctionGreen)",
            boxShadow: "0 0 10px var(--FunctionGreen)",
            borderRadius: "8px",
            height: "100%",
            width: 100 - xp_to_level.percent + "%",
          }}
        />
        <div id="xp_level">
          <section
            style={{
              position: "relative",
              display: "flex",
              height: "100%",
              paddingLeft: "5px",
            }}
          >
            <span style={{ margin: "auto" }}>
              Level{" "}
              <span style={{ fontWeight: "bold" }}>
                {props.user.account_level}
              </span>
            </span>
          </section>
        </div>
        {props?.show_remaining ? (
          <div id="xp_left">
            <div style={{ display: "inline-block", height: "100%" }}>
              <section
                style={{
                  position: "relative",
                  display: "flex",
                  height: "100%",
                }}
              >
                <span style={{ fontWeight: "normal", margin: "auto" }}>
                  {xp_to_level.progressed + " / " + xp_to_level.xp}
                </span>
              </section>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Index;
