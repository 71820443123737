import React from "react";

import "./style.css";

export default (props) => {
  return (
    <select
      {...props}
      className={
        "highlighted Dropdown" + (props.className ? " " + props.className : "")
      }
      onChange={({ target }) => props.onChange(target.value)}
    >
      {props.options.map((option) => {
        return (
          <option value={option.value} style={{ color: option?.color }}>
            {option.title}
          </option>
        );
      })}
    </select>
  );
};
