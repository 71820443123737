import Search_Crates from "./server/search_crates";
import load_category_crates from "./server/load_category_crates";
import get_pinned_crates from "./server/get_pinned_crates";
import get_daily_crates from "./server/get_daily_crates";
import get_crate from "./server/get_crate";
import get_daily_crate from "./server/get_daily_crate";
import get_random_crate_id from "./server/get_random_crate_id";

//STORE
import store from "../../redux/store";

export default {
  Search_Crates: async (filters) => {
    store.dispatch({
      type: "POPULATE_PAGE_CONTENT",
      content: Object.assign(
        {},
        { page: "crates", order_by: "A-Z", loading: true },
        { pinned_crates: store.getState().page_content_reducer.pinned_crates },
        filters
      ),
    });

    let data = await Search_Crates(filters);

    store.dispatch({
      type: "UPDATE_PAGE_CONTENT",
      content: { data: data || [], order_by: filters.order_by, loading: false },
    });
  },
  load_category_crates: async (filters) => {
    const page_content = store.getState().page_content_reducer;
    filters = Object.assign({}, { page: "crates", order_by: "A-Z" }, filters);

    if (
      !Object.keys(filters).reduce(
        (prev, next) =>
          (prev = filters[next] == page_content[next] ? prev : false),
        true
      )
    ) {
      store.dispatch({
        type: "POPULATE_PAGE_CONTENT",
        content: Object.assign({}, filters, {
          loading: true,
          pinned_crates: page_content.pinned_crates,
        }),
      });

      let data = await load_category_crates(filters);

      store.dispatch({
        type: "UPDATE_PAGE_CONTENT",
        content: { data: data || [], order_by: "A-Z", loading: false },
      });
    }
  },
  get_pinned_crates: async () => {
    const data = await get_pinned_crates();

    store.dispatch({
      type: "UPDATE_PAGE_CONTENT",
      content: { pinned_crates: data },
    });
  },
  get_daily_crates: async () => {
    let data = await get_daily_crates();

    if (data) {
      store.dispatch({
        type: "POPULATE_PAGE_CONTENT",
        content: { page: "rewards", data },
      });
    }
  },
  get_crate: async (crate_id) => {
    let data = await get_crate(crate_id);

    if (data) {
      store.dispatch({
        type: "POPULATE_CRATE_INVENTORY",
        crate_inventory: { crate: data, products: data.products },
      });
    }
  },
  get_daily_crate: async (crate_id) => {
    let data = await get_daily_crate({ crate_id });

    if (data) {
      store.dispatch({
        type: "POPULATE_CRATE_INVENTORY",
        crate_inventory: { crate: data, products: data.products },
      });
    }
  },
  get_random_crate_id: async () => {
    return await get_random_crate_id();
  },
};
