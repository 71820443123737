import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/exports";

//API
import Unboxings from "../../../../common/api/Unboxings";

//COMPONENTS
import SearchOptions from "../../../core/search/SearchOptions";
import ContentList from "../../../core/ContentList";
import ListElement from "./elements/ListElement";
import PageControls from "../../../core/PageControls";
import SearchBar from "../../../core/search/SearchBar";
import Background from "../../../common/backgrounds/Background";
import EmptyPlaceholder from "../../../common/EmptyPlaceholder";

function Index(props) {
  const [filters, setFilters] = useState({
    query: null,
    order_by: "Recently Won",
    offset: 0,
    max: 0,
    limit: 10,
  });
  const [data, setData] = useState({ content: [], max: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    search_unboxings(filters);
  }, [filters]);

  async function search_unboxings(filters) {
    setLoading(true);
    const data = await Unboxings.search({
      product_name: filters.query,

      offset: filters.offset,
      limit: filters.limit,
      order_by: filters.order_by,
    });

    setData(data);
    setLoading(false);
  }

  return !loading ? (
    <div id="UnboxHistory">
      <SearchOptions
        selected={[]}
        style={{
          borderBottom: "0",
          top: "0",
        }}
        children={
          <>
            <Background
              style={{
                background: "var(--background)",
                opacity: "0.85",
              }}
            />
            <div
              style={{ position: "relative", margin: "auto", marginLeft: "0" }}
            >
              <h2 style={{ position: "relative", margin: "0" }}>
                Unbox History
              </h2>
            </div>
            <div
              style={{ margin: "auto", marginRight: "0", minWidth: "300px" }}
            >
              <SearchBar
                context={"wins"}
                onSearch={({ query = null, order_by = null }) =>
                  setFilters(
                    Object.assign(
                      {},
                      filters,
                      query ? { query } : null,
                      order_by ? { order_by } : null
                    )
                  )
                }
                order_by={{
                  selected: filters.order_by,
                  options: [
                    { title: "Recently Won", value: "Recently Won" },
                    { title: "Price: Low - High", value: "Price: Low - High" },
                    { title: "Price: High - Low", value: "Price: High - Low" },
                  ],
                }}
              />
            </div>
          </>
        }
      />

      <div style={{ margin: "auto" }}>
        <div
          style={{
            background: "var(--secondaryBackground)",
            padding: "10px",
            marginTop: "10px",
            borderRadius: "8px",
            overflowX: "auto",
          }}
        >
          <ContentList
            style={{
              overflow: "auto",
              width: "100%",
              margin: "auto",
              overflow: "visible",
              minWidth: "1010px",
            }}
            headers={[
              {
                style: { minWidth: "100px", paddingRight: "10px" },
                element: <span>Item</span>,
              },
              {
                style: { flex: "1 0 180px", minWidth: "180px" },
                element: <span></span>,
              },
              {
                style: {
                  flex: "1 0 200px",
                  minWidth: "200px",
                },
                element: <span>Crate</span>,
              },
              {
                style: { flex: "1 0 100px", minWidth: "100px" },
                element: <span>Roll</span>,
              },
              {
                style: { flex: "1 0 100px", minWidth: "100px" },
                element: <span>Outcome</span>,
              },
            ]}
            content={data.content.map((obj) => (
              <ListElement obj={obj} />
            ))}
          />
          {!data.content.length ? (
            <EmptyPlaceholder
              context={"unboxings"}
              redirect={{ page: "crates", route: "/crates" }}
            />
          ) : null}
        </div>
        <PageControls
          offset={filters.offset}
          limit={data?.max}
          pageLimit={filters.limit}
          onSelectPage={(offset) =>
            setFilters(Object.assign({}, filters, { offset }))
          }
        />
      </div>
    </div>
  ) : null;
}

export default Index;
