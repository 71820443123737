import React from "react";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import List from "../../../../../../core/List";
import FlexArea from "../../../../../../layout/flex/FlexArea";

function Index(props) {
  return (
    <div id="Social">
      <FlexArea
        children={
          <List
            elements={[
              {
                style: {},
                element: (
                  <a
                    href="https://www.facebook.com/officialvivavim"
                    target="_blank"
                  >
                    <img
                      src={
                        envConfig.s3 + "/images/icons/social_media/facebook.svg"
                      }
                    />
                  </a>
                ),
              },
              {
                style: {},
                element: (
                  <a href="https://twitter.com/vivavim" target="_blank">
                    <img
                      src={
                        envConfig.s3 + "/images/icons/social_media/twitter.svg"
                      }
                    />
                  </a>
                ),
              },
              {
                style: {},
                element: (
                  <a
                    href="https://www.instagram.com/vivavimofficial/"
                    target="_blank"
                  >
                    <img
                      src={
                        envConfig.s3 +
                        "/images/icons/social_media/instagram.svg"
                      }
                    />
                  </a>
                ),
              },
              {
                style: {},
                element: (
                  <a
                    href="https://www.youtube.com/channel/UCXFLS8oLUmO5pDP6uM1O__A"
                    target="_blank"
                  >
                    <img
                      src={
                        envConfig.s3 + "/images/icons/social_media/youtube.svg"
                      }
                    />
                  </a>
                ),
              },
              /*{
            style: { marginLeft: "8px", marginRight: "8px" },
            element: (
              <Link to="/provably_fair">
                <li className="navElement">
                  <img
                    src={
                      envConfig.s3 + "/images/icons/common/prov_fair_white.svg"
                    }
                    style={{ verticalAlign: "middle" }}
                  />
                  <span style={{ marginLeft: "5px", verticalAlign: "middle" }}>
                    Provably Fair
                  </span>
                </li>
              </Link>
            ),
          },*/
            ]}
          />
        }
      />
    </div>
  );
}

export default Index;
