import React from "react";

//STYLES
import "./style.css";

export default (props) => {
  return (
    <div className="Document">
      <iframe src={props.src} />
    </div>
  );
};
