import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";

function Index(props) {
  const session = useSelector((state) => state.session_reducer);

  function renderList(title, links) {
    return (
      <div>
        <div>
          <h3 className="title">{title}</h3>
        </div>
        {links.map((x) => (
          <div style={{ marginTop: "5px" }}>
            <Link to={x.route} className="link">
              <span>{x.title}</span>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div id="Nav">
      <FlexGrid
        children={[
          {
            style: { flex: "1", boxSizing: "border-box", padding: "5px" },
            element: renderList("Customer Care", [
              { title: "Contact Us", route: "/contact" },
              { title: "Provably Fair", route: "/provably_fair" },
              { title: "Privacy Policy", route: "/privacy_policy" },
              { title: "Terms and Conditions", route: "/T&Cs" },
            ]),
          },
          {
            style: { flex: "1", boxSizing: "border-box", padding: "5px" },
            element: renderList("Account", [
              { title: "Inventory", route: "/inventory" },
              { title: "Orders", route: "/Orders" },
              {
                title: "Stats",
                route: "/users/" + (session?.user?.id || null),
              },
              { title: "Account Management", route: "/account" },
            ]),
          },
          {
            style: { flex: "1", boxSizing: "border-box", padding: "5px" },
            element: renderList("Rewards", [
              { title: "Daily Free Cases", route: "/crates/daily" },
              { title: "Affiliate", route: "/account/affiliate" },
            ]),
          },
          {
            style: { flex: "1 0 100%", marginTop: "10px" },
            element: (
              <div>
                <div className="payMethods">
                  <img
                    src={
                      envConfig.s3 +
                      "/images/icons/checkout/cards/mastercard.svg"
                    }
                    className="payIcon"
                  />
                  <img
                    src={envConfig.s3 + "/images/icons/checkout/cards/visa.svg"}
                    style={{ height: "25px" }}
                    className="payIcon"
                  />
                </div>
                <div className="payMethods">
                  <img
                    src={
                      envConfig.s3 + "/images/icons/checkout/crypto/bitcoin.svg"
                    }
                    className="payIcon"
                  />
                  <img
                    src={
                      envConfig.s3 +
                      "/images/icons/checkout/crypto/litecoin.svg"
                    }
                    className="payIcon"
                  />
                  <span>50+ more</span>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
