import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../../common/config/env.config";

//STYLES
import "./style.css";

//API
import Unboxings from "../../../common/api/Unboxings";

//COMPONENTS
import Background from "../../common/backgrounds/Background";
import Info from "./elements/Info";
import Product from "./elements/Product";
import User from "../account/ProfileSection";
import Crate from "./elements/Crate";
import ProvFair from "./elements/ProvFair";
import FlexGrid from "../../layout/flex/FlexGrid";
import GlowImageBackground from "../../common/backgrounds/GlowImageBackground";
import FlexArea from "../../layout/flex/FlexArea";
import getProductRank from "../../../common/functions/getProductRank";
import BlendedBackground from "../../common/backgrounds/BlendedBackground";

function Index(props) {
  let [unboxing, setUnboxing] = useState(null);

  if (!unboxing || unboxing.id !== props.unboxing_id) {
    (async () => {
      let x = await Unboxings.get_unboxing(props.unboxing_id);
      setUnboxing(x);
    })();
  }

  return unboxing ? (
    <div id="Unboxing" className="mainPadding">
      <Helmet>
        <title>{unescape(unboxing.username) + " - " + unboxing.roll} </title>
        <meta
          name="description"
          content={unescape(unboxing.username) + " rolled " + unboxing.roll}
        />
      </Helmet>
      <BlendedBackground
        style={{
          borderRadius: "8px",
          backgroundImage:
            "url(" +
            (unboxing.crate_id
              ? envConfig.s3 +
                "/images/crates2/wallpapers/" +
                unboxing.crate_id +
                ".webp"
              : envConfig.s3 + "/images/crates2/banner/banner2.webp") +
            ")",
        }}
      />
      <FlexGrid
        style={{
          flexDirection: "column-reverse",
          maxWidth: "1750px",
          margin: "auto",
          marginTop: "50px",
          marginBottom: "50px",
        }}
        children={[
          {
            style: { flex: "1 0 100%" },
            element: (
              <div
                style={{
                  position: "relative",
                  padding: "20px",
                  boxSizing: "border-box",
                }}
              >
                {/*<GlowImageBackground
                  style={{
                    border: "1px solid var(--highlighted)",
                    borderRadius: "8px",
                  }}
                  image={
                    unboxing.crate_id
                      ? envConfig.s3 +
                        "/images/crates2/wallpapers/" +
                        unboxing.crate_id +
                        ".webp"
                      : envConfig.s3 + "/images/crates2/banner/banner2.webp"
                  }
                />*/}

                <div
                  style={{
                    position: "relative",
                    padding: "10px",
                    border:
                      "2px solid var(--" +
                      getProductRank(unboxing.winning_product_price) +
                      ")",
                    borderRadius: "8px",
                  }}
                >
                  <Background
                    style={{
                      opacity: "0.7",
                      borderRadius: "8px",
                      background: "var(--background)",
                    }}
                  />
                  <FlexGrid
                    children={[
                      {
                        style: { flex: "1 0 100%" },
                        element: <Info unboxing={unboxing} />,
                      },
                      {
                        style: { flex: "1", padding: "5px" },
                        element: (
                          <div>
                            <Product unboxing={unboxing} />
                          </div>
                        ),
                      },
                      {
                        style: {
                          flex: "1 0 250px",
                          maxWidth: "500px",
                        },
                        element: (
                          <div
                            style={{
                              position: "relative",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <h4
                              style={{
                                margin: "0",
                                background: "var(--mainPurple)",
                                textAlign: "center",
                                padding: "5px",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                              }}
                            >
                              Crate Opened
                            </h4>
                            <FlexArea
                              style={{
                                height: "unset",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                              children={
                                <>
                                  <Background
                                    style={{
                                      background: "var(--background)",
                                      opacity: "0.6",
                                      borderBottomLeftRadius: "8px",
                                      borderBottomRightRadius: "8px",
                                      border: "1px solid var(--highlighted)",
                                    }}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                      maxWidth: "250px",
                                      width: "100%",
                                      margin: "auto",
                                    }}
                                  >
                                    <Crate unboxing={unboxing} />
                                  </div>
                                </>
                              }
                            />
                          </div>
                        ),
                      },
                    ]}
                  />

                  <div
                    id="userWrapper"
                    style={{
                      borderTop:
                        "2px solid var(--" +
                        getProductRank(unboxing.winning_product_price) +
                        ")",
                    }}
                  >
                    <Link to={"/users/" + unboxing.user_id}>
                      <User user={unboxing} withDeposit={true} />
                    </Link>
                  </div>
                </div>
              </div>
            ),
          },
          {
            style: { marginBottom: "10px" },
            element: <ProvFair unboxing={unboxing} />,
          },
        ]}
      />
    </div>
  ) : (
    <div></div>
  );
}

export default Index;
