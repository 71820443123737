import React from "react";

import "./style.css";

//COMPONENTS
import FlexArea from "../../../../../../layout/flex/FlexArea";
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import Background from "../../../../../../common/backgrounds/Background";

export default (props) => {
  return (
    <div className="stat" style={{ border: "2px solid " + props.color }}>
      <Background style={{ background: props.color, opacity: "0.2" }} />
      <FlexGrid
        style={{ height: "100%" }}
        children={[
          {
            style: { flex: "1 0 100%" },
            element: (
              <div>
                <span>{props.title}</span>
              </div>
            ),
          },
          {
            style: { flex: "1 0 100%" },
            element: (
              <FlexArea
                children={
                  <div style={{ margin: "auto" }}>{props.children}</div>
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
};
