import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../common/config/env.config";

//API
import Users from "../../../common/api/Users";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../../common/backgrounds/Background";
import FlexGrid from "../../layout/flex/FlexGrid";
import Info from "./elements/Info";
import Stats from "./elements/Stats";
import FlexArea from "../../layout/flex/FlexArea";
import RecentWins from "./elements/RecentWins";
import FavouriteCrate from "./elements/FavouriteCrate";
import Win from "./elements/Win";

function Index(props) {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    load_profile();
  }, []);

  async function load_profile() {
    setProfile(await Users.get_user(props.user_id));
    setLoading(false);
  }

  return (
    <div id="Profile">
      {!loading && profile ? (
        <div>
          <Helmet>
            <title>{unescape(profile.user.username) + "'s profile"}</title>
            <meta
              name="description"
              content={
                unescape(profile.user.username) +
                "'s unboxings and playing stats on vivavim"
              }
            />
          </Helmet>
          <div
            style={{
              position: "relative",
            }}
          >
            <Background
              style={{
                backgroundImage:
                  "url(" +
                  envConfig.s3 +
                  "/images/crates2/banner/banner2.webp)",
              }}
              children={
                <>
                  <Background
                    style={{
                      left: "0",
                      right: "0",
                      top: "0",
                      background:
                        "linear-gradient(180deg, var(--background), transparent)",
                      height: "50px",
                    }}
                  />
                  <Background
                    style={{
                      bottom: "0",
                      left: "0",
                      right: "0",
                      top: "unset",
                      background:
                        "linear-gradient(0deg, var(--background), transparent)",
                      height: "50px",
                    }}
                  />
                  <Background
                    style={{
                      bottom: "0",
                      left: "unset",
                      right: "0",
                      top: "0",
                      background:
                        "linear-gradient(90deg, transparent,var(--background))",
                      width: "10%",
                    }}
                  />
                  <Background
                    style={{
                      bottom: "0",
                      left: "0",
                      right: "unset",
                      top: "0",
                      background:
                        "linear-gradient(-90deg, transparent,var(--background))",
                      width: "10%",
                    }}
                  />
                </>
              }
            />
            <FlexArea
              children={
                <div className="top mainPadding">
                  <div
                    style={{
                      position: "relative",
                      padding: "10px",
                      border: "2px solid var(--highlighted)",
                      borderRadius: "8px",
                    }}
                  >
                    <Background
                      style={{
                        background: "var(--background)",
                        opacity: "0.7",
                        borderRadius: "8px",
                      }}
                    />
                    <FlexGrid
                      children={[
                        {
                          style: { flex: "1 0 50%" },
                          element: (
                            <FlexArea
                              children={
                                <div style={{ margin: "auto", width: "100%" }}>
                                  <Info profile={profile} />
                                </div>
                              }
                            />
                          ),
                        },
                        {
                          style: { flex: "1 0 50%" },
                          element: (
                            <FlexArea
                              children={
                                <div
                                  style={{
                                    position: "relative",
                                    margin: "auto",
                                  }}
                                >
                                  <Stats profile={profile} />
                                </div>
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="mainPadding">
            <FlexGrid
              style={{ marginTop: "40px" }}
              children={[
                {
                  style: {
                    flex: "1 0 100%",
                  },
                  element: (
                    <FlexGrid
                      children={[
                        {
                          style: { flex: "1 0 50%", padding: "25px" },
                          element: <RecentWins profile={profile} />,
                        },
                        {
                          style: {
                            flex: "1 0 50%",
                            padding: "25px",
                          },
                          element: <FavouriteCrate profile={profile} />,
                        },
                      ]}
                    />
                  ),
                },
                {
                  style: { flex: "1 0 100%" },
                  element: (
                    <FlexGrid
                      children={[
                        {
                          style: {
                            flex: "1 0 50%",
                            padding: "25px",
                            alignSelf: "stretch",
                          },
                          element: (
                            <Win
                              title={"Biggest Win"}
                              win={profile.highest_win}
                            />
                          ),
                        },
                        {
                          style: {
                            flex: "1 0 50%",
                            padding: "25px",
                            alignSelf: "stretch",
                          },
                          element: (
                            <Win
                              title={"Luckiest Win"}
                              win={profile.luckiest_win}
                            />
                          ),
                        },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Index;
