import React from "react";
import { useParams } from "react-router-dom";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Unboxing from "../../components/content/Unboxing";

function Index(props) {
  const params = useParams();
  let x = props.params ? props.params : params;
  return (
    <PageLayout
      background={{
        backgroundImage:
          "url(" + envConfig.s3 + "/images/crates2/background.webp)",
        opacity: "0.6",
        position: "fixed",
      }}
      children={<Unboxing unboxing_id={x.unboxing_id} />}
    />
  );
}

export default Index;
