const chat_style_reducer = (
  state = { is_mobile: false, show: false },
  action
) => {
  switch (action.type) {
    case "UPDATE_CHAT_STYLE":
      return Object.assign({}, state, action.obj);
    default:
      return state;
  }
};

export default chat_style_reducer;
