import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//API
import Unbox from "../../../../common/api/Unbox";

//STYLES
import "./style.css";

//COMPONENTS
import Popup from "../../../layout/popups/Popup";
import Info from "./elements/Info";
import RevealRoll from "./elements/RevealRoll";
import ProvFairBtn from "../ProvFairBtn";

export default (props) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);
  const [unboxSession, setUnboxSession] = useState(null);

  useEffect(() => {
    if (props.unboxing_id) {
      load_session(props.unboxing_id);
    }
  }, []);

  async function load_session(id) {
    setUnboxSession(await Unbox.get_unboxing_seeds({ id }));
  }

  async function reveal_server_seed() {
    const data = await Unbox.reveal_server_seed({
      id: props.unboxing_id,
    });

    if (data.new_hashed) {
      dispatch({
        type: "UPDATE_UNBOXING_HASHED_SERVER_SEED",
        hashed_server_seed: data.new_hashed,
      });
    }

    setUnboxSession(
      Object.assign({}, unboxSession, {
        server_seed: data.server_seed,
        nonce: data.nonce,
      })
    );
  }

  return (
    <Popup
      id="RollChecker"
      title={"Verify Roll"}
      onClose={() => dispatch({ type: "CLOSE_POPUP" })}
      children={
        unboxSession ? (
          <div>
            <Info session={unboxSession} />
            <RevealRoll
              session={unboxSession}
              reveal_server_seed={reveal_server_seed}
            />
            <div style={{ margintop: "5px", textAlign: "right" }}>
              <Link to="/provably_fair">
                <ProvFairBtn context={"How This Works"} />
              </Link>
            </div>
          </div>
        ) : null
      }
    />
  );
};
