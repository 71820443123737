import React, { useRef, useEffect } from "react";
import confetti from "canvas-confetti";

function Confetti(props) {
  const canvasRef = useRef(null);
  const confettiRefs = useRef([]);

  useEffect(() => {
    addConfetti();
    if (props.infinite) {
      const intervalId = setInterval(() => {
        if (canvasRef.current && !document.hidden) {
          addConfetti();
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, []);

  function addConfetti() {
    const myConfetti =
      confettiRefs.current.pop() ||
      confetti.create(canvasRef.current, {
        resize: true,
      });

    myConfetti({
      particleCount: 100,
      spread: 160,
      origin: {
        y: 0.6,
      },
    });

    confettiRefs.current.unshift(myConfetti);
  }

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
      }}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
}

export default Confetti;
