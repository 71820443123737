import store from "../../redux/store";

//API
import get_addresses from "./server/get_addresses";
import make_default from "./server/make_default";
import add_address from "./server/add_address";
import delete_address from "./server/delete_address";

export default {
  get_addresses: async () => {
    let addresses = store.getState().addresses_reducer;

    if (!addresses.length) {
      addresses = await get_addresses();
      store.dispatch({ type: "POPULATE_ADDRESSES", addresses });
      return addresses;
    } else {
      return addresses;
    }
  },
  make_default: async (obj) => {
    if (await make_default(obj)) {
      store.dispatch({
        type: "SET_DEFAULT_ADDRESS",
        id: obj.id,
      });
    }
  },
  new: async (obj) => {
    const data = await add_address(obj);
    if (data) {
      if (obj.id) {
        store.dispatch({ type: "UPDATE_ADDRESS", address: obj });
        return obj;
      } else {
        const new_address = {
          id: data.address_id,
          address: obj.address,
          is_default: !store.getState().addresses_reducer.length,
        };
        store.dispatch({
          type: "ADD_ADDRESS",
          address: new_address,
        });

        return new_address;
      }
    }
  },
  delete: async (obj) => {
    const data = await delete_address(obj);
    if (data) {
      store.dispatch({ type: "DELETE_ADDRESS", id: obj.id });

      if (data?.new_default_id) {
        store.dispatch({
          type: "UPDATE_ADDRESS",
          address: { id: data.new_default_id, is_default: true },
        });

        return true;
      } else {
        return false;
      }
    }
  },
};
