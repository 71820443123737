import React from "react";

//API
import Transactions from "../../../../../common/api/Transactions";

//COMPONENTS
import Btn from "../../../../core/buttons/Btn";

export default (props) => {
  async function confirm(checkout) {
    let obj = await Transactions.add_crypto_order(
      Object.assign({}, checkout, props?.order ? { order: props.order } : null)
    );

    if (obj?.payment_url) {
      window.location.href = obj.payment_url;
    }
  }

  return (
    <div>
      <div>
        <ul>
          <li>
            <span>Fast and secure transactions </span>
          </li>
          <li>
            <span>over 70 crypto currencies accepted</span>
          </li>
          <li>
            <span>over 70 different crypto currencies accepted</span>
          </li>
        </ul>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ width: "65%" }}
          src={
            "https://coingate.com/_next/static/media/payment_bage.9e48efc9.svg"
          }
        />
      </div>
      <div>
        <Btn
          style={{
            width: "100%",
            background: "var(--crypto)",
            boxShadow: "0 0 5px var(--crypto)",
            marginBottom: "10px",
          }}
          context={"Pay with Coingate"}
          onClick={() => confirm(props.checkout)}
        />
      </div>
    </div>
  );
};
