import UnboxingFunctions from "./Unboxing.functions";
import Errors from "../Errors";

export default {
  validate_unboxing: (products, account_balance = null) => {
    let error = null;

    let crate_info = UnboxingFunctions.calculate_crate_info(products);

    //PROBABILITY EQUALS 100%
    if (crate_info.probability !== 100) {
      error = Errors.get_error_code("odds not 100%");
    }

    if (account_balance !== null) {
      //USER HAS ENOUGH BALANCE
      if (crate_info.cost > account_balance) {
        error = Errors.get_error_code("insufficient balance");
      }
    }

    return error;
  },
};
