import API from "../../index";

export default async (obj) => {
  try {
    let { data, status } = await API.get(
      "/api/products?ids=" + encodeURIComponent(obj.id_list)
    );

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
