import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import SuccessBtn from "../../../../../../../../core/buttons/SuccessBtn";
import FlexGrid from "../../../../../../../../layout/flex/FlexGrid";
import Btn from "../../../../../../../../core/buttons/Btn";

export default (props) => {
  return (
    <div className="bottomBar">
      <FlexGrid
        children={[
          {
            style: { flex: "1" },
            element: (
              <div className="options">
                {props.saveAddress ? (
                  <Btn
                    className="save active"
                    onClick={props.saveAddress}
                    context={"Save"}
                  />
                ) : null}
                {props.deleteAddress ? (
                  <Btn
                    className="delete active"
                    onClick={props.deleteAddress}
                    context={"Delete"}
                  />
                ) : null}
                {!props.isDefault && props.makeDefault ? (
                  <Btn
                    className="active"
                    onClick={props.makeDefault}
                    context={"Make Default"}
                  />
                ) : null}
              </div>
            ),
          },
          {
            style: { flex: "1" },
            element: (
              <div>
                <SuccessBtn
                  context={
                    props.address?.id && props.saveAddress
                      ? "Save & Continue"
                      : props.context || "Continue"
                  }
                  onClick={props.confirmAddress}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
