import store from "../../redux/store";

//FUNCTIONS
import Cookies from "../../Cookies";

export default (obj) => {
  store.dispatch({ type: "UPDATE_ACCOUNT_BALANCE", account_balance: 0 });
  store.dispatch({
    type: "POPULATE_UNBOXING_SESSION",
    unboxing_session: null,
  });
  store.dispatch({ type: "CLEAR_ACCOUNT_LEVEL" });
  store.dispatch({ type: "LOGOUT" });
  store.dispatch({ type: "CLEAR_ACCOUNT_DROPDOWN" });

  Cookies.remove("session-token");
  Cookies.remove("refresh-token");
};
