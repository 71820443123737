import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../common/config/env.config";
import categories from "../../Search/categories.json";
//FUNCTIONS
import ParseDateTime from "../../../../../common/functions/ParseDateTime";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import Countdown from "../../../../common/Countdown";
import Background from "../../../../common/backgrounds/Background";
import Price from "../../../../common/Price";

function Index(props) {
  const dispatch = useDispatch();
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);
  const session = useSelector((state) => state.session_reducer);

  const color = categories.find(
    (x) => x.title == props?.crate?.category
  )?.color;

  return (
    <div className="crateInfoDiv">
      {/*<Background
        style={{ borderRadius: "8px", overflow: "hidden" }}
        children={
          <>
            <div
              style={{
                position: "absolute",
                inset: "0",
                bottom: "unset",
                height: "25%",
                background: "linear-gradient(180deg, black, transparent)",
              }}
            />
            <div
              style={{
                position: "absolute",
                inset: "0",
                top: "unset",
                height: "25%",
                background: "linear-gradient(0deg, black, transparent)",
              }}
            />
          </>
        }
      />*/}
      <FlexGrid
        children={[
          {
            style: {
              flex: "1 0 100%",
            },
            element: (
              <div style={{ textAlign: "center" }}>
                <h2 className="crateTitle">
                  <Background
                    style={{
                      background: "var(--background)",
                      opacity: "0.6",
                    }}
                  />
                  <span style={{ position: "relative" }}>
                    {props.crate.name
                      ? unescape(props.crate.name)
                      : "Make a Crate"}
                  </span>
                </h2>
              </div>
            ),
          },
          {
            style: {
              flex: "1 0 100%",
              marginTop: "auto",
              textAlign: "center",
            },
            element: (() => {
              if (props.crate.time_locked_until || props.crate.levels_to) {
                if (props.crate.time_locked_until) {
                  return (
                    <div className="crateCostStyling">
                      <img
                        src={envConfig.s3 + "/images/icons/Lockedicon.svg"}
                        className="lockedIcon"
                        style={{
                          height: "25px",
                          verticalAlign: "middle",
                          marginRight: "10px",
                        }}
                      />
                      <span
                        style={{
                          verticalAlign: "middle",
                          paddingRight: "1px",
                          fontSize: "0.8rem",
                          color: "var(--error)",
                        }}
                      >
                        <Countdown
                          end_time={ParseDateTime.adjustForTimezone(
                            new Date(props.crate.time_locked_until)
                          )}
                          onEnd={() => {
                            if (crate_inventory?.crate?.id == props.crate.id) {
                              dispatch({
                                type: "UPDATE_CRATE",
                                crate: { time_locked_until: null },
                              });
                            }

                            dispatch({
                              type: "UPDATE_PAGE_CONTENT_ELEMENT",
                              element: {
                                id: props.crate.id,
                                time_locked_until: null,
                              },
                            });
                          }}
                        />
                      </span>
                    </div>
                  );
                }
                if (props.crate.levels_to) {
                  return (
                    <div className="crateCostStyling">
                      <img
                        src={envConfig.s3 + "/images/icons/Lockedicon.svg"}
                        className="lockedIcon"
                        style={{
                          height: "25px",
                          verticalAlign: "middle",
                          marginRight: "10px",
                        }}
                      />
                      <span
                        style={{
                          verticalAlign: "middle",
                          paddingRight: "1px",
                          fontSize: "0.9rem",
                        }}
                      >
                        + {props.crate.levels_to} Levels
                      </span>
                    </div>
                  );
                }
              } else {
                return !session && props.crate.isFree ? (
                  <div className="crateCostStyling">
                    <span style={{ fontWeight: "normal", fontSize: "1rem" }}>
                      Login to Claim
                    </span>
                  </div>
                ) : (
                  <div className="crateCostStyling">
                    <Background
                      style={{
                        background: "var(--background)",
                        opacity: "0.6",
                      }}
                    />
                    <Price
                      style={{ fontSize: "0.9rem" }}
                      price={props.crate.isFree ? 0 : props.crate.price}
                      sale_price={props.crate?.sale_price}
                      sale={
                        props.crate?.sale_price
                          ? {
                              price: props.crate?.sale_price,
                              ends: new Date("2023-01-17"),
                            }
                          : null
                      }
                    />
                  </div>
                );
              }
            })(),
          },
        ]}
      />
    </div>
  );
}

export default Index;
