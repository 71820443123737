import API from "../../index";

export default async (filters) => {
  try {
    let { data, status } = await API.get(
      "/products/search/" +
        (filters.query ? filters.query : null) +
        "/" +
        filters.vender +
        "/" +
        (filters.order_by ? filters.order_by : null) +
        "/" +
        (filters.offset ? filters.offset : 0)
    );
    return status == 201 ? { content: data.content, max: data.count } : null;
  } catch (e) {
    throw e;
  }
};
