import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import AddressFormat from "../../../../../../../../account2/Addresses/AddressFormat";
import Background from "../../../../../../../../../common/backgrounds/Background";

export default (props) => {
  return (
    <div
      onClick={props.setAddress}
      className={("AddressOption " + (props.selected ? "selected" : "")).trim()}
    >
      <Background
        style={{
          background: "var(--background",
          opacity: "0.5",
          borderRadius: "8px",
        }}
      />
      <AddressFormat address={props.address} />
    </div>
  );
};
