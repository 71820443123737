import React from "react";
import { useNavigate } from "react-router-dom";

//STYLES
import "./style.css";

//COMPONENTS
import QuickInfo from "./elements/QuickInfo";
import FAQs from "./elements/FAQs";
import Background from "../../common/backgrounds/Background";
import FlexGrid from "../../layout/flex/FlexGrid";

function Index(props) {
  const navigate = useNavigate();

  return (
    <div id="HowItWorks" className="mainPadding">
      <FlexGrid
        children={[
          {
            className: "quickInfoRow",
            element: <QuickInfo />,
          },
          {
            className: "faqsRow",
            element: <FAQs />,
          },
        ]}
      />
    </div>
  );
}

export default Index;
