import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//COOKIES
import Cookies from "../../../common/Cookies";

function Index(props) {
  const dispatch = useDispatch();
  const [sound, setSound] = useState(null);
  const audio = useSelector((state) => state.audio_reducer);

  //RESTORE FROM COOKIES
  useEffect(() => {
    console.log(Cookies.get("volume"));
    dispatch({
      type: "SET_AUDIO",
      audio: {
        muted:
          Cookies.get("muted") && parseInt(Cookies.get("muted")) ? true : false,
        volume:
          Cookies.get("volume") !== null ? parseInt(Cookies.get("volume")) : 50,
      },
    });
  }, []);

  useEffect(() => {
    if (audio.source) {
      setSound(audio.source);
    }

    Cookies.set("volume", audio.volume ? audio.volume.toString() : 50);
    Cookies.set("muted", audio.muted ? 1 : 0);
  }, [audio]);

  useEffect(() => {
    if (sound) {
      playSound();
    }
  }, [sound]);

  function playSound() {
    let audioEl = document.getElementById("Audio");
    audioEl.volume = audio.volume / 100;
    audioEl.setAttribute("src", sound);
    audioEl.play();
  }

  function finishSound(x) {
    dispatch({ type: "REMOVE_AUDIO", source: x });
    setSound(null);
  }

  return sound ? (
    <audio id="Audio" onEnded={() => finishSound(sound)}></audio>
  ) : null;
}

export default Index;
