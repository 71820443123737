import store from "../../redux/store";

//SERVER
import get_affiliate from "./server/get_affiliate";
import toggle_active_code from "./server/toggle_active_code";
import change_code from "./server/change_code";
import add_payout from "./server/add_payout";
import search_code_usage from "./server/search_code_usage";

export default {
  get_affiliate: async () => {
    /*if (store.getState().page_content_reducer.page !== "affiliate") {
      const affiliate = await get_affiliate();

      store.dispatch({
        type: "POPULATE_PAGE_CONTENT",
        content: Object.assign(
          {},
          {
            balance: affiliate.balance,
            code: affiliate.code,
            stats: affiliate.stats,
            data: affiliate.payouts,
          },
          { page: "affiliate" }
        ),
      });
    }*/

    return await get_affiliate();
  },
  add_payout: async (obj) => {
    let return_obj = await add_payout(obj);
    if (return_obj) {
      let payouts = store.getState().page_content_reducer.data;
      store.dispatch({
        type: "UPDATE_ACCOUNT_BALANCE",
        account_balance: return_obj.new_balance,
      });

      return return_obj;
    }
  },
  toggle_active_code: async (obj) => {
    const active = await toggle_active_code(obj);
    let code = store.getState().page_content_reducer.code;
    store.dispatch({
      type: "UPDATE_PAGE_CONTENT",
      content: { code: Object.assign({}, code, { active: obj.active }) },
    });
  },
  change_code: async (obj) => {
    const new_code = await change_code(obj);
    if (new_code) {
      return new_code;
    }
  },
  search_code_usage: async (obj) => {
    return await search_code_usage(obj);
  },
};
