import React, { useState } from "react";

//API
import Auth from "../../../../common/api/Auth";

//STYLES
import "./style.css";

//COMPONENTS
import PopupWithRedirect from "../../../layout/popups/PopupWithRedirect";
import Textbox from "../../../core/inputs/text/Textbox";
import FlexGrid from "../../../layout/flex/FlexGrid";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const [emailTxt, setEmailTxt] = useState("");
  const [sent, setSent] = useState(false);

  async function confirm() {
    if (emailTxt.length > 0) {
      await Auth.email_password_reset({ email: emailTxt });

      setSent(true);
    }
  }

  return (
    <PopupWithRedirect
      id="ForgotPassword"
      title={"Forgot Password"}
      onSuccess={confirm}
      context={"Send Email"}
      style={{ textAlign: "left" }}
      children={
        !sent ? (
          <div>
            <p>Enter your Email Address to recover your account</p>

            <Textbox
              value={emailTxt}
              onChange={(e) => setEmailTxt(e.target.value)}
            />
          </div>
        ) : (
          <div>
            <FlexGrid
              style={{ marginTop: "20px", marginBottom: "20px" }}
              children={[
                {
                  style: { flex: "1 0 100%", display: "inline-block" },
                  element: (
                    <div>
                      <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                        Account Recovery Pending
                      </p>
                      <p>
                        If your account exists you will receive a recovery email
                        to reset your password.
                      </p>
                    </div>
                  ),
                },
                {
                  style: {
                    flex: "1 0 100%",
                    display: "inline-block",
                    minHeight: "100px",
                  },
                  element: (
                    <FlexArea
                      style={{
                        boxSizing: "border-box",
                        border: "1px solid var(--FunctionGreen)",
                      }}
                      children={
                        <span
                          style={{
                            margin: "auto",
                            fontWeight: "bold",
                            color: "var(--FunctionGreen)",
                            fontSize: "1.2rem",
                          }}
                        >
                          {emailTxt}
                        </span>
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        )
      }
    />
  );
};
