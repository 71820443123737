import React from "react";

//STYLES
import "./style.css";

export default (props) => {
  return (
    <div className="element">
      <p>{props.name}</p>
      {props.children}
    </div>
  );
};
