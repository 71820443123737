import API from "../../index";

export default async (obj) => {
  console.log(obj);
  try {
    let { data, status } = await API.post("/api/transactions/intents", {
      amount: obj.value * 100,
      code: obj.code ? obj.code.code : null,
      price_change_products: obj?.products,
      order: obj?.order,
    });

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
