export default function getProductRank(price) {
  var colourUrl = "";
  if (price >= 500) {
    colourUrl = "gold";
  }
  if (price < 500) {
    colourUrl = "orange";
  }
  if (price < 250) {
    colourUrl = "red";
  }
  if (price < 100) {
    colourUrl = "purple";
  }
  if (price < 35) {
    colourUrl = "blue";
  }
  if (price < 10) {
    colourUrl = "green";
  }

  return colourUrl;
}
