import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

//API
import Orders from "../../../../common/api/Orders";

//FUNCTIONS
import URLFunctions from "../../../../common/functions/URL.functions";

//STYLES
import "./style.css";

//COMPONENTS
import ContentList from "../../../core/ContentList";
import OrderView from "./elements/OrderView";
import Background from "../../../common/backgrounds/Background";
import PageControls from "../../../core/PageControls";
import ProductView from "./elements/ProductView";
import EmptyPlaceholder from "../../../common/EmptyPlaceholder";

function Index(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const page_content = useSelector((state) =>
    state.page_content_reducer?.page == "orders"
      ? state.page_content_reducer
      : null
  );

  const [filters, setFilters] = useState({
    offset: 0,
    limit: 15,
    ongoing: true,
    order_by: "Time: Low - High",
  });
  const [productsObj, setProductsObj] = useState(null);
  const [ordersObj, setOrdersObj] = useState(null);

  useEffect(() => {
    if (props.params?.name || props.params?.order_by)
      searchProducts(
        props.params?.name,
        props.params?.order_by,
        props.params?.offset
      );
    else
      searchOrders(null, null, props?.params?.ongoing, props?.params?.offset);
  }, [props.params]);

  async function searchProducts(
    product_name = null,
    order_by = "time: low - high",
    offset = 0
  ) {
    setOrdersObj(null);
    setProductsObj(
      await Orders.search_orders_products({
        product_name,
        order_by,
        offset,
      })
    );
  }

  async function searchOrders(
    id = null,
    status = null,
    ongoing = true,
    offset,
    limit = 15
  ) {
    setProductsObj(null);
    console.log(ongoing);

    setOrdersObj(
      await Orders.search_orders({
        id,
        status,
        ongoing,
        order_by: null,
        list_offset: offset,
        limit,
      })
    );
  }

  //console.log(filters.ongoing);

  return (
    <div id="OrderList" className="mainPadding">
      <Background
        style={{
          background: "var(--background)",
          opacity: "0.8",
        }}
      />
      {ordersObj?.data?.length || productsObj?.data ? (
        <div style={{ position: "relative" }}>
          <div style={{ position: "relative" }}>
            <Background
              style={{
                background: "var(--secondaryBackground",
                borderRadius: "8px",
              }}
            />
            <ContentList
              style={{
                display: "flex",
                padding: "10px",
              }}
              content={
                ordersObj
                  ? ordersObj.data.map((x) => (
                      <div
                        style={{
                          flex: "1 0 50%",
                          padding: "5px",
                          boxSizing: "border-box",
                          minWidth: "300px",
                          maxWidth: "800px",
                        }}
                      >
                        <OrderView key={x.id} order={x} />
                      </div>
                    ))
                  : [...(productsObj?.data || [])].map((x) => (
                      <div style={{ flex: "1 0 100%" }}>
                        <ProductView product={x} />
                      </div>
                    ))
              }
            />
          </div>
          <PageControls
            offset={props.params?.offset || 0}
            limit={ordersObj?.max || productsObj?.max || 0}
            onSelectPage={(offset) =>
              ordersObj
                ? navigate(
                    URLFunctions.parse_with_params("/orders", [
                      {
                        name: "ongoing",
                        value:
                          props?.params?.ongoing !== undefined
                            ? props.params.ongoing
                            : true,
                      },
                      { name: "offset", value: offset },
                    ])
                  )
                : navigate(
                    URLFunctions.parse_with_params("/orders/products", [
                      { name: "order_by", value: props?.params?.order_by },
                      { name: "offset", value: offset },
                    ])
                  )
            }
            pageLimit={15}
          />
        </div>
      ) : (
        <EmptyPlaceholder
          context={"orders"}
          redirect={{ route: "/inventory", page: "inventory" }}
        />
      )}
    </div>
  );
}

export default Index;
