//STORE
import store from "../../../../../common/redux/store";

//FUNCTIONS
import CarouselFunctions from "./Carousel.functions";

export default {
  init: (obj) => {
    store.dispatch({
      type: "POPULATE_UNBOXING_CAROUSEL",
      carousel: Object.assign({}, obj, {
        /*order: CarouselFunctions.random_order(
          obj.products, //PRODUCTS
          80, //NO OF CAROUSEL EL
          obj.unboxing ? obj.unboxing.winning_product : null //WINNING PRODUCT
        ),*/
        roll: obj.roll,
        dec_rate: null,
        speed: store.getState().unboxing_reducer.fast_unbox ? 100 : 50,
        xp_amount: obj.xp_amount,
      }),
    });
  },
};
