import React from "react";
import { useState } from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../../../buttons/Btn";

export default (props) => {
  const [show, setShow] = useState(false);
  return (
    <div style={{ position: "relative" }}>
      <input
        className={(
          "textbox " +
          (props.error ? "error " : "") +
          (props.className ? props.className + " " : "")
        ).trim()}
        {...props}
        type={show ? "text" : props.type}
      />
      {props.type == "password" &&
      (props.show == undefined || props.show == true) ? (
        <Btn
          style={{
            position: "absolute",
            top: "11px",
            right: "9px",
            fontSize: "0.8rem",
            padding: "0",
          }}
          onClick={() => setShow(!show)}
          children={
            <img
              style={{ width: "20px" }}
              src={
                !show
                  ? envConfig.s3 + "/images/icons/reveal.svg"
                  : envConfig.s3 + "/images/icons/hide.svg"
              }
            />
          }
        />
      ) : null}
    </div>
  );
};
