import Cookies from "../../Cookies";
import store from "../../redux/store";

//CONFIG
import get_config from "./server/get_config";

export default {
  get_config: async (callback) => {
    let config = await get_config();

    if (config) {
      store.dispatch({
        type: "POPULATE_ACCOUNT_LEVEL",
        object: { boundaries: config.boundaries },
      });
      delete config.boundaries;
      store.dispatch({
        type: "POPULATE_CONFIG",
        config,
      });

      if (Cookies.get("muted")) {
        store.dispatch({
          type: "SET_AUDIO",
          audio: { muted: Cookies.get("muted") },
        });
      }

      callback(true);
    }

    callback(false);
  },
};
