import React from "react";

//CONFIG
import envConfig from "../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../layout/flex/FlexGrid";
import Background from "../../common/backgrounds/Background";

export default (props) => {
  return (
    <div
      className="TabTitle"
      style={
        props.title_background == false
          ? { paddingTop: "20px", paddingBottom: "10px" }
          : null
      }
    >
      {props.title_background == undefined || props.title_background == true ? (
        <Background
          id="PageBackground"
          style={{
            backgroundImage:
              "url(" + envConfig.s3 + "/images/crates2/banner/banner2.webp)",
            opacity: "0.8",
            position: "absolute",
          }}
          children={
            <>
              <Background
                style={{
                  background:
                    "linear-gradient(0deg, var(--background), transparent)",
                  top: "unset",
                  height: "50%",
                  opacity: "1",
                }}
              />
              <Background
                style={{
                  background:
                    "linear-gradient(180deg, var(--background), transparent)",
                  height: "50%",
                  bottom: "unset",
                  opacity: "1",
                }}
              />
            </>
          }
        />
      ) : null}
      <div className="mainPadding">
        <FlexGrid
          children={[
            {
              style: {
                flex: "1",
                marginTop: "auto",
                marginLeft: "0",
                marginRight: "auto",
              },
              element: (
                <div>
                  <h2 className="title" style={props.title_style}>
                    {props.title}
                  </h2>
                  {props.sub_title ? (
                    <h3 className="sub_title">{props.sub_title}</h3>
                  ) : null}
                </div>
              ),
            },
            props.content
              ? {
                  style: {
                    flex: "1",
                    marginTop: "auto",
                    marginLeft: "auto",
                  },
                  element: props.content,
                }
              : "",
          ]}
        />
      </div>
    </div>
  );
};
