import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//STYLES
import "../style.css";

//API
import Orders from "../../../../../common/api/Orders";

//FUNCTIONS
import OrderFunctions from "../../functions/Order.Functions";

//COMPONENTS
import Address from "./elements/Address";
import Giftcard from "./elements/Giftcard";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import PopupWithRedirect from "../../../../layout/popups/PopupWithRedirect";
import StageTimeline from "./elements/StageTimeline";
import FlexArea from "../../../../layout/flex/FlexArea";
import Btn from "../../../../core/buttons/Btn";

function Index(props) {
  const [order, setOrder] = useState(null);
  const [stage, setStage] = useState(null);

  useEffect(() => {
    get_order(props.params?.id);
  }, [props.params?.id]);

  useEffect(() => {
    setStage(order ? OrderFunctions.parse_order_state(order) : null);
  }, [order]);

  async function get_order(id) {
    setOrder(await Orders.get_order({ id }));
  }

  return (
    <PopupWithRedirect
      id="Order"
      className="mainPadding"
      title={"Track Order"}
      children={
        <div>
          {order ? (
            <div>
              <FlexGrid
                style={{ justifyContent: "start" }}
                children={[
                  {
                    style: { flex: "1 0 50%" },
                    element:
                      order.order_type == "deliver" ? (
                        <h2
                          style={{ margin: "0", color: "var(--FunctionGreen)" }}
                        >
                          Shipping Order
                        </h2>
                      ) : (
                        <h2 style={{ margin: "auto", color: "var(--crypto)" }}>
                          Giftcard Order
                        </h2>
                      ),
                  },
                  {
                    style: { flex: "1 0 50%" },
                    element: (
                      <FlexArea
                        children={
                          <div
                            style={{
                              position: "relative",
                              margin: "auto",
                              marginRight: "0",
                            }}
                          >
                            <span>ID: {order.id}</span>
                            <Link to={"/contact"}>
                              <Btn
                                style={{
                                  background: "var(--error)",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                  border: "1px solid var(--error)",
                                  fontSize: "0.8rem",
                                }}
                                context={"Contact Us"}
                              />
                            </Link>
                          </div>
                        }
                      />
                    ),
                  },
                  {
                    style: {
                      flex: "1 0 100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    },
                    element:
                      order.status !== "refunded" ? (
                        order.order_type == "deliver" ? (
                          Object.keys(
                            OrderFunctions.getArrivingGroups(order.products)
                          ).length ? (
                            Object.keys(
                              OrderFunctions.getArrivingGroups(order.products)
                            ).map((x, i) => (
                              <div>
                                <StageTimeline
                                  order={order}
                                  current_stage={
                                    OrderFunctions.parse_order_state(
                                      Object.assign({}, order, {
                                        products:
                                          OrderFunctions.getArrivingGroups(
                                            order.products
                                          )[x],
                                      })
                                    ).status
                                  }
                                  products={
                                    OrderFunctions.getArrivingGroups(
                                      order.products
                                    )[x]
                                  }
                                  stages={[
                                    {
                                      name: "deliver_review",
                                      title: "in Review",
                                      image: "review.svg",
                                    },
                                    {
                                      name: "deliver_confirmed",
                                      title: "Confirmed",
                                      image: "tick.svg",
                                    },
                                    {
                                      name: "deliver_shipped",
                                      title: "Arriving Today",
                                      image: "shipped.svg",
                                    },
                                    {
                                      name: "deliver_completed",
                                      title: "Completed",
                                      image: "delivered.svg",
                                    },
                                  ]}
                                />
                              </div>
                            ))
                          ) : (
                            <StageTimeline
                              current_stage={stage.status}
                              products={order.products}
                              stages={[
                                {
                                  name: "deliver_in_review",
                                  title: "in Review",
                                  image: "review.svg",
                                },
                                {
                                  name: "deliver_confirmed",
                                  title: "Confirmed",
                                  image: "tick.svg",
                                },
                                {
                                  name: "deliver_shipped",
                                  title: "Arriving Today",
                                  image: "shipped.svg",
                                },
                                {
                                  name: "deliver_completed",
                                  title: "Completed",
                                  image: "delivered.svg",
                                },
                              ]}
                            />
                          )
                        ) : (
                          <StageTimeline
                            order={order}
                            current_stage={stage?.status}
                            products={order.products}
                            stages={[
                              {
                                name: "giftcard_review",
                                title: "in Review",
                                image: "review.svg",
                              },
                              {
                                name: "giftcard_confirmed",
                                title: "Email Sent",
                                image: "tick.svg",
                              },
                            ]}
                          />
                        )
                      ) : (
                        <StageTimeline
                          order={order}
                          current_stage={stage?.status}
                          products={order.products}
                          stages={[
                            {
                              name: "review",
                              title: "in Review",
                              image: "review.svg",
                            },
                            {
                              name: "refunded",
                              title: "Refunded",
                              image: "tick.svg",
                            },
                          ]}
                        />
                      ),
                  },
                  {
                    style: { flex: "1 0 50%", padding: "5px" },
                    element: (
                      <div
                        className="highlighted"
                        style={{
                          borderTopLeftRadius: "0",
                          border:
                            "1px solid " +
                            (order.order_type == "deliver"
                              ? "var(--FunctionGreen)"
                              : "var(--crypto)"),
                        }}
                      >
                        {(() => {
                          switch (order.order_type) {
                            case "deliver":
                              return <Address address={order.method} />;
                            case "withdraw":
                              return <div></div>;
                            case "giftcard":
                              return (
                                <Giftcard
                                  giftcard={order.method}
                                  value={order.products.reduce(
                                    (prev, next) => prev + next.price,
                                    0
                                  )}
                                />
                              );
                          }
                        })()}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ) : null}
        </div>
      }
    />
  );
}

export default Index;
