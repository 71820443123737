import API from "../../index";

//FUNCTIONS
import URLFunctions from "../../../../common/functions/URL.functions";

export default async (obj) => {
  const { data, status } = await API.get(
    URLFunctions.parse_with_params("/api/affiliate/code_usage", [
      { name: "query", value: obj.query },
      {
        name: "order_by",
        value: obj.order_by,
      },
      {
        name: "offset",
        value: obj.offset,
      },
      {
        name: "limit",
        value: obj.limit,
      },
    ])
  );

  return status == 201 ? data : null;
};
