import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function Index(props) {
  const current_route = useLocation().pathname;
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);

  console.log(current_route);

  if (!session) {
    //dispatch({ type: "SET_LAST_ROUTE", last_route: current_route });
    return <Navigate to="/login" />;
  } else {
    return props.children;
  }
}

export default Index;
