import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Document from "../../components/content/Document";

export default (props) => {
  return (
    <PageLayout
      title={"Terms & Conditions"}
      children={
        <div className="mainPadding">
          <Helmet>
            <title>T&Cs | Vivavim</title>
            <meta name="description" content="Vivavim's terms and conditions" />
            <link rel="canonical" href={envConfig.server + "/T&Cs"} />
          </Helmet>
          <Document src={envConfig.s3 + "/documents/Terms&Conditions.html"} />
        </div>
      }
    />
  );
};
