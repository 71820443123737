import store from "../../redux/store";

//POPUPS
import Transaction from "../../../components/content/account/Transactions/popups/Transaction";

export default (obj) => {
  const balance = store.getState().account_balance_reducer;
  store.dispatch({
    type: "UPDATE_ACCOUNT_BALANCE",
    account_balance: balance + obj.amount,
  });

  store.dispatch({
    type: "NEW_POPUP",
    element: <Transaction params={{ id: obj.id }} />,
  });
};
