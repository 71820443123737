import React, { Component, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//API
import Unbox from "../../../../common/api/Unbox";

//FUNCTIONS
import Cookies from "../../../../common/Cookies";
import GeneralFunctions from "../functions/General.functions";
import UnboxingFunctions from "../functions/Unboxing.functions";

//COMPONENTS
import FlexGrid from "../../../layout/flex/FlexGrid";
import Btn from "../../../core/buttons/Btn";
import PlayBtn from "./elements/PlayBtn";
import ProvFairBtn from "../../provablyFair/ProvFairBtn";
import Checkbox from "../../../core/inputs/Checkbox";
import SeedSetter from "../../provablyFair/SeedSetter";
import SoundControls from "../../../common/Audio/SoundControls";
import FlexArea from "../../../layout/flex/FlexArea";
import Background from "../../../common/backgrounds/Background";

function Index(props) {
  const dispatch = useDispatch();
  const muted = useSelector((state) => state.audio_reducer).muted;
  const unboxing = useSelector((state) => state.unboxing_reducer);
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);
  let cost = UnboxingFunctions.calculate_crate_info(
    crate_inventory.products
  ).cost;

  return (
    <div id="UnboxControls">
      <Background
        style={{
          background: "var(--background)",
          opacity: "0.6",
        }}
      />
      <div className="inner mainPadding">
        <FlexGrid
          children={[
            {
              style: { flex: "1 0 100%", marginBottom: "10px" },
              element: (
                <FlexArea
                  children={
                    <h1 style={{ margin: " auto", marginLeft: "0" }}>
                      {unescape(crate_inventory?.crate?.name || "Make a Crate")}
                    </h1>
                  }
                />
              ),
            },
            {
              style: { flex: "1 0 50%" },
              element: (
                <div>
                  <div>
                    <FlexGrid
                      style={{ justifyContent: "start" }}
                      children={[
                        {
                          style: {
                            flex: "1 0 50%",
                            padding: "5px",
                            boxSizing: "border-box",
                            minWidth: "250px",
                          },
                          element: (
                            <PlayBtn
                              type={props.type}
                              crate={crate_inventory.crate}
                              cost={cost}
                              add_unboxing={GeneralFunctions.unbox_crate}
                            />
                          ),
                        },
                        {
                          style: {
                            flex: "1 0 50%",
                            padding: "5px",
                            boxSizing: "border-box",

                            minWidth: "250px",
                          },
                          element: (
                            <Btn
                              id="testBtn"
                              style={{
                                fontSize: "1.2rem",
                                width: "100%",
                                height: "100%",
                              }}
                              onClick={() => GeneralFunctions.test_unbox()}
                              context={"Test Spin"}
                            />
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <FlexGrid
            style={{ justifyContent: "start" }}
            children={[
              {
                style: { marginRight: "auto", padding: "5px" },
                element: (
                  <FlexArea
                    children={
                      <ProvFairBtn
                        style={{ margin: "auto" }}
                        context={"Provably Fair"}
                        onClick={() =>
                          dispatch({
                            type: "NEW_POPUP",
                            element: <SeedSetter />,
                          })
                        }
                      />
                    }
                  />
                ),
              },
              {
                style: {
                  marginLeft: "10px",
                  padding: "5px",
                  paddingRight: "0",
                },
                element: (
                  <FlexArea
                    children={
                      <div style={{ margin: "auto" }}>
                        <span style={{ textShadow: "0 0 10px black" }}>
                          Fast Unbox
                        </span>
                        <Checkbox
                          style={{ width: "15px", height: "15px" }}
                          checked={unboxing.fast_unbox}
                          onChange={() =>
                            dispatch({
                              type: "POPULATE_UNBOXING",
                              object: { fast_unbox: !unboxing.fast_unbox },
                            })
                          }
                        />
                      </div>
                    }
                  />
                ),
              },
              {
                style: { padding: "5px", paddingLeft: "0" },
                element: (
                  <FlexArea
                    children={
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          margin: "auto",
                          marginRight: "0",
                        }}
                      >
                        <SoundControls />
                      </div>
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Index;
