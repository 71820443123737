import API from "../../index";

export default async (obj) => {
  const { data, status } = await API.patch(
    "/api/auth/password/forgot/confirm",
    obj
  );

  return status == 200 ? true : false;
};
