import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import ProductRank from "../../../../products/ProductRank";
import ProvFairBtn from "../../../../provablyFair/ProvFairBtn";
import RollChecker from "../../../../provablyFair/RollChecker";

function Index(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div
      style={{ minWidth: "100%", marginBottom: "15px", left: "0" }}
      className="listElement"
      /*onClick={() => navigate("/unboxing/" + props.obj.id)}*/
    >
      <Link to={"/unboxing/" + props.obj.id}>
        <FlexGrid
          style={{ flexFlow: "initial" }}
          children={[
            {
              style: {
                paddingRight: "10px",
                flexWrap: "nowrap",
                minWidth: "100px",
              },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <ProductRank product={props.obj} />
                  </div>
                </section>
              ),
            },
            {
              style: { flex: "1 0 180px", minWidth: "180px" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <img
                        style={{
                          verticalAlign: "middle",
                          width: "25px",
                          marginRight: "5px",
                        }}
                        src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          verticalAlign: "middle",
                        }}
                      >
                        {props.obj.price}
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      <span
                        style={{
                          fontWeight: "normal",
                          fontSize: "0.8rem",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          paddingRight: "5px",
                        }}
                      >
                        {unescape(props.obj.name).length > 80
                          ? unescape(props.obj.name).substr(0, 80) + "..."
                          : unescape(props.obj.name)}
                      </span>
                    </div>
                  </div>
                </section>
              ),
            },

            {
              style: {
                flex: "1 0 200px",
                minWidth: "200px",
                paddingRight: "5px",
              },
              element: (
                <section
                  style={{
                    display: "flex",
                    height: "100%",
                    borderRadius: "8px",
                    backgroundImage: props.obj.crate_id
                      ? "url(" +
                        envConfig.s3 +
                        "/images/crates2/images/" +
                        props.obj.crate_id +
                        ".webp)"
                      : "url(" +
                        envConfig.s3 +
                        "/images/crates2/banner/banner2.webp)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    boxShadow: "0 0 10px rgb(12, 12, 12)",
                  }}
                >
                  <FlexGrid
                    style={{ margin: "auto", height: "100%" }}
                    children={[
                      {
                        style: { flex: "1 0 50%" },
                        element: (
                          <div style={{ display: "flex", height: "100%" }}>
                            <div
                              style={{
                                display: "inline-block",
                                margin: "auto",
                                marginLeft: "0",
                                textAlign: "left",
                                width: "100%",
                                boxSizing: "border-box",
                                marginRight: "5px",
                                marginLeft: "5px",
                                height: "80%",
                                borderRadius: "8px",
                              }}
                            >
                              <div>
                                {props.obj.cost ? (
                                  <div>
                                    <img
                                      style={{
                                        verticalAlign: "middle",
                                        width: "30px",
                                        marginRight: "5px",
                                      }}
                                      src={
                                        envConfig.s3 +
                                        "/images/icons/common/vivacoin.svg"
                                      }
                                    />
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "1.2rem",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {props.obj.cost}
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <span
                                      style={{
                                        verticalAlign: "middle",
                                        fontWeight: "bold",
                                        color: "var(--free)",
                                      }}
                                    >
                                      FREE
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {unescape(props.obj.crate_name)}
                                </span>
                              </div>
                            </div>
                          </div>
                        ),
                      },
                    ]}
                  />
                </section>
              ),
            },
            {
              style: { flex: "1 0 100px", minWidth: "100px" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        verticalAlign: "middle",
                      }}
                    >
                      <span>Roll: </span>
                      <span style={{ color: "var(--FunctionGreen)" }}>
                        {props.obj.roll}
                      </span>
                    </span>
                    <div style={{ marginTop: "5px" }}>
                      <ProvFairBtn
                        context={"Verify Roll"}
                        onClick={() =>
                          dispatch({
                            type: "NEW_POPUP",
                            element: <RollChecker unboxing_id={props.obj.id} />,
                          })
                        }
                      />
                    </div>
                  </div>
                </section>
              ),
            },
            {
              style: { flex: "1 0 100px", minWidth: "100px" },
              element: (
                <section style={{ display: "flex", height: "100%" }}>
                  <div
                    style={{
                      display: "inline-block",
                      margin: "auto",
                      marginLeft: "0",
                      margin: "auto",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      {(() => {
                        switch (props.obj.product_status) {
                          case "inventory":
                            return <span>{props.obj.product_status}</span>;
                          case "ordered":
                            return (
                              <span style={{ color: "var(--FunctionGreen)" }}>
                                {props.obj.product_status}
                              </span>
                            );
                          case "converted":
                            return (
                              <span style={{ color: "gold" }}>
                                {props.obj.product_status}
                              </span>
                            );
                        }
                      })()}
                    </span>
                  </div>
                </section>
              ),
            },
          ]}
        />
      </Link>
    </div>
  );
}

export default Index;
