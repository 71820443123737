import React from "react";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import ProductList from "../../../elements/ProductList";
import Confirm from "../Confirm";
import Background from "../../../../../../common/backgrounds/Background";
import SuccessBtn from "../../../../../../core/buttons/SuccessBtn";
import Warning from "../Warning";

export default (props) => {
  const account_balance = useSelector((state) => state.account_balance_reducer);

  const checkout_order =
    props.method == "deliver" &&
    account_balance < props.price_changes + props.delivery_cost
      ? true
      : false;
  return (
    <div>
      <div id="InfoPanel">
        <Background
          style={{
            background: "var(--highlighted)",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        />
        <ProductList
          products={props.products}
          order_type={props.method}
          warning={false}
          can_add={true}
        />
        <Confirm
          price_changes={props.price_changes}
          delivery_cost={props.delivery_cost}
          method={props.method}
        />
        {props.can_confirm && checkout_order ? (
          <div
            className="warning"
            style={{
              position: "relative",
              border: "1px dashed var(--warning)",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Background
              style={{
                background: "var(--warning)",
                opacity: "0.1",
                borderRadius: "8px",
              }}
            />
            <div style={{ position: "relative" }}>
              <img
                className="icon"
                src={envConfig.s3 + "/images/icons/common/warning.svg"}
              />
              <h3
                style={{
                  position: "relative",
                  fontWeight: "bold",
                  verticalAlign: "middle",
                  display: "inline-block",
                  margin: "0",
                }}
              >
                Insufficient Balance
              </h3>
              <div>
                <span style={{ fontSize: "0.8rem" }}>
                  You have insufficient balance to complete this order. Click
                  below to complete it via the checkout page.
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {props.can_confirm ? (
        <SuccessBtn
          style={{
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            width: "100%",
          }}
          context={checkout_order ? "Pay with Checkout" : "Confirm Order"}
          onClick={props.create_order}
        />
      ) : null}
    </div>
  );
};
