const page_content_reducer = (
  state = {
    page: null,
    data: [],
    page_limit: null,
    offset: 0,
    max: null,
    order_by: null,
    selected: [],
    loading: false,
    show_selected: false,
  },
  action
) => {
  switch (action.type) {
    /*---SET CONTENT---*/
    case "POPULATE_PAGE_CONTENT":
      return Object.assign(
        {},
        {
          page: null,
          data: [],
          page_limit: null,
          offset: 0,
          max: null,
          order_by: null,
          selected: [],
          loading: false,
          show_selected: false,
        },
        action.content
      );
    case "UPDATE_PAGE_CONTENT":
      return Object.assign({}, state, action.content);

    /*---CONTENT ELEMENT CONTROLS---*/
    case "ADD_PAGE_CONTENT_ELEMENT":
      return Object.assign({}, state, {
        data:
          state.order_by == null
            ? [action.element, ...state.data.slice(0, state.page_limit - 1)]
            : state.data,
      });

    case "UPDATE_PAGE_CONTENT_ELEMENT":
      return Object.assign({}, state, {
        data: state.data.map((el) => {
          return el.id === action.element.id
            ? Object.assign({}, el, action.element)
            : el;
        }),
      });

    case "DELETE_PAGE_CONTENT_ELEMENTS":
      return Object.assign({}, state, {
        data: state.data.filter((el) => {
          return !action.elements.find((y) => {
            return el.id == y;
          });
        }),
        max: state.max - action.elements.length,
      });

    case "ADD_PAGE_CONTENT_SELECTED":
      return Object.assign({}, state, {
        selected: [
          ...action.selected.filter(
            (x) => !state.selected.find((y) => x.id == y.id)
          ),
          ...state.selected,
        ],
      });
      break;
    case "REMOVE_PAGE_CONTENT_SELECTED":
      return Object.assign({}, state, {
        selected: state.selected.filter((el) => {
          return !action.elements.find((y) => {
            return el.id == y;
          });
        }),
      });
    case "CLEAR_PAGE_CONTENT_SELECTED":
      return Object.assign({}, state, {
        selected: [],
      });
    default:
      return state;
  }
};

export default page_content_reducer;
