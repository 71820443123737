import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//STYLES
import "./style.css";

//COMPONENTS
import Btn from "../Btn";

export default (props) => {
  const last_route = useSelector((state) => state.last_route_reducer);
  return (
    <div style={{ textAlign: "left" }}>
      <Link to={props.route || last_route}>
        <Btn
          {...props}
          id="BackBtn"
          children={
            <div>
              <i class="arrow" />
              <span>Back</span>
            </div>
          }
        />
      </Link>
    </div>
  );
};
