import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//FUNCTIONS
import getProductRank from "../../../../common/functions/getProductRank";

//STYLES
import "./style.css";

//COMPONENTS
import Product from "../Product";
import Background from "../../../common/backgrounds/Background";
import Textbox from "../../../core/inputs/text/Textbox";
import FlexGrid from "../../../layout/flex/FlexGrid";

export default (props) => {
  const dispatch = useDispatch();

  const [probTxt, setProbTxt] = useState(props.product.probability + "%");

  useEffect(() => {
    setProbTxt(props.product.probability + "%");
  }, [props.product.probability]);

  function onChangeProb({ target }) {
    setProbTxt(target.value);
  }

  function onConfirmChangeProb() {
    setProbTxt(probTxt.replace("%", "") + "%");

    let product = Object.assign({}, props.product, {
      probability: parseFloat(probTxt),
    });

    props.onChange(product);
  }

  function onEnterPress(e) {
    if (e.key == "Enter") {
      onConfirmChangeProb();
    }
  }

  return (
    <Product
      className="CrateProduct"
      product={props.product}
      onClick={() => {}}
      onSuccessProduct={() => dispatch({ type: "CLOSE_POPUP" })}
      context={"Continue"}
      children={
        <div id="probabilityWrapper">
          <Background
            style={{ background: "var(--background)", opacity: "0.3" }}
          />
          <div className="TxtWrapper">
            {!props.is_editing ? (
              <p className="probTxt">{props.product.probability + "%"}</p>
            ) : (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  padding: "5px",
                  boxSizing: "border-box",
                }}
              >
                <FlexGrid
                  children={[
                    {
                      style: { flex: "1" },
                      element: (
                        <div style={{ position: "relative" }}>
                          <Background
                            style={{
                              background: "var(--highlighted)",
                              opacity: "1",
                            }}
                          />
                          <Textbox
                            style={{
                              position: "relative",
                              color: "white",
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              borderRadius: "0",
                              background: "transparent",
                              border: "0",
                              boxShadow: "0",
                              fontFamily: "Orbitron",
                            }}
                            value={probTxt}
                            onChange={(e) => setProbTxt(e.target.value)}
                            onFocus={(e) =>
                              setProbTxt(e.target.value.replace("%", ""))
                            }
                            onBlur={onConfirmChangeProb}
                            onKeyDown={onEnterPress}
                          />
                        </div>
                      ),
                    },
                    {
                      style: { minWidth: "30px" },
                      element: props.is_editing ? (
                        <div
                          className="unboxingProductRemoveBtn"
                          onClick={() =>
                            !props.animation_lock
                              ? props.remove_unboxing_product(props.product)
                              : null
                          }
                        ></div>
                      ) : null,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};
