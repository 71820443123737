import React, { useState, useEffect } from "react";

//CONFIG
import envConfig from "../../../common/config/env.config";

//FUNCTIONS
import CoundownFunctions from "../../../common/functions/Countdown.Functions";
import GeneralFunctions from "../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

function Index(props) {
  const [saleTime, setSaleTime] = useState(null);

  useEffect(() => {
    if (props.sale) {
      //CoundownFunctions().start(props.sale.ends, setSaleTime, () => {});
    }
  }, []);

  return (
    <div
      id="Price"
      style={Object.assign({}, { fontSize: "1rem" }, props.style)}
      {...props}
    >
      {props.sale ? (
        <span
          style={{
            position: "absolute",
            bottom: "100%",
            left: "0",
            fontSize: "0.7rem",
            color: "var(--error)",
          }}
        >
          {saleTime}
        </span>
      ) : null}
      {props.price !== 0 ? (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            textDecoration: props.sale_price ? "line-through" : null,
          }}
        >
          <img
            src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
            style={{
              height: props.imgSize || "1.1rem",
              marginRight: "5px",
              verticalAlign: "middle",
            }}
          />
          <span style={{ verticalAlign: "middle" }}>
            {GeneralFunctions.parse_currency(props.price)}
          </span>
        </div>
      ) : (
        <div style={{ position: "relative", display: "inline-block" }}>
          <span
            style={{
              verticalAlign: "middle",
              fontWeight: "bold",
              color: "var(--FunctionGreen)",
            }}
          >
            FREE
          </span>
        </div>
      )}
      {props.sale ? (
        <div
          style={{
            position: "relative",
            display: "inline-block",
            marginLeft: "5px",
            color: "var(--FunctionGreen)",
          }}
        >
          <span
            style={{
              position: "absolute",
              bottom: "100%",
              right: "0",
              fontSize: "0.7rem",
              color: "white",
            }}
          >
            Now
          </span>
          <img
            src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
            style={{
              height: "1.3rem",
              marginRight: "5px",
              verticalAlign: "middle",
            }}
          />
          <span
            style={{
              position: "relative",
              verticalAlign: "middle",
            }}
          >
            {GeneralFunctions.parse_currency(props.sale.price)}
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default Index;
