import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Manage from "../../components/content/account/Manage";

export default (props) => {
  return (
    <PageLayout
      title={"My Account"}
      children={
        <div id="Account" className="mainPadding">
          <Helmet>
            <title>Account</title>
            <meta name="description" content="Manage your account on vivavim" />
            <link rel="canonical" href={envConfig.server + "/account"} />
          </Helmet>
          <Manage {...props} />
        </div>
      }
    />
  );
};
