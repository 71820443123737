import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import ContentList from "../ContentList";

export default (props) => (
  <ContentList
    {...props}
    className={"ContentGrid " + (props.className || "").trim()}
  />
);
