import React, { useState, useEffect } from "react";
let API_KEY = "AIzaSyAXovboLTh2NEf6dq5qV08alyDMZsFnAY4&";

function Index(props) {
  const [parseAddress, setParseAddress] = useState(null);

  useEffect(() => {
    if (props.address) {
      setParseAddress(
        unescape(props.address.street_address) +
          ", " +
          unescape(props.address.city) +
          ", " +
          unescape(props.address.state) +
          ", " +
          unescape(props.address.zip) +
          ", " +
          props.address.country
      );
    }
  }, [props.address]);

  return parseAddress ? (
    <iframe
      frameborder="0"
      style={{
        border: "0",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        background: "var(--background)",
      }}
      src={
        "https://www.google.com/maps/embed/v1/place?key=" +
        API_KEY +
        "q=" +
        parseAddress
      }
      allowfullscreen
    />
  ) : (
    <iframe
      frameborder="0"
      style={{
        border: "0",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        background: "var(--background)",
      }}
      src={
        "https://www.google.com/maps/embed/v1/place?key=" +
        API_KEY +
        "q=" +
        "country=USA"
      }
      allowfullscreen
    />
  );
}

export default Index;
