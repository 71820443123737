import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../../../common/config/env.config";

//COMPONENTS
import Btn from "../../../../../../../../core/buttons/Btn";
import AddressManagement from "../../../../../../../account2/Addresses/AddressManagement";
import AddressFormat from "../../../../../../../account2/Addresses/AddressFormat";
import AddressOption from "./AddressOption";
import FlexGrid from "../../../../../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../../../../../layout/flex/FlexArea";
import Icon from "../../../../../../../../core/Icon";

export default (props) => {
  const dispatch = useDispatch();

  var addressOptions = [];

  if (props?.address) {
    addressOptions.push(props.address);
  }

  if (props?.addresses?.length) {
    addressOptions = [
      ...addressOptions,
      ...props.addresses.filter((x) => x.id !== props?.address?.id),
    ];
  }

  return (
    <div
      className="highlighted"
      style={{
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        background: "var(--secondaryBackground)",
        border: "1px solid var(--FunctionGreen)",
        borderTop: "0",
      }}
    >
      <FlexGrid
        style={{ marginBottom: "10px", justifyContent: "start" }}
        children={[
          {
            element: (
              <FlexArea
                children={<h4 style={{ margin: "auto" }}>Shipping Address</h4>}
              />
            ),
          },
          {
            style: { flex: "1" },
            element: (
              <FlexArea
                children={
                  props.can_edit ? (
                    <Btn
                      context={"View All"}
                      style={{
                        background: "var(--background)",
                        border: "2px solid var(--FunctionGreen)",
                        padding: "5px 10px 5px 10px",
                        margin: "auto",
                        marginRight: "0",
                      }}
                      onClick={() =>
                        dispatch({
                          type: "NEW_POPUP",
                          element: (
                            <AddressManagement
                              selected={props.address}
                              onSuccess={props.setAddress}
                            />
                          ),
                        })
                      }
                    />
                  ) : null
                }
              />
            ),
          },
        ]}
      />

      <div className="highlighted" style={{ border: "0" }}>
        <FlexGrid
          style={{ alignItems: "stretch" }}
          children={[
            {
              style: {
                flex: "1 1 50%",
                maxWidth: "250px",
                padding: "5px",
                padding: "5px",
              },
              element: (
                <div
                  className="AddressOption add"
                  onClick={() =>
                    dispatch({
                      type: "NEW_POPUP",
                      element: (
                        <AddressManagement
                          selected={props.address}
                          onSuccess={props.setAddress}
                        />
                      ),
                    })
                  }
                >
                  <FlexArea
                    children={
                      <span style={{ margin: "auto", fontSize: "0.8rem" }}>
                        <Icon
                          src={envConfig.s3 + "/images/icons/plus.svg"}
                          style={{ width: "15px", marginRight: "5px" }}
                        />
                        <span style={{ verticalAlign: "middle" }}>
                          Add Address
                        </span>
                      </span>
                    }
                  />
                </div>
              ),
            },
            ...(addressOptions.length
              ? addressOptions.map(
                  (x) =>
                    new Object({
                      style: {
                        flex: "1 1 50%",
                        maxWidth: "250px",
                        padding: "5px",
                      },
                      element: (
                        <AddressOption
                          selected={props.address?.id === x.id}
                          address={x.address}
                          setAddress={() => props.setAddress(x)}
                        />
                      ),
                    })
                )
              : []),
          ]}
        />
      </div>
      {!props.address ? (
        <div style={{ marginTop: "10px" }}>
          <span
            style={{
              color: "var(--error)",
              fontWeight: "bold",
            }}
          >
            No Address Set
          </span>
        </div>
      ) : null}
    </div>
  );
};
