import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Form from "../../../../core/Form";
import Textbox from "../../../../core/inputs/text/Textbox";
import Dropdown from "../../../../core/Dropdown";
import FlexGrid from "../../../../layout/flex/FlexGrid";
import SuccessBtn from "../../../../core/buttons/SuccessBtn";

import Layout from "../../../payment/stripe/components/Layout";
import CheckoutForm from "../../../payment/stripe/components/CheckoutForm";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  return (
    <div id="Card">
      <Layout title="Vivacoin Checkout">
        <CheckoutForm
          price={props.checkout.value}
          checkout={props.checkout}
          order={props.order}
          onSuccessfulCheckout={() => {}}
        />
      </Layout>
      <div>
        <FlexGrid
          style={{ justifyContent: "center" }}
          children={[
            {
              style: { height: "70px" },
              element: (
                <img
                  src={envConfig.s3 + "/images/icons/checkout/stripe.webp"}
                  style={{ height: "100%" }}
                />
              ),
            },
            {
              element: (
                <FlexArea
                  children={
                    <img
                      src={envConfig.s3 + "/images/icons/ssl.svg"}
                      style={{ height: "60px", marginTop: "auto" }}
                    />
                  }
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
