import React from "react";

//STYLES
import "./style.css";

function Index(props) {
  return (
    <div className="Banner" {...props}>
      {props.children}
    </div>
  );
}

export default Index;
