import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//STYLES
import "./style.css";

//FUNCTIONS
import getProductRank from "../../../../../../common/functions/getProductRank";
import getVivacoinRank from "../../../../../../common/functions/getVivacoinRank";

//COMPONENTS
import ProductRank from "../../../../products/ProductRank";
import FlexArea from "../../../../../layout/flex/FlexArea";

function Index(props) {
  const dispatch = useDispatch();
  const carousel = useSelector((state) => state.unboxing_carousel_reducer);
  const [visible, setVisible] = useState(true);
  const [product, setProduct] = useState(null);

  const carousel_segments = useSelector(
    (state) => state.carousel_segments_reducer
  );

  useEffect(() => {
    setProduct(carousel_segments[props.index].product);
  }, [carousel_segments[props.index]]);

  return product ? (
    <div
      className="segment"
      key={props.index + product.id}
      style={{
        top: props.style.top,
        left: props.style.left,
        height: props.size,
        width: props.size,
        transform: props.style.transform,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      {(() => {
        switch (product.type) {
          case "ITEM":
            return (
              <div
                className="background"
                style={{
                  backgroundImage:
                    "url(" +
                    envConfig.s3 +
                    "/images/unbox/carousel/" +
                    getProductRank(product.price) +
                    ".svg)",
                }}
              >
                <FlexArea
                  style={{ transform: "rotate(180deg)" }}
                  children={
                    <div className="image">
                      <ProductRank product={product} background={false} />
                    </div>
                  }
                />
              </div>
            );
          case "COIN":
            return (
              <div
                className="background"
                style={{
                  backgroundImage:
                    "url(" +
                    envConfig.s3 +
                    "/images/unbox/carousel/" +
                    getProductRank(product.price) +
                    "_vivacoin.svg)",
                }}
              >
                <FlexArea
                  children={
                    <div
                      className="image"
                      style={{
                        width: "130px",
                        height: "130px",
                        transform: "rotate(180deg)",
                      }}
                    >
                      <ProductRank product={product} background={false} />
                    </div>
                  }
                />
                <div className="coinPrice">
                  <FlexArea
                    children={
                      <span style={{ margin: "auto" }}>{product.price}</span>
                    }
                  />
                </div>
              </div>
            );
        }
      })()}
    </div>
  ) : null;
}

export default Index;
