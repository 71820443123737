const crate_inventory_reducer = (
  state = { crate: null, products: [], isCustom: false },
  action
) => {
  switch (action.type) {
    case "POPULATE_CRATE_INVENTORY":
      return {
        crate: action.crate_inventory.crate,
        products: action.crate_inventory.products,
        isCustom:
          action.crate_inventory.isCustom !== undefined
            ? action.crate_inventory.isCustom
            : false,
      };
    case "CLEAR_CRATE_INVENTORY":
      return { crate: null, products: [], isCustom: false };
    case "EXIT_CRATE":
      return {
        crate: null,
        products: state.products,
        isCustom: false,
      };
    case "UPDATE_CRATE_PRODUCT":
      return {
        crate: Object.assign({}, state.crate, {
          products: state.crate.products.map((product) => {
            return product.id == action.updated_product.id
              ? Object.assign({}, product, action.updated_product)
              : product;
          }),
        }),
        products: state.products.map((product) => {
          return product.id == action.updated_product.id
            ? Object.assign({}, product, action.updated_product)
            : product;
        }),
        isCustom: true,
      };
    case "CHANGE_UNBOXING_PRODUCT_PROB":
      return {
        crate: state.crate,
        products: state.products.map((product) => {
          return product.id == action.product.id
            ? Object.assign({}, product, action.product)
            : product;
        }),
        isCustom: true,
      };
    case "REMOVE_UNBOXING_PRODUCT":
      return {
        crate: null,
        products: state.products.filter((product) => {
          return product.id !== action.product.id;
        }),
        isCustom: true,
      };
    case "ADD_UNBOXING_PRODUCT":
      return {
        crate: state.crate,
        products: [action.product, ...state.products].sort((a, b) => {
          return a.price > b.price ? -1 : 1;
        }),
        isCustom: true,
      };
    case "SET_UNBOXING_PRODUCTS":
      return {
        crate: null,
        products: [...action.products].sort((a, b) => {
          return a.price > b.price ? -1 : 1;
        }),
        isCustom: true,
      };
    case "ADD_CRATE_UNBOXING":
      return {
        crate:
          state.crate && state.crate.id == action.unboxing.crate_id
            ? Object.assign({}, state.crate, {
                last_unboxings: [
                  action.unboxing,
                  ...state.crate.last_unboxings.slice(0, 29),
                ],
              })
            : state.crate,
        products: state.products,
        isCustom: false,
      };
    case "UPDATE_CRATE":
      return state.crate
        ? {
            crate: Object.assign({}, state.crate, action.crate),
            products: state.products,
            isCustom: true,
          }
        : state;
    default:
      return state;
  }
};

export default crate_inventory_reducer;
