import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import Win from "./elements/Win";
import Background from "../../../../../common/backgrounds/Background";

export default (props) => {
  return (
    <div id="BiggestWins">
      <FlexGrid
        children={props.wins.map(
          (x, i) =>
            new Object({
              className: ["gold", "silver", "bronze"][i],
              element: (
                <Win
                  win={x}
                  image={
                    ["goldcrown.webp", "silvercrown.webp", "bronzecrown.webp"][
                      i
                    ]
                  }
                />
              ),
            })
        )}
      />
    </div>
  );
};
