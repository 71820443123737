import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//API
import Transactions from "../../../common/api/Transactions";

//FUNCTIONS
import GeneralFunctions from "../unbox/functions/General.functions";
import URLFunctions from "../../../common/functions/URL.functions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../layout/flex/FlexGrid";
import SelectAmount from "./elements/SelectAmount";
import SelectMethod from "./elements/SelectMethod";
import PromoCode from "./elements/PromoCode";
import Info from "./elements/Info";
import USA_Only from "../../common/banners/USA_Only";
import FlexArea from "../../layout/flex/FlexArea";
import Background from "../../common/backgrounds/Background";
import Select from "./elements/Select";
import Card from "./elements/Card";
import Crypto from "./elements/Crypto";

export default (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = props.params;

  const [checkout, setCheckout] = useState({
    value: parseFloat(props.value || 10),
    method: props.method || "card",
    code: null,
    products: props?.products || null,
  });

  useEffect(() => {
    if (props.code) {
      VerifyCode(props.code);
    }
  }, []);

  async function VerifyCode(code) {
    const codeObj = await await Transactions.verify_code(code);

    if (codeObj.data) {
      setCheckout(
        Object.assign({}, checkout, {
          code: Object.assign({}, codeObj.data, { code }),
        })
      );
    } else {
      setCheckout(Object.assign({}, checkout, { code: null }));
    }
  }

  useEffect(() => {
    setCheckout({
      value: parseFloat(props.value) || checkout.value,
      method: props.method || checkout.method,
      code: checkout.code,
      products: props?.products || checkout?.products || [],
    });

    if (searchParams.code && checkout.code?.code !== searchParams.code) {
      VerifyCode(searchParams.code);
    }
  }, [searchParams]);

  function setProperty(obj) {
    const url_params = Object.assign({}, searchParams, obj);

    navigate(
      URLFunctions.parse_with_params(
        "/checkout",
        Object.keys(url_params).map(
          (x) => new Object({ name: x, value: url_params[x] })
        )
      )
    );
  }

  return (
    <FlexGrid
      children={[
        {
          className: "SelectMethod",
          element: <Select checkout={checkout} setProperty={setProperty} />,
        },
        {
          style: { flex: "1 0 40%" },
          element: <></>,
        },
        {
          style: { flex: "1 0 100%" },
          element: (
            <div
              style={{
                position: "relative",
                border: "2px solid var(--" + checkout.method + ")",
                borderRadius: "8px",
                borderTopLeftRadius: "0",
              }}
            >
              <Background
                style={{
                  background: "var(--" + checkout.method + ")",
                  opacity: "0.2",
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              />
              <FlexGrid
                children={[
                  {
                    style: { flex: "1 0 60%" },
                    element: (
                      <div
                        style={{
                          position: "relative",
                          padding: "10px",
                          height: "100%",
                          boxSizing: "border-box",
                          padding: "10px",
                        }}
                      >
                        <Background
                          style={{
                            background: "var(--background)",
                            opacity: "0.6",
                            borderBottomLeftRadius: "8px",
                          }}
                        />
                        <FlexGrid
                          style={{ height: "100%", flexDirection: "column" }}
                          children={[
                            {
                              style: { marginBottom: "15px" },
                              element: <USA_Only />,
                            },
                            {
                              style: { marginBottom: "15px" },
                              element: (
                                <SelectAmount
                                  checkout={checkout}
                                  setProperty={setProperty}
                                />
                              ),
                            },
                            {
                              style: { marginBottom: "15px" },
                              element: (
                                <PromoCode
                                  checkout={checkout}
                                  setProperty={setProperty}
                                />
                              ),
                            },
                            {
                              style: { flex: "1" },
                              element: (
                                <FlexArea
                                  children={
                                    <div
                                      style={{
                                        marginTop: "auto",
                                        width: "100%",
                                      }}
                                    >
                                      <Info checkout={checkout} />
                                    </div>
                                  }
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    style: {
                      flex: "1 0 40%",
                      padding: "10px",
                      minWidth: "300px",
                    },
                    element: (
                      <FlexArea
                        children={
                          <div style={{ margin: "auto", width: "100%" }}>
                            {checkout.method == "card" ? (
                              <Card checkout={checkout} />
                            ) : (
                              <Crypto checkout={checkout} />
                            )}
                          </div>
                        }
                      />
                    ),
                  },
                  /*{
          style: { flex: "1 0 60%", padding: "10px" },
          element: (
            <FlexGrid
              style={{ flexDirection: "column", height: "100%" }}
              children={[
                { element: <USA_Only /> },
                {
                  style: { marginTop: "20px" },
                  element: (
                    <SelectAmount
                      checkout={checkout}
                      setProperty={setProperty}
                    />
                  ),
                },
                {
                  style: { marginTop: "20px" },
                  element: (
                    <PromoCode
                      checkout={checkout}
                      setProperty={setProperty}
                    />
                  ),
                },
                {
                  style: { marginTop: "auto" },
                  element: (
                    <FlexArea children={<Info checkout={checkout} />} />
                  ),
                },
              ]}
            />
          ),
        },*/
                  /*{
          style: {
            flex: "1 1 100%",
            padding: "10px",
            minWidth: "350px",
          },
          element: (
            <SelectMethod
              checkout={checkout}
              setProperty={setProperty}
              order={location?.state?.order}
              onSuccess={(transaction_id) => {}}
            />
          ),
        },*/
                ]}
              />
            </div>
          ),
        },
      ]}
    />
  );
};
