const popups_reducer = (state = [], action) => {
  switch (action.type) {
    case "NEW_POPUP":
      return action.element?.props?.popup_id
        ? !state.find(
            (x) => x?.props?.popup_id == action.element.props.popup_id
          )
          ? [action.element, ...state]
          : state
        : [action.element, ...state];
    case "CLOSE_POPUP":
      return state.slice(1);
    case "CLEAR_POPUPS":
      return [];
    default:
      return state;
  }
};

export default popups_reducer;
