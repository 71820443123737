import { useSelector } from "react-redux";

export default (props) => {
  const popups = useSelector((state) => state.popups_reducer);

  let pop_els = [];

  for (var i = popups.length; i >= 0; i--) {
    pop_els.push(popups[i]);
  }

  return pop_els;
};
