import React from "react";
import styled from "@emotion/styled";

const Row = styled.div`
  width: 98%;
  max-width: 475px;
  margin: 10px auto;
  border-radius: 4px;
  position: relative;
  border: 0;
`;

export default Row;
