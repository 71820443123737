import React, { useState, useEffect } from "react";

//STYLES
import "./style.css";

//COMPONENTS
import HomeBar from "../../common/HomeBar";
import PageTop from "../PageTop";
import BottomBar from "../../common/BottomBar";
import Background from "../../common/backgrounds/Background";
import TabTitle from "../TabTitle";
import LiveWins from "../../content/LiveWins2";
import FlexGrid from "../flex/FlexGrid";
import PopupDisplay from "../../common/PopupDisplay";

function Index(props) {
  return (
    <div id="PageLayout">
      <div id="content">
        <FlexGrid
          children={[
            {
              style: { flex: "1" },
              element: (
                <div>
                  <HomeBar />
                  <div
                    style={{
                      marginTop: "-65px",
                      paddingTop: "var(--topOffset)",
                    }}
                  >
                    <div id="middle">
                      {
                        <FlexGrid
                          children={[
                            { style: {}, element: <LiveWins /> },
                            {
                              style: { flex: "1" },
                              element: (
                                <div
                                  id="PAGE_SCROLL"
                                  style={{
                                    position: "absolute",
                                    overflow: "auto",
                                    height: "calc(100vh - var(--topOffset))",
                                    width: "100%",
                                    overflowX: "hidden",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      paddingBottom: "40px",
                                    }}
                                  >
                                    {[
                                      props.pre_content,
                                      props.title ? (
                                        <TabTitle {...props} />
                                      ) : null,
                                      props.children,
                                    ]}
                                  </div>
                                  <BottomBar />,
                                </div>
                              ),
                            },
                          ]}
                        />
                      }
                    </div>
                    <PopupDisplay />
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Index;
