import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";

function Index(props) {
  return (
    <div id="Social">
      <div style={{ marginBottom: "10px" }}>
        <img
          style={{ width: "65px", verticalAlign: "middle" }}
          src={envConfig.s3 + "/images/logos/white-logo.svg"}
        />
        <img
          style={{ height: "30px", verticalAlign: "middle", margin: "5px" }}
          src={envConfig.s3 + "/images/logos/text-logo.svg"}
        />
      </div>
      <div>
        <a href="https://www.facebook.com/officialvivavim" target="_blank">
          <img
            className="socialIcon"
            src={envConfig.s3 + "/images/icons/social_media/facebook.svg"}
          />
        </a>
        <a href="https://twitter.com/vivavim" target="_blank">
          <img
            className="socialIcon"
            src={envConfig.s3 + "/images/icons/social_media/twitter.svg"}
          />
        </a>
        <a href="https://www.instagram.com/vivavimofficial/" target="_blank">
          <img
            className="socialIcon"
            src={envConfig.s3 + "/images/icons/social_media/instagram.svg"}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCXFLS8oLUmO5pDP6uM1O__A"
          target="_blank"
        >
          <img
            className="socialIcon"
            src={envConfig.s3 + "/images/icons/social_media/youtube.svg"}
          />
        </a>
      </div>
    </div>
  );
}

export default Index;
