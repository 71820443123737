import API from "../../../../common/api";
import store from "../../../../common/redux/store";

//FUNCTIONS
import UnboxingFunctions from "./Unboxing.functions";

export default {
  get_crate_inventory: async (crate_id) => {
    if (crate_id) {
      let { data, status } = await API.get(
        "/api/crates/" + crate_id + "/unboxing"
      );

      return status == 201
        ? {
            crate: Object.assign({}, data, {
              products: data.products,
              removed: data.products
                .filter((x) => {
                  return x.in_stock == 0;
                })
                .map((y) => y.product_id),
            }),
            products: data.products,
          }
        : null;
    }
  },
  get_daily_crate_inventory: async (crate_id) => {
    let { data, status } = await API.get(
      "/api/crates/" + crate_id + "/unboxing/daily"
    );

    return status == 201
      ? {
          crate: Object.assign({}, data, {
            products: data.products,
            removed: data.products
              .filter((x) => {
                return x.in_stock == 0;
              })
              .map((y) => y.product_id),
          }),
          products: data.products,
        }
      : null;
  },
  create_unboxing: async (crate_inventory, unboxing_session) => {
    let { data, status } = await API.post("/api/unboxing/add", {
      crate_inventory:
        UnboxingFunctions.process_crate_inventory(crate_inventory),
      unboxing_cost: UnboxingFunctions.calculate_crate_info(
        crate_inventory.products
      ).cost,
      unboxing_session,
    });

    console.log(data);

    if (status == 201) {
      if (data.new_hashed_server_seed) {
        store.dispatch({
          type: "UPDATE_UNBOXING_HASHED_SERVER_SEED",
          hashed_server_seed: data.new_hashed_server_seed,
        });
      }
      return data;
    } else {
      store.dispatch({ type: "UNLOCK_ANIMATION" });
    }
  },
  create_daily_unboxing: async (crate_id, unboxing_session) => {
    let { data, status } = await API.post("/api/unboxing/daily/add", {
      crate_id,
      unboxing_session,
    });

    if (status == 201) {
      if (status == 201) {
        return data;
      } else {
        store.dispatch({ type: "UNLOCK_ANIMATION" });
      }
      return data;
    } else {
      store.dispatch({ type: "UNLOCK_ANIMATION" });
    }
  },
  set_client_seed: async (client_seed) => {
    let { status } = await API.post("/api/account/client_seed", {
      client_seed,
    });

    return status == 200 ? client_seed : null;
  },
};
