import React from "react";

//STYLES
import "./style.css";

export default (props) => {
  return (
    <div
      {...props}
      className={"FlexArea" + (props.className ? " " + props.className : "")}
    >
      {Array.isArray(props.children)
        ? props.children.map((x) => x)
        : props.children}
    </div>
  );
};
