import React from "react";

//COMPONENTS
import FlexGrid from "../../../../../layout/flex/FlexGrid";

import NavBtn from "./elements/NavBtn";
import Background from "../../../../../common/backgrounds/Background";

export default (props) => {
  const links = [
    {
      name: "unboxings",
      title: "Unbox History",
      path: "/account/unboxings",
      image: "/icons/crates.svg",
    },
    {
      name: "transactions",
      title: "Transactions",
      path: "/account/transactions",
      image: "/icons/common/vivacoin.svg",
    },
    {
      name: "affiliate",
      title: "Affiliate",
      path: "/account/affiliate",
      image: "/icons/account/affiliate.svg",
    },
  ];

  return (
    <div
      style={{
        position: "relative",
        border: "0",
        padding: "5px",
        marginTop: "10px",
      }}
    >
      <Background
        style={{
          background: "var(--background)",
          borderRadius: "8px",
        }}
      />
      <div style={{ marginTop: "5px", marginBottom: "5px", maxWidth: "800px" }}>
        <FlexGrid
          style={{ justifyContent: "start" }}
          children={links.map(
            (x) =>
              new Object({
                style: { alignItems: "stretch", flex: "1", padding: "5px" },
                element: (
                  <NavBtn
                    title={x.title}
                    path={x.path}
                    image={x.image}
                    selected={props.page === x.name}
                  />
                ),
              })
          )}
        />
      </div>
    </div>
  );
};
