import React from "react";

//API
import Orders from "../../../../../../../common/api/Orders";

//COMPONENTS
import InfoForm from "../../../../../../core/InfoForm";
import Price from "../../../../../../core/Price";

function Index(props) {
  return (
    <div style={{ position: "relative" }}>
      {props.method == "deliver" ? (
        <div>
          <InfoForm
            elements={[
              {
                title: "Shipping Costs",
                value: <Price price={props.delivery_cost} />,
              },
              {
                title: "Price Changes",
                value: (
                  <Price
                    style={{ color: "orange" }}
                    price={props.price_changes}
                  />
                ),
              },
            ]}
          />
          <InfoForm
            style={{ borderTop: "1px solid var(--highlighted)" }}
            elements={[
              {
                title: "Total Cost",
                value: (
                  <Price price={props.price_changes + props.delivery_cost} />
                ),
              },
            ]}
          />
        </div>
      ) : (
        <div>
          <InfoForm
            style={{ borderTop: "1px solid var(--highlighted)" }}
            elements={[
              {
                title: "Total Cost",
                value: <Price price={0} />,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default Index;
