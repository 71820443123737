import React from "react";

//COMPONENTS
import Form from "../../../../../core/Form";
import Textbox from "../../../../../core/inputs/text/Textbox";

export default (props) => {
  return (
    <div id="Info">
      <Form
        children={[
          {
            name: "Client Seed",
            el: <Textbox disabled={true} value={props.session.client_seed} />,
          },
          {
            name: "Hashed Server Seed",
            el: (
              <Textbox
                disabled={true}
                value={props.session.hashed_server_seed}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
