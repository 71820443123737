import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import SignUp from "../../components/common/auth/SignUp";

export default (props) => {
  return (
    <>
      <Helmet>
        <title>Sign Up | Vivavim</title>
        <meta name="description" content="Sign up to vivavim" />
        <link rel="canonical" href={envConfig.server + "/sign-up"} />
      </Helmet>
      <SignUp {...props} />
    </>
  );
};
