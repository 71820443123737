import Cookies from "../../Cookies";

//CONFIG
import envConfig from "../../config/env.config";

const session_reducer = (state = null, action) => {
  switch (action.type) {
    case "LOGIN":
      return action.session;
    case "LOGOUT":
      return null;
    case "UPDATE_USER":
      return Object.assign({}, state, {
        user: state.user
          ? Object.assign({}, state.user, action.user)
          : state.user,
      });
    case "REFRESH_USER_IMAGE":
      let img =
        envConfig.s3 +
        "/images/users/" +
        state.user.id +
        ".jpg?t=" +
        new Date(action.profile_img_datetime).getTime();
      let user = JSON.parse(JSON.stringify(state.user));
      user.image = img;

      return Object.assign({}, state, { user });

    case "UPDATE_TOKEN":
      Cookies.set("session-token", action.token);

      return state !== null
        ? Object.assign({}, state, { token: action.token })
        : state;
    default:
      return state;
  }
};

export default session_reducer;
