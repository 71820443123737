import React from "react";
import envConfig from "../../common/config/env.config";
import { Helmet } from "react-helmet-async";

//COMPONENTS
import PageLayout from "../../components/layout/PageLayout";
import Banner from "../../components/content/crates/Banner";
import PageTop from "../../components/layout/PageTop";
import PinnedCrates from "../../components/content/crates/PinnedCrates";
import CrateList from "../../components/content/crates/CrateList";
import SuccessBtn from "../../components/core/buttons/SuccessBtn";
import MakeACrateBtn from "../../components/content/crates/MakeACrateBtn";
import RandomCrateBtn from "../../components/content/crates/RandomCrateBtn";
import FlexGrid from "../../components/layout/flex/FlexGrid";

//ELEMENTS
import Search from "../../components/content/crates/Search";
import Background from "../../components/common/backgrounds/Background";
import BlendedBackground from "../../components/common/backgrounds/BlendedBackground";

export default (props) => {
  return (
    <div id="Crates">
      <Helmet>
        <title>Online Mystery Boxes | Vivavim</title>
        <meta
          name="description"
          content="Unbox online mystery boxes to win amazing prizes"
        />
        <link rel="canonical" href={envConfig.server} />
      </Helmet>
      <PageLayout
        title={"Browse Crates"}
        title_background={false}
        content={
          <div
            style={{
              position: "relative",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <MakeACrateBtn />
          </div>
        }
        popup_page={props.params?.popup_page}
        pre_content={
          <div>
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                maxWidth: "100%",
                overflow: "hidden",
                height: "100vh",
                minHeight: "800px",
              }}
            >
              <div>
                <BlendedBackground
                  style={{
                    backgroundImage:
                      "url(" +
                      envConfig.s3 +
                      "/images/crates2/banner/banner2.webp)",
                    //backgroundPositionX: "0",
                  }}
                  children={<Banner />}
                />
              </div>
            </div>
            <div className="mainPadding">
              <PinnedCrates />
            </div>
          </div>
        }
        children={
          <div>
            <Search />
            <CrateList type={"crates"} {...props} />
          </div>
        }
      />
    </div>
  );
};
