import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactVisibilitySensor from "react-visibility-sensor";

//FUNCTIONS
import Crates from "../../../../common/api/Crates";

//STYLES
import "./style.css";

import CrateCategories from "../Search/categories.json";

//COMPONENTS
import ContentList from "../../../core/ContentList";
import ContentGrid from "../../../core/ContentGrid";
import SearchOptions from "../../../core/search/SearchOptions";
import Crate from "../Crate";
import Btn from "../../../core/buttons/Btn";
import BackBtn from "../../../core/buttons/BackBtn";
import Background from "../../../common/backgrounds/Background";
import envConfig from "../../../../common/config/env.config";
import EmptyPlaceholder from "../../../common/EmptyPlaceholder";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import FlexArea from "../../../layout/flex/FlexArea";

function Index(props) {
  const categories = props.categories || CrateCategories;
  const dispatch = useDispatch();
  const page_content = useSelector((state) =>
    state.page_content_reducer.page == "crates" ||
    state.page_content_reducer.page == "rewards"
      ? state.page_content_reducer
      : null
  );
  const session = useSelector((state) => state.session_reducer);

  useEffect(() => {
    Crates.get_pinned_crates();
  }, []);

  useEffect(() => {
    if (props.type == "crates") {
      if (Object.keys(props.params).length) {
        Crates.Search_Crates({
          query: props.params?.query || null,
          category: props.params?.category || null,
          order_by: props.params.order_by || null,
        });
      } else {
        Crates.load_category_crates({ offset: 0, query: null });
      }
    } else {
      Crates.get_daily_crates();
    }
  }, [props.type, session, props.params]);

  return (
    <div id="cratesListWrapper">
      {page_content ? (
        page_content.loading ? (
          <div className="cratesTabList">
            <div className="lds-loading">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="cratesTabAlphabetList">
            {page_content?.query && !page_content?.data?.length ? (
              <div style={{ marginTop: "20px" }}>
                <EmptyPlaceholder
                  context={"crates"}
                  redirect={{ route: "/crates/Featured", page: "Featured" }}
                />
              </div>
            ) : (
              page_content.data
                .reduce(
                  (prev, next) =>
                    !prev.find((x) => x == next.category)
                      ? [...prev, next.category]
                      : prev,
                  []
                )
                .map((x, i) => (
                  <div
                    style={{
                      position: "relative",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      <div
                        className="mainPadding"
                        style={{ position: "relative" }}
                      >
                        {props?.params && Object.keys(props.params).length ? (
                          <BackBtn
                            route={"/crates"}
                            style={{ marginTop: "10px" }}
                          />
                        ) : null}
                        <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                          {categories.find((y) => y.title == x)?.image ? (
                            <img
                              src={
                                envConfig.s3 +
                                categories.find((y) => y.title == x)?.image
                              }
                              style={{
                                verticalAlign: "middle",
                                width: "25px",
                                marginRight: "5px",
                                verticalAlign: "middle",
                              }}
                            />
                          ) : null}
                          <h3
                            style={{
                              color: categories.find((y) => y.title == x)
                                ?.color,
                              display: "inline-block",
                              verticalAlign: "middle",
                              margin: "0",
                            }}
                          >
                            {x}
                            {(page_content.category || page_content.query) &&
                            page_content.order_by ? (
                              <span
                                style={{
                                  color: "white",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {" " + page_content?.order_by}
                              </span>
                            ) : null}
                          </h3>
                        </div>
                        {
                          <div
                            style={{
                              position: "relative",
                              width: "100%",

                              justifyContent: "center",
                              boxSizing: "border-box",
                              background: "var(--secondaryBackground)",
                              padding: "10px 0 10px 0",
                              borderTop:
                                "2px solid " +
                                categories.find((y) => y.title == x)?.color,
                              borderBottom:
                                "2px solid " +
                                categories.find((y) => y.title == x)?.color,
                              borderLeft: "2px solid transparent",
                              borderRight: "2px solid transparent",
                            }}
                          >
                            <ContentGrid
                              className="CratesList"
                              content={
                                page_content
                                  ? page_content.data
                                      .filter((y) => y.category == x)
                                      .map((z, i) => (
                                        <Crate
                                          crate={z}
                                          key={
                                            z.id +
                                            "-" +
                                            z?.levels_to +
                                            "-" +
                                            z?.time_locked_until
                                          }
                                          session={session}
                                        />
                                      ))
                                  : []
                              }
                            />
                          </div>
                        }
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Link
                            to={
                              "/crates/" +
                              categories.find((y) => y.title == x)?.title
                            }
                          >
                            {!props?.params?.category &&
                            props?.view_all_option !== false ? (
                              <Btn
                                style={{
                                  border:
                                    "2px solid " +
                                    (categories.find((y) => y.title == x)
                                      ?.color || "var(--highlighted)"),
                                  borderTop: "0",
                                  borderTopLeftRadius: "0",
                                  borderTopRightRadius: "0",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: "0.8rem",
                                }}
                                children={
                                  <div>
                                    <Background
                                      style={{
                                        background:
                                          categories.find((y) => y.title == x)
                                            ?.color || "var(--highlighted)",
                                        opacity: "0.2",
                                      }}
                                    />
                                    <span style={{ position: "relative" }}>
                                      <span style={{ verticalAlign: "middle" }}>
                                        View All
                                      </span>

                                      <i
                                        style={{
                                          marginLeft: "5px",
                                          verticalAlign: "middle",
                                        }}
                                        className="arrow"
                                      ></i>
                                    </span>
                                  </div>
                                }
                              />
                            ) : null}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        )
      ) : null}
    </div>
  );
}

export default Index;
