import React, { Component } from "react";
import { connect } from "react-redux";

//CONFIG
import envConfig from "../../../../../../common/config/env.config";

//FUNCTIONS
import Products from "../../../../../../common/api/Products";
import getProductRank from "../../../../../../common/functions/getProductRank";

//STYLES
import "./style.css";

//COMPONENTS
import Popup from "../../../../../layout/popups/Popup";
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import ProductRank from "../../../../products/ProductRank";
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";
import Background from "../../../../../common/backgrounds/Background";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      showLoading: true,
    };
  }

  componentDidMount() {
    this.load_product(this.props.product_id);
  }

  load_product = async (id) => {
    let product = await Products.get_product(id);

    this.setState({
      showLoading: false,
      product,
    });
  };

  render() {
    return this.state.product ? (
      <Popup
        id="ExpandedProduct"
        title={""}
        children={
          <div>
            <FlexGrid
              children={[
                {
                  style: { flex: "1 0 40%", minWidth: "220px" },
                  element: (
                    <div className="image">
                      <ProductRank product={this.state.product} />
                    </div>
                  ),
                },
                {
                  style: { flex: "1 0 60%" },
                  element: (
                    <div className="infoDiv">
                      <div style={{ marginBottom: "5px" }}>
                        <img
                          src={
                            envConfig.s3 + "/images/icons/common/vivacoin.svg"
                          }
                          style={{ verticalAlign: "middle", width: "30px" }}
                        />{" "}
                        <span
                          style={{
                            verticalAlign: "middle",
                          }}
                          className="cost"
                        >
                          {this.state.product.price}
                        </span>
                      </div>
                      <p className="title">
                        {unescape(this.state.product.name)}
                      </p>
                      <div className="shippingInfo">
                        <h4 style={{ marginTop: "5px", marginBottom: "10px" }}>
                          Shipping
                        </h4>
                        {/*<p className="amazon info">amazon US</p>*/}
                        <p className="info">
                          {this.state.product.delivery_cost == 0 ? (
                            <span
                              style={{
                                color: "var(--FunctionGreen)",
                                fontWeight: "bold",
                              }}
                            >
                              FREE
                            </span>
                          ) : (
                            <span>
                              <img
                                src={
                                  envConfig.s3 +
                                  "/images/icons/common/vivacoin.svg"
                                }
                                style={{
                                  width: "32px",
                                  verticalAlign: "middle",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.state.product.delivery_cost}
                              </span>
                            </span>
                          )}
                        </p>{" "}
                        {this.state.product.delivery_time ? (
                          <p className="info">
                            Estimate:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {this.state.product.delivery_time} Days
                            </span>
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ),
                },
              ]}
            />
            <p className="description highlighted">
              {unescape(this.state.product.description)}
            </p>
          </div>
        }
        context={this.props.context}
        onSuccess={() => {
          this.props.close_popup();
          if (this.props.onSuccess) {
            this.props.onSuccess(this.state.product);
          }
        }}
        onClose={this.props.close_popup}
        show_loading={this.state.showLoading}
      />
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close_popup: () => {
      dispatch({ type: "CLOSE_POPUP" });
    },
  };
};

export default connect(null, mapDispatchToProps)(index);
