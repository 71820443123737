import React, { Component } from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Dropdown from "../../Dropdown";
import OrderBy from "../OrderBy";
import FloatingBar from "../../../layout/FloatingBar";

export default class index extends Component {
  render() {
    return (
      <FloatingBar
        style={Object.assign(
          {},
          {
            top: "0px",
          },
          this.props.style
        )}
        children={
          <div
            className={
              "SearchOptions" +
              (this.props.className ? " " + this.props.className : "")
            }
          >
            <section className="section">
              <div>
                {this.props.data ? (
                  <div className="selectedInfo">
                    {this.props.selectAll ? (
                      <button
                        className="selectBtn"
                        onClick={this.props.onSelectAll}
                      >
                        Select All
                      </button>
                    ) : null}
                    {this.props.data.selected.length ? (
                      <span>
                        {this.props.data.selected.length} /{" "}
                        {this.props.data.max}
                        <button
                          className="cancelBtn"
                          onClick={this.props.onCancelSelect}
                        >
                          Cancel
                        </button>
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div
                style={{
                  marginLeft: "auto",
                  flex: "1 0 60%",
                  textAlign: "right",
                }}
              >
                <section
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                  }}
                  className="section"
                >
                  {this.props.children ? (
                    this.props.children
                  ) : this.props.data ? (
                    <div
                      style={{
                        fontSize: "0.9rem",
                        margin: "auto",
                        marginRight: "0",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {this.props.data.offset +
                          1 +
                          " - " +
                          (this.props.data.offset == 0
                            ? Math.min(
                                Math.max(
                                  1,
                                  Math.floor(
                                    this.props.data.offset / this.props.data.max
                                  ) + 1
                                ) * this.props.data.max,
                                this.props.data.max
                              )
                            : Math.min(
                                Math.max(
                                  1,
                                  Math.floor(
                                    this.props.data.offset / this.props.data.max
                                  ) + 1
                                ) * this.props.data.max,
                                this.props.data.max
                              ) + 1)}
                      </span>{" "}
                      of{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {this.props.data.offset == 0
                          ? this.props.data.max
                          : this.props.data.max + 1}
                      </span>
                    </div>
                  ) : null}
                </section>
              </div>
            </section>
          </div>
        }
      />
    );
  }
}
