import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//FUNCTIONS
import Crates from "../../../../common/api/Crates";
import URLFunctions from "../../../../common/functions/URL.functions";

//COMPONENTS
import FlexGrid from "../../../layout/flex/FlexGrid";
import OptionsList from "../../../../components/core/OptionsList";
import SearchBar from "../../../core/search/SearchBar";
import Dropdown from "../../../core/Dropdown";
import FloatingBar from "../../../layout/FloatingBar";
import Background from "../../../common/backgrounds/Background";

import categories from "./categories.json";
import Btn from "../../../core/buttons/Btn";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const navigate = useNavigate();
  const page_content = useSelector((state) =>
    state.page_content_reducer.page == "crates"
      ? state.page_content_reducer
      : null
  );
  const category = categories.find((x) => x.title == page_content?.category);

  return page_content ? (
    <FloatingBar
      style={{
        top: "0",
        borderBottom: "1px solid var(--highlighted)",
      }}
      children={
        <div style={{ position: "relative", width: "100%" }}>
          <Background
            style={{
              background: "var(--background)",
              opacity: "0.85",
            }}
          />
          <SearchBar
            context={"crates"}
            style={{
              background: "transparent",
              borderBottom: "0",
            }}
            value={page_content.query}
            order_by={{
              selected: page_content?.order_by || "A-Z",
              options: [
                { title: "A-Z" },
                {
                  title: "Price: Low - High",
                  value: "Price: Low - High",
                },
                {
                  title: "Price: High - Low",
                  value: "Price: High - Low",
                },
              ],
            }}
            onSearch={({ query = null, order_by = "A-Z" }) => {
              navigate(
                URLFunctions.parse_to("/", [
                  !query ? page_content?.category : null,
                  query,
                  order_by,
                ])
              );
            }}
            elements={[
              {
                style: { display: "inline-block", flex: "1" },
                element: (
                  <FlexArea
                    style={{ position: "relative" }}
                    children={
                      <>
                        <Dropdown
                          style={Object.assign(
                            {},
                            {
                              fontSize: "0.8rem",
                              margin: "auto",
                              width: "unset",
                              marginLeft: "0",
                              marginRight: "0",
                            },
                            category
                              ? {
                                  border: "1px solid " + category.color,
                                  fontWeight: "bold",
                                }
                              : null
                          )}
                          value={page_content?.category || "all"}
                          onChange={(cat) =>
                            navigate(
                              URLFunctions.parse_to("/crates", [
                                cat !== "All Categories" ? cat : "",
                              ])
                            )
                          }
                          options={categories}
                        />
                        <Btn
                          context={"View All"}
                          style={{
                            color: "var(--FunctionGreen)",
                            whiteSpace: "nowrap",
                            paddingLeft: "10px",
                          }}
                          onClick={() => navigate("/crates/All")}
                        />
                      </>
                    }
                  />
                ),
              },
            ]}
          />
        </div>
      }
    />
  ) : null;
};
