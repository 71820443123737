import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../common/config/env.config";

//STYLES
import "./style.css";

//COMPONENTS
import Icon from "../../../core/Icon";
import FlexArea from "../../../layout/flex/FlexArea";

export default (props) => {
  const dispatch = useDispatch();
  const audio_reducer = useSelector((state) => state.audio_reducer);

  const [hover, setHover] = useState(false);
  const [volume, setVolume] = useState(audio_reducer.volume);

  useEffect(() => {
    if (audio_reducer.volume !== volume) {
      setVolume(audio_reducer.volume);
    }
  }, [audio_reducer]);

  return (
    <div
      id="SoundControls"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <FlexArea
        onClick={() =>
          hover
            ? dispatch({
                type: "SET_AUDIO",
                audio: { muted: !audio_reducer.muted },
              })
            : {}
        }
        children={
          <Icon
            style={{ width: "25px", margin: "auto" }}
            src={
              envConfig.s3 +
              "/images/icons/common/" +
              (audio_reducer.muted || audio_reducer.volume == 0
                ? "sound_mute.svg"
                : "sound.svg")
            }
          />
        }
      />
      <div id="volume">
        <div id="value">
          <FlexArea
            children={
              <span style={{ margin: "auto" }}>
                {audio_reducer.muted ? 0 : volume}
              </span>
            }
          />
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={audio_reducer.muted ? 0 : volume}
          onMouseUp={() =>
            dispatch({ type: "SET_AUDIO", audio: { volume: parseInt(volume) } })
          }
          onChange={(e) => {
            setVolume(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
