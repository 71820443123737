import React from "react";

//CONFIG
import envConfig from "../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../common/functions/GeneralFunctions";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../layout/flex/FlexArea";
import Background from "../../../../common/backgrounds/Background";

export default (props) => {
  return (
    <div id="Info">
      <FlexGrid
        style={{ justifyContent: "right" }}
        children={[
          {
            style: {
              flex: "1",
              padding: "5px",
              minWidth: "165px",
              marginTop: "25px",
            },
            element: (
              <div
                className="highlighted"
                style={{ padding: "5px", height: "100%" }}
              >
                <FlexArea
                  children={
                    <div style={{ margin: "auto" }}>
                      {props.checkout.code ? (
                        <span
                          style={{
                            color: "var(--FunctionGreen)",
                            fontSize: "1.4rem",
                            fontWeight: "bold",
                          }}
                        >
                          +
                          {parseFloat(
                            props.checkout.code.bonus_multiplier * 100 - 100
                          ).toFixed(2)}
                          %
                        </span>
                      ) : (
                        <span style={{ fontWeight: "bold" }}>No Code</span>
                      )}
                    </div>
                  }
                />
                <span className="title">Code Bonus</span>
              </div>
            ),
          },
          {
            style: {
              flex: "1",
              padding: "5px",
              minWidth: "165px",
              marginTop: "25px",
            },
            element: (
              <div
                className="highlighted"
                style={{ padding: "5px", height: "100%" }}
              >
                <FlexArea
                  children={
                    <div style={{ margin: "auto" }}>
                      <span
                        style={{
                          color: "var(--FunctionGreen",
                          fontSize: "1.4rem",
                        }}
                      >
                        ${GeneralFunctions.parse_currency(props.checkout.value)}
                      </span>
                    </div>
                  }
                />
                <span className="title">You Pay</span>
              </div>
            ),
          },
          {
            style: {
              flex: "1",
              padding: "5px",

              minWidth: "165px",
              marginTop: "25px",
            },
            element: (
              <div
                className="highlighted"
                style={{ padding: "5px", height: "100%" }}
              >
                <FlexArea
                  children={
                    <div style={{ margin: "auto" }}>
                      <img
                        src={envConfig.s3 + "/images/icons/common/vivacoin.svg"}
                        style={{
                          width: "30px",
                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                      />
                      <span
                        style={{ fontSize: "1.4rem", verticalAlign: "middle" }}
                      >
                        {GeneralFunctions.parse_currency(
                          props.checkout.value *
                            (props.checkout.code
                              ? props.checkout.code.bonus_multiplier
                              : 1)
                        )}
                      </span>
                    </div>
                  }
                />
                <span className="title">You Get</span>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
