import React from "react";

function Index(props) {
  return (
    <div
      style={Object.assign(
        {},
        {
          position: "absolute",
          inset: "0px",
          borderRadius: "2px",
          boxShadow: "0 0 10px black",
          background:
            "linear-gradient(to left, #462623, #cb9b50 50%, #cb9b50 50%, #462623 100%)",
        },
        props.style
      )}
    >
      <div
        style={{
          position: "absolute",
          inset: "1px",
          borderRadius: "8px",
          background: "var(--background)",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Index;
