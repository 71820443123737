import API from "../../index";

export default async (obj) => {
  try {
    const { data, status } = await API.get(
      "/api/inventory/get?products=" + encodeURIComponent(obj.products)
    );

    return status == 201 ? data : null;
  } catch (e) {
    throw e;
  }
};
