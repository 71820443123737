import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Background from "../Background";

export default (props) => {
  return (
    <Background
      style={Object.assign(
        {},
        {
          backgroundImage: "url(" + props.image + ")",
          backgroundSize: "cover",
        },
        props.style
      )}
      children={
        <Background
          id="GlowMask"
          children={
            <Background
              style={Object.assign(
                {},
                {
                  position: "relative",
                  display: "inline-block",
                  width: "100%",
                  height: "100%",
                  backgroundImage: "url(" + props.image + ")",
                  backgroundSize: "cover",
                },
                props.style
              )}
            />
          }
        />
      }
    />
  );
};
