import API from "../../index";

//FUNCTIONS
import URLFunctions from "../../../functions/URL.functions";

export default async (obj) => {
  const { data, status } = await API.get(
    URLFunctions.parse_with_params("/api/orders/products", [
      { name: "product_name", value: obj.product_name },
      { name: "order_by", value: obj.order_by },
      { name: "offset", value: obj.offset || 0 },
    ])
  );

  return status == 201 ? data : null;
};
