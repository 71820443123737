import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import Crate from "../../../crates/Crate";
import ContentList from "../../../../core/ContentList";

function Index(props) {
  let unboxing = props.unboxing;
  return (
    <div id="crate">
      <ContentList
        content={[
          <Crate
            crate={{
              name: unboxing.crate_name,
              isFree: unboxing.isFree,
              price:
                unboxing.crate_cost > 0 ? unboxing.crate_cost : unboxing.cost,
              id: unboxing.crate_id,
            }}
          />,
        ]}
      />
    </div>
  );
}

export default Index;
