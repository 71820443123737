import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//API
import Auth from "../../../../../../../common/api/Auth";

//STYLES
import "./style.css";

//COMPONENTS
import List from "../../../../../../core/List";
import Btn from "../../../../../../core/buttons/Btn";
import Background from "../../../../../backgrounds/Background";

function Index(props) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);
  const account_level = useSelector((state) => state.account_level_reducer);

  return (
    <div id="Nav">
      <div style={{ position: "relative", display: "inline-block" }}>
        {session ? (
          <List
            elements={[
              {
                style: { marginLeft: "5px", marginRight: "5px" },
                element: (
                  <Link to="/account">
                    <li className="navElement" title={"Account"}>
                      <img
                        src={
                          envConfig.s3 +
                          "/images/icons/account/account_management.svg"
                        }
                      />
                      <span>Account</span>
                    </li>
                  </Link>
                ),
              },

              {
                style: { marginLeft: "5px", marginRight: "5px" },
                element: (
                  <li
                    onClick={() =>
                      dispatch({
                        type: "UPDATE_ACCOUNT_DROPDOWN",
                        object: { show: "inventory" },
                      })
                    }
                    className="navElement"
                    title={"Inventory"}
                  >
                    <img
                      src={envConfig.s3 + "/images/icons/account/inventory.svg"}
                    />
                    <span>Inventory</span>
                  </li>
                ),
              },
              {
                style: { marginLeft: "5px", marginRight: "5px" },
                element: (
                  <li
                    onClick={() =>
                      dispatch({
                        type: "UPDATE_ACCOUNT_DROPDOWN",
                        object: { show: "orders" },
                      })
                    }
                    className="navElement"
                    title={"Orders"}
                  >
                    <img
                      src={envConfig.s3 + "/images/icons/account/orders.svg"}
                    />
                    <span>Orders</span>
                  </li>
                ),
              },
              {
                style: { marginLeft: "5px", marginRight: "5px" },
                element: (
                  <Link to={"/users/" + session.user.id}>
                    <li className="navElement" title={"Stats"}>
                      <img
                        src={envConfig.s3 + "/images/icons/account/stats.svg"}
                      />
                      <span>Stats</span>
                    </li>
                  </Link>
                ),
              },
              {
                style: { marginLeft: "5px", marginRight: "5px" },
                element: (
                  <Link to={"/account/affiliate"}>
                    <li className="navElement" title={"Affiliate"}>
                      <img
                        src={
                          envConfig.s3 + "/images/icons/account/affiliate.svg"
                        }
                      />
                      <span>Affiliate</span>
                    </li>
                  </Link>
                ),
              },
              {
                style: { marginLeft: "5px", marginRight: "5px" },
                element: (
                  <li className="navElement">
                    <Btn
                      style={{
                        border: "2px solid var(--error)",
                        fontSize: "0.8rem",
                        padding: "5px",
                      }}
                      onClick={Auth.logout_user}
                      children={
                        <>
                          <Background
                            style={{
                              background: "var(--error)",
                              opacity: "0.2",
                            }}
                          />
                          <span
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            Sign Out
                          </span>
                        </>
                      }
                    />
                  </li>
                ),
              },
            ]}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Index;
