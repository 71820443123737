import React, { Component } from "react";
import { connect } from "react-redux";

//STYLES
import "./style.css";

//COMPONENTS
import Product from "../Product";
import FlexArea from "../../../layout/flex/FlexArea";

class index extends Component {
  render() {
    return (
      <Product
        key={this.props.product.id + this.props.selected}
        product={this.props.product}
        onClick={(product) => {
          this.props.selected
            ? this.props.remove_page_content_selected([product.id])
            : this.props.add_page_content_selected([product]);
        }}
        context={"Continue"}
        children={
          this.props.product.in_stock == 0 ? (
            <div className="OutOfStock">
              <FlexArea
                children={
                  <div style={{ margin: "auto", paddingLeft: "40%" }}>
                    <span
                      style={{
                        fontSize: "15px",
                        color: "var(--error)",
                        marginLeft: "4px",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                      }}
                    >
                      Out of Stock
                    </span>
                  </div>
                }
              />
            </div>
          ) : null
        }
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_page_content_selected: (selected) => {
      dispatch({ type: "ADD_PAGE_CONTENT_SELECTED", selected });
    },
    remove_page_content_selected: (elements) => {
      dispatch({ type: "REMOVE_PAGE_CONTENT_SELECTED", elements });
    },
  };
};

export default connect(null, mapDispatchToProps)(index);
