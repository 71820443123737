import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import ContentGrid from "../../../core/ContentGrid";

export default (props) => {
  return (
    <ContentGrid
      className="ProductList"
      style={props.style}
      content={props.products}
    />
  );
};
