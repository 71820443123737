import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

//CONFIG
import envConfig from "../../../../../../../../common/config/env.config";

//FUNCTIONS
import OrderFunctions from "../../../../../functions/Order.Functions";

import ProductRank from "../../../../../../products/ProductRank";

import ParseDateTime from "../../../../../../../../common/functions/ParseDateTime";

//COMPONENTS
import SuccessBtn from "../../../../../../../core/buttons/SuccessBtn";
import Background from "../../../../../../../common/backgrounds/Background";
import Icon from "../../../../../../../core/Icon";

export default function (props) {
  function renderTracking(order) {
    let products = JSON.parse(JSON.stringify(order.products));
    let now = new Date();
    now = new Date(
      now.getFullYear() +
        "/" +
        (now.getMonth() + 1) +
        "/" +
        now.getDate() +
        " 00:00:00"
    );

    if (
      //if the orders isn't fully delivered
      products.find((x) => {
        return (
          x.date_arriving &&
          ParseDateTime.calculate_days(new Date(x.date_arriving), new Date()) >
            0
        );
      })
    ) {
      //if the orders isn't fully delivered
      products = products
        .filter((x) => {
          return x.date_arriving && new Date(x.date_arriving) >= now;
        })
        .sort(function (a, b) {
          return new Date(a.date_arriving) - new Date(b.date_arriving);
        });
    } else {
      products = products.sort(function (a, b) {
        return new Date(b.date_arriving) - new Date(a.date_arriving);
      });
    }
    var current_status = OrderFunctions.parse_order_state(order)?.status;

    let productsLists = groupBy(order.products, "date_arriving");

    let dates = Object.keys(productsLists);

    let first_products = order.products.filter((x) => {
      return x.date_arriving == dates[0];
    });

    switch (current_status) {
      case "refunded":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle cancelled">
              Order Was Refunded.
            </h4>
          </div>
        );
        break;
      case "deliver_review":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle review">
              <Icon
                className="orderViewTrackingIcon"
                src={envConfig.s3 + "/images/icons/order/tracking/review.svg"}
              />
              We are processing your order...
            </h4>
          </div>
        );
        break;
      case "deliver_confirmed":
        return (
          <div className="orderViewTrackingSection">
            {dates[0] !== "null" ? (
              <h4 className="orderViewTrackingTitle">
                <Icon
                  className="orderViewTrackingIcon"
                  src={
                    envConfig.s3 + "/images/icons/order/tracking/shipped.svg"
                  }
                  style={{ width: "35px" }}
                />
                {(first_products.length > 1
                  ? first_products.length + " Items "
                  : "") +
                  "Arriving " +
                  ParseDateTime.parse(dates[0]) +
                  "."}
              </h4>
            ) : (
              <h4 className="orderViewTrackingTitle">
                <Icon
                  className="orderViewTrackingIcon"
                  src={envConfig.s3 + "/images/icons/order/tracking/tick.svg"}
                />
                Order Confirmed
              </h4>
            )}
          </div>
        );
        break;
      case "deliver_arriving_tomorrow":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle confirmed">
              <Icon
                className="orderViewTrackingIcon"
                src={envConfig.s3 + "/images/icons/order/tracking/shipped.svg"}
                style={{ width: "35px" }}
              />
              You Have {first_products.length} Items Arriving Tomorrow...
            </h4>
          </div>
        );
        break;
      case "deliver_delivery":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle confirmed">
              <Icon
                className="orderViewTrackingIcon"
                src={envConfig.s3 + "/images/icons/order/tracking/shipped.svg"}
                style={{ width: "35px" }}
              />
              You Have {first_products.length} Items Arriving Today...
            </h4>
          </div>
        );
        break;
      case "deliver_completed":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle completed">
              <Icon
                className="orderViewTrackingIcon"
                src={
                  envConfig.s3 + "/images/icons/order/tracking/delivered.svg"
                }
              />
              All Items Delivered...
            </h4>
          </div>
        );
        break;

      case "withdraw_review":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle review">
              <Icon
                className="orderViewTrackingIcon"
                src={envConfig.s3 + "/images/icons/order/tracking/review.svg"}
              />
              Transaction Being Reviewed.
            </h4>
            {/*<h4 className="orderViewTrackingSubTitle">
              We will notify you by email when we complete the transaction.
            </h4>
          </div>
        );
        break;
      case "withdraw_confirmed":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle">Transaction Confirmed.</h4>
            {/*<h4 className="orderViewTrackingSubTitle">
              Sent {order.method.coin_value}
              {" " + order.method.coin_type}
        </h4>*/}
          </div>
        );
      case "giftcard_review":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle review">
              <Icon
                className="orderViewTrackingIcon"
                src={envConfig.s3 + "/images/icons/order/tracking/review.svg"}
              />
              We are reviewing your gifcard request.
            </h4>
          </div>
        );
      case "giftcard_confirmed":
        return (
          <div className="orderViewTrackingSection">
            <h4 className="orderViewTrackingTitle completed">
              <Icon
                className="orderViewTrackingIcon"
                src={envConfig.s3 + "/images/icons/order/tracking/tick.svg"}
              />
              Your giftcard has been confirmed
            </h4>
          </div>
        );
    }
  }

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  function calculate_status(product) {
    let now = new Date();
    now = new Date(
      now.getFullYear() +
        "/" +
        (now.getMonth() + 1) +
        "/" +
        now.getDate() +
        " 00:00:00"
    );

    if (product.date_arriving == null) {
      return "review";
    } else if (
      product.date_arriving &&
      new Date(product.date_arriving) >= now
    ) {
      if (ParseDateTime.calculate_days(product.date_arriving, now) == 0) {
        return "delivery";
      } else if (
        ParseDateTime.calculate_days(product.date_arriving, now) == 1
      ) {
        return "arriving_tomorrow";
      } else {
        return "ordered";
      }
    } else if (product.date_arriving && new Date(product.date_arriving) < now) {
      return "delivered";
    }
  }

  return (
    <div className="orderViewTracking">
      <div
        style={{
          position: "relative",
          display: "inline-block",
          margin: "auto",
          marginLeft: "10px",
          width: "100%",
        }}
      >
        {renderTracking(props.order)}
        <div>
          {(() => {
            switch (props.order.order_type) {
              case "deliver":
                return (
                  <p className="orderViewTrackingSubTitle">
                    {props.order.method.state}{" "}
                    {unescape(props.order.method.zip)}
                  </p>
                );
                break;
              case "withdraw":
                return (
                  <p className="orderViewTrackingSubTitle">
                    {props.order.method.wallet_address}
                  </p>
                );
                break;
              case "giftcard":
                return (
                  <p className="orderViewTrackingSubTitle">
                    {props.order.method.email_address}
                  </p>
                );
                break;
            }
          })()}
        </div>
      </div>
    </div>
  );
}
