import API from "../../index";

export default async (object) => {
  try {
    /*const { data, status } = await API.post("/api/auth_user", {
      username: object.username,
      password: object.password,
    });



    console.log(data);
    console.log(status);
    return status == 201 ? { data } : { error: "error" };*/

    const req = await API.post("/api/auth_user", {
      username: object.username,
      password: object.password,
    });

    return req?.status == 201
      ? { data: req?.data }
      : { error: "invalid request" };
  } catch (e) {
    throw e;
  }
};
