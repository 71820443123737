import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//STYLES
import "./style.css";

//COMPONENTS
import ContentList from "../../../../../../core/ContentList";
import OrderView from "../../../../../../content/orders/OrderList/elements/OrderView";

function Index(props) {
  const account_dropdown = useSelector(
    (state) => state.account_dropdown_reducer
  );

  return (
    <div id="Orders">
      <div className="top">
        <Link to="/orders">
          <h3>Go To Orders</h3>
        </Link>
      </div>
      <div className="middle">
        <ContentList
          content={
            account_dropdown.orders
              ? account_dropdown.orders.data.map((x) => <OrderView order={x} />)
              : []
          }
        />
      </div>
      <div id="bottom"></div>
    </div>
  );
}

export default Index;
