const carousel_segments_reducer = (state = [], action) => {
  switch (action.type) {
    case "POPULATE_CAROUSEL_SEGMENTS":
      return action.segments;
    case "POPULATE_RANDOM_CAROUSEL_SEGMENTS": // INPUT => {segments, products, winning_product}
      return action.obj.segments.reduce(
        (prev, next, i) =>
          (prev = [
            ...prev,
            {
              index: i,
              product: action.obj.products.filter(
                (x) =>
                  x.id !==
                  prev[i >= 0 ? i - 1 : action.obj.segments.length]?.product?.id
              )[Math.floor(Math.random() * (action.obj.products.length - 1))],
              rotations: 0,
            },
          ]),
        []
      );
    /*case "NEW_CAROUSEL_SEGMENT_PRODUCTS": // => {segments, products, rotations}
      return state.map((x, i) =>
        action.obj.segments.find((y) => y.index == x.index)
          ? {
              index: x.index,
              product:
                x.rotations < action.obj.rotations
                  ? action.obj.products.filter(
                      (y) =>
                        y.id !==
                        state[x.index > 0 ? x.index - 1 : state.length - 1]
                          .product.id
                    )[
                      Math.floor(
                        Math.random() * (action.obj.products.length - 1)
                      )
                    ]
                  : x.product,
              rotations: action.obj.rotations,
            }
          : x
      );*/
    case "NEW_CAROUSEL_SEGMENT_PRODUCT":
      return state.map((x) =>
        x.index == action.segment.index ? action.segment : x
      );
    case "SHOW_WINNING_CAROUSEL_SEGMENT":
      return state.map((x) =>
        x.index == 0
          ? Object.assign({}, x, { product: action.winning_product })
          : x
      );
    default:
      return state;
  }
};

export default carousel_segments_reducer;
