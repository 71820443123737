import React, { Component, useEffect, useState } from "react";

//CONFIG
import config from "../../../config.json";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import GoogleMap from "../../../../../../common/GoogleMap";
import Form from "../../../../../../core/Form/index";
import Textbox from "../../../../../../core/inputs/text/Textbox";
import Dropdown from "../../../../../../core/Dropdown";

import USA_Only from "../../../../../../common/banners/USA_Only";
import Section from "./elements/Section/Section";
import BottomBar from "./elements/BottomBar";

function Index(props) {
  const [inputs, setInputs] = useState(null);
  const [can_edit, setCanEdit] = useState(false);

  useEffect(() => {
    if (props.data?.address) {
      setInputs(
        Object.keys(props.data.address).reduce(
          (x, cur) =>
            Object.assign({}, x, {
              [cur]: props.data.address[cur] ? props.data.address[cur] : "",
            }),
          {}
        )
      );
    }
  }, [props.data]);

  //DETECT IF CAN SAVE
  useEffect(() => {
    if (
      (inputs &&
        Object.keys(inputs).filter(
          (x) =>
            (inputs[x] !== "" ? inputs[x] : null) !== props.data?.address[x]
        ).length) ||
      !props?.data?.id
    ) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [inputs]);

  function handleTextChange(e) {
    setInputs(Object.assign({}, inputs, { [e.target.id]: e.target.value }));
  }

  return inputs ? (
    <div className="selected">
      <USA_Only />
      <FlexGrid
        children={[
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "Name",
                    el: (
                      <Textbox
                        id="name"
                        value={inputs.name}
                        onChange={handleTextChange}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "Phone Number",
                    el: (
                      <Textbox
                        id="phone_number"
                        value={inputs.phone_number}
                        onChange={handleTextChange}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "Street Address",
                    el: (
                      <Textbox
                        id="street_address"
                        onChange={handleTextChange}
                        value={inputs.street_address}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "Street Address 2",
                    el: (
                      <Textbox
                        id="street_address_2"
                        onChange={handleTextChange}
                        value={inputs.street_address_2}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "City",
                    el: (
                      <Textbox
                        id="city"
                        onChange={handleTextChange}
                        value={inputs.city}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "State",
                    el: (
                      <Dropdown
                        options={config.states}
                        value={inputs.state}
                        onChange={(value) =>
                          setInputs(Object.assign({}, inputs, { state: value }))
                        }
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "Zip",
                    el: (
                      <Textbox
                        id="zip"
                        onChange={handleTextChange}
                        value={inputs.zip}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: {
              flex: "1 0 50%",
              padding: "5px",
              boxSizing: "border-box",
            },
            element: (
              <Form
                children={[
                  {
                    name: "Country",
                    el: (
                      <Dropdown
                        options={config.countries}
                        value={inputs.country}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <GoogleMap address={props.data?.address} />

      {props.confirmAddress ? (
        <BottomBar
          context={props.context}
          address={props.data}
          confirmAddress={() =>
            props.confirmAddress(
              Object.assign({}, props.data, {
                address: {
                  name: inputs.name,
                  street_address: inputs.street_address,
                  street_address_2: inputs.street_address_2,
                  city: inputs.city,
                  state: inputs.state,
                  zip: inputs.zip,
                  phone_number: inputs.phone_number,
                  country: inputs.country,
                },
              })
            )
          }
          saveAddress={
            can_edit
              ? () => {
                  Object.keys(inputs).forEach((x) => {
                    inputs[x] = inputs[x] !== "" ? inputs[x] : null;
                  });
                  props.saveAddress({
                    id: props.data?.id,
                    address: Object.assign({}, inputs, {
                      country: "United States",
                    }),
                    is_default: props.data?.is_default,
                  });
                }
              : null
          }
          makeDefault={
            props.data?.id ? () => props.makeDefault(props.data.id) : null
          }
          deleteAddress={
            props.data?.id ? () => props.deleteAddress(props.data.id) : null
          }
          isDefault={props.data?.is_default}
        />
      ) : null}
    </div>
  ) : null;
}

export default Index;
