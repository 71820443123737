import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import FlexArea from "../../layout/flex/FlexArea";

function Index(props) {
  return (
    <ul className="List">
      {props.elements.map((x) => (
        <li className="element" style={x.style}>
          <FlexArea
            style={{ position: "relative" }}
            children={
              <div style={{ margin: "auto", display: "inline-block" }}>
                {x.element}
              </div>
            }
          />
        </li>
      ))}
    </ul>
  );
}

export default Index;
