import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../../common/functions/GeneralFunctions";

//API
import Transactions from "../../../../../../../common/api/Transactions";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import Dropdown from "../../../../../../core/Dropdown";
import Textbox from "../../../../../../core/inputs/text/Textbox";

function Index(props) {
  const config = useSelector((state) => state.config_reducer);

  const value = (1 - config.withdraw_fee / 100) * props.value;

  return (
    <div>
      <FlexGrid
        children={[
          {
            style: { flex: "0", margin: "auto", display: "flex" },
            element: (
              <div
                style={{
                  position: "relative",
                  margin: "auto",
                  width: "300px",
                  boxSizing: "border-box",
                  padding: "5px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "12px",
                    left: "15px",
                    color: "var(--FunctionGreen)",
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                  }}
                >
                  ${GeneralFunctions.parse_currency(value)}
                </span>
                <img
                  src={envConfig.s3 + "/images/icons/giftcard.svg"}
                  style={{ width: "100%" }}
                />
              </div>
            ),
          },
          {
            style: { flex: "1", margin: "auto" },
            element: (
              <div
                style={{
                  maxWidth: "450px",
                  margin: "auto",
                  boxSizing: "border-box",
                  padding: "10px",
                }}
              >
                <span>Country: </span>
                <Dropdown
                  disabled={true}
                  options={[
                    { value: "USA", title: "United States" },
                    { value: "GB", title: "United Kingdom" },
                    { value: "CA", title: "Canada" },
                    { value: "MX", title: "Mexico" },
                    { value: "IRL", title: "Ireland" },
                    { value: "GER", title: "Germany" },
                    { value: "AT", title: "Austria" },
                    { value: "LUX", title: "Luxembourg" },
                    { value: "FR", title: "France" },
                    { value: "BEL", title: "Belgium" },
                    { value: "ITA", title: "Italy" },
                    { value: "ESP", title: "Spain" },
                    { value: "POR", title: "Portugal" },
                    { value: "NET", title: "Netherlands" },
                    { value: "PL", title: "Poland" },
                    { value: "SWE", title: "Sweden" },
                    { value: "TUR", title: "Turkey" },
                    { value: "JP", title: "Japan" },
                    { value: "SG", title: "Singapore" },
                    { value: "AU", title: "Australia" },
                    { value: "IN", title: "India" },
                  ]}
                />
                <h3>Amazon Account Email</h3>
                <Textbox
                  value={props?.giftcard?.email_address}
                  disabled={true}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Index;
