import React from "react";
import { useSelector, useDispatch } from "react-redux";

//FUNCTIONS
import parseDateTime from "../../../../../common/functions/ParseDateTime";

//COMPONENTS
import ProvFairBtn from "../../../provablyFair/ProvFairBtn";
import RollChecker from "../../../provablyFair/RollChecker";

export default (props) => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session_reducer);

  return session && props.unboxing.user_id == session.user.id ? (
    <div
      style={{
        display: "inline-block",
        borderRadius: "8px",
      }}
    >
      <span>
        You won this item{" "}
        <span style={{ fontWeight: "bold" }}>
          {parseDateTime.parseDateTime(new Date(props.unboxing.timestamp))}
        </span>
      </span>
      <ProvFairBtn
        style={{
          verticalAlign: "middle",
          marginLeft: "15px",
        }}
        onClick={() =>
          dispatch({
            type: "NEW_POPUP",
            element: <RollChecker unboxing_id={props.unboxing.id} />,
          })
        }
        context={"Verify Roll"}
      />
    </div>
  ) : null;
};
