import styled from "@emotion/styled";

const SubmitButton = styled.button`
  display: block;
  height: 35px;
  width: 100%;
  font-size: inherit;
  background-color: ${(props) => (props.disabled ? "#7795f8" : "var(--card)")};
  box-shadow: ${(props) => (props.disabled ? "none" : "0 0 5px var(--card)")};
  border-radius: 10px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: white;
  font-weight: 600;
  cursor: pointer;
  border: none;
`;

export default SubmitButton;
