import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import Account from "../../../../common/api/Account";

//STYLES
import "./style.css";

//COMPONENTS
import FlexGrid from "../../../layout/flex/FlexGrid";
import ProfileImg from "../ProfileImg";
import FlexArea from "../../../layout/flex/FlexArea";
import Background from "../../../common/backgrounds/Background";
import SuccessBtn from "../../../core/buttons/SuccessBtn";
import Btn from "../../../core/buttons/Btn";

import Info from "./elements/Info";
import AccountNav from "./elements/AccountNav";
import ImageSelect from "../ImageSelect";
import UnboxHistory from "../UnboxHistory";
import Transactions from "../Transactions";
import Affiliate from "../AccountManage/elements/affiliate/Manage/";

export default (props) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const session = useSelector((state) => state.session_reducer);

  useEffect(() => {}, []);

  async function get_account() {
    //setUser(await Account.get_account());
  }

  function change_profile_img(image, default_image) {
    Account.set_profile_img({ image, default_image });
  }

  return (
    <div>
      <div id="Manage">
        <Background
          style={{
            background: "var(--secondaryBackground)",
            borderRadius: "8px",
          }}
        />
        <FlexGrid
          children={[
            {
              style: {
                flex: "1 0 40%",
                padding: "5px",
                minWidth: "280px",
              },
              element: (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    height: "100%",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    boxSizing: "border-box",
                  }}
                >
                  <Background
                    style={{
                      background: "var(--background)",
                      borderRadius: "8px",
                    }}
                  />
                  <FlexArea
                    children={
                      <div
                        style={{
                          margin: "auto",
                          width: "210px",
                          height: "210px",
                        }}
                      >
                        <ProfileImg user={session.user} />
                      </div>
                    }
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    <Btn
                      context={"Change"}
                      style={{
                        fontSize: "0.8rem",
                        color: "var(--FunctionGreen)",
                        fontWeight: "bold",
                      }}
                      onClick={() =>
                        dispatch({
                          type: "NEW_POPUP",
                          element: (
                            <ImageSelect
                              onSuccess={({ image, default_image }) =>
                                change_profile_img(image, default_image)
                              }
                            />
                          ),
                        })
                      }
                    />
                  </div>
                </div>
              ),
            },
            {
              style: { flex: "1 0 60%", padding: "5px" },
              element: (
                <div>
                  <Info user={session.user} />
                </div>
              ),
            },
          ]}
        />
        <AccountNav page={props.params.page} />
      </div>
      {(() => {
        switch (props.params.page) {
          case "unboxings":
            return <UnboxHistory />;
          case "transactions":
            return <Transactions />;
          case "affiliate":
            return <Affiliate />;
          default:
            return null;
        }
      })()}
    </div>
  );
};
