import React, { useState, useEffect } from "react";

import envConfig from "../../../../../common/config/env.config";

//API
import s3 from "../../../../../common/api/s3";

//STYLES
import "./style.css";

//COMPONENTS
import ExpandInfo from "../../../../core/ExpandInfo";

function Index(props) {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    getJSON();
  }, []);

  const getJSON = async () => {
    setElements(
      await s3.get_resource({
        url: envConfig.s3 + "/documents/HowItWorks/FAQs.json",
      })
    );
  };

  return (
    <div id="FAQs">
      {elements?.length ? <ExpandInfo elements={elements} /> : null}
    </div>
  );
}

export default Index;
