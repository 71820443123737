import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//API
import Affiliate from "../../../../../../../common/api/Affiliate";

//COMPONENTS
import CodeInfo from "./CodeInfo";
import PromoCode from "./PromoCode";
import Payouts from "./Payouts";
import Stats from "./Stats";
import Withdraw from "./Withdraw";
import FlexGrid from "../../../../../../layout/flex/FlexGrid";

function Index(props) {
  const [affiliate, setAffiliate] = useState(null);

  useEffect(() => {
    load_dashboard();
  }, []);

  async function load_dashboard() {
    setAffiliate(await Affiliate.get_affiliate());
  }

  async function add_payout(value) {
    const data = await Affiliate.add_payout({ value });

    if (data) {
      setAffiliate(
        Object.assign({}, affiliate, {
          payouts: Object.assign(
            {},
            { max: affiliate.payouts.max || 1 },
            {
              content: [
                data.payout,
                ...affiliate?.payouts?.content?.slice(0, -1),
              ],
            }
          ),
          balance: parseFloat(
            (affiliate.balance - data?.payout?.value).toFixed(2)
          ),
        })
      );
    }
  }

  async function change_code(code) {
    const new_code = await Affiliate.change_code({ code });

    if (new_code) {
      setAffiliate(
        Object.assign({}, affiliate, {
          code: Object.assign({}, code, { code: new_code }),
        })
      );
    }
  }

  return affiliate ? (
    <div id="AffiliateManage">
      <h2>Affiliate</h2>
      <div>
        <FlexGrid
          style={{ marginTop: "10px", marginBottom: "10px" }}
          children={[
            {
              style: { flex: "1 0 100%" },
              element: (
                <FlexGrid
                  style={{ maxWidth: "1500px", margin: "auto" }}
                  children={[
                    {
                      style: { flex: "1", padding: "10px" },
                      element: (
                        <CodeInfo
                          affiliate={affiliate}
                          add_payout={add_payout}
                          change_code={change_code}
                        />
                      ),
                    },
                    {
                      style: { padding: "10px" },
                      element: <Stats affiliate={affiliate} />,
                    },
                  ]}
                />
              ),
            },
          ]}
        />

        <Payouts affiliate={affiliate} />
      </div>
    </div>
  ) : null;
}

export default Index;
