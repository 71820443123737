import API from "../../index";

export default async (obj) => {
  let { status, data } = await API.post(
    "/api/orders/giftcard",
    Object.assign({}, obj.order, {
      products: obj.order.products.map((x) => x.id),
    })
  );
  if (status == 201) {
    return data;
  } else {
    return null;
  }
};
