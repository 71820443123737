import React from "react";

//STYLES
import "./style.css";

//COMPONENTS
import FlexArea from "../../layout/flex/FlexArea";

export default (props) => {
  return (
    <div
      className={"Icon" + (props.className ? " " + props.className : "")}
      style={props.style}
    >
      <FlexArea children={<img src={props.src} />} />
    </div>
  );
};
