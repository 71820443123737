import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//FUNCTIONS
import Inventory from "../../../../common/api/Inventory";

//COMPONENTS
import ContentList from "../../../core/ContentList";
import MultiSelectOptions from "../MultiSelectOptions";
import InventoryProduct from "../../products/InventoryProduct";
import PageControls from "../../../core/PageControls";
import ProductList from "../../products/ProductList";

import EmptyContent from "../../../common/EmptyPlaceholder";
import Btn from "../../../core/buttons/Btn";
import Background from "../../../common/backgrounds/Background";

function Index(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "POPULATE_PAGE_CONTENT", content: { page: "inventory" } });

    Inventory.search_inventory({
      query: null,
      offset: 0,
      items: true,
      coin: true,
    });
  }, []);

  let page_content = useSelector((state) =>
    state.page_content_reducer.page == "inventory"
      ? state.page_content_reducer
      : null
  );
  return page_content && !page_content.loading ? (
    <div id="Inventory">
      <div
        style={{
          position: "relative",
          borderTop: "0",
          borderRadius: "0",
          padding: "0",
          paddingTop: "20px",
          minHeight: "calc(100vh - var(--topOffset))",
        }}
      >
        {page_content ? (
          <div className="mainPadding">
            {page_content.query ? (
              <div
                style={{ position: "absolute", top: "0", left: "0" }}
                className="mainPadding"
              >
                <Btn
                  context={"Back"}
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                  onClick={() =>
                    Inventory.search_inventory({
                      query: null,
                      offset: 0,
                    })
                  }
                />
              </div>
            ) : null}

            {page_content.data.length ? (
              <ProductList
                products={page_content.data.map((x) => (
                  <InventoryProduct
                    product={x}
                    selected={page_content.selected.find((y) => x.id == y.id)}
                    show_selected={/*page_content.selected.length*/ true}
                  />
                ))}
              />
            ) : null}
          </div>
        ) : null}
        {page_content && page_content.max ? (
          <PageControls
            offset={page_content.offset}
            limit={page_content.max}
            onSelectPage={(offset) =>
              Inventory.search_inventory({
                query: null,
                order_by: page_content?.order_by,
                offset,
              })
            }
            pageLimit={25}
          />
        ) : (
          <EmptyContent
            context={"Won Products"}
            redirect={{ page: "Crates", route: "/" }}
          />
        )}
      </div>
    </div>
  ) : (
    <div id="Inventory">
      <div className="lds-loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Index;
