import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

//STYLES
import "./style.css";

//COMPONENTS
import FloatingBar from "../../../../../layout/FloatingBar";
import SuccessBtn from "../../../../../core/buttons/SuccessBtn";
import Btn from "../../../../../core/buttons/Btn";
import Background from "../../../../../common/backgrounds/Background";
import FlexGrid from "../../../../../layout/flex/FlexGrid";
import FlexArea from "../../../../../layout/flex/FlexArea";
import ProbabilityBar from "./elements/ProbabilityBar";

export default (props) => {
  const navigate = useNavigate();
  const crate_inventory = useSelector((state) => state.crate_inventory_reducer);
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <FloatingBar
      style={{
        top: "0",
        width: "100%",
      }}
      children={
        <div id="OptionsBar">
          <Background
            style={{ background: "var(--background)", opacity: "0.75" }}
          />
          <div className="mainPadding">
            <section
              style={{ position: "relative", display: "flex", width: "100%" }}
            >
              <FlexGrid
                style={{ justifyContent: "start" }}
                children={[
                  {
                    style: {
                      marginRight: "10px",
                    },
                    element:
                      props.unboxing.can_edit && !props.unboxing.is_editing ? (
                        <FlexArea
                          children={
                            <div style={{ margin: "auto", marginBottom: "0" }}>
                              <Link
                                to={
                                  (location.pathname.endsWith("/")
                                    ? location.pathname.slice(0, -1)
                                    : location.pathname
                                  ).replace("odds", "") +
                                  "/add" +
                                  (window.location.href.includes("?")
                                    ? "?" + window.location.href.split("?")[1]
                                    : "")
                                }
                              >
                                <SuccessBtn context={"Add Items"} />
                              </Link>
                            </div>
                          }
                        />
                      ) : null,
                  },
                  {
                    style: {
                      marginRight: "10px",
                    },
                    element:
                      props.unboxing.can_edit && !props.unboxing.is_editing ? (
                        <FlexArea
                          children={
                            <div style={{ margin: "auto", marginBottom: "0" }}>
                              <Link
                                to={
                                  (location.pathname.endsWith("/")
                                    ? location.pathname.slice(0, -1)
                                    : location.pathname
                                  ).replace("add", "") +
                                  "/odds" +
                                  (window.location.href.includes("?")
                                    ? "?" + window.location.href.split("?")[1]
                                    : "")
                                }
                              >
                                <Btn
                                  style={{
                                    background: "var(--mainPurple)",
                                    border: "1px solid var(--mainPurple)",
                                    boxShadow: "0 0 5px var(--mainPurple)",
                                  }}
                                  context={"Edit Items"}
                                />
                              </Link>
                            </div>
                          }
                        />
                      ) : null,
                  },

                  {
                    style: {
                      flex: "1",
                      minWidth: "300px",
                      paddingTop: "10px",
                    },
                    element: (
                      <div
                        style={{
                          position: "relative",
                          maxWidth: "800px",
                          margin: "auto",
                          marginRight: "0",
                        }}
                      >
                        <ProbabilityBar
                          is_editing={props.unboxing.is_editing}
                          products={crate_inventory.products}
                        />
                      </div>
                    ),
                  },
                  /*{
                    style: { marginLeft: "auto", paddingLeft: "10px" },
                    element: props.unboxing.is_editing ? (
                      <FlexArea
                        children={
                          <div
                            style={{
                              margin: "auto",
                              marginBottom: "0",
                              textAlign: "right",
                            }}
                          >
                            <SuccessBtn
                              style={{ display: "inline-block" }}
                              context={"Finish Editing"}
                              disabled={props.info.probability !== 100}
                              onClick={() =>
                                crate_inventory.isCustom
                                  ? navigate(
                                      "/unbox/?products=" +
                                        encodeURIComponent(
                                          JSON.stringify(
                                            crate_inventory.products.map(
                                              (x) => {
                                                return {
                                                  id: x.id,
                                                  prob: x.probability,
                                                };
                                              }
                                            )
                                          )
                                        )
                                    )
                                  : navigate(
                                      "/unbox/" + crate_inventory.crate.id
                                    )
                              }
                            />
                          </div>
                        }
                      />
                    ) : null,
                  },*/
                ]}
              />
            </section>
          </div>
        </div>
      }
    />
  );
};
