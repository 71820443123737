import React from "react";
import { Link } from "react-router-dom";

//CONFIG
import envConfig from "../../../../../../../common/config/env.config";

//COMPONENTS
import FlexGrid from "../../../../../../layout/flex/FlexGrid";
import Btn from "../../../../../../core/buttons/Btn";
import Background from "../../../../../../common/backgrounds/Background";
import FlexArea from "../../../../../../layout/flex/FlexArea";

export default (props) => {
  return (
    <FlexArea
      children={
        <FlexGrid
          children={[
            {
              style: { flex: "1", boxSizing: "border-box" },
              element: (
                <Link
                  to={
                    "/order/deliver?products=" +
                    encodeURIComponent(JSON.stringify(props.params.products))
                  }
                >
                  <Btn
                    style={{
                      width: "100%",
                      borderRadius: "0",
                      borderTopLeftRadius: "8px",
                      border:
                        props.params.method == "deliver"
                          ? "1px solid #2bbd73"
                          : "1px solid var(--highlighted)",
                      borderBottom: "0",
                      height: "100%",
                      padding: "0",
                    }}
                  >
                    {props.method == "deliver" ? (
                      <Background
                        style={{
                          background: "var(--FunctionGreen)",
                          opacity: "0.2",
                        }}
                      />
                    ) : null}
                    <div style={{ position: "relative" }}>
                      <img
                        src={envConfig.s3 + "/images/icons/deliver.svg"}
                        style={{
                          height: "25px",
                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                      />
                      <span
                        style={{
                          verticalAlign: "middle",
                          fontSize: "1rem",
                        }}
                      >
                        Deliver
                      </span>
                    </div>
                  </Btn>
                </Link>
              ),
            },
            {
              style: { flex: "1", boxSizing: "border-box" },
              element: (
                <Link
                  to={
                    "/order/giftcard?products=" +
                    encodeURIComponent(JSON.stringify(props.params.products))
                  }
                >
                  <Btn
                    style={{
                      width: "100%",

                      borderRadius: "0",
                      borderTopRightRadius: "8px",
                      border:
                        props.params.method == "giftcard"
                          ? "1px solid #f39224"
                          : "1px solid var(--highlighted)",
                      borderBottom: "0",
                      padding: "0px",
                      height: "100%",
                    }}
                  >
                    {props.method == "giftcard" ? (
                      <Background
                        style={{
                          background: "var(--crypto)",
                          opacity: "0.2",
                        }}
                      />
                    ) : null}
                    <div style={{ position: "relative" }}>
                      <img
                        src={envConfig.s3 + "/images/icons/giftcard.svg"}
                        style={{
                          width: "50px",
                          verticalAlign: "middle",
                          marginRight: "5px",
                        }}
                      />
                      <span
                        style={{
                          verticalAlign: "middle",
                          fontSize: "1rem",
                        }}
                      >
                        Gift Card
                      </span>
                    </div>
                  </Btn>
                </Link>
              ),
            },
          ]}
        />
      }
    />
  );
};
