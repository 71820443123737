export default {
  calculate_level: (xp, boundaries) => {
    let xp_offset = 0;
    for (var i = 0; i < boundaries.length; i++) {
      let x = checkboundaryGroup(xp, boundaries[i]);
      //IF MATCH RETURN LEVEL
      if (x !== undefined) {
        return x;
      }
    }

    function checkboundaryGroup(xp, group) {
      for (var i = group.level_min; i < group.level_max; i++) {
        if (xp >= xp_offset && xp < xp_offset + group.xp_per_level) {
          return i;
        }

        xp_offset += group.xp_per_level;
      }
    }
  },
  calculate_xp_to_level: (xp, current_level, boundaries) => {
    let xp_offset = 0;
    for (var i = 0; i < boundaries.length; i++) {
      if (
        current_level >= boundaries[i].level_min &&
        current_level < boundaries[i].level_max
      ) {
        let xp_left =
          boundaries[i].xp_per_level -
          (xp -
            xp_offset -
            boundaries[i].xp_per_level *
              (current_level - boundaries[i].level_min));
        return {
          xp: xp_left,
          level_boundary_amount: boundaries[i].xp_per_level,
          progressed: boundaries[i].xp_per_level - xp_left,
          percent: parseFloat(
            ((xp_left / boundaries[i].xp_per_level) * 100).toFixed(2)
          ),
        };
      }

      xp_offset +=
        (boundaries[i].level_max - boundaries[i].level_min) *
        boundaries[i].xp_per_level;
    }

    return 0;
  },
};
