//STORE
import store from "../../redux/store";

//FUNCTIONS
import UnboxingFunctions from "../../../components/content/unbox/functions/Unboxing.functions";
import ControlFunctions from "../../../components/content/unbox/Carousel/functions/Control.Functions";

//API
import add_unboxing from "./server/add_unboxing";
import add_daily_unboxing from "./server/add_daily_unboxing";
import get_unboxing_seeds from "./server/get_unboxing_seeds";
import reveal_server_seed from "./server/reveal_server_seed";

export default {
  add_unboxing: async () => {
    const animation_lock = store.getState().animation_lock_reducer;

    if (!animation_lock) {
      let crate_inventory = store.getState().crate_inventory_reducer;
      let unboxing_session = store.getState().unboxing_session_reducer;

      let unboxing_cost = UnboxingFunctions.calculate_crate_info(
        crate_inventory.products
      ).cost;

      let data = await add_unboxing({
        crate_inventory,
        unboxing_session,
        unboxing_cost,
      });

      if (data) {
        new_unbox_received(crate_inventory, data);
      } else {
        store.dispatch({ type: "SET_ANIMATION_LOCK", locked: false });
      }
    }
  },
  add_daily_unboxing: async () => {
    let crate_inventory = store.getState().crate_inventory_reducer;
    let unboxing_session = store.getState().unboxing_session_reducer;

    let data = await add_daily_unboxing({
      crate_id: crate_inventory?.crate.id,
      unboxing_session,
    });

    //SET TIMER OF DAILY CRATE
    store.dispatch({
      type: "UPDATE_CRATE",
      crate: { time_locked_until: data.timestamp },
    });

    if (store.getState().page_content_reducer?.page == "rewards") {
      store.dispatch({
        type: "UPDATE_PAGE_CONTENT_ELEMENT",
        element: {
          id: crate_inventory.crate.id,
          time_locked_until: data.timestamp,
        },
      });
    }

    new_unbox_received(crate_inventory, data);
  },
  get_unboxing_seeds: async (obj) => {
    return await get_unboxing_seeds(obj);
  },
  reveal_server_seed: async (obj) => {
    return await reveal_server_seed(obj);
  },
};

//AFTER UNBOXING RECEIVED
function new_unbox_received(crate_inventory, data) {
  let winning_product = crate_inventory.products.find(
    (x) => x.id == data.winning_product_id
  );
  store.dispatch({
    type: "UPDATE_ACCOUNT_BALANCE",
    account_balance: data.new_balance,
  });

  if (store.getState().page_content_reducer.page == "inventory") {
    store.dispatch({
      type: "ADD_PAGE_CONTENT_ELEMENT",
      element: winning_product,
    });
  }

  if (data.new_hashed_server_seed) {
    store.dispatch({
      type: "UPDATE_UNBOXING_HASHED_SERVER_SEED",
      hashed_server_seed: data.new_hashed_server_seed,
    });
  }

  //SET UNBOXING CAROUSEL
  ControlFunctions.init({
    products: crate_inventory.products,
    unboxing: {
      winning_product: Object.assign({}, winning_product, {
        account_product_id: data.account_product_id,
      }),
      xp_amount: data.xp_amount,
      roll: data.roll,
    },
    type: "unbox",
  });
}
