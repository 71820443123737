import React, { Component } from "react";
import { connect } from "react-redux";

//COMPONENTS
import Product from "../Product";

class index extends Component {
  handleAddToCrate = (product) => {
    this.props.populate_crate_inventory({
      crate: null,
      products: this.props.crate_inventory.products,
    });
    let no_added = this.props.crate_inventory.products.filter(
      (x) => x.custom_index !== null
    ).length;
    this.props.add_unboxing_product(
      Object.assign({}, product, {
        product_id: product.id,
        custom_index: no_added,
        probability: 1,
      })
    );
  };

  render() {
    let product = this.props.product;
    let in_crate = this.props.crate_inventory.products.find(
      (x) => x.id == product.id
    )
      ? true
      : false;

    return (
      <Product
        onClick={(product) =>
          in_crate
            ? this.props.remove_unboxing_product({ product_id: product.id })
            : this.handleAddToCrate(product)
        }
        onSuccessProduct={(product) => this.handleAddToCrate(product)}
        selected={in_crate}
        show_selected={this.props.crate_inventory.products.length}
        context={"Add to Crate"}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    crate_inventory: state.crate_inventory_reducer,
  };
};

const mapDisatchToProps = (dispatch) => {
  return {
    add_unboxing_product: (product) => {
      dispatch({ type: "ADD_UNBOXING_PRODUCT", product });
    },
    remove_unboxing_product: (product) => {
      dispatch({ type: "REMOVE_UNBOXING_PRODUCT", product });
    },
    populate_crate_inventory: (crate_inventory) => {
      dispatch({ type: "POPULATE_CRATE_INVENTORY", crate_inventory });
    },
  };
};

export default connect(mapStateToProps, mapDisatchToProps)(index);
