import React from "react";
import { Helmet } from "react-helmet-async";

//CONFIG
import envConfig from "../../common/config/env.config";

//COMPONENTS
import Login from "../../components/common/auth/Login";

export default (props) => {
  return (
    <>
      {/*<Helmet>
        <title>Login | Vivavim</title>
        <meta name="description" content="Login to your vivavim account" />
        <link rel="canonical" href={envConfig.server} />
      </Helmet>*/}
      <Login {...props} />
    </>
  );
};
