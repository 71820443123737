import React from "react";
import { useDispatch } from "react-redux";

//CONFIG
import envConfig from "../../../../../../../../common/config/env.config";

//FUNCTIONS
import GeneralFunctions from "../../../../../../../../common/functions/GeneralFunctions";

//COMPONENTS
import Background from "../../../../../../../common/backgrounds/Background";
import FlexArea from "../../../../../../../layout/flex/FlexArea";
import FlexGrid from "../../../../../../../layout/flex/FlexGrid";
import SuccessBtn from "../../../../../../../core/buttons/SuccessBtn";
import Btn from "../../../../../../../core/buttons/Btn";

//POPUPS
import CreatePayout from "../Payouts/popups/CreatePayout";
import NewPromoCode from "../PromoCode/popups/NewPromoCode";

export default (props) => {
  const dispatch = useDispatch();
  return (
    <div
      className="highlighted"
      style={{ position: "relative", height: "100%" }}
    >
      <FlexGrid
        style={{ height: "100%" }}
        children={[
          {
            style: { flex: "1 0 40%", padding: "5px" },
            element: (
              <FlexGrid
                style={{ flexDirection: "column", height: "100%" }}
                children={[
                  {
                    style: {},
                    element: (
                      <div>
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          Balance
                        </span>
                      </div>
                    ),
                  },
                  {
                    style: { flex: "1", minWidth: "215px", minHeight: "100px" },
                    element: (
                      <FlexArea
                        children={
                          <div style={{ margin: "auto" }}>
                            <Background
                              style={{
                                background: "var(--background",
                                borderRadius: "8px",
                                border: "2px solid var(--gold)",

                                borderBottomRightRadius: "0",
                                borderBottomLeftRadius: "0",
                              }}
                            />

                            <img
                              id="vivacoinIcon"
                              style={{
                                position: "relative",

                                verticalAlign: "middle",
                                marginRight: "5px",
                                width: "30px",
                              }}
                              src={
                                envConfig.s3 +
                                "/images/icons/common/vivacoin.svg"
                              }
                            />
                            <span
                              style={{
                                position: "relative",
                                verticalAlign: "middle",
                                fontSize: "1.4rem",
                                fontWeight: "bold",
                              }}
                            >
                              {GeneralFunctions.parse_currency(
                                props.affiliate.balance
                              )}
                            </span>
                          </div>
                        }
                      />
                    ),
                  },
                  {
                    style: { textAlign: "right" },
                    element: (
                      <Btn
                        context={"Withdraw"}
                        onClick={() =>
                          dispatch({
                            type: "NEW_POPUP",
                            element: (
                              <CreatePayout
                                value={props.affiliate.balance}
                                add_payout={props.add_payout}
                              />
                            ),
                          })
                        }
                        style={{
                          position: "relative",
                          width: "100%",
                          border: "2px solid var(--FunctionGreen)",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderTop: "0",
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                        }}
                        children={
                          <Background
                            style={{
                              background: "var(--FunctionGreen)",
                              opacity: "0.2",
                            }}
                          />
                        }
                      />
                    ),
                  },
                ]}
              />
            ),
          },
          {
            style: { flex: "1 0 60%", padding: "5px" },
            element: (
              <FlexGrid
                style={{ flexDirection: "column", height: "100%" }}
                children={[
                  {
                    style: {},
                    element: (
                      <div>
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          Your Code
                        </span>
                      </div>
                    ),
                  },
                  {
                    style: { flex: "1", marginTop: "10px" },
                    element: (
                      <FlexArea
                        children={
                          <div style={{ margin: "auto", marginLeft: "0" }}>
                            <div
                              style={{
                                background: "var(--background)",
                                borderRadius: "8px",
                                width: "100%",
                                padding: "5px",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                border: "2px solid var(--FunctionGreen)",
                                boxSizing: "border-box",
                              }}
                            >
                              <span
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "1.4rem",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                {props.affiliate.code.code}
                              </span>
                            </div>
                            <p>Earn commission from deposits using this code</p>
                          </div>
                        }
                      />
                    ),
                  },
                  {
                    style: {},
                    element: (
                      <div style={{ textAlign: "right" }}>
                        <Btn
                          context={"Copy Link"}
                          style={{
                            border: "2px solid var(--FunctionGreen)",
                            marginRight: "5px",
                          }}
                          children={
                            <Background
                              style={{
                                background: "var(--FunctionGreen)",
                                opacity: "0.2",
                              }}
                            />
                          }
                        />
                        <Btn
                          context={"Change Code"}
                          onClick={() =>
                            dispatch({
                              type: "NEW_POPUP",
                              element: (
                                <NewPromoCode
                                  code={props.affiliate.code.code}
                                  change_code={props.change_code}
                                />
                              ),
                            })
                          }
                          style={{
                            border: "2px solid var(--error)",
                          }}
                          children={
                            <Background
                              style={{
                                background: "var(--error)",
                                opacity: "0.2",
                              }}
                            />
                          }
                        />
                      </div>
                    ),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
