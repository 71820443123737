//STORE
import store from "../../redux/store";

//FUNCTIONS
import Cookies from "../../Cookies";

import auth_user from "./server/auth_user";
import token_auth from "./server/token_auth";
import logout_user from "./server/logout_user";
import create_user from "./server/create_user";
import email_confirmed from "./server/email_confirmed";
import password_reset_confirm from "./server/password_reset_confirm";
import reset_password from "./server/reset_password";
import email_password_reset from "./server/email_password_reset";

export default {
  auth_user: async (object, onError) => {
    let { data, error } = await auth_user(object);
    if (!error && data) {
      loginUser(data);
    } else {
      onError(error);
    }
  },
  token_auth: async (callback) => {
    let session = store.getState().session_reducer;
    let session_token = Cookies.get("session-token");
    let refresh_token = Cookies.get("refresh-token");

    if (!session && session_token && refresh_token) {
      let { data, error } = await auth_user({
        session_token,
        refresh_token,
      });
      if (!error && data) {
        loginUser(data);
      } else {
        //onError(error);
      }
    }

    callback();
  },
  logout_user: async () => {
    if (await logout_user()) {
      store.dispatch({ type: "UPDATE_ACCOUNT_BALANCE", account_balance: 0 });
      store.dispatch({
        type: "POPULATE_UNBOXING_SESSION",
        unboxing_session: null,
      });
      store.dispatch({ type: "CLEAR_ACCOUNT_LEVEL" });
      store.dispatch({ type: "LOGOUT" });
      store.dispatch({ type: "CLEAR_ACCOUNT_DROPDOWN" });

      Cookies.remove("session-token");
      Cookies.remove("refresh-token");
    }
  },
  create_user: async (obj) => {
    let data = await create_user(obj);
    return data;
  },
  email_confirmed: async (obj) => {
    const data = await email_confirmed(obj);

    return data;
  },
  password_reset_confirm: async (obj) => {
    const data = await password_reset_confirm(obj);

    return data;
  },
  reset_password: async (obj) => {
    const data = await reset_password(obj);

    return data;
  },
  email_password_reset: async (obj) => {
    const data = await email_password_reset(obj);
    return data;
  },
};

function loginUser(data) {
  const session = {
    session_token: data.session.session_token,
    refresh_token: data.session.refresh_token,
    user: data.user,
  };

  //REDUX DISPATCHES
  store.dispatch({ type: "LOGIN", session });
  store.dispatch({
    type: "UPDATE_ACCOUNT_BALANCE",
    account_balance: data.balance,
  });
  store.dispatch({
    type: "POPULATE_UNBOXING_SESSION",
    unboxing_session: data.unboxing_session,
  });
  store.dispatch({
    type: "POPULATE_ACCOUNT_LEVEL",
    object: { xp: data.total_xp },
  });

  console.log(data.dropdown);
  store.dispatch({
    type: "POPULATE_ACCOUNT_DROPDOWN",
    object: data.dropdown,
  });

  //SET COOKIES
  Cookies.set("session-token", session.session_token);
  Cookies.set("refresh-token", session.refresh_token);
}
